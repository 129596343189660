<!-- Image -->
<div class="avatar avatar-sm card-avatar">
  <span class="avatar-title rounded-circle text-wait" style="font-size: 1.2rem;">
    <i class="fa-duotone text-info fa-beat fa-circle-question"></i>
  </span>
</div>
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader"> Help </h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline"> default help drawer </p>
<!-- Divider -->
<hr class="mb-4">
<h4 class="mt-2 mb-2"> This is default help drawer </h4>
<ol>
  <li class="text-muted mb-2">See the <a href="https://docs.openbridge.com/en/" rel="noopener" target="_blank">help
      docs</a> for guides, tips, and troubleshooting
  </li>
</ol>