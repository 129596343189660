<div class="modal-header">
  <h4 class="modal-title pull-left">Create refresh token.</h4>
  <button type="button" class="btn-close pull-right" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <!-- <form class="tab-content"> -->
  <form class="tab-content" novalidate [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="dbHost" class="mb-2">Refresh token name</label>
          <input type="text" class="form-control" id="refreshTokenName" formControlName="refreshTokenName"
            aria-describedby="refreshTokenNameHelp" placeholder="Refresh token name" [ngClass]="{
              'is-invalid': formGroup.controls.refreshTokenName.invalid && formGroup.controls.refreshTokenName.touched,
              'is-valid': formGroup.controls.refreshTokenName.valid && formGroup.controls.refreshTokenName.touched
            }" formControlName="refreshTokenName">
          <div
            *ngIf="formGroup.controls.refreshTokenName.invalid && (formGroup.controls.refreshTokenName.dirty || formGroup.controls.refreshTokenName.touched)"
            class="invalid-feedback">
            A name is required.
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <button type="submit" class="btn btn-primary">Create</button>
          <!-- <button type="button" class="btn btn-danger m-2" (click)="cancel()">Cancel</button> -->
        </div>
      </div>
    </div>
  </form>
</div>