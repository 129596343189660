import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IdentityService } from 'src/app/core/services/identity.service';
import { ImageServiceService } from 'src/app/shared/services/image-service.service';

@Component({

  selector: '[appPickListCard]',
  templateUrl: './pick-list-card.component.html'
})
export class PickListCardComponent implements OnInit {
  @Input() cardData;
  @Input() parentForm = UntypedFormGroup;
  @Input() controlName: string;
  formControlValue: string = '';


  constructor(
    public imageServiceService: ImageServiceService,
    protected identityService: IdentityService
  ) { }

  ngOnInit(): void {
    if (this.parentForm && this.parentForm['controls'] && this.parentForm['controls']['identity']) {
      this.formControlValue = this.parentForm['controls']['identity'].value;
    }
  }

  async reauthIdentity(identityId: any): Promise<void> {
    const identity = this.identityService.getIdentityById(identityId);
    this.identityService.reauthOauthIdentity(identity, window.location.href)
  }
}
