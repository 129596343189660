import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseWizardService } from '../../base-wizard.service';
import { EncryptionService } from '../../../../core/services/encryption.service';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { identityTypeIdGoogleBigquery } from '../../../../shared/constants/identity-ids';
import { v4 as uuidv4 } from 'uuid';
import { Md5 } from 'ts-md5/dist/md5';
import { wizardIntegrationChecklist, wizardIntegrationConfig,
         wizardIntegrationStagelessConfig, wizardIntegrationSave } from '../../../../core/store/actions/wizard.actions';
import { errorWizardGeneralError, errorWizardIdentityExists } from '../../../../shared/constants/flash-notifications';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/store/reducers';
import { identityCreate } from '../../../../core/store/actions/identities.actions';
import { IdentityService } from '../../../../core/services/identity.service';
import { ConfigGoogleBigquery } from '../../../../shared/models/product-configuration.types';
import { modalsClose, modalsUpdate } from '../../../../core/store/actions/modals.action';
import { DateTimeService } from '../../../../core/services/date-time.service';
import { observableToPromise } from '../../../../core/functions/utility';

@Injectable({
  providedIn: 'root'
})
export class GoogleBigqueryService extends BaseWizardService {
  identityState$: any;
  baseConfigState: ConfigGoogleBigquery = {
    integrationName: null,
    remoteIdentityId: null,
    serviceAccountJson: null,
    projectId: null,
    datasetId: null
  };

  constructor(
    protected httpClient: HttpClient,
    protected store$: Store<AppState>,
    protected router: Router,
    protected encryptService: EncryptionService,
    protected identityService: IdentityService,
    protected datetimeService: DateTimeService
  ) {
    super(httpClient, store$);
    this.stages = [
      'checklist',
      'identity',
      'details',
      'integrationName',
      'save'
    ];
  }

  doProcessCheckList(): void {

    // Set the next stage index
    this.nextStageIndex();
    const nextStage =  this.stages[this.stageIndex];

    // Dispatch
    this.store$.dispatch(wizardIntegrationChecklist({
      checklist: true,
      stage: nextStage
    }));
  }

  // Fix the type later...
  doProcessConfig(config: any): void {

    if (this.stages[(this.stageIndex + 1)] === 'save') {
      // Dispatch
      this.store$.dispatch(wizardIntegrationStagelessConfig({
        config: config
      }));

      this.store$.dispatch(wizardIntegrationSave());
      return;
    }

    this.nextStageIndex();
    const nextStage =  this.stages[this.stageIndex];

    // Dispatch save integration.
    this.store$.dispatch(wizardIntegrationConfig({
      config: config,
      stage: nextStage
    }));

  }

  async getProfilesAndDatasetsFromIdentity(identityId: string): Promise<void> {
    const delay = ms => new Promise(res => setTimeout(res, ms));
    try {
      this.stageDataRequestState$.next({state: 'data-load-start', payload: null});
      if (environment.enableMock) {
        await delay(2000);
      }
      // make payload request.
      if (environment.enableMock) {
        await delay(2000);
      }
      this.stageDataRequestState$.next({state: 'data-load-complete', payload: null});
    }
    catch (error) {
      this.stageDataRequestState$.next({state: 'data-load-complete', payload: error});
    }
  }

  async createBigQueryIdentityFromServiceAccount(serviceAccountJsonString: string): Promise<void> {

    const md5 = new Md5();
    // const authState = await this.store$.select('auth').pipe(take(1)).toPromise();
    // This one worries me because I don't know how well it'll work with a pipe and take function call.
    const authState = await observableToPromise(this.store$.select('auth').pipe(take(1)));
    const delay = ms => new Promise(res => setTimeout(res, ms));

    this.store$.dispatch(modalsUpdate({
      modalType: 'progress',
      title: 'Validating Service Account',
      message: 'Uploading your service account file.',
      progress: 33
    }));

    if (environment.enableMock) {
      await delay(2000);
    }

    const serviceAccountJSON = JSON.parse(serviceAccountJsonString);

    this.store$.dispatch(modalsUpdate({
      modalType: 'progress',
      title: 'Validating Service Account',
      message: 'Checking if duplicate',
      progress: 45
    }));

    if (!this.identityService.identityExists(identityTypeIdGoogleBigquery, serviceAccountJSON['private_key_id'])){
      try {

        this.store$.dispatch(modalsUpdate({
          modalType: 'progress',
          title: 'Validating Service Account',
          message: 'Validating the your Service Account credentials.',
          progress: 66
        }));

        if (environment.enableMock) {
          await delay(2000);
        }

        const serviceAccountResponse = await this.getProfilesAndDatasetsFromServiceAccount(serviceAccountJsonString);

        const encodedServiceAccount = await this.encodeServiceAccount(serviceAccountJsonString);

        this.store$.dispatch(modalsUpdate({
          modalType: 'progress',
          title: 'Validating Service Account',
          message: 'Saving identity record.',
          progress: 66
        }));

        const remoteIdentityPayload: PayloadRemoteIdentity = {
          data: {
            type: 'RemoteIdentity',
            attributes: {
              remote_identity_type: identityTypeIdGoogleBigquery,
              name: serviceAccountJSON['client_email'],
              account: +authState.currentAccount.accountId,
              user: +authState.currentAccount.userId,
              identity_hash: uuidv4(),
              remote_unique_id: serviceAccountJSON['private_key_id'],
              remote_identity_meta_attributes: [
                {
                  remote_identity_type_meta_key: 10,
                  meta_value: encodedServiceAccount['data']['attributes']['serviceAccount'],
                  meta_format: 'ENCRYPTED_STRING'
                }
              ],
              region: 'global'
            }
          }
        };

        const identityResponse = await observableToPromise(this.httpClient.post(`${environment.openbridgeApiUris.identities}/ri`, remoteIdentityPayload));


        const createPayload = {
          id: identityResponse['data'].id,
          identityHash: identityResponse['data'].attributes.identity_hash,
          name: identityResponse['data'].attributes.name,
          userId: identityResponse['data'].attributes.user_id,
          firstName: identityResponse['data'].relationships.user.first_name,
          lastName: identityResponse['data'].relationships.user.last_name,
          remoteIdentityTypeId: +identityResponse['data'].relationships.remote_identity_type.data.id,
          invalidIdentity: identityResponse['data'].attributes.invalid_identity,
          createdAt: identityResponse['data'].attributes.created_at,
          createdAtEpoch: (this.datetimeService.convertTimeStampToEpoch(identityResponse['data'].attributes.created_at+'+00:00')),
          modifiedAt: identityResponse['data'].attributes.modified_at,
          region: identityResponse['data'].attributes.region,
          email: identityResponse['data'].attributes.email
        };

        this.store$.dispatch(identityCreate(createPayload));

        // make payload request.
        if (environment.enableMock) {
          await delay(2000);
        }

        this.store$.dispatch(modalsUpdate({
          modalType: 'progress',
          title: 'Validating Service Account',
          message: 'Service account validated and saved.',
          progress: 100
        }));

        this.store$.dispatch(modalsClose());

      }
      catch (error) {
        await delay(500);
        this.store$.dispatch(modalsClose());
        this.doProcessErrorWithDetails(errorWizardGeneralError, JSON.stringify(error.error));
      }
    }
    else {
      await delay(500);
      this.store$.dispatch(modalsClose());
      this.doProcessError(errorWizardIdentityExists);
    }
  }

  async getProfilesAndDatasetsFromServiceAccount(serviceAccountJsonString: string): Promise<any> {
    const encodedServiceAccountJsonString = window.btoa(serviceAccountJsonString);

    const payload = {
      "data": {
        "attributes": {
          "service_account_json": encodedServiceAccountJsonString
        }
      }
    };    

    return await observableToPromise(this.httpClient.post(`${environment.openbridgeApiUris.service}/service/bq/validate-acct`, payload));
  }

  async encodeServiceAccount(serviceAccountJsonString: string): Promise<any> {
    const encodedResponse = await this.encryptService.encryptStringList({serviceAccount: serviceAccountJsonString});
    return encodedResponse;
  }

  initializeConfigState(wizardMode: string, subscription: any, spm: any): any {
    super.initializeConfigState(wizardMode, subscription, spm);

    // I do not know why we have !spm !=== null, it bothers me, but it works, so i'm just going to keep it for now.
    if (typeof subscription === 'undefined' && !spm !== null) {
      return {
        ...this.baseConfigState,
        wizardMode: wizardMode,
        subscriptionId: null
      };
    }

    const spmInfo = this.buildObjectFromSpmDataArray(spm.data);

    const configState = {
      ...this.baseConfigState,
      wizardMode: wizardMode,
      subscriptionId: subscription.id,
      integrationName: subscription.name,
      remoteIdentityId: spmInfo.remote_identity_id,
      serviceAccountJson: '',
      projectId: spmInfo.project_id,
      datasetId: spmInfo.dataset_id
    };

    return configState;
  }
}

interface PayloadRemoteIdentity {
  data: {
    type: string;
    attributes: {
      remote_identity_type: number;
      name: string;
      account: number;
      user: number;
      identity_hash: string;
      remote_unique_id: string;
      remote_identity_meta_attributes: PayloadRemoteIdentityMeta[];
      region: string;
    }
  };
}

interface PayloadRemoteIdentityMeta {
  remote_identity_type_meta_key: number;
  meta_value: string;
  meta_format: string;
}
