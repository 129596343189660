import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { updateSelectedPipeline } from 'src/app/core/store/actions/subscriptions.actions';
import { AppState } from 'src/app/core/store/reducers';

@Component({
  selector: 'app-pipeline-input',
  templateUrl: './pipeline-input.component.html',
  styleUrls: ['./pipeline-input.component.scss']
})
export class PipelineInputComponent implements OnInit, OnChanges {
  @Input() pipelineId: number;
  @Input() selectedPipelineList: any[];
  @Output() isSelectedMenu = new EventEmitter<any>();

  isSelected = false;

  constructor(
    private store$: Store<AppState>,
  ) { }

  ngOnInit(): void {
    this.selectedPipelineList.filter(val => {
      if (val.id === this.pipelineId) {
        this.isSelected = val.isSelected;
      }
    });
  }

  ngOnChanges(): void {
    this.selectedPipelineList.filter(val => {
      if (val.id === this.pipelineId) {
        this.isSelected = val.isSelected;
      }
    });
  }

  check($event): void {
    const filterPipeline = {
      id: this.pipelineId,
      isSelected: $event.target.checked
    };
    this.store$.dispatch(updateSelectedPipeline({
      selectedPipeline: filterPipeline
    }));
  }
}
