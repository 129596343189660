import { Component, Input, OnInit } from '@angular/core';
import { Integration } from 'src/app/shared/models/integration.model';
import { ImageServiceService } from 'src/app/shared/services/image-service.service';

@Component({
  selector: 'app-help-csv-batch-loader-tester',
  templateUrl: './help-csv-batch-loader-tester.component.html',
})
export class HelpCsvBatchLoaderTesterComponent implements OnInit {

  @Input() integration: Integration;

  constructor(
    public imageServiceService: ImageServiceService
  ) { }

  ngOnInit(): void {
  }

}
