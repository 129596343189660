<div class="modal-header">
  <h1 class="modal-title pull-left">Dataset Template Deletion</h1>
  <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="mb-1">
    <p>When a template is deleted globally:</p>
    <div>
      <ol>
        <li>It remains attached to existing subscriptions to prevent errors in previously configured datasets.</li>
        <li>The deleted template will only be removed from a subscription if:
          <ul>
            <li>It is manually unselected, and</li>
            <li>The subscription is then saved again.</li>
          </ul>
        </li>
      </ol>      
    </div>
    <p>This ensures continuity for previously configured subscriptions while allowing for template management.</p>
  </div>
</div>
<!-- <div class="modal-footer">
  <button class="btn btn-primary" routerLink="/pipelines" (click)="bsModalRef.hide()">
    Review Active Pipelines <i class="fe fe-arrow-right-circle ms-2"></i>
  </button>
</div> -->