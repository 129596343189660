<div class="row">
  <div class="col-12">
    <!-- Card -->
    <div class="card">
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col">
            <!-- Heading -->
            <span class="h3 mb-0"> Activate New Google Service Account </span>
            <!-- Text -->
            <p class="card-text small text-muted mb-1">In Google Cloud console, go to
              <code>IAM & admin > Service accounts</code>. Next, <code>Create service account</code> and assign
              permissions <code>XXXX, XXXXX</code> Lastly, create and export your JSON key for upload here.
            </p>
          </div>
          <div class="col-auto">
            <div class="form-group">
              <!-- Card -->
              <div class="card">
                <button type="button" class="btn btn-secondary" (click)="createIdentity()">Link shopify store.</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>