import { Component, Input, OnInit } from '@angular/core';
import { Integration } from 'src/app/shared/models/integration.model';
import { ImageServiceService } from 'src/app/shared/services/image-service.service';

@Component({
  selector: 'app-help-facebook-common-details',
  templateUrl: './help-facebook-common-details.component.html',
})
export class HelpFacebookCommonDetailsComponent implements OnInit {
  @Input() integration: Integration;

  constructor(
    public imageServiceService: ImageServiceService
  ) { }

  ngOnInit(): void {
  }

}
