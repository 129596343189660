<div class="row">
  <div class="col-12 col-xl-12">
    <div class="card">

      <div class="card-header">
        <!-- Title -->
        <h3 class="card-header-title">Daily Active Pipelines</h3>
      </div>

      <div class="card-body" *ngIf="dailyPipelines == null">
        <ng-container *ngIf="notifications.length > 0;else dataError">
          <app-flash-notifications></app-flash-notifications>
        </ng-container>
        <ng-template #dataError>
          <p class="text-center">
            Loading daily pipeline usage...
          </p>
        </ng-template>
      </div>


      <div class="card-body pb-0" *ngIf="dailyPipelines != null">
        <!-- Chart -->
        <div class="chart">
          <!-- it is important to style the containing object -->
          <app-chart-bar 
            [chartLabels]="barChartLabels" 
            [chartDataSets]="barChartDataSets"
            [chartTickLimit]="barChartTickLimit" 
            [chartId]="barChartId"
            chartClass="chart-canvas chartjs-render-monitor" 
            chartHeight="600"
            chartStyle="display: block; height: 100%; width: 100%;" 
            chartWidth="1372">
          </app-chart-bar>
        </div>
      </div>

    </div>
  </div>
</div>