import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { copyDetails } from 'src/app/core/functions/utility';

@Component({
  selector: 'app-modal-error-details',
  templateUrl: './modal-error-details.component.html'
})
export class ModalErrorDetailsComponent implements OnInit {
  details: string;
  copyMessage = copyDetails;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }
}
