<!-- Image -->
<div class="avatar avatar-sm card-avatar">
  <img src="{{integration.logoPath}}" class="avatar-img rounded" alt="..."
    (error)="imageServiceService.handleMissingImage($event)">
</div>
<!-- <div class="avatar avatar-sm card-avatar">
  <span class="avatar-title rounded-circle text-wait" style="font-size: 1.2rem;">
    <i class="fa-duotone fa-file-csv"></i>
  </span>
</div> -->
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader"> Getting Started </h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline"> Key considerations for CSV File Validation & Schema Generation </p>
<!-- Divider -->
<hr class="mb-4">
<h4 class="mt-2 mb-2"> Openbridge, Inc. </h4>
<ol>
  <li class="text-muted mb-2">
    We want to help simplify working with data, especially common formats like <strong>CSV.</strong> This includes
    providing tools that
    help validate <strong>CSV</strong> files for compliance with established norms such as <a
      href="https://www.rfc-editor.org/rfc/rfc4180" rel="noopener" target="_blank" class="light-link">RFC4180.</a>
  </li>

  <li class="text-muted mb-2">
    Interested in automated data processing and loading of <strong>CSV</strong> to a destination warehouse? Sign up for a <a
      href="https://www.openbridge.com/register" rel="noopener" target="_blank" class="light-link">free account at
      Openbridge.</a>
  </li>
</ol>

<h4 class="mt-2 mb-2"> Validation API </h4>
<ol>
  <li class="text-muted mb-2">
    <a href="https://github.com/openbridge/ob_file_validation#getting-started-apiusage" rel="noopener" target="_blank"
      class="light-link">
      Getting Started with the API
    </a>
  </li>
  <li class="text-muted mb-2">
    <a href="https://github.com/openbridge/ob_file_validation#client-code-bash-and-python" rel="noopener"
      target="_blank" class="light-link">
      Client Code: Bash and Python
    </a>
  </li>

  <li class="text-muted mb-2">
    <a href="https://github.com/openbridge/ob_file_validation#troubleshooting-problem-files-common-use-cases"
      rel="noopener" target="_blank" class="light-link">
      Troubleshooting Problem Files: Common Use Cases
    </a>
  </li>
</ol>

<h4 class="mt-2 mb-2"> Automation </h4>
<ol>
  <li class="text-muted mb-2">
    <a href="https://www.openbridge.com/register" rel="noopener" target="_blank"
      class="light-link">
      Sign up for free account at Openbridge  
    </a>
  </li>
  <li class="text-muted mb-2">
    <a href="https://www.openbridge.com/data-pipeline/batch" rel="noopener"
      target="_blank" class="light-link">
      Automated CSV data pipelines
    </a>
  </li>
  <li class="text-muted mb-2">
    <a href="https://www.openbridge.com/integrations/"
      rel="noopener" target="_blank" class="light-link">
      Data Connectors
    </a>
  </li>
  <li class="text-muted mb-2">
    <a href="https://www.openbridge.com/expert-services"
      rel="noopener" target="_blank" class="light-link">
      Consulting
    </a>
  </li>
</ol>

<h4 class="mt-2 mb-2"> Examples </h4>
<ol>
  <li class="text-muted mb-2">
    <a href="https://blog.openbridge.com/how-to-setup-a-batch-data-pipeline-for-csv-files-8c4d0cd7394b" rel="noopener" target="_blank"
      class="light-link">
      How To Setup A Batch Data Pipeline For CSV Files To Redshift, Redshift Spectrum, Athena or BigQuery  
    </a>
  </li>
  <li class="text-muted mb-2">
    <a href="https://blog.openbridge.com/how-to-do-csv-file-validation-and-schema-generation-43a9cb78e1eb" rel="noopener"
      target="_blank" class="light-link">
      How To Do CSV File Validation And Schema Generation
    </a>
  </li>
  <li class="text-muted mb-2">
    <a href="https://blog.openbridge.com/how-to-drag-and-drop-csv-files-directly-to-google-bigquery-amazon-redshift-and-athena-639f229f6828"
      rel="noopener" target="_blank" class="light-link">
      How To Drag And Drop CSV Files Directly To Google BigQuery, Amazon Redshift and Athena
    </a>
  </li>
  <li class="text-muted mb-2">
    <a href="https://blog.openbridge.com/export-tracking-data-from-salesforce-marketing-cloud-8a0a4c1f37dc"
      rel="noopener" target="_blank" class="light-link">
      How To Export Email Tracking Data From ExactTarget (Salesforce Marketing Cloud)
    </a>
  </li>
</ol>