<div class="modal-body">
  <div class="row align-items-center">

    <div class="col-12">
      <div class="text-center">
        <div class="avatar avatar-lg">
          <img class="avatar-img rounded-circle bg-white" style="padding: 3px;" src="{{ data.productId | logoPathFromProductId:data.subProductId }}"
            alt="{{ data.productId | integrationNameFromProductId: data.subProductId }}"
            title="{{ data.productId | integrationNameFromProductId: data.subProductId }}"
            (error)="imageServiceService.handleMissingImage($event)">
        </div>
        <h4 class="mt-3 mb-5">
          {{data.productName}}
        </h4>
      </div>
    </div>

    <div class="col-12">
      <div class="list-group list-group-flush my-n3">


        <div class="list-group-item">
          <div class="row align-items-center">
            <div class="col">
              <h5 class="mb-0">
                Subscription Id:
              </h5>
            </div>
            <div class="col-auto">
              <span class="small text-muted" id="subscriptionId">
                {{ data.id | number:'8.0' | removeComma }}
              </span>
            </div>
          </div>
        </div>

        <div class="list-group-item">
          <div class="row align-items-center">
            <div class="col">
              <h5 class="mb-0">
                Remote Identity Id:
              </h5>
            </div>
            <div class="col-auto">
              <span class="small text-muted" id="remoteIdentityId">
                {{ data.remoteIdentityId | number:'8.0' | removeComma }}
              </span>
            </div>
          </div>
        </div>

        <div class="list-group-item">
          <div class="row align-items-center">
            <div class="col">
              <h5 class="mb-0">
                Name:
              </h5>
            </div>
            <div class="col-auto">
              <span class="small text-muted" id="name" [tooltip]="data.name">
                {{data.name | truncate:[45, '...']}}
              </span>
            </div>
          </div>
        </div>

        <div class="list-group-item">
          <div class="row align-items-center">
            <div class="col">
              <h5 class="mb-0">
                Status:
              </h5>
            </div>
            <div class="col-auto">
              <span class="small text-muted" id="status">
                {{data.status === 'active'? 'Active' : 'Paused'}}
              </span>
            </div>
          </div>
        </div>

        <div class="list-group-item">
          <div class="row align-items-center">
            <div class="col">
              <h5 class="mb-0">
                Created At:
              </h5>
            </div>
            <div class="col-auto">
              <span class="small text-muted" id="createdAt">
                {{data.createdAt | customDate}}
              </span>
            </div>
          </div>
        </div>

        <div class="list-group-item">
          <div class="row align-items-center">
            <div class="col">
              <h5 class="mb-0">
                Remote Identity Name:
              </h5>
            </div>
            <div class="col-auto">
              <span class="small text-muted" id="remoteIdentityName" [tooltip]="data.remoteIdentityName">
                {{data.remoteIdentityName | truncate:[45, '...']}}
              </span>
            </div>
          </div>
        </div>


        <div class="list-group-item">
          <div class="row align-items-center">
            <div class="col">
              <h5 class="mb-0">
                Storage Group Name:
              </h5>
            </div>
            <div class="col-auto">
              <span class="small text-muted" id="storageGroupName" [tooltip]="data.storageGroupName">
                {{data.storageGroupName | truncate:[45, '...']}}
              </span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>

<div class="modal-footer p-3">
  <div class="text-center">
    <button type="button" class="btn btn-sm btn-primary" (click)="bsModalRef.hide()">
      Close
    </button>
  </div>
</div>