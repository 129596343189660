import { Component, Input, OnInit } from '@angular/core';
import { ImageServiceService } from '../../services/image-service.service';

@Component({
  selector: 'app-subscription-info-label',
  templateUrl: './subscription-info-label.component.html',
  styleUrls: ['./subscription-info-label.component.scss']
})
export class SubscriptionInfoLabelComponent implements OnInit {

  @Input() pipeline: any = null;


  constructor(
    public imageServiceService: ImageServiceService
  ) { }

  ngOnInit(): void {}

}
