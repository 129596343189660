<!-- Image -->
<div class="avatar avatar-sm card-avatar">
  <img src="{{integration.logoPath}}" class="avatar-img rounded" alt="..."
    (error)="imageServiceService.handleMissingImage($event)">
</div>
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader"> Getting Started </h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline"> Fully-managed data pipeline into {{ integration |
  integrationFullname }} </p>
<!-- Divider -->
<hr class="mb-4">
<!-- Heading-->
<h4 class="mt-2 mb-2"> Configure Google Cloud Console </h4>
<ol>
  <li class="text-muted mb-2"> BigQuery requires a Google Cloud account. New Google Cloud accounts are eligible for <a
      class="light-link" href="https://gcpsignup.page.link/quG5" rel="noopener" target="_blank">$350 worth of
      credits</a>
  </li>
  <li class="text-muted mb-2"> If you already have a Google Cloud account, navigate to the <a class="light-link" href=""
      rel="noopener" target="_blank">Developers Console</a>
  </li>
  <li class="text-muted mb-2"> If you don’t have a Google project you need to create one </li>
  <li class="text-muted mb-2"> If you have an existing project, you will need to enable the BigQuery API </li>
  <li class="text-muted mb-2"> Billing must be enabled and current on your project. We will not be able to load data
    into trial <a class="light-link" href="https://cloud.google.com/bigquery/docs/sandbox" rel="noopener"
      target="_blank">sandbox</a> account or any
    account that has suspended billing. </li>
  <li class="text-muted mb-2">See the {{ integration | integrationFullname }} <a
      href="{{ integration | integrationDocLink}}" rel="noopener" target="_blank">help docs</a> for
    guides, tips, and troubleshooting</li>
</ol>