<div class="row align-items-center">
  <div class="col">
    <!-- Form -->
    <form>
      <div class="input-group input-group-flush input-group-merge input-group-reverse">
        <input class="form-control list-search" type="search" placeholder="Search" #filterField
          (keyup)="processFilteredText(filterField.value)">
        <span class="input-group-text">
          <i class="fe fe-search"></i>
        </span>
      </div>
    </form>
  </div>

  <div class="col-auto me-n3">
    <!-- Select -->
    <form>
      <select class="form-select form-select-sm cursor-pointer" placeholder="select sender" [(ngModel)]="sender"
        name="sender" #senderType (change)="updateSender(senderType.value)">
        <option value="all" selected>All</option>
        <option value="supervisor" selected>Supervisor</option>
        <option value="scheduler">Scheduler</option>
        <option value="ftpsync">FTP Sync</option>
        <option value="zeroadmin">Zero Admin</option>
      </select>
    </form>
  </div>

  <div class="col-auto me-n3">
    <!-- Select -->
    <form>
      <select class="form-select form-select-sm cursor-pointer" placeholder="select page size" [(ngModel)]="pageSize"
        name="pageSize" #item (change)="updatePageEntries(item.value)">
        <option value="10" selected>10 per page</option>
        <option value="25">25 per page</option>
        <option value="50">50 per page</option>
      </select>
    </form>
  </div>
  <!-- <div class="col-auto">
    <!-- Button 
    <a href="#!" class="btn btn-sm btn-white">
      Export
    </a>
  </div> -->
</div> <!-- / .row -->