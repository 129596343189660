<!-- Image -->
<div class="avatar avatar-sm card-avatar">

  <span class="avatar-title rounded-circle text-wait" style="font-size: 1.2rem;">
    <i class="fas fa-file-check"></i>
  </span>
</div>
<!-- Heading -->
<h2 class="text-center mb-2"> Getting Started </h2>
<!-- Text -->
<p class="text-center mb-4"> Overview of Healthchecks </p>
<!-- Divider -->
<hr class="mb-4">
<!-- Heading-->
<h4 class="mt-2 mb-2"> Key considerations for Healthchecks </h4>
<ol>

  <li class="text-muted mb-2">
    A health check provides a snapshot of lower level system errors. The most common errors are related to permissions at your data source or destination. For example, Amazon may report <code>"Invalid grant: account not found"</code> if permissions have been lost. Snowflake may report access is blocked with <code>"foo.snowflakecomputing.com:443. Incorrect username or password was specified"</code>. Shopify may issue an error <code>"Invalid API key or access token (unrecognized login or wrong password)"</code> if your app is not functioning properly. All these errors need to be resolved within the third-party system. Openbridge is not able to resolve errors in these upstream systems for you.
  </li>

  <li class="text-muted mb-2">
    If you are using a trial of Snowflake, BigQuery, or other services with a "free tier", they may block usage if you exceed the limits of the trial or free tier. You must set up a billing account to remove these restriction with those vendors. For example, BigQuery will list an error like <code>"Fault partition expiration time of less than 60 days while in sandbox mode"</code> which is an indication you are in a locked trial account.
 
  </li>

  <li class="text-muted mb-2">
    Errors can occur if third-party service have been terminated or turned off. For example, if you see an error like <code>"myredshift.eu-west-1.redshift.amazonaws.com to address: Name or service not known"</code> it likely means that your Redshift instance is not available, terminated, or blocked by firewall. 
  
  </li>
  <li class="text-muted mb-2">
    For more information on errors, see the <a href="https://docs.openbridge.com/en/articles/6906772-how-to-use-health-checks" rel="noopener" target="_blank" class="light-link">documentation</a>.
 
  </li>
</ol>