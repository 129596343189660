import { Component, Input, OnInit } from '@angular/core';
import { IdentityService } from 'src/app/core/services/identity.service';

@Component({
  selector: 'app-identity-label',
  templateUrl: './identity-label.component.html',
  styleUrls: ['./identity-label.component.scss']
})
export class IdentityLabelComponent implements OnInit {
  @Input() pipeline: any;
  identity: any = null;

  constructor(private identityService: IdentityService) { }

  ngOnInit(): void {
    this.identity = this.identityService.getIdentityById(this.pipeline.remoteIdentityId); 
  }

}
