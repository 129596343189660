import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ImageServiceService } from 'src/app/shared/services/image-service.service';

@Component({
  selector: 'app-modal-pipeline-details',
  templateUrl: './modal-pipeline-details.component.html',
  styleUrls: ['./modal-pipeline-details.component.scss']
})
export class ModalPipelineDetailsComponent implements OnInit {
  data: any = {};

  constructor(
    public bsModalRef: BsModalRef,
    public imageServiceService: ImageServiceService
  ) { }

  ngOnInit(): void {
  }

}
