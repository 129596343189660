import { EnvironmentConfig } from './environement-config.interface';
import { testPlanStandard, testPlanPremium, testPlanBusiness } from '../app/shared/constants/stripe';
import { productionAppIdIntercom, testAppIdIntercom } from '../app/shared/constants/intercom';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: EnvironmentConfig = {
  production: false,
  enableMock: false,
  maintenanceMode: false,
  accMapping: {
    bucketKey: 'openbridge-zeroadmin-dev'
  },
  auth0: {
    domain: 'openbridge-dev.us.auth0.com',
    clientId: '2RQxdFxBYybN45S0w2R4plVeP1yUguZz',
    audience: 'https://authentication.api.dev.openbridge.io/auth',
    connectionId: 'con_2wpt6dNQGLBbIzNw',
    roleMap: {
      apiUser: 'rol_9Ai7Ec7MpS4f8b5S'
    }
  },
  cache: {
    forceInvalidationAt: '1669799769',
    identitiesKey: '-cache-list-identities',
    lastLoginKey: '-cache-last-login',
    subscriptionsKey: '-cache-list-subscriptions',
    subscriptionsExpectedKeyCount: 23
  },
  openbridgeApiUris: {
    partialIdentifier: 'dev',
    partialIdentifierTwo: 'dev',
    healtChecksPartialIdentifier: 'dev',
    account: 'https://account.api.dev.openbridge.io/account',
    auth0Proxy: 'http://localhost:3000/auth0/',
    authentication: 'https://authentication.api.dev.openbridge.io',
    identities: 'https://remote-identity.api.dev.openbridge.io',
    oauth: 'https://oauth.api.dev.openbridge.io/oauth',
    oauthAppCreate: 'https://oauth.api.dev.openbridge.io',
    pipelineCount: 'https://pipeline-count.api.dev.openbridge.io',
    preset: 'https://preset.api.dev.openbridge.io',
    service: 'https://service.api.dev.openbridge.io',
    state: 'https://state.api.dev.openbridge.io/state',
    subscription: 'https://subscriptions.api.dev.openbridge.io',
    user: 'https://user.api.dev.openbridge.io/user'
  },
  s3BucketUris: {
    csvValidator: 'https://openbridge-api-service-csv-validator-dev.s3.amazonaws.com/',
    airbyteConfig: ''
  },
  siteUpdate: {
    versionCheckIntervalLengthInMinutes: 1
  },
  stripe: {
    publishableKey: 'pk_test_cNH6JqzL4Lpxn2zN2Ujrr5nw',
    prices: {
      trialPlanId: 'trial-14-day',
      standardPriceId: testPlanStandard,
      premiumPriceId: testPlanPremium,
      businessPriceId: testPlanBusiness,
      enterprisePriceId: 'enterprise'
    },
    name: {
      trial: 'Trial',
      standard: 'Basic',
      premium: 'Premium',
      business: 'Business',
      enterprise: 'Enterprise'
    }
  },
  premiumEnabledPrices: {
    trialPlanId: true,
    standardPriceId: false,
    premiumPriceId: true,
    businessPriceId: true,
    enterprisePriceId: true
  },
  intercom: {
    appId: testAppIdIntercom
  },
  httpInterceptor: {
    tokenExcludeDomains: [
      'https://openbridge-dev.us.auth0.com',
      'https://openbridge-airbyte-config-temp-dev.s3.amazonaws.com'
    ],
    tokenExcludeUrlExpressions: []
  },
  storageValidation: {
    timeBetweenPollsInMilliseconds: 2000,
    numberOfPollsBeforeFailure: 300
  },
  csvUploadValidation: {
    pollingTimeInSeconds: 180
  },
  sftp: {
    host: 'sftp.dev.openbridge.io'
  },
  organizations: {
    openbridgeSupportAuth0UserId: 'auth0|636e2173b888999dc954a315'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
