<div class="modal-header">
  <h1 class="modal-title pull-left" id="modalTitle">
    {{modalData.modalTitle}}
  </h1>
  <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="mb-1">
    <p id="modalContent">
      {{modalData.modalContent}}
    </p>
  </div>
</div>
<!-- <div class="modal-footer">
  <a href class="btn btn-sm btn-info mt-3">Modal Button</a>
</div> -->