<!-- Image -->
<div class="avatar avatar-sm card-avatar">
  <img src="{{integration.logoPath}}" class="avatar-img rounded" alt="..."
    (error)="imageServiceService.handleMissingImage($event)">
</div>
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader"> Getting Started </h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline"> Fully-automated {{ integration | integrationFullname }} data
  pipeline </p>
<!-- Divider -->
<hr class="mb-4">
<!-- Heading-->
<h4 class="mt-2 mb-2"> Getting ready for automated {{ integration | integrationFullname }} data pipelines </h4>
<ol>
  <li class="text-muted mb-2"> If you do not have a DSP advertising account, Amazon makes it easy to get started. Amazon
    offers <a href="https://advertising.amazon.com/contact-sales?" rel="noopener" target="_blank"> a quick and simple
      online
      registration</a> for new customers </li>
  <li class="text-muted mb-2"> {{ integration | integrationFullname }} API functionality for Reporting is Generally
    Available for customers in the
    US, CA, MX, DE, ES, FR, IT, ES, NL, UK, AU, IN, JP and UAE. However, please refer to Amazon documentation for the
    latest supported markets.</li>
  <li class="text-muted mb-2"> Openbridge only support Amazon performance reports for DSP campaigns as defined by the
    Ads API.</li>


  <li class="text-muted mb-2"> If you already have an Amazon Advertising DSP account, make sure you have the correct
    permissions to grant access to your profile to collect performance data. </li>
  <li class="text-muted mb-2">{{ integration | integrationFullname }} is currently available for professional sellers
    enrolled in Amazon
    Brand Registry, vendors, and agencies with clients who sell products on Amazon.</li>
  <li class="text-muted mb-2">See the {{ integration | integrationFullname }} <a
      href="{{ integration | integrationDocLink}}" rel="noopener" target="_blank">help docs</a> for
    guides, tips, and troubleshooting</li>
</ol>