import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modal-refresh-token-create',
  templateUrl: './modal-refresh-token-create.component.html'
})
export class ModalRefreshTokenCreateComponent implements OnInit {
  modalState: any;
  formGroup: UntypedFormGroup = new UntypedFormGroup({
    refreshTokenName: new UntypedFormControl(null, [Validators.required, Validators.pattern(/^[A-Za-z\s]*$/)]),
  });
  onClose$: Subject<any> = new Subject<any>();

  constructor(
    private bsModalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
  }

  onSubmit(): void {
    if (this.formGroup.valid) {
      this.onClose$.next({
        refreshTokenName: this.formGroup.value.refreshTokenName
      });
      this.closeModalWithComponent();
    }
  }

  cancel(): void {
    this.onClose$.next({
      canceled: true
    });
    this.closeModalWithComponent();
  }

  private closeModalWithComponent(): void {
    if (this.bsModalRef !== null) {
       this.bsModalRef.hide();
    }
    this.bsModalRef = null;
  }

}
