<div class="row align-items-center">
  <div class="col">
    <!-- Form -->
    <form>
      <div class="input-group input-group-flush input-group-merge input-group-reverse">
        <input class="form-control list-search" type="search" placeholder="Search" [formControl]="filterSearch" id="searchInput"
          (keyup)="processFilteredText(filterSearch.value)">
        <span class="input-group-text">
          <i class="fe fe-search"></i>
        </span>
      </div>
    </form>
  </div>

  <div class="col-auto me-n3">
    <div class="d-flex align-items-center custom-jobtype-badge" *ngIf="activeJobType !== 'all'" >
      <div class="me-1">
        {{ activeJobType}} jobs
      </div>
      <div class="cursor-pointer">
        <a href class="btn btn-sm btn-outline-light btn-custom-outline" (click)="removeBadge($event)">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="12" height="12" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg>
        </a>
      </div>
    </div>
  </div>
  <div class="col-auto me-n3">
    <!-- Select -->
    <form>
      <select class="form-select form-select-sm cursor-pointer" placeholder="select page size" [(ngModel)]="pageSize"
        name="pageSize" #item (change)="updatePageEntries(item.value)">
        <option value="10" selected>10 per page</option>
        <option value="25">25 per page</option>
        <option value="50">50 per page</option>
      </select>
    </form>
  </div>
  
  <div class="col-auto">
  
    <!-- Dropdown -->
    <div class="dropdown" dropdown #dropdown="bs-dropdown" [insideClick]="true">
  
      <!-- Toggle -->
      <button dropdownToggle class="btn btn-sm btn-white" type="button">
        <i class="fe fe-sliders me-1"></i> Filter <span class="badge bg-primary ms-1 d-none">0</span>
      </button>
  
      <!-- Menu -->
      <form *dropdownMenu class="dropdown-menu dropdown-menu-end dropdown-menu-card" style="border: 1px solid #95aac9;">
        <div class="card-header">
  
          <!-- Title -->
          <h4 class="card-header-title">
            Filters
          </h4>
  
          <!-- Link -->
          <button class="btn btn-sm btn-link text-reset d-none" type="reset">
            <small>Clear filters</small>
          </button>
  
        </div>
        <div class="card-body">
  
          <!-- List group -->
          <div class="list-group list-group-flush mt-n4 mb-4">
            <div class="list-group-item">
              <div class="row">
                <div class="col">
  
                  <!-- Text -->
                  <small>Job Type</small>
  
                </div>
                <div class="col-auto">
  
                  <!-- Select -->
                  <select class="form-select form-select-sm cursor-pointer form-select-custom" placeholder="select type" [(ngModel)]="activeJobType" name="activeJobType">
                    <option value="all" selected>All</option>
                    <option value="primary">Primary</option>
                    <option value="history">History</option>
                  </select>
  
                </div>
              </div> <!-- / .row -->
            </div>
            <!-- <div class="list-group-item">
              <div class="row">
                <div class="col">
  
                  <small>Lead scrore</small>
  
                </div>
                <div class="col-auto">
  
                  <select class="form-select form-select-sm" name="item-score">
                    <option value="*" selected>Any</option>
                    <option value="1/10">1+</option>
                    <option value="2/10">2+</option>
                    <option value="3/10">3+</option>
                  </select>
  
                </div>
              </div>
            </div> -->
          </div>
  
          <!-- Button -->
          <button class="btn w-100 btn-primary" type="submit" (click)="applyFilter($event, dropdown)">
            Apply filter
          </button>
  
        </div>
      </form>
  
    </div>
  
  </div>

</div>
