<div class="row" [formGroup]="parentForm">
  <div class="col-auto align-self-start">
    <!-- Avatar -->
    <div class="avatar avatar-lg">
      <img [src]="imgSrc" [alt]="cardData.logoAltText" [title]="cardData.logoAltText"
        class="avatar-img rounded" (error)="imgSrc='/assets/img/products/youtube.svg'">
    </div>
  </div>
  <div class="col align-self-center ms-n2">
    <!-- Title -->
    <h4 class="mb-1 name text-white">
      {{ cardData.title }}
      <!-- <span *ngIf="cardData.new" class="badge bg-success-soft ms-2">New</span> -->
    </h4>

    <ng-container *ngFor="let metaData of cardData.meta; index as metaIdx;">
      <!-- Text -->
      <ng-container *ngIf="metaData | objectHasKey: 'link' ; else nolink">
        <p class="card-text small text-muted mb-1"> {{ metaData.name }}: <a [href]="metaData.link" target="_blank" rel="noopener">{{ metaData.value }}</a>         
      </ng-container>
      <ng-template #nolink>
        <p class="card-text small text-muted mb-1"> {{ metaData.name }}: {{ metaData.value }} </p>
      </ng-template>
    </ng-container>
  </div>
  <div class="col-auto align-self-center">
    <a (click)="removeCard(cardData.id)" class="btn btn-sm btn-white d-none d-md-inline-block" id="radioBtn">
      Remove
    </a>
  </div>
  <div class="col-auto align-self-center">
    <!-- Dropdown -->
    <p class="card-text small text-white">
      <span class="text-success">●</span> Active
    </p>
  </div>
</div> <!-- / .row -->