<!-- Image -->
<div class="avatar avatar-sm card-avatar">
    <img src="{{integration.logoPath}}" class="avatar-img rounded" alt="..."
        (error)="imageServiceService.handleMissingImage($event)">
</div>
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader"> Getting Started </h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline"> Fully-automated {{ integration | integrationFullname }} data
    pipeline </p>
<!-- Divider -->
<hr class="mb-4">
<!-- Heading-->
<h4 class="mt-2 mb-2"> Getting ready for automated {{ integration | integrationFullname }} data pipelines </h4>
<ol>
    <li class="text-muted mb-2"> If you do not have a {{ integration | integrationFullname }} account, Google makes it
        easy to get started. Google
        offers <a href="https://ads.google.com/" rel="noopener" target="_blank"> a quick and simple online
            registration</a> for new customers </li>
    <li class="text-muted mb-2"> Openbridge only support {{ integration | integrationFullname }} performance reports as
        defined by the {{ integration | integrationFullname }}
        API.</li>
    <li class="text-muted mb-2">See our {{ integration | integrationFullname }} <a
            href="{{ integration | integrationDocLink}}" rel="noopener" target="_blank">help docs</a> for guides,
        tips, and troubleshooting</li>
</ol>