import { Pipe, PipeTransform } from '@angular/core';
import { Integration } from '../models/integration.model';

@Pipe({ name: 'integrationFullname' })
export class IntegrationFullnamePipe implements PipeTransform {

  constructor(
  ) { }

  transform(integration: Integration): string {
    if (integration.brand === 'Azure' || integration.brand === 'Snowflake' || integration.brand === 'Databricks' || integration.name === 'Google BigQuery') {
      return integration.name;
    } else {
      return integration.brand + ' ' + integration.name;
    }
  }
}