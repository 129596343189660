import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modal-member-invite',
  templateUrl: './modal-member-invite.component.html'
})
export class ModalMemberInviteComponent implements OnInit {
  formGroup: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl(null, [Validators.required, Validators.email])
  });
  public onClose: Subject<any>;

  constructor(
    private bsModalRef: BsModalRef
  ) { }

  ngOnInit(): void {
    this.onClose = new Subject();
  }

  onSubmit(): void {
    if (this.formGroup.valid) {
      this.onClose.next({
        email: this.formGroup.value.email
      });
      this.closeModalWithComponent();
    }
  }

  cancel(): void {
    this.onClose.next(false);
    this.closeModalWithComponent();
  }

  private closeModalWithComponent(): void {
    if (this.bsModalRef !== null) {
       this.bsModalRef.hide();
    }
    this.bsModalRef = null;
  }

}
