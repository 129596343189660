<div class="modal-header">
  <h3 class="modal-title pull-left">Oauth Redirection...<span
      class="me-2 badge bg-info-soft ms-1 mt-n1">Redirecting</span></h3>
</div>
<div class="modal-body">
  <div class="mb-1">
    Please wait a moment while we redirect you to
    <strong id="brandName">
      {{integration.brand}}’s
    </strong>
    authentication portal.
  </div>
</div>