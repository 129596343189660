import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store/reducers';
import { environment } from 'src/environments/environment';
import { IntegrationService } from '../services/integration.service';
import { StripeService } from '../services/stripe.service';

@Pipe({
  name: 'canRequestHistory'
})

// checks if user is eligible to request history based on his current plan
export class CanRequestHistoryPipe implements PipeTransform {
  stripeDetails: any;
  stripeReducerSubscription$: any;

  constructor(
    private store$: Store<AppState>,
    private stripeService: StripeService  ) {
    this.stripeReducerSubscription$ = this.store$.select('stripe').subscribe(response => {
      this.stripeDetails = this.stripeService.processStripeCustomer(response.stripeCustomer);
    });
  }

  canRequestHistory(value: number): boolean {
    return (
      (this.stripeDetails !== null) &&
      (this.stripeDetails.planId !== environment.stripe.prices.trialPlanId)       
    );
  }

  transform(value: number): boolean {
    return this.canRequestHistory(value);
  }

}
