import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';

@Component({
  selector: 'app-filter-jobs',
  templateUrl: './filter-jobs.component.html',
  styleUrls: ['./filter-jobs.component.scss']
})
export class FilterJobsComponent implements OnInit {
  @Input() filterableObjects: any[] = [];
  @Output() filteredObjects = new EventEmitter<any[]>();
  @Output() updatedPageSize = new EventEmitter<number>();
  @Output() updatedJobType = new EventEmitter<string>();

  filterSearch = new UntypedFormControl('');
  pageSize = 10;
  activeJobType: 'all' | 'primary' | 'history' = 'all';

  constructor() { }

  ngOnInit(): void {
    this.processFilteredText(this.filterSearch.value);
    // this.filteredObjects.emit([...this.filterableObjects]);    
  }

  ngOnChanges(): void {
    this.processFilteredText(this.filterSearch.value);
    // this.filteredObjects.emit([...this.filterableObjects]);    
  }

  processFilteredText(filterText: string): void {
    if (filterText === '') {
      this.filteredObjects.emit([...this.filterableObjects]);
    }
    else {
      const filteredObjects: any[] = [];      
      this.filterableObjects.forEach((val, idx) => {
        const jobType = val.attributes.is_primary ? 'Recurring' : 'History'
        // job id
        if (val.id && +val.id === +filterText) {
          filteredObjects.push(val);
        }
        // subscription id
        else if (val.attributes.subscription_id && val.attributes.subscription_id === +filterText) {
          filteredObjects.push(val);
        }
        // request date 
        else if (val.attributes.created_at && val.attributes.created_at.toLowerCase().includes(filterText.toLowerCase())) {
          filteredObjects.push(val);
        }
        // job type
        else if (jobType && jobType.toLowerCase().includes(filterText.toLowerCase())) {
          filteredObjects.push(val);
        }
        // status
        else if (val.attributes.status && val.attributes.status.toLowerCase().includes(filterText.toLowerCase())) {
          filteredObjects.push(val);
        }
      });

      this.filteredObjects.emit([...filteredObjects]);
    }
  }

  updatePageEntries(pageSize): void {
    this.updatedPageSize.emit(pageSize);
  }

  updateJobType(activeJobType): void {
    this.updatedJobType.emit(activeJobType);
  }

  applyFilter(event: Event, dropdown: BsDropdownDirective): void {
    event.stopPropagation();
    this.filterSearch.setValue('');
    this.updatedJobType.emit(this.activeJobType);
    dropdown.hide();
  }

  removeBadge($event: MouseEvent): void {
    $event.stopPropagation();
    $event.preventDefault();
    this.activeJobType = 'all';
    this.updatedJobType.emit(this.activeJobType);
  }

}
