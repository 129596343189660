import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiManagementService {

  constructor(
    private httpClient: HttpClient
  ) { }

  async createRefreshToken(refreshTokenName: string): Promise<string> {

    const payload = {
      data: {
          type: 'APIAuth',
          attributes: {
              name: refreshTokenName
          }
      }
    };

    const response = await this.httpClient.post(environment.openbridgeApiUris.authentication + '/auth/api/ref/list', payload).toPromise();
    return response['data']['attributes']['refresh_token'];
  }

  async deleteRefreshToken(refreshTokenId: string): Promise<void> {
    try {
      const payload = {
        data: {
          type: 'APIAuth',
          attributes: {
            id: refreshTokenId
          }
        }
      };

      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        body: payload,
      };

      const response = await this.httpClient.delete(environment.openbridgeApiUris.authentication + '/auth/api/ref', options).toPromise();

    } catch (error) {
      console.error(error);
    }
  }

  async getRefreshTokens(): Promise<[]> {
    const response  = await this.httpClient.get(environment.openbridgeApiUris.authentication + '/auth/api/ref/list').toPromise();
    return response['data'];
  }

}

