<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item" [ngClass]="{ 'active': selectedPrefixes.length > 0 }">
      <a *ngIf="selectedPrefixes.length > 0" class="light-link cursor-pointer" (click)="navigatePrefix('root')" [tooltip]="bucketName">
        {{ bucketName | truncate:[20, '...']}}
      </a>
      <span *ngIf="selectedPrefixes.length === 0" [tooltip]="bucketName">
        {{ bucketName | truncate:[20, '...']}}
      </span>
    </li>
    <li class="breadcrumb-item" *ngFor="let prefix of selectedPrefixes; let i = index; let last = last" [ngClass]="{ 'active': last }">
      <a class="light-link cursor-pointer" *ngIf="!last" (click)="navigatePrefix(prefix)" [tooltip]="prefix">
        {{ prefix | truncate:[20, '...']}}
      </a>
      <span *ngIf="last" [tooltip]="prefix">
        {{ prefix | truncate:[20, '...']}}
      </span>
    </li>
  </ol>
</nav>