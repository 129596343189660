import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { copyDetails } from 'src/app/core/functions/utility';
@Component({
  selector: 'app-modal-transaction-view',
  templateUrl: './modal-transaction-view.component.html',
  styleUrls: ['./modal-transaction-view.component.scss']
})
export class ModalTransactionViewComponent implements OnInit {
  data: any = {};
  copyMessage = copyDetails;
  constructor(
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit(): void {
  }

}
