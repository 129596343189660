
<ng-container *ngIf="pipeline">
  <div class="d-flex align-items-center">
    <!-- Avatar -->
    <div class="avatar avatar-xs float-start me-2">
      <img class="avatar-img rounded-circle bg-white" style="padding: 1px;"
        src="{{ pipeline.productId | logoPathFromProductId:pipeline.subProductId }}"
        alt="{{ pipeline.productId | integrationNameFromProductId:pipeline.subProductId }}"
        title="{{ pipeline.productId | integrationNameFromProductId:pipeline.subProductId }}"
        (error)="imageServiceService.handleMissingImage($event)">
    </div>
    <div>
      <h6 class="text-muted mb-1" id="productName"
        tooltip="{{ pipeline.productId | integrationNameFromProductId: pipeline.subProductId }}">
        {{ pipeline.productId | integrationNameFromProductId:pipeline.subProductId | truncate:[20, '...'] }}
        <ng-container *ngIf="pipeline.productBadge !== 'none'">
          <span class="badge mt-n1 text-capitalize"
          [ngClass]="{ 'bg-secondary-soft': (pipeline.productBadge === 'legacy'), 'bg-success-soft': (pipeline.productBadge === 'new') }"
        >{{ pipeline.productBadge }}</span>
        </ng-container>
      </h6>
      <h5 class="m-0" id="pipelineName" [tooltip]="pipeline.name">
        {{ pipeline.name | truncate:[20, '...'] }}
      </h5>
    </div>
  </div>
</ng-container>