import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { observableToPromise } from '../../core/functions/utility';

@Injectable({
  providedIn: 'root'
})
export class ApiManagementService {

  constructor(
    private httpClient: HttpClient
  ) { }

  async createRefreshToken(refreshTokenName: string): Promise<string> {

    const payload = {
      data: {
          type: 'APIAuth',
          attributes: {
              name: refreshTokenName
          }
      }
    };

    const response = await observableToPromise(this.httpClient.post(`${environment.openbridgeApiUris.authentication}/auth/api/ref/list`, payload));
    return response['data']['attributes']['refresh_token'];
  }

  async deleteRefreshToken(refreshTokenId: string): Promise<void> {
    try {
      const payload = {
        data: {
          type: 'APIAuth',
          attributes: {
            id: refreshTokenId
          }
        }
      };

      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        body: payload,
      };

      const response = await observableToPromise(this.httpClient.delete(`${environment.openbridgeApiUris.authentication}/auth/api/ref`, options))

    } catch (error) {
      console.error(error);
    }
  }

  async getRefreshTokens(): Promise<[]> {
    const response = await observableToPromise(this.httpClient.get(`${environment.openbridgeApiUris.authentication}/auth/api/ref/list`));
    return response['data'];
  }

}

