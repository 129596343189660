import { Pipe, PipeTransform } from '@angular/core';
import { productIdAmzSellingBusRepFees, productIdAmzSellingBusRepFullfillment, productIdAmzSellingBusRepInventory, productIdAmzSellingBusRepInventoryRt, productIdAmzSellingBusRepSales, productIdAmzSellingPartnersBrandAnalyticsReports, productIdAmzSellingPartnersFinanceRt, productIdAmzSellingPartnersInbound, productIdAmzSellingPartnersOrders, productIdAmzSellingPartnersSalesAndTraffic, productIdAmzSellingPartnersSettlement, productIdAmzVendorBrandAnalyticsReports, productIdAmzVendorRealtimeAnalytics, productIdAmzVendorsRetailAnalytics, productIdAmzVendorsRetailProcurmentOrder, productIdGoogleAds } from '../constants/product-ids';

@Pipe({
  name: 'canViewPipelineDetails'
})
export class CanViewPipelineDetailsPipe implements PipeTransform {

  productIds: number[] = [
    productIdAmzSellingPartnersOrders,
    productIdAmzSellingPartnersFinanceRt,
    productIdAmzSellingPartnersInbound,
    productIdAmzSellingPartnersSettlement,
    productIdAmzSellingBusRepFullfillment,
    productIdAmzSellingBusRepInventoryRt,
    productIdAmzSellingBusRepInventory,
    productIdAmzSellingBusRepSales,
    productIdAmzSellingBusRepFees,
    productIdAmzSellingPartnersSalesAndTraffic,
    productIdAmzSellingPartnersBrandAnalyticsReports,
    productIdAmzVendorBrandAnalyticsReports,
    productIdAmzVendorsRetailAnalytics, 
    productIdAmzVendorsRetailProcurmentOrder, 
    productIdAmzVendorRealtimeAnalytics
  ];

  transform(productId: number): boolean {
    const canViewDetails = this.productIds.includes(productId);
    if (canViewDetails) {
      return true;
    }
    else {
      return false;
    }
  }

}
