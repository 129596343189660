<!-- Image -->
<div class="avatar avatar-sm card-avatar">
  <img src="{{integration.logoPath}}" class="avatar-img rounded" alt="..."
    (error)="imageServiceService.handleMissingImage($event)">
</div>
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader"> Attribution Profiles</h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline"> Selecting Your {{ integration | integrationFullname }} Profile </p>
<!-- Divider -->
<hr class="mb-4">
<!-- Heading-->
<h4 class="mt-2 mb-2"> Selecting A {{integration.name}} Profile</h4>
<ol>
  <li class="text-muted mb-2"> The permissions granted to us limit the access only to those profiles within an
    advertiser's account that a user has permission. The profile list is dynamically generated based on the response
    from the Amazon Advertising API. If you do not see a seller or vendor profile listed, you need to verify that the
    user has the correct access permissions. </li>

  <li class="text-muted mb-2">
    Profiles represent a single advertiser and the account marketplace. All data is associated with this single profile.
    Please note that Advertisers cannot have more than one profile for each marketplace.</li>
  <li class="text-muted mb-2">
    Advertisers who operate in more than one marketplace (i.e., Amazon.com, Amazon.co.uk, Amazon.co.jp) will have one
    profile for each each marketplace. </li>

  <li class="text-muted mb-2">
    In the event you do not see profile <code>name</code>, registering in the <a
      href="https://brandservices.amazon.com/" rel="noopener" target="_blank">Amazon Brand Registry</a> is required.
  </li>
</ol>