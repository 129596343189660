import { Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-generic-confirm',
  templateUrl: './modal-generic-confirm.component.html'
})
export class ModalGenericConfirmComponent {
  @Output() modalClosed = new EventEmitter<object>();
  title: string;
  message: string;

  constructor(
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
  }

  confirm(): void {
    this.modalClosed.emit({cancelCurrentHistory: true});
    this.bsModalRef.hide();
  }

  cancel(): void {
    this.bsModalRef.hide();
  }

}
