import { Component, Input, OnInit } from '@angular/core';
import { Integration } from 'src/app/shared/models/integration.model';

@Component({
  selector: 'app-help-google-ads-detail',
  templateUrl: './help-google-ads-detail.component.html'
})
export class HelpGoogleAdsDetailComponent implements OnInit {
  @Input() integration: Integration;

  constructor() { }

  ngOnInit(): void {
  }

}
