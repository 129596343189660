import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { flashNotificationsClear, flashNotificationsFormValidationError, flashNotificationsFormValidationErrorWithDetails } from '../../../../core/store/actions/flash-notifications.actions';
import { AppState } from '../../../../core/store/reducers';
import { ApiStateService } from '../../../../core/services/api-state.service';
import { take } from 'rxjs/operators';
import { identityTypes } from '../../../../shared/constants/identity-types';
import { IdentityType } from '../../../../shared/models/identity-type.type';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ModalOauthButtonLoadingComponent } from '../../modals/modal-oauth-button-loading/modal-oauth-button-loading.component';
import { environment } from '../../../../../environments/environment';
import { errorWizardGeneralError } from '../../../../shared/constants/flash-notifications';
import { ImageServiceService } from '../../../../shared/services/image-service.service';
import { ModalThirdPartyIdentityComponent } from '../../modals/modal-third-party-identity/modal-third-party-identity.component';
import { productIdAmzCatalogApi } from '../../../../shared/constants/product-ids';
import { ActivatedRoute } from '@angular/router';
import { IdentityTypeService } from '../../../../shared/services/identity-type.service';

@Component({
  selector: 'app-identity-oauth-create',
  templateUrl: './identity-oauth-create.component.html',
  styleUrls: ['./identity-oauth-create.component.scss']
})
export class IdentityOauthCreateComponent implements OnInit {
  @Input() integration: any;
  @Input() regions: { value: string, name: string }[];
  @Input() remoteIdentityAppId: number;
  @Input() extraParams: {};
  @Input() remoteIdentityTypeId: number | null;
  @Input() subId: string;
  @Input() mode: string;
  bsModalRef: BsModalRef | null;

  accountId: number | null = null;
  formGroup: UntypedFormGroup;
  defaultRegion: string;
  // Create default global identity regions
  identityRegions: { value: string, name: string }[] = [
    { value: 'global', name: 'global' }
  ];
  identityType: IdentityType | null | undefined;
  userId: number | null = null;
  thirdPartySharable: boolean = false;

  constructor(
    protected store$: Store<AppState>,
    private apiStateService: ApiStateService,
    protected modalService: BsModalService,
    public imageServiceService: ImageServiceService,
    private activatedRoute: ActivatedRoute,
    private identityService: IdentityTypeService,
  ) { }

  ngOnInit(): void {

    if(this.integration.remoteIdentityTypeId === null ) {
      this.identityType = identityTypes.find(type => type.remoteIdentityTypeId === this.remoteIdentityTypeId);
    } else {
      this.identityType = identityTypes.find(type => type.remoteIdentityTypeId === this.integration.remoteIdentityTypeId);
    }

    if(!this.identityType) {
      if(this.integration.productId === productIdAmzCatalogApi) {
        const identitySlug: string | null = this.activatedRoute.firstChild.snapshot.paramMap.get('identitySlug');
        this.identityType = this.identityService.findIdentityTypeFromSlug(identitySlug);
      }
    }

    this.generateRegions();

    this.defaultRegion = '';
    if (this.identityRegions[0].value === 'global') {
      this.defaultRegion = this.identityRegions[0].value;
    }

    // Create a new formGroup
    this.formGroup = new UntypedFormGroup({
      region: new UntypedFormControl(this.defaultRegion, [Validators.required]),
    });

    this.store$.select('auth').pipe(take(1)).subscribe(response => {
      if (response) {
        this.accountId = response.currentAccount.accountId;
        this.userId = response.currentAccount.userId;
      }
    });
  }

  async submitForm(): Promise<void> {
    this.store$.dispatch(flashNotificationsClear());
    this.formGroup.markAllAsTouched();

    if (!this.formGroup.valid) {
      this.doProcessError('Region is a required field.');
    }
    else {

      this.openModalWithComponent();

      const queryParams = this.generateQueryParams({remoteIdentityTypeId: this.identityType?.remoteIdentityTypeId});
      const identityAuthParams = this.generateIdentityAuthParams(queryParams, false, this.identityType?.remoteIdentityTypeId);
      window.location.href = await this.generateAuthorizationLink(identityAuthParams);
    }
  }

  private generateQueryParams(params: any = null) {
    let queryParams = {};

    if(params !== null) {
      queryParams = {
        ...queryParams,
        ...params
      };
    }

    if(this.integration.productId === productIdAmzCatalogApi) {
      queryParams = {
        ...queryParams,
        stage: 'identity-type-select'
      }
    }

    if (this.subId && this.mode) {
      queryParams = {
        ...queryParams,
        id: this.subId,
        mode: this.mode
      }
    }

    return queryParams;
  }

  private generateIdentityAuthParams(queryParams: object, external: boolean = false, remoteIdentityTypeId: number | null = null): object {
    return {
      accountId: this.accountId,
      userId: this.userId,
      remoteIdentityTypeId: (remoteIdentityTypeId !== null) ? remoteIdentityTypeId : this.integration.remoteIdentityTypeId,
      returnUrl: window.location.origin + ((this.thirdPartySharable) ? '/identity-invite/thank-you' : this.integration.routerLink),
      region: this.formGroup.value.region,
      oauthAppId: this.remoteIdentityAppId || null,
      extraParams: this.extraParams,
      external: external,
      queryParams: queryParams
    };
  }

  async submitAsThirdPartySharable(): Promise<void> {
    this.thirdPartySharable = true;
    this.store$.dispatch(flashNotificationsClear());
    this.formGroup.markAllAsTouched();

    if (!this.formGroup.valid) {
      this.doProcessError('Region is a required field.');
    }
    else {

      this.openModalWithComponent();
      const queryParams = this.generateQueryParams();
      const identityAuthParams = this.generateIdentityAuthParams(queryParams, true, this.identityType?.remoteIdentityTypeId);
      const authLink = await this.generateAuthorizationLink(identityAuthParams);
      this.closeModalWithComponent();
      if (authLink) {
        this.openModalToCreateExternalIdentityLink(authLink);
      }
    }
  }

  private openModalToCreateExternalIdentityLink(thirdPartyShareUrl: string): void {
    const initialState: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      initialState: {
        externalIdentityLink: thirdPartyShareUrl,
        integration: this.integration
      }
    };

    this.bsModalRef = this.modalService.show(ModalThirdPartyIdentityComponent, initialState);
  }

  async generateAuthorizationLink(identityAuthParams: any): Promise<any> {
    const delay = ms => new Promise(res => setTimeout(res, ms));

    try {
      const stateResponse = await this.apiStateService.createOauthState(identityAuthParams);
      if (stateResponse === null) {
        throw Error("Failed to create oauth state")
      }
      return environment.openbridgeApiUris.oauth +
      '/initialize?state=' + stateResponse['body']['data']['id'];
  }
    catch (error) {
      await delay(1000); // allows modal to fully open before we try to close it.
      // tslint:disable-next-line: max-line-length
      this.store$.dispatch(flashNotificationsFormValidationErrorWithDetails({ message: errorWizardGeneralError, details: JSON.stringify(error) }));
      this.closeModalWithComponent();
    }
  }

  private openModalWithComponent(): void {

    const initialState: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      initialState: {
        integration: this.integration,
      }
    };
    this.bsModalRef = this.modalService.show(ModalOauthButtonLoadingComponent, initialState);
  }

  private closeModalWithComponent(): void {
    if (this.bsModalRef !== null) {
      this.bsModalRef.hide();
    }
    this.bsModalRef = null;
  }

  private generateRegions() {
    [];
    const enabledRegions: any[] = [];
    const disabledRegions: any[] = [];

    for (let region of this.regions) {

      const nRegion = {
        ...region,
        disabled: false
      };

      let includeRegion = true;

      if (Array.isArray(this.integration.includeRegions)) {
        includeRegion = this.integration.includeRegions.includes(region.value);
      }

      if (includeRegion === false) {
        nRegion.disabled = true
        disabledRegions.push(nRegion);
      }
      else {
        enabledRegions.push(nRegion);
      }
    }

    const generatedRegions: any[] = [
      ...enabledRegions,
      ...disabledRegions
    ]

    this.identityRegions = generatedRegions;
  }

  private doProcessError(error: string): void {
    this.store$.dispatch(flashNotificationsFormValidationError({ message: error }));
  }

}

