import { Component, Input, OnInit } from '@angular/core';
import {  ChartOptions, ChartType } from 'chart.js';

@Component({
  selector: 'app-chart-sparkline',
  templateUrl: './chart-sparkline.component.html'
})
export class ChartSparklineComponent implements OnInit {
  // Data Related
  @Input() chartDataSets: any[];
  @Input() chartLabels: any[];
  @Input() chartPlugins: any[];
  @Input() chartLegend: boolean;

  // Style related.
  @Input() chartClass: string;
  @Input() chartHeight: string;
  @Input() chartStyle: string;
  @Input() chartWidth: string;

  chartType: ChartType = 'line';
  chartOptions: ChartOptions;

  constructor() { }

  ngOnInit(): void {
    this.chartOptions = {
      // scales: {
      //   yAxes: [{
      //     display: false
      //   }],
      //   xAxes: [{
      //     display: false
      //   }]
      // },
    //   elements: {
    //     line: {
    //       borderWidth: 2,
    //       borderColor: '#D2DDEC'
    //     },
    //     point: {
    //       hoverRadius: 0
    //     }
    //   },
    //   tooltips: {
    //     custom: function() {
    //       return false;
    //     }
    //   }
    };
  }
}
