import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { flashNotificationsClear } from 'src/app/core/store/actions/flash-notifications.actions';
import { modalsClose, modalsOpen } from 'src/app/core/store/actions/modals.action';
import { AppState } from 'src/app/core/store/reducers';
import { GoogleBigqueryService } from 'src/app/modules/wizards/pages/google-bigquery/google-bigquery.service';

@Component({
  selector: 'app-identity-bigquery-create',
  templateUrl: './identity-bigquery-create.component.html',
  styleUrls: ['./identity-bigquery-create.component.scss']
})
export class IdentityBigqueryCreateComponent implements OnInit {
  files: File[] = [];
  json: any;
  bsModalRef: BsModalRef;
  jsonExpectedKeys: string[] = [];

  constructor(
    protected store$: Store<AppState>,
    private bigQueryService: GoogleBigqueryService,

  ) { }

  ngOnInit(): void {}

  async validate(serviceAccountString: string): Promise<void> {
    const delay = ms => new Promise(res => setTimeout(res, ms));

    if (this.files.length > 1) {
      this.clearAllFiles();
      this.bigQueryService.doProcessError('Only one BigQuery Service Account file can be processed at a time');
      this.closeModal();
      this.clearAllFiles();
  } else {
      this.openModal();
      try {
        this.bigQueryService.createBigQueryIdentityFromServiceAccount(serviceAccountString.replace(/(\r\n|\n|\r)/gm, ''));
        await delay(2000);
        // Do not close the modal here, it'll close later.
        this.clearAllFiles();
      }
      catch (error) {
        await delay(2000);
        this.closeModal();
        this.clearAllFiles();
      }
    }
  }

  onSelect(event): void {
    this.store$.dispatch(flashNotificationsClear());
    const self = this;
    this.files.push(...event.addedFiles);
    const reader: FileReader = new FileReader();
    reader.onload = ((theFile) => {
      return (e: any) => {
        self.validate(e.target.result);
      };
    })(this.files[0]);
    reader.readAsText(this.files[0]);
  }

  onRemove(event): void {
    this.files.splice(this.files.indexOf(event), 1);
  }

  private openModal(): void {
    this.store$.dispatch(modalsOpen({
      modalType: 'progress',
      title: 'Validating Service Account',
      message: 'Uploading your service account file.',
      progress: 33
    }));
  }

  private removeFileForIndex(index: number): void{
    this.files.splice(index, 1);
  }

  private clearAllFiles(): void {
    let length = this.files.length;

    while (length !== 0) {
      this.removeFileForIndex((length - 1));
      length--;
    }
  }

  private closeModal(): void {
    this.store$.dispatch(modalsClose());
  }


}
