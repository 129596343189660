import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Integration } from '../../models/integration.model';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ModalDatasetTemplateDeleteComponent } from '../modals/modal-dataset-template-delete/modal-dataset-template-delete.component';
import { reportTypes } from '../../constants/reportTypes';

@Component({
  selector: 'app-product-table-checkbox',
  templateUrl: './product-table-checkbox.component.html',
  styleUrls: ['./product-table-checkbox.component.scss']
})
export class ProductTableCheckboxComponent implements OnInit {
  bsModalRef: BsModalRef = null;
  // make sure to initialize preSelectedStageList as null 
  @Input() integration: Integration;
  @Input() preSelectedStageList: any[] | null = null;
  @Input() inputData: any;
  @Input() templateEditMode: boolean = false;
  @Output() selectedStageList = new EventEmitter<any>();
  @Output() populateTemplateEditInfo = new EventEmitter<any>();

  reports = reportTypes;
  selectedReport: any;

  deletedTemplate: boolean = false;
  
  constructor(
    private modalService: BsModalService
  ) { }
  
  ngOnInit(): void {
    this.selectedReport = this.reports.find(report => report.reportType === this.inputData?.attributes?.reportType);
    this.checkForPreSelection();
  }

  onCheckboxChange($event): void {
    const updatedSelection = {
      stageId: this.inputData.attributes.stage_id,
      name: this.inputData.attributes.name,
      isSelected: $event.target.checked
    };

    this.selectedStageList.emit(updatedSelection);
  }

  checkForPreSelection(): void {

    // special case for deleted templates
    if (this.inputData.id === null && this.inputData.isDeleted) {
      this.deletedTemplate = true;
    } else {
      this.deletedTemplate = false;
    }

    if(this.preSelectedStageList != null) {
      const isSelected = (this.preSelectedStageList.length === 0 || this.preSelectedStageList.includes(this.inputData.attributes.stage_id)) ? true : false;

      if (isSelected) {
        const updatedSelection = {
          stageId: this.inputData.attributes.stage_id,
          name: this.inputData.attributes.name,
          isSelected: true
        };
        this.inputData.isSelected = true;

        
        this.selectedStageList.emit(updatedSelection);
      } 
    }
  }

  getCommaSeparatedDimensions(dimensions: any[]): string {
    return dimensions
      .map(dimension => dimension.dimensionName)
      .join(', ');
  }

  // sending template details for dsp v2 for editing template
  templateDetails($event: MouseEvent, details: any): void {
    $event.stopPropagation();
    $event.preventDefault();
    this.populateTemplateEditInfo.emit(details);
  };

  openDeleteInfoModal($event: MouseEvent) {
    $event.stopPropagation();
    $event.preventDefault();

    const modalParams: ModalOptions = {
      class: 'modal-dialog modal-dialog-centered'
    };

    this.bsModalRef = this.modalService.show(ModalDatasetTemplateDeleteComponent, modalParams);
  }

}
