import { DateTimeService, DATE_FORMAT_MMM_DD_YYYY } from './date-time.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { identityTypes } from '../../shared/constants/identity-types';
import { IdentityTypeService } from '../../shared/services/identity-type.service';
import { environment } from '../../../environments/environment';
import { AppState } from '../store/reducers';
import { ApiStateService } from './api-state.service';
import { regionIdentifiers } from '../../shared/constants/region';
import { identityTypeIdShopify } from '../../shared/constants/identity-ids';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ModalThirdPartyIdentityComponent } from '../..//shared/components/modals/modal-third-party-identity/modal-third-party-identity.component';
import { PipelineSubscription } from '../../shared/models/pipeline-subscription.model';
import { identityDelete } from '../store/actions/identities.actions';
import { observableToPromise } from '../functions/utility';

@Injectable({
  providedIn: 'root'
})
export class IdentityService {

  identities$: Subject<any>;
  bsModalRef: BsModalRef;
  pipelines: PipelineSubscription[] = null;
  subscriptionsReducerSubscription$ = this.store$.select('subscriptions').subscribe(response => {
    let tmpArray: any[] = [];

    if (typeof response.subscriptions[Symbol.iterator] === 'function') {
      response.subscriptions.forEach((val) => {
        if (val.status !== 'invalid') {
          tmpArray = [
            val,
            ...tmpArray
          ];
        }
      });
    }

    this.pipelines = [...tmpArray];
  });


  constructor(
    private httpClient: HttpClient,
    private store$: Store<AppState>,
    private apiStateService: ApiStateService,
    private identityTypeService: IdentityTypeService,
    private router: Router,
    private dateTimeService: DateTimeService,
    private modalService: BsModalService,
  ) {
    this.identities$ = new Subject();
  }

  getIdentitiesByRemoteIdentityType(remoteIdentityTypeId: number): void {
    this.store$.select('identities').pipe(take(1)).subscribe(state => {
      const identities: any[] = [];
      state.identities.forEach(identityItem => {
        if (identityItem.remoteIdentityTypeId === remoteIdentityTypeId) {
          identities.push(this.identityObjectForIdentity(identityItem));
        }
      });
      this.identities$.next(identities);
    });
  }

  getIdentityById(identityId: number): any {
    let responseIdentity: any = null;
    this.store$.select('identities').pipe(take(1)).subscribe(state => {
      const identities: any[] = [];
      state.identities.forEach(identityItem => {
        if (identityItem.id === identityId) {
          responseIdentity = identityItem;
        }
      });
    });
    return responseIdentity;
  }

  identityObjectForIdentity(identityItem: any): any {

    const identityType = identityTypes.find(type => +type.remoteIdentityTypeId === +identityItem.remoteIdentityTypeId);
    const providerTitle = identityType.providerAlias || 'Provider Id';
    const regionTitle = identityType.regionAlias || 'Region';

    const tmpIdentity = {
      id: identityItem.id,
      remoteIdentityTypeId: +identityItem.remoteIdentityTypeId,
      new: this.isConsideredNewIdentity(identityItem),
      updated: this.isConsideredUpdatedIdentity(identityItem),
      title: identityItem.name,
      logoUri: identityType.logoPath,
      region: identityItem.region,
      status: (identityItem.invalidIdentity) ? 'expired' : 'active',
      external: (identityItem?.external) ? identityItem?.external : false,
      createdAt: new Date(identityItem.createdAt),
      meta: [
        {
          name: 'Created',
          value: this.dateTimeService.format(new Date(identityItem.createdAt).getTime(), DATE_FORMAT_MMM_DD_YYYY),
        },
        {
          name: providerTitle,
          value: identityItem.remoteUniqueId
        },
        {
          name: regionTitle,
          value: this.regionIdentifyerConverstion(identityItem)
        }
      ]
    };

    if (Object.keys(identityItem).includes('email') && identityItem.email !== '' && identityItem.email !== null) {
      tmpIdentity.meta.unshift({
        name: 'Email',
        value: identityItem.email
      });
    }

    return tmpIdentity;
  }

  isConsideredNewIdentity(identity: any): boolean {
    const now = new Date().getTime() / 1000;
    const yesterday = now - 86400;

    if (identity.createdAtEpoch > yesterday) {
      return true;
    }
    return false;
  }

  isConsideredUpdatedIdentity(identity: any): boolean {
    const now = new Date().getTime() / 1000;
    const yesterday = now - 86400;

    const isSameDate = this.dateTimeService.compareDatesForSameDay(identity.createdAtEpoch, identity.modifiedAtEpoch);
    
    if (isSameDate === false && identity.modifiedAtEpoch > yesterday) {
      return true;
    }
    return false;
  }  

  async getAll(): Promise<any> {

    try {
      const pageSize = 100;
      const identityResponses = [];

      const firstPage = await this.requestIdentitypage(1, pageSize);
      const pages = firstPage['body'].meta.pagination.pages;
      identityResponses.push(firstPage.body);

      for (let index = 2; index <= pages; index++) {
        const requestedPage = await this.requestIdentitypage(index, pageSize);
        identityResponses.push(requestedPage.body);
      }

      return identityResponses;
    }
    catch (error) {
      throw error;
    }
  }

  async requestIdentitypage(page: number, pageSize: number): Promise<any> {
    const baseUri = environment.openbridgeApiUris.identities + '/sri';
    const identitiesGetUri = baseUri + '?sort=-created_at&page_size=' + pageSize + '&page=' + page;
    return await observableToPromise(this.httpClient.get(identitiesGetUri, { observe: 'response' }));
  }

  appendArrayToArray(firstArray, secondArray): any[] {
    return [
      ...firstArray,
      secondArray
    ];
  }

  transformResponse(value: any): any {

    const newDate = value.attributes.created_at;
    const transformedData = {
      id: +value.id,
      identityHash: value.attributes.identity_hash,
      name: value.attributes.name,
      accountId: value.attributes.account_id,
      userId: value.attributes.user_id,
      firstName: value.relationships.user.first_name,
      lastName: value.relationships.user.last_name,
      remoteIdentityTypeId: +value.relationships.remote_identity_type.data.id,
      invalidIdentity: value.attributes.invalid_identity,
      remoteUniqueId: value.attributes.remote_unique_id,
      createdAt: value.attributes.created_at,
      createdAtEpoch: (this.dateTimeService.convertTimeStampToEpoch(value.attributes.created_at + '+00:00')),
      modifiedAt: value.attributes.modified_at,
      modifiedAtEpoch: (this.dateTimeService.convertTimeStampToEpoch(value.attributes.modified_at + '+00:00')),
      region: value.attributes.region,
      email: value.attributes.email,
      external: (value.attributes?.external) ? value.attributes?.external : false,
      customerAlias: (value.attributes?.customer_alias) ? value.attributes?.customer_alias : null
    };

    const identityType = this.identityTypeService.findIdentityTypeFromId(transformedData.remoteIdentityTypeId);
    // check for identity type is exist for this remoteIdentity if yes
    // then return the transformedData otherwise return null
    if (identityType) {
      return transformedData;
    }

  }

  identityExists(remoteIdentityTypeId: number, remoteUniqueId: string): boolean {
    let exists = false;
    this.store$.select('identities').pipe(take(1)).subscribe(state => {
      const identities: any[] = [];
      state.identities.forEach(identityItem => {
        if (identityItem.remoteIdentityTypeId === remoteIdentityTypeId && identityItem.remoteUniqueId === remoteUniqueId) {
          exists = true;
        }
      });
    });
    return exists;
  }

  async reauthOauthIdentity(identity: any, returnUrl: string): Promise<void> {

    const identityReauthParams = {
      remoteIdentityId: identity.id,
      accountId: identity.accountId,
      userId: identity.userId,
      remoteIdentityTypeId: identity.remoteIdentityTypeId,
      region: identity.region,
      external: (identity?.external && identity.external === true) ? true : false,
      returnUrl: returnUrl
    };

    if (identity.remoteIdentityTypeId === identityTypeIdShopify) {
      this.router.navigate(['/identity/shopify/shopify'], { queryParams: { stage: 'redirect_link' } });
    }
    else {
      const stateResponse = await this.apiStateService.createOauthState(identityReauthParams);
      const oauthUrl = environment.openbridgeApiUris.oauth +
      '/initialize?state=' + stateResponse.body['data']['id'];

      if(identity.external === true) {
        this.openModalToCreateExternalIdentityLink(oauthUrl);
      }
      else {
        window.location.href = oauthUrl;
      }
    }
  }

  regionIdentifyerConverstion(identityItem: any): string {
    const hasKey = Object.keys(regionIdentifiers).includes(identityItem.region);

    if (hasKey) {
      return regionIdentifiers[identityItem.region];
    }

    return identityItem.region;
  }

  deletable(identity: any): boolean {

    let subscriptions = this.pipelines.filter((sub) => { 
      if(sub.remoteIdentityId === identity.id) {
        return sub;
      }
    });

    subscriptions = subscriptions.filter((sub) => { 
      if(sub.status !== 'inactive') {
        return sub;
      }
    });      

    return (subscriptions.length > 0) ? false : true;

  }

  async deleteIdentity(identityId: number): Promise<any> {
    const identitiesDeleteUri = environment.openbridgeApiUris.identities + '/ri/' + identityId;
    const response = await observableToPromise(this.httpClient.delete(identitiesDeleteUri));
    this.store$.dispatch(identityDelete({remoteIdentityId: identityId}));
    return response;
  }

  async updateIdentityCustomerAlias(identityId: number, customerAlias: string): Promise<any> {
    
    const payload = {
      data: {
        type: 'RemoteIdentity',
        id: identityId,
        attributes: {
          customer_alias: customerAlias
        }
      }
    };

    const identitiesPatchUri = environment.openbridgeApiUris.identities + '/ri/' + identityId;
    return await observableToPromise(this.httpClient.patch(identitiesPatchUri, payload));
  }

  private openModalToCreateExternalIdentityLink(thirdPartyShareUrl: string): void {
    const initialState: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      initialState: {
        externalIdentityLink: thirdPartyShareUrl,
        integration: null
      }
    };

    this.bsModalRef = this.modalService.show(ModalThirdPartyIdentityComponent, initialState);
  }

  private closeModalWithComponent(): void {
    if (this.bsModalRef !== null) {
      this.bsModalRef.hide();
    }
    this.bsModalRef = null;
  }
}
