<div class="row align-items-center">
  <div class="col">
    <!-- Nav -->
    <ul class="nav nav-tabs nav-overflow header-tabs">
      <li class="nav-item">
        <a  routerLink="/jobs/{{ subscriptionId }}" routerLinkActive="active" class="nav-link text-nowrap" id="primary"> Jobs
        </a>
      </li>
      <li class="nav-item">
        <a routerLink="/jobs/transactions/{{ subscriptionId}}" routerLinkActive="active" class="nav-link text-nowrap" id="history"> Transactions
        </a>
      </li>
    </ul>
  </div>
</div>