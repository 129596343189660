import { Component, Input } from '@angular/core';
import { ImageServiceService } from '../../services/image-service.service';

@Component({
  selector: 'app-wizard-header',
  templateUrl: './wizard-header.component.html'
})
export class WizardHeaderComponent {
  @Input() logoPath = '';
  @Input() logoText = '';
  @Input() headline = '';
  @Input() badge = 'none';
  @Input() tagline = '';
  @Input() stagePosition = 0;
  @Input() totalStages = 0;


  constructor(
    public imageServiceService: ImageServiceService
  ) {
  }
}