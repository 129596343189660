import { interval, take, lastValueFrom, Observable } from 'rxjs';

export const copyDetails = (input: any): boolean => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(JSON.stringify(input));
  } else {
    console.info('Browser Not compatible');
    // alert('Browser Not compatible');
  }
  return false;
}

export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

export const findObjectByKeyValue = <T>( array: T[], key: keyof T, value: any): T | undefined => {
  return array.find(obj => obj[key] === value);
}

export const isIterable = (obj: any): obj is Iterable<any> => {
  return typeof obj[Symbol.iterator] === 'function';
}

export const getQueueUrlFromArn = (arn: string): string => {
  // Split the ARN into its parts
  const arnParts = arn.split(":");
  
  // Ensure the ARN has the correct format
  if (arnParts.length !== 6 || arnParts[0] !== "arn" || arnParts[2] !== "sqs") {
      throw new Error("Invalid SQS ARN format");
  }

  // Extract region, account ID, and queue name
  const region = arnParts[3];
  const accountId = arnParts[4];
  const queueName = arnParts[5];

  // Construct the SQS queue URL
  return `https://sqs.${region}.amazonaws.com/${accountId}/${queueName}`;
}

export const observableToPromise = async <T>(source$: Observable<T>): Promise<T> => {
  return await lastValueFrom(source$);
}