import { Pipe, PipeTransform } from '@angular/core';
import { regionIdentifiersFlags } from '../constants/region';

@Pipe({
  name: 'flagFromRegionId'
})
export class FlagFromRegionIdPipe implements PipeTransform {

  findFlagUrlFromRegionName(regionName: string): any {
    let result: any = null;

    result = regionIdentifiersFlags.filter((region) => {
      return region.name === regionName;
    });
    if (result.length === 1) {
      return result[0];
    }
    return false;
  }

  transform(name: string): string {
    const defaultFlagURL: string = '/assets/img/avatars/regions/global.svg';
    const regionFlag = this.findFlagUrlFromRegionName(name);
    if (regionFlag) {
      return regionFlag.flagURL;
    } else {
      return defaultFlagURL;
    }
  }

}
