import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { flashNotificationsClear } from 'src/app/core/store/actions/flash-notifications.actions';
import { AppState } from 'src/app/core/store/reducers';
import { FlashNotification } from 'src/app/shared/models/flash-notification.model';
import { colors } from '../../charts/chart-options';

@Component({
  selector: 'app-card-daily-pipelines',
  templateUrl: './card-daily-pipelines.component.html',
  styleUrls: ['./card-daily-pipelines.component.scss']
})
export class CardDailyPipelinesComponent implements OnInit {
  // limiting the number of labels on x axis
  @Input() barChartTickLimit: number;
  @Input() barChartId: string;

  // Chart Data related
  barChartDataSets: any[] = [];
  barChartLabels: any[] = [];

  notifications: FlashNotification[] = [];
  accountReducerSubscription$: any;
  dailyPipelines: any;

  constructor(
    private store$: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.store$.dispatch(flashNotificationsClear());
    this.store$.select('flashNotifications').subscribe((response) => {
      this.notifications = response.notifications;
    });

    this.accountReducerSubscription$ = this.store$.select('account').subscribe(response => {
      if (response.dailyPipelines !== null) {
        this.dailyPipelines = response.dailyPipelines;
        const labels = [...response.dailyPipelines.labels];
        const data = [...response.dailyPipelines.data];
        labels.splice(30, 1);
        data.splice(30, 1);
        this.barChartLabels = labels;
        this.barChartDataSets = [{
          maxBarThickness: 10,
          hoverBackgroundColor: colors['700'],
          backgroundColor: colors['300'],
          data: data
        }];
      }
    });
  }
}
