<ul *ngIf="fileState.length > 0" class="list-group list-group-lg list-group-flush list my-n4 picklist"
  [formGroup]="parentForm">
  <li class="list-group-item" *ngFor="let state of fileState; let i = index; let last = last">
    <div class="row">
      <div class="col-auto align-self-start">
        <!-- icon -->
        <div class="avatar avatar-lg">
          <span class="avatar-title rounded-circle text-wait ps-2" style="font-size: 2rem;">
            <i class="fa-duotone fa-file-csv"></i>
          </span>
        </div>
      </div>

      <ng-container *ngIf="state.dropzoneRejected === false; else dropzoneRejectedFiles">
        <div class="col-8 align-self-center ms-n2">
          <!-- Title -->
          <h4 class="mb-1 name text-white">
            Filename:
            <span id="Filename" [tooltip]="state.file.name">
              {{ state.file.name | truncate:[40, '...'] }}
            </span>

          </h4>
          <!-- Type -->
          <p class="card-text small text-muted mb-1">
            Type: {{ state.file.type }} | Size: {{ filterFileSize(state.file.size) }} {{
            filterUnitBasedOnFileSize(state.file.size) }}
          </p>

          <p class="card-text small text-muted mb-1">
            <span class="text-capitalize">
              {{ state.status }}
            </span>
            <span *ngIf="state.status === 'completed'">
              | Status:
              <span class="badge" [ngClass]="state.verifyResponse?.ok === true ? 'bg-success' : 'bg-danger'">
                {{ state.verifyResponse?.ok === true ? 'SUCCESS' : 'ERROR'}}
              </span>
            </span>
          </p>

          <div class="progress progress-sm mt-2" *ngIf="state.status !== 'completed'">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="25"
              aria-valuemin="0" aria-valuemax="100" [style.width.%]="state.progress"></div>
          </div>

        </div>
      </ng-container>

      <ng-template #dropzoneRejectedFiles>
        <div class="col-8 align-self-center ms-n2">
          <!-- Title -->
          <h4 class="mb-1 name text-white">
            Filename:
            <span id="Filename" [tooltip]="state.file.name">
              {{ state.file.name | truncate:[40, '...'] }}
            </span>
          </h4>

          <p class="card-text small text-muted mb-1" [ngSwitch]="state.file.reason">
            Reason:
            <span *ngSwitchCase="'type'" id="fileErrorReason">
              An incorrect file type <strong>{{state.file.type}}</strong> was provided.
            </span>
            <span *ngSwitchCase="'size'" id="fileErrorReason">
              Supplied file exceeds the maximum file size limit.
            </span>
            <span *ngSwitchCase="'no_multiple'" id="fileErrorReason">
              More than one file is provided.
            </span>
            <span *ngSwitchDefault id="fileErrorReason">
              Unknown error occurred with supplied file.
            </span>
          </p>


          <p class="card-text small text-muted mb-1">
            <span class="text-capitalize">
              {{ state.status }}
            </span>
            <span>
              | Status: <span class="badge bg-danger">ERROR</span>
            </span>
          </p>

        </div>
      </ng-template>

      <div class="col-2 align-self-center text-center">
        <div
          *ngIf="state.status === 'completed' && state.verifyResponse?.ok === true && state.dropzoneRejected === false">

          <input type="radio" [attr.name]="controlName" style="display: none;" id="{{ state.hashId }}"
            value="{{ state.hashId }}" [formControlName]="controlName">
          <span class="mt-3 me-4 avatar-title rounded text-success h1 ng-tns-c100-0 radio-selected">
            <span class="fe fe-check ng-tns-c100-0">
            </span>
          </span>
          <label for="{{ state.hashId }}" class="btn btn-sm btn-white d-none d-md-inline-block" id="radioBtn">
            Select
          </label>

        </div>
      </div>
      <div class="col-auto align-self-center">
        <!-- Dropdown -->
        <div class="btn-group" dropdown #dropdown="bs-dropdown" container="body" *ngIf="state.status === 'completed'">
          <a dropdownToggle (click)="clickDropdown($event, dropdown)" type="button"
            class="dropdown-toggle dropdown-ellipses">
            <i class="fe fe-more-vertical"></i>
          </a>
          <div *dropdownMenu class="dropdown-menu dropdown-menu-right" [ngClass]="{'dropdown-up': last == true}"
            role="menu">
            <a *ngIf="state.verifyResponse?.ok === true" class="dropdown-item cursor-pointer"
              (click)="downloadCsvSchema(state)"> Download Schema </a>
            <a *ngIf="state.verifyResponse?.ok === false" class="dropdown-item cursor-pointer"
              (click)="displayErrorDetails(state.verifyResponse)"> View Error </a>
            <a class="dropdown-item cursor-pointer" (click)="removeFile(state.hashId)"> Remove </a>
          </div>
        </div>

      </div>
    </div>
  </li>
</ul>