import { HttpParams } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { Integration } from '../models/integration.model';

@Pipe({
  name: 'integrationDocLink'
})
export class IntegrationDocLinkPipe implements PipeTransform {

  docLink = 'https://docs.openbridge.com/en/';
  searchParameters: string;

  transform(integration: Integration): string {

    if (integration.brand === 'Azure' || integration.brand === 'Snowflake' || integration.name === 'Google BigQuery') {
      this.searchParameters = integration.name.toLowerCase();
    } else {
      this.searchParameters = integration.brand.toLowerCase() + ' ' + integration.name.toLowerCase();
    }

    const searchParametersFormatted = this.searchParameters.replace(/\s+/g, '+');

    const params = {
      q: searchParametersFormatted
    };

    const httpParams: HttpParams = new HttpParams({ fromObject: params });
    const requestUrl = this.docLink + '?' + httpParams.toString();
    return requestUrl;
  }

}
