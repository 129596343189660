import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-filter-nav-jobs',
  templateUrl: './filter-nav-jobs.component.html',
  styleUrls: ['./filter-nav-jobs.component.scss']
})
export class FilterNavJobsComponent implements OnInit, OnChanges {
  @Input() jobs: any[] = [];
  @Output() filteredJobs = new EventEmitter<any[]>();
  allJobsCount: number;
  allPrimaryCount: number;
  allHistoryCount: number;
  activeFilterType: 'all' | 'primary' | 'history' = 'all';
  // show primary vs show history vs show all
  constructor() { }

  ngOnInit(): void {
    this.allJobsCount = this.jobs.length;
    this.allPrimaryCount = this.countPipelinesByType('primary');
    this.allHistoryCount = this.countPipelinesByType('history');
    this.setFilter('all');
  }

  ngOnChanges(): void {
    this.allJobsCount = this.jobs.length;
    this.allPrimaryCount = this.countPipelinesByType('primary');
    this.allHistoryCount = this.countPipelinesByType('history');
    this.setFilter(this.activeFilterType);
  }

  countPipelinesByType(filterType: 'primary' | 'history'): number {
    let count = 0;
    this.jobs.forEach((val, idx) => {
      if (filterType === 'primary' && val.attributes.is_primary === true) {
        count++;
      }
      if (filterType === 'history' && val.attributes.is_primary === false) {
        count++;
      }
    });
    return count;
  }

  setFilter(filterType: 'all' | 'primary' | 'history'): boolean {
    this.activeFilterType = filterType;
    let filteredJobs: any[] = [];
    if (filterType === 'all') {
      filteredJobs = [...this.jobs];
    }
    else {
      this.jobs.forEach((val, idx) => {

        if (filterType === 'primary' && val.attributes.is_primary === true) {
          filteredJobs.push(val);
        }
        if (filterType === 'history' && val.attributes.is_primary === false) {
          filteredJobs.push(val);
        }
      });
    }

    this.filteredJobs.emit(filteredJobs);
    // Return false to prevent default.
    return false;
  }

}
