<!-- Image -->
<div class="avatar avatar-sm card-avatar">
  <img src="{{integration.logoPath}}" class="avatar-img rounded" alt="..."
    (error)="imageServiceService.handleMissingImage($event)">
</div>
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader"> Getting Started </h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline"> Fully-automated data pipeline </p>
<!-- Divider -->
<hr class="mb-4">
<!-- Heading-->
<h4 class="mt-2 mb-2"> Getting ready for {{ integration.name }} automated data pipelines </h4>
<ol>
  <li class="text-muted mb-2">
    If you do not have an <strong>{{ integration.name }}</strong> account, it is easy to get started.
    <strong>{{ integration.name }}</strong> offers a quick and simple
    <a [href]="integration.vendor.uri" rel="noopener" target="_blank">online registration</a> for new customers
  </li>
  <li class="text-muted mb-2">
    If you already have an {{ integration.name }} account, make sure you have the correct permissions to grant access to
    your account to allow Openbridge to collect performance data.
  </li>
  <li class="text-muted mb-2">See the {{ integration | integrationFullname }}
    <a href="{{ integration | integrationDocLink}}" rel="noopener" target="_blank">help docs</a> for
    guides, tips, and troubleshooting
  </li>
</ol>