import { Component, Input, OnInit } from '@angular/core';
import { Integration } from '../../models/integration.model';

@Component({
  selector: 'app-loading-notifier',
  templateUrl: './loading-notifier.component.html',
})
export class LoadingNotifierComponent implements OnInit {
  @Input() showDataLoading: boolean;
  @Input() integration: Integration;
  @Input() cardData: any[];
  @Input() profileType: string;

  constructor() { }

  ngOnInit(): void {
  }

}
