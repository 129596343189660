<div class="modal-header">
  <h4 class="modal-title pull-left">Error Details</h4>
  <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="mb-1">
    <pre>
      <code id="errorDetails">
        {{ details }}
      </code>
    </pre>
    <a href class="btn btn-sm btn-info mt-3" (click)="copyMessage(details);">Copy Details</a>
  </div>
</div>