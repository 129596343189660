import { Component, Input, OnInit } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';

@Component({
  selector: 'app-chart-doughnut',
  templateUrl: './chart-doughnut.component.html'
})
export class ChartDoughnutComponent implements OnInit {
 // Data Related
 @Input() chartDataSets: any[];
 @Input() chartLabels: any[];
 @Input() chartPlugins: any[];
 @Input() chartLegend: boolean;

 // Style related.
 @Input() chartClass: string;
 @Input() chartHeight: string;
 @Input() chartStyle: string;
 @Input() chartWidth: string;

 chartType: ChartType = 'doughnut';
 chartOptions: ChartOptions;

 constructor() { }

 ngOnInit(): void {
   this.chartOptions = {
    //   elements: {
    //     arc: {
    //       borderWidth: 2,
    //       borderColor: colors.blue
    //     },
    //   },
    //   responsive: true,
    //   cutoutPercentage: 83,
    //   scales: {
       
    //    yAxes: [{
    //      id: 'yAxisOne',
    //      type: 'linear',
    //      display: 'auto',
    //      ticks: {
    //        callback: function(value) {
    //          return '$' + value + 'k';
    //        }
    //      }
    //    }, {
    //      id: 'yAxisTwo',
    //      type: 'linear',
    //      display: 'auto',
    //      ticks: {
    //        callback: function(value) {
    //          return value + 'hrs';
    //        }
    //      }
    //    }]
    //  }
   }
 }
}
