export interface DspReportDefinition {
  id: number;
  dimensions: string[];
  alias: string;
}

export interface DspReportGroup {
  report_type: string;
  reports: DspReportDefinition[];
}

export function findReportByAlias(myReportData: any, alias: string): DspReportDefinition | null {
  // Loop through each report group
  for (const group of myReportData) {
    // Find the report with the matching alias in the current group's reports
    const foundReport = group.reports.find(report => report.alias === alias);
    if (foundReport) {
      return foundReport; // Return the matching report
    }
  }

  // If no report is found, return null
  return null;
}

export const dspReportMap = [
    {
        "report_type": "audience",
        "reports": [
            {
                "id": 0,
                "dimensions": [
                    "order"
                ],
                "alias": "audience_70a17ffa722a3985b86d30b034ad06d7"
            },
            {
                "id": 1,
                "dimensions": [
                    "lineItem"
                ],
                "alias": "audience_867cf1ff92974922abc5d5b045b4a2d8"
            },
            {
                "id": 2,
                "dimensions": [
                    "order",
                    "lineItem"
                ],
                "alias": "audience_8d7cecaece9ed583d9854d1891aede73"
            }
        ]
    },
    {
        "report_type": "audio_and_video",
        "reports": [
            {
                "id": 3,
                "dimensions": [
                    "campaign"
                ],
                "alias": "audio_and_video_6c8bff2269a89ef7ec3cf87e72be656f"
            },
            {
                "id": 4,
                "dimensions": [
                    "ad"
                ],
                "alias": "audio_and_video_523af537946b79c4f8369ed39ba78605"
            },
            {
                "id": 5,
                "dimensions": [
                    "creative"
                ],
                "alias": "audio_and_video_828e096fef42d94858dd49b27ab903f3"
            },
            {
                "id": 6,
                "dimensions": [
                    "supplySource"
                ],
                "alias": "audio_and_video_cd902b516deb1f20d9a454c270797a6d"
            },
            {
                "id": 7,
                "dimensions": [
                    "content"
                ],
                "alias": "audio_and_video_9a0364b9e99bb480dd25e1f0284c8555"
            },
            {
                "id": 8,
                "dimensions": [
                    "campaign",
                    "ad"
                ],
                "alias": "audio_and_video_d7415c0269f588d632099daec589675d"
            },
            {
                "id": 9,
                "dimensions": [
                    "campaign",
                    "creative"
                ],
                "alias": "audio_and_video_f80fb70fc9b366636256d6c4b1cfbd8f"
            },
            {
                "id": 10,
                "dimensions": [
                    "campaign",
                    "supplySource"
                ],
                "alias": "audio_and_video_569aa96a6c96ab18b83464ac8cfc6898"
            },
            {
                "id": 11,
                "dimensions": [
                    "campaign",
                    "content"
                ],
                "alias": "audio_and_video_127ff684a261f79b34d639cacf1a00d9"
            },
            {
                "id": 12,
                "dimensions": [
                    "ad",
                    "creative"
                ],
                "alias": "audio_and_video_8f7da534f547cfa47a47c51e2dad0ef1"
            },
            {
                "id": 13,
                "dimensions": [
                    "ad",
                    "supplySource"
                ],
                "alias": "audio_and_video_6545bc3a8e589676d4c543a35496812d"
            },
            {
                "id": 14,
                "dimensions": [
                    "ad",
                    "content"
                ],
                "alias": "audio_and_video_a76cac8a6b3c5b9398709b3f235e0c94"
            },
            {
                "id": 15,
                "dimensions": [
                    "creative",
                    "supplySource"
                ],
                "alias": "audio_and_video_1249f18471ea947282f23b6342982e6c"
            },
            {
                "id": 16,
                "dimensions": [
                    "creative",
                    "content"
                ],
                "alias": "audio_and_video_8d7e1c99c9566ef6cec56a82cf065791"
            },
            {
                "id": 17,
                "dimensions": [
                    "supplySource",
                    "content"
                ],
                "alias": "audio_and_video_343d78bc1dd0932302d716daa5b6b25a"
            },
            {
                "id": 18,
                "dimensions": [
                    "campaign",
                    "ad",
                    "creative"
                ],
                "alias": "audio_and_video_1cdef549c0c5537255354e9631b840fe"
            },
            {
                "id": 19,
                "dimensions": [
                    "campaign",
                    "ad",
                    "supplySource"
                ],
                "alias": "audio_and_video_fcb83b978bba06a0509220da600c6346"
            },
            {
                "id": 20,
                "dimensions": [
                    "campaign",
                    "ad",
                    "content"
                ],
                "alias": "audio_and_video_b708e1879b31b8d9bcfe23b18f78a87e"
            },
            {
                "id": 21,
                "dimensions": [
                    "campaign",
                    "creative",
                    "supplySource"
                ],
                "alias": "audio_and_video_38dcc37c3c87ea306d0c81b2c9040fb0"
            },
            {
                "id": 22,
                "dimensions": [
                    "campaign",
                    "creative",
                    "content"
                ],
                "alias": "audio_and_video_adf9d0d2861af32deea81c8ba0524d68"
            },
            {
                "id": 23,
                "dimensions": [
                    "campaign",
                    "supplySource",
                    "content"
                ],
                "alias": "audio_and_video_9d828aa3a32a873a10b16ba64c661696"
            },
            {
                "id": 24,
                "dimensions": [
                    "ad",
                    "creative",
                    "supplySource"
                ],
                "alias": "audio_and_video_d37345d8ea66cd463456c64759620ddd"
            },
            {
                "id": 25,
                "dimensions": [
                    "ad",
                    "creative",
                    "content"
                ],
                "alias": "audio_and_video_3f8cc1f0749965ec193f5053a8fdb672"
            },
            {
                "id": 26,
                "dimensions": [
                    "ad",
                    "supplySource",
                    "content"
                ],
                "alias": "audio_and_video_e878eee6cb5f3c36095013d2eb5e8aa3"
            },
            {
                "id": 27,
                "dimensions": [
                    "creative",
                    "supplySource",
                    "content"
                ],
                "alias": "audio_and_video_de27b585716bdf0dce5941bb421bd2bc"
            },
            {
                "id": 28,
                "dimensions": [
                    "campaign",
                    "ad",
                    "creative",
                    "supplySource"
                ],
                "alias": "audio_and_video_007e218c026dd5880549196343df4721"
            },
            {
                "id": 29,
                "dimensions": [
                    "campaign",
                    "ad",
                    "creative",
                    "content"
                ],
                "alias": "audio_and_video_83ec1be9f31f3c103a0544188a294b97"
            },
            {
                "id": 30,
                "dimensions": [
                    "campaign",
                    "ad",
                    "supplySource",
                    "content"
                ],
                "alias": "audio_and_video_ebab02047e9e2bb4546b391b7601a474"
            },
            {
                "id": 31,
                "dimensions": [
                    "campaign",
                    "creative",
                    "supplySource",
                    "content"
                ],
                "alias": "audio_and_video_bfafa573e51c294fa864399099a6350d"
            },
            {
                "id": 32,
                "dimensions": [
                    "ad",
                    "creative",
                    "supplySource",
                    "content"
                ],
                "alias": "audio_and_video_894cb5053edb53c72b7d234de6db5863"
            },
            {
                "id": 33,
                "dimensions": [
                    "campaign",
                    "ad",
                    "creative",
                    "supplySource",
                    "content"
                ],
                "alias": "audio_and_video_d0ecc32a06c30e33e562a440e21bdec6"
            }
        ]
    },
    {
        "report_type": "campaign",
        "reports": [
            {
                "id": 34,
                "dimensions": [
                    "campaign"
                ],
                "alias": "campaign_6c8bff2269a89ef7ec3cf87e72be656f"
            },
            {
                "id": 35,
                "dimensions": [
                    "ad"
                ],
                "alias": "campaign_523af537946b79c4f8369ed39ba78605"
            },
            {
                "id": 36,
                "dimensions": [
                    "creative"
                ],
                "alias": "campaign_828e096fef42d94858dd49b27ab903f3"
            },
            {
                "id": 37,
                "dimensions": [
                    "campaign",
                    "ad"
                ],
                "alias": "campaign_d7415c0269f588d632099daec589675d"
            },
            {
                "id": 38,
                "dimensions": [
                    "campaign",
                    "creative"
                ],
                "alias": "campaign_f80fb70fc9b366636256d6c4b1cfbd8f"
            },
            {
                "id": 39,
                "dimensions": [
                    "ad",
                    "creative"
                ],
                "alias": "campaign_8f7da534f547cfa47a47c51e2dad0ef1"
            },
            {
                "id": 40,
                "dimensions": [
                    "campaign",
                    "ad",
                    "creative"
                ],
                "alias": "campaign_1cdef549c0c5537255354e9631b840fe"
            }
        ]
    },
    {
        "report_type": "geo",
        "reports": [
            {
                "id": 41,
                "dimensions": [
                    "order"
                ],
                "alias": "geo_70a17ffa722a3985b86d30b034ad06d7"
            },
            {
                "id": 42,
                "dimensions": [
                    "lineItem"
                ],
                "alias": "geo_867cf1ff92974922abc5d5b045b4a2d8"
            },
            {
                "id": 43,
                "dimensions": [
                    "country"
                ],
                "alias": "geo_e909c2d7067ea37437cf97fe11d91bd0"
            },
            {
                "id": 44,
                "dimensions": [
                    "region"
                ],
                "alias": "geo_960db2ed82202a9706b97775a4269378"
            },
            {
                "id": 45,
                "dimensions": [
                    "city"
                ],
                "alias": "geo_4ed5d2eaed1a1fadcc41ad1d58ed603e"
            },
            {
                "id": 46,
                "dimensions": [
                    "postalCode"
                ],
                "alias": "geo_e0b7d6e40ee559d78fd0c98aab89ab8b"
            },
            {
                "id": 47,
                "dimensions": [
                    "dma"
                ],
                "alias": "geo_695578cfd3a6c798fefb9d3f475360ba"
            },
            {
                "id": 48,
                "dimensions": [
                    "order",
                    "lineItem"
                ],
                "alias": "geo_8d7cecaece9ed583d9854d1891aede73"
            },
            {
                "id": 49,
                "dimensions": [
                    "order",
                    "country"
                ],
                "alias": "geo_d63aaa46405146a8b88eb0b9ae696676"
            },
            {
                "id": 50,
                "dimensions": [
                    "order",
                    "region"
                ],
                "alias": "geo_e31a75042b8b75c084dbc2c49b3ca48a"
            },
            {
                "id": 51,
                "dimensions": [
                    "order",
                    "city"
                ],
                "alias": "geo_c454d45e0fa6a1651e7b6f05ee12db60"
            },
            {
                "id": 52,
                "dimensions": [
                    "order",
                    "postalCode"
                ],
                "alias": "geo_bf22de016494902fdbfc25ed7696cb77"
            },
            {
                "id": 53,
                "dimensions": [
                    "order",
                    "dma"
                ],
                "alias": "geo_5c2b5bc9da0db6234fcb0c9062036da2"
            },
            {
                "id": 54,
                "dimensions": [
                    "lineItem",
                    "country"
                ],
                "alias": "geo_0302327f9a1fa0607b5b1a3a0ef6b121"
            },
            {
                "id": 55,
                "dimensions": [
                    "lineItem",
                    "region"
                ],
                "alias": "geo_b7e745c43be53628ded5af20dbb113e9"
            },
            {
                "id": 56,
                "dimensions": [
                    "lineItem",
                    "city"
                ],
                "alias": "geo_a8d34d0bc65713af77bb0c2017f704f7"
            },
            {
                "id": 57,
                "dimensions": [
                    "lineItem",
                    "postalCode"
                ],
                "alias": "geo_1bcbeaa5af25c7a9f836a93aa8fc409f"
            },
            {
                "id": 58,
                "dimensions": [
                    "lineItem",
                    "dma"
                ],
                "alias": "geo_5567ced5b07cd4ad0706124be9d52aea"
            },
            {
                "id": 59,
                "dimensions": [
                    "country",
                    "region"
                ],
                "alias": "geo_9ada558c8793dfe0b0ea04fed7d26247"
            },
            {
                "id": 60,
                "dimensions": [
                    "country",
                    "city"
                ],
                "alias": "geo_da26bfa8c9a4810dfb8c4d465bba8dc0"
            },
            {
                "id": 61,
                "dimensions": [
                    "country",
                    "postalCode"
                ],
                "alias": "geo_ca9a0ab163d195534c2de81ac10c7726"
            },
            {
                "id": 62,
                "dimensions": [
                    "country",
                    "dma"
                ],
                "alias": "geo_ca62fce5b4abe7263caa1eac4ceb0f35"
            },
            {
                "id": 63,
                "dimensions": [
                    "region",
                    "city"
                ],
                "alias": "geo_63888208a5229aaf0254dadde2e47fc9"
            },
            {
                "id": 64,
                "dimensions": [
                    "region",
                    "postalCode"
                ],
                "alias": "geo_66b55332bc992d289b3e9aab784808d4"
            },
            {
                "id": 65,
                "dimensions": [
                    "region",
                    "dma"
                ],
                "alias": "geo_5417dbd70b5160428f706bb7c0f56ced"
            },
            {
                "id": 66,
                "dimensions": [
                    "city",
                    "postalCode"
                ],
                "alias": "geo_965641e7e25f006cf244cb9f934ce012"
            },
            {
                "id": 67,
                "dimensions": [
                    "city",
                    "dma"
                ],
                "alias": "geo_32debeeb2b231a7786bc7ba899e6e6e0"
            },
            {
                "id": 68,
                "dimensions": [
                    "postalCode",
                    "dma"
                ],
                "alias": "geo_dfa62099aa51acf254d9763cd580225e"
            },
            {
                "id": 69,
                "dimensions": [
                    "order",
                    "lineItem",
                    "country"
                ],
                "alias": "geo_b5e21b4afe0dd8dbd61ccfcb1b0537e1"
            },
            {
                "id": 70,
                "dimensions": [
                    "order",
                    "lineItem",
                    "region"
                ],
                "alias": "geo_4df5d91f0ec71788ea101deedec43588"
            },
            {
                "id": 71,
                "dimensions": [
                    "order",
                    "lineItem",
                    "city"
                ],
                "alias": "geo_7f0b640030d3a29f6611ed58bb23ee2f"
            },
            {
                "id": 72,
                "dimensions": [
                    "order",
                    "lineItem",
                    "postalCode"
                ],
                "alias": "geo_14cb9daeace5df87207ccce889d0e9fb"
            },
            {
                "id": 73,
                "dimensions": [
                    "order",
                    "lineItem",
                    "dma"
                ],
                "alias": "geo_b0eb1037484a3b8421eb8829ed263680"
            },
            {
                "id": 74,
                "dimensions": [
                    "order",
                    "country",
                    "region"
                ],
                "alias": "geo_8801bfe51a3b4602ec72e012d07b8965"
            },
            {
                "id": 75,
                "dimensions": [
                    "order",
                    "country",
                    "city"
                ],
                "alias": "geo_4513d172013ba47de1c0ff23f13880dd"
            },
            {
                "id": 76,
                "dimensions": [
                    "order",
                    "country",
                    "postalCode"
                ],
                "alias": "geo_b02c8e98b4f8338931d90b3de7b0bdad"
            },
            {
                "id": 77,
                "dimensions": [
                    "order",
                    "country",
                    "dma"
                ],
                "alias": "geo_ab640a5b061ea76544cbc082c1b48340"
            },
            {
                "id": 78,
                "dimensions": [
                    "order",
                    "region",
                    "city"
                ],
                "alias": "geo_fcf6f802fc0248af7228fdda028ca63c"
            },
            {
                "id": 79,
                "dimensions": [
                    "order",
                    "region",
                    "postalCode"
                ],
                "alias": "geo_48845f2a341d7914d296922c36e37ecb"
            },
            {
                "id": 80,
                "dimensions": [
                    "order",
                    "region",
                    "dma"
                ],
                "alias": "geo_00cbc2015fceae93f35c95c5aa40fdbb"
            },
            {
                "id": 81,
                "dimensions": [
                    "order",
                    "city",
                    "postalCode"
                ],
                "alias": "geo_f315d677847340a4b931897060705a93"
            },
            {
                "id": 82,
                "dimensions": [
                    "order",
                    "city",
                    "dma"
                ],
                "alias": "geo_2bb430ca0da60f565763c0896f570632"
            },
            {
                "id": 83,
                "dimensions": [
                    "order",
                    "postalCode",
                    "dma"
                ],
                "alias": "geo_fe0899bc1a08cafa0e3f882182b7288b"
            },
            {
                "id": 84,
                "dimensions": [
                    "lineItem",
                    "country",
                    "region"
                ],
                "alias": "geo_5002f310e2dad4e11f0d7ca6bceb426e"
            },
            {
                "id": 85,
                "dimensions": [
                    "lineItem",
                    "country",
                    "city"
                ],
                "alias": "geo_2fb6cca64d654f52c3eb2194fb95bf2b"
            },
            {
                "id": 86,
                "dimensions": [
                    "lineItem",
                    "country",
                    "postalCode"
                ],
                "alias": "geo_8f85d9ad527afaad52043a901d16848b"
            },
            {
                "id": 87,
                "dimensions": [
                    "lineItem",
                    "country",
                    "dma"
                ],
                "alias": "geo_4f04d40bf7f40024e9df7541f502786f"
            },
            {
                "id": 88,
                "dimensions": [
                    "lineItem",
                    "region",
                    "city"
                ],
                "alias": "geo_872467efd6f1bda3debf77e9eb170acb"
            },
            {
                "id": 89,
                "dimensions": [
                    "lineItem",
                    "region",
                    "postalCode"
                ],
                "alias": "geo_ed0c2a6a2809cf1d4f5ddcfaa5400f56"
            },
            {
                "id": 90,
                "dimensions": [
                    "lineItem",
                    "region",
                    "dma"
                ],
                "alias": "geo_0be9bf497ec853f798439e25f60b230b"
            },
            {
                "id": 91,
                "dimensions": [
                    "lineItem",
                    "city",
                    "postalCode"
                ],
                "alias": "geo_eb9513c489aed77648840c39e2c0d977"
            },
            {
                "id": 92,
                "dimensions": [
                    "lineItem",
                    "city",
                    "dma"
                ],
                "alias": "geo_794ae1ea7346257e3d62f16cfd92c34c"
            },
            {
                "id": 93,
                "dimensions": [
                    "lineItem",
                    "postalCode",
                    "dma"
                ],
                "alias": "geo_488fbfd62d81218e41d027800e0e5091"
            },
            {
                "id": 94,
                "dimensions": [
                    "country",
                    "region",
                    "city"
                ],
                "alias": "geo_231e64b867b4cc19de5e2f8df11bd1fe"
            },
            {
                "id": 95,
                "dimensions": [
                    "country",
                    "region",
                    "postalCode"
                ],
                "alias": "geo_8ae37d73beb9135f32022d5d49f3d557"
            },
            {
                "id": 96,
                "dimensions": [
                    "country",
                    "region",
                    "dma"
                ],
                "alias": "geo_d25a6c7dbbc7b5424a1952a11ae45ca2"
            },
            {
                "id": 97,
                "dimensions": [
                    "country",
                    "city",
                    "postalCode"
                ],
                "alias": "geo_28009747050edf858817d61af2d9bfd4"
            },
            {
                "id": 98,
                "dimensions": [
                    "country",
                    "city",
                    "dma"
                ],
                "alias": "geo_33e9442e64ce044f4f56f41b70c18319"
            },
            {
                "id": 99,
                "dimensions": [
                    "country",
                    "postalCode",
                    "dma"
                ],
                "alias": "geo_eaaaa18a3d4e20a284feb22a07f51122"
            },
            {
                "id": 100,
                "dimensions": [
                    "region",
                    "city",
                    "postalCode"
                ],
                "alias": "geo_183f2e2995dfff7f26c5bafe263520d4"
            },
            {
                "id": 101,
                "dimensions": [
                    "region",
                    "city",
                    "dma"
                ],
                "alias": "geo_f1f286dcb0b45a0bba4bfd5406391296"
            },
            {
                "id": 102,
                "dimensions": [
                    "region",
                    "postalCode",
                    "dma"
                ],
                "alias": "geo_c7b543f2db5feb1e5569f68942741ad3"
            },
            {
                "id": 103,
                "dimensions": [
                    "city",
                    "postalCode",
                    "dma"
                ],
                "alias": "geo_32d6bcaa44b011c9f7f8345e34a8966a"
            },
            {
                "id": 104,
                "dimensions": [
                    "order",
                    "lineItem",
                    "country",
                    "region"
                ],
                "alias": "geo_95ddf388d9f45ee6f6166abbdb276583"
            },
            {
                "id": 105,
                "dimensions": [
                    "order",
                    "lineItem",
                    "country",
                    "city"
                ],
                "alias": "geo_4f209a6777d0b70a152bd720bf82c795"
            },
            {
                "id": 106,
                "dimensions": [
                    "order",
                    "lineItem",
                    "country",
                    "postalCode"
                ],
                "alias": "geo_81a23def282b28af8d166920101fbfd0"
            },
            {
                "id": 107,
                "dimensions": [
                    "order",
                    "lineItem",
                    "country",
                    "dma"
                ],
                "alias": "geo_4a49996658731083f20bf81d79fc7d60"
            },
            {
                "id": 108,
                "dimensions": [
                    "order",
                    "lineItem",
                    "region",
                    "city"
                ],
                "alias": "geo_35874a8f411450abf12a777432681b0b"
            },
            {
                "id": 109,
                "dimensions": [
                    "order",
                    "lineItem",
                    "region",
                    "postalCode"
                ],
                "alias": "geo_4e48758212c94229942517454ce5d7fc"
            },
            {
                "id": 110,
                "dimensions": [
                    "order",
                    "lineItem",
                    "region",
                    "dma"
                ],
                "alias": "geo_d213efe0e5adaed380e684fb6a423c41"
            },
            {
                "id": 111,
                "dimensions": [
                    "order",
                    "lineItem",
                    "city",
                    "postalCode"
                ],
                "alias": "geo_e35d99ca054c1bc286bff5974bdc5ee5"
            },
            {
                "id": 112,
                "dimensions": [
                    "order",
                    "lineItem",
                    "city",
                    "dma"
                ],
                "alias": "geo_876d4bf4012d6c3a9f17bd73c83abc2d"
            },
            {
                "id": 113,
                "dimensions": [
                    "order",
                    "lineItem",
                    "postalCode",
                    "dma"
                ],
                "alias": "geo_adbbee1af6c54a6e120580b960f9dae5"
            },
            {
                "id": 114,
                "dimensions": [
                    "order",
                    "country",
                    "region",
                    "city"
                ],
                "alias": "geo_1b9bd1ebda71724a6ae7931a5a4781d8"
            },
            {
                "id": 115,
                "dimensions": [
                    "order",
                    "country",
                    "region",
                    "postalCode"
                ],
                "alias": "geo_b6ed3eab2c816f9f935aa7b96d7a7265"
            },
            {
                "id": 116,
                "dimensions": [
                    "order",
                    "country",
                    "region",
                    "dma"
                ],
                "alias": "geo_10b49d43fb493df69c141bdf3ad60356"
            },
            {
                "id": 117,
                "dimensions": [
                    "order",
                    "country",
                    "city",
                    "postalCode"
                ],
                "alias": "geo_99bd3bc09b2a5eebd84b1d8adba17631"
            },
            {
                "id": 118,
                "dimensions": [
                    "order",
                    "country",
                    "city",
                    "dma"
                ],
                "alias": "geo_e26102655da26ddc2ddb2661f71927e9"
            },
            {
                "id": 119,
                "dimensions": [
                    "order",
                    "country",
                    "postalCode",
                    "dma"
                ],
                "alias": "geo_139655e846a7c9a64d6bd0de0e6b8465"
            },
            {
                "id": 120,
                "dimensions": [
                    "order",
                    "region",
                    "city",
                    "postalCode"
                ],
                "alias": "geo_b9315f4e3e77187d667dc2894218adf6"
            },
            {
                "id": 121,
                "dimensions": [
                    "order",
                    "region",
                    "city",
                    "dma"
                ],
                "alias": "geo_cd62e2a8c25e79e50a002efa4bc9df12"
            },
            {
                "id": 122,
                "dimensions": [
                    "order",
                    "region",
                    "postalCode",
                    "dma"
                ],
                "alias": "geo_b43e26856dabbf58086b1ec6e933cc48"
            },
            {
                "id": 123,
                "dimensions": [
                    "order",
                    "city",
                    "postalCode",
                    "dma"
                ],
                "alias": "geo_ea90eaa174d595218cada70fc84bcf74"
            },
            {
                "id": 124,
                "dimensions": [
                    "lineItem",
                    "country",
                    "region",
                    "city"
                ],
                "alias": "geo_f800052eb5ac56b7f369f6ca33b042f6"
            },
            {
                "id": 125,
                "dimensions": [
                    "lineItem",
                    "country",
                    "region",
                    "postalCode"
                ],
                "alias": "geo_61a3b312fe4026a2df1a43497da95489"
            },
            {
                "id": 126,
                "dimensions": [
                    "lineItem",
                    "country",
                    "region",
                    "dma"
                ],
                "alias": "geo_5bf01cd92a977d05aa20be68a3b5f375"
            },
            {
                "id": 127,
                "dimensions": [
                    "lineItem",
                    "country",
                    "city",
                    "postalCode"
                ],
                "alias": "geo_71736e411be0c740ab76e53cc788aa4f"
            },
            {
                "id": 128,
                "dimensions": [
                    "lineItem",
                    "country",
                    "city",
                    "dma"
                ],
                "alias": "geo_df11aceba49fe7013e961285ea6abe0b"
            },
            {
                "id": 129,
                "dimensions": [
                    "lineItem",
                    "country",
                    "postalCode",
                    "dma"
                ],
                "alias": "geo_50e9497071d7d72efc65d79e64fb6071"
            },
            {
                "id": 130,
                "dimensions": [
                    "lineItem",
                    "region",
                    "city",
                    "postalCode"
                ],
                "alias": "geo_fceaa2de2112a78efd15b239e6f69328"
            },
            {
                "id": 131,
                "dimensions": [
                    "lineItem",
                    "region",
                    "city",
                    "dma"
                ],
                "alias": "geo_cb6d8c42b24130a0c84da496f42d46d1"
            },
            {
                "id": 132,
                "dimensions": [
                    "lineItem",
                    "region",
                    "postalCode",
                    "dma"
                ],
                "alias": "geo_7ff85c5d88f08073734ffb5f43350342"
            },
            {
                "id": 133,
                "dimensions": [
                    "lineItem",
                    "city",
                    "postalCode",
                    "dma"
                ],
                "alias": "geo_ab43195226a6d395e6c0c04fae456192"
            },
            {
                "id": 134,
                "dimensions": [
                    "country",
                    "region",
                    "city",
                    "postalCode"
                ],
                "alias": "geo_fd549a86618e961c3cf1a2e7bb2c98af"
            },
            {
                "id": 135,
                "dimensions": [
                    "country",
                    "region",
                    "city",
                    "dma"
                ],
                "alias": "geo_47c11eca05b01f1435b204b9b7f873d3"
            },
            {
                "id": 136,
                "dimensions": [
                    "country",
                    "region",
                    "postalCode",
                    "dma"
                ],
                "alias": "geo_71b7e08f101f1e33d2ca2b34ffff67bf"
            },
            {
                "id": 137,
                "dimensions": [
                    "country",
                    "city",
                    "postalCode",
                    "dma"
                ],
                "alias": "geo_d8f4650f5571012735382580f12c4b0d"
            },
            {
                "id": 138,
                "dimensions": [
                    "region",
                    "city",
                    "postalCode",
                    "dma"
                ],
                "alias": "geo_141bd3132beba82df5689fa64874b876"
            },
            {
                "id": 139,
                "dimensions": [
                    "order",
                    "lineItem",
                    "country",
                    "region",
                    "city"
                ],
                "alias": "geo_456da1c3b9fa4e9a74c8f7a4f4e5f0cc"
            },
            {
                "id": 140,
                "dimensions": [
                    "order",
                    "lineItem",
                    "country",
                    "region",
                    "postalCode"
                ],
                "alias": "geo_6ea968fa397fa563502cadaaef3132e8"
            },
            {
                "id": 141,
                "dimensions": [
                    "order",
                    "lineItem",
                    "country",
                    "region",
                    "dma"
                ],
                "alias": "geo_06461e1add555200369879ed609f44e2"
            },
            {
                "id": 142,
                "dimensions": [
                    "order",
                    "lineItem",
                    "country",
                    "city",
                    "postalCode"
                ],
                "alias": "geo_c1d1e5f9dd9a345c3676f2f713b926a0"
            },
            {
                "id": 143,
                "dimensions": [
                    "order",
                    "lineItem",
                    "country",
                    "city",
                    "dma"
                ],
                "alias": "geo_b9f48d4580f13e047c2de654d9164314"
            },
            {
                "id": 144,
                "dimensions": [
                    "order",
                    "lineItem",
                    "country",
                    "postalCode",
                    "dma"
                ],
                "alias": "geo_9bccf7b48df489e534b0fe49741e8c70"
            },
            {
                "id": 145,
                "dimensions": [
                    "order",
                    "lineItem",
                    "region",
                    "city",
                    "postalCode"
                ],
                "alias": "geo_ec46739611def08fc8ecfeac14139bd8"
            },
            {
                "id": 146,
                "dimensions": [
                    "order",
                    "lineItem",
                    "region",
                    "city",
                    "dma"
                ],
                "alias": "geo_eb363a9b276b168fe099a863a206fc67"
            },
            {
                "id": 147,
                "dimensions": [
                    "order",
                    "lineItem",
                    "region",
                    "postalCode",
                    "dma"
                ],
                "alias": "geo_ebba48e9aab4abfd3b1ad7f8128e4dc7"
            },
            {
                "id": 148,
                "dimensions": [
                    "order",
                    "lineItem",
                    "city",
                    "postalCode",
                    "dma"
                ],
                "alias": "geo_2166e821954f6ac967e8617f66d6fe1d"
            },
            {
                "id": 149,
                "dimensions": [
                    "order",
                    "country",
                    "region",
                    "city",
                    "postalCode"
                ],
                "alias": "geo_7ff0e31741eafb11e104f808e260dec5"
            },
            {
                "id": 150,
                "dimensions": [
                    "order",
                    "country",
                    "region",
                    "city",
                    "dma"
                ],
                "alias": "geo_661e764c818828f2088c8b744ebadd9e"
            },
            {
                "id": 151,
                "dimensions": [
                    "order",
                    "country",
                    "region",
                    "postalCode",
                    "dma"
                ],
                "alias": "geo_dfc64565c13698dc9eca1bced03ab61b"
            },
            {
                "id": 152,
                "dimensions": [
                    "order",
                    "country",
                    "city",
                    "postalCode",
                    "dma"
                ],
                "alias": "geo_dde218ab4ba115a5b537fb076c8c746c"
            },
            {
                "id": 153,
                "dimensions": [
                    "order",
                    "region",
                    "city",
                    "postalCode",
                    "dma"
                ],
                "alias": "geo_9ae991383ed0be1579ea5f116e65560d"
            },
            {
                "id": 154,
                "dimensions": [
                    "lineItem",
                    "country",
                    "region",
                    "city",
                    "postalCode"
                ],
                "alias": "geo_94fef25bc9a3050b0f0b6e0fd77ba8bb"
            },
            {
                "id": 155,
                "dimensions": [
                    "lineItem",
                    "country",
                    "region",
                    "city",
                    "dma"
                ],
                "alias": "geo_ce7a2eedb367419ad4677408b7a79f1b"
            },
            {
                "id": 156,
                "dimensions": [
                    "lineItem",
                    "country",
                    "region",
                    "postalCode",
                    "dma"
                ],
                "alias": "geo_1799e81abf115506bd638397670c3963"
            },
            {
                "id": 157,
                "dimensions": [
                    "lineItem",
                    "country",
                    "city",
                    "postalCode",
                    "dma"
                ],
                "alias": "geo_988feb9a1e1d77c289312a4b5fc6be9d"
            },
            {
                "id": 158,
                "dimensions": [
                    "lineItem",
                    "region",
                    "city",
                    "postalCode",
                    "dma"
                ],
                "alias": "geo_5fe409a0a19b773e7c4d4dd7b5753123"
            },
            {
                "id": 159,
                "dimensions": [
                    "country",
                    "region",
                    "city",
                    "postalCode",
                    "dma"
                ],
                "alias": "geo_9b2160b0621437a2e6c5ca415e037439"
            },
            {
                "id": 160,
                "dimensions": [
                    "order",
                    "lineItem",
                    "country",
                    "region",
                    "city",
                    "postalCode"
                ],
                "alias": "geo_4cfe83c59b6aa339ec1919bc7629de4b"
            },
            {
                "id": 161,
                "dimensions": [
                    "order",
                    "lineItem",
                    "country",
                    "region",
                    "city",
                    "dma"
                ],
                "alias": "geo_8f9f5dd9d2691e27536b8350cbaa4dfd"
            },
            {
                "id": 162,
                "dimensions": [
                    "order",
                    "lineItem",
                    "country",
                    "region",
                    "postalCode",
                    "dma"
                ],
                "alias": "geo_5bac09a593b847df5d99e81b3f547a8e"
            },
            {
                "id": 163,
                "dimensions": [
                    "order",
                    "lineItem",
                    "country",
                    "city",
                    "postalCode",
                    "dma"
                ],
                "alias": "geo_5285e578d24e95b6e75087f6f99e9693"
            },
            {
                "id": 164,
                "dimensions": [
                    "order",
                    "lineItem",
                    "region",
                    "city",
                    "postalCode",
                    "dma"
                ],
                "alias": "geo_655c98e857bb3b6188905fa6413ec0a9"
            },
            {
                "id": 165,
                "dimensions": [
                    "order",
                    "country",
                    "region",
                    "city",
                    "postalCode",
                    "dma"
                ],
                "alias": "geo_a8818ffec86413d9ebd68b8cba169944"
            },
            {
                "id": 166,
                "dimensions": [
                    "lineItem",
                    "country",
                    "region",
                    "city",
                    "postalCode",
                    "dma"
                ],
                "alias": "geo_7a5cc373a1f8d8108f0f3f8038e52014"
            },
            {
                "id": 167,
                "dimensions": [
                    "order",
                    "lineItem",
                    "country",
                    "region",
                    "city",
                    "postalCode",
                    "dma"
                ],
                "alias": "geo_773fdc74c32e3d70886c8ab2ec7f86cc"
            }
        ]
    },
    {
        "report_type": "inventory",
        "reports": [
            {
                "id": 168,
                "dimensions": [
                    "supplySource"
                ],
                "alias": "inventory_cd902b516deb1f20d9a454c270797a6d"
            },
            {
                "id": 169,
                "dimensions": [
                    "site"
                ],
                "alias": "inventory_98defd6ee70dfb1dea416cecdf391f58"
            },
            {
                "id": 170,
                "dimensions": [
                    "campaign"
                ],
                "alias": "inventory_6c8bff2269a89ef7ec3cf87e72be656f"
            },
            {
                "id": 171,
                "dimensions": [
                    "creative"
                ],
                "alias": "inventory_828e096fef42d94858dd49b27ab903f3"
            },
            {
                "id": 172,
                "dimensions": [
                    "ad"
                ],
                "alias": "inventory_523af537946b79c4f8369ed39ba78605"
            },
            {
                "id": 173,
                "dimensions": [
                    "placementSize"
                ],
                "alias": "inventory_7e1a2272a3c814c9021186477d335ea6"
            },
            {
                "id": 174,
                "dimensions": [
                    "placement"
                ],
                "alias": "inventory_2a5540b421ce0577456c823d81fcd010"
            },
            {
                "id": 175,
                "dimensions": [
                    "deal"
                ],
                "alias": "inventory_1a78f5ca98f29692a8d507230ec483b1"
            },
            {
                "id": 176,
                "dimensions": [
                    "supplySource",
                    "site"
                ],
                "alias": "inventory_9d542d96c1ebc56ae62f4112acb37a1c"
            },
            {
                "id": 177,
                "dimensions": [
                    "supplySource",
                    "campaign"
                ],
                "alias": "inventory_569aa96a6c96ab18b83464ac8cfc6898"
            },
            {
                "id": 178,
                "dimensions": [
                    "supplySource",
                    "creative"
                ],
                "alias": "inventory_1249f18471ea947282f23b6342982e6c"
            },
            {
                "id": 179,
                "dimensions": [
                    "supplySource",
                    "ad"
                ],
                "alias": "inventory_6545bc3a8e589676d4c543a35496812d"
            },
            {
                "id": 180,
                "dimensions": [
                    "supplySource",
                    "placementSize"
                ],
                "alias": "inventory_7d2b801ca87f42dc1056f968954ec75c"
            },
            {
                "id": 181,
                "dimensions": [
                    "supplySource",
                    "placement"
                ],
                "alias": "inventory_477e39690611e54f9ae11a1e2ed9e213"
            },
            {
                "id": 182,
                "dimensions": [
                    "supplySource",
                    "deal"
                ],
                "alias": "inventory_adabf805d5d8220cd65bf227aa0713fc"
            },
            {
                "id": 183,
                "dimensions": [
                    "site",
                    "campaign"
                ],
                "alias": "inventory_cacd7d5385de042946ca2b0f713dbefe"
            },
            {
                "id": 184,
                "dimensions": [
                    "site",
                    "creative"
                ],
                "alias": "inventory_66efbcb00df95ccac69dae501558353f"
            },
            {
                "id": 185,
                "dimensions": [
                    "site",
                    "ad"
                ],
                "alias": "inventory_7f3f34a8c0184b73bbada35559498608"
            },
            {
                "id": 186,
                "dimensions": [
                    "site",
                    "placementSize"
                ],
                "alias": "inventory_1ac3ccf4a390b59e34493eab9487edaa"
            },
            {
                "id": 187,
                "dimensions": [
                    "site",
                    "placement"
                ],
                "alias": "inventory_0c14475163274e2de9083f5e5757460c"
            },
            {
                "id": 188,
                "dimensions": [
                    "site",
                    "deal"
                ],
                "alias": "inventory_3318f153dfbb8334f6ecb4fb88688cb5"
            },
            {
                "id": 189,
                "dimensions": [
                    "campaign",
                    "creative"
                ],
                "alias": "inventory_f80fb70fc9b366636256d6c4b1cfbd8f"
            },
            {
                "id": 190,
                "dimensions": [
                    "campaign",
                    "ad"
                ],
                "alias": "inventory_d7415c0269f588d632099daec589675d"
            },
            {
                "id": 191,
                "dimensions": [
                    "campaign",
                    "placementSize"
                ],
                "alias": "inventory_9b34303f26b42203e8592e14390bc640"
            },
            {
                "id": 192,
                "dimensions": [
                    "campaign",
                    "placement"
                ],
                "alias": "inventory_bc8fadfc4a1c25f503bfde591444b84b"
            },
            {
                "id": 193,
                "dimensions": [
                    "campaign",
                    "deal"
                ],
                "alias": "inventory_1c969036cdd0ffb5ca6b2ed84c5b723a"
            },
            {
                "id": 194,
                "dimensions": [
                    "creative",
                    "ad"
                ],
                "alias": "inventory_8f7da534f547cfa47a47c51e2dad0ef1"
            },
            {
                "id": 195,
                "dimensions": [
                    "creative",
                    "placementSize"
                ],
                "alias": "inventory_9abbd01ad6337733b48552d85c2050cf"
            },
            {
                "id": 196,
                "dimensions": [
                    "creative",
                    "placement"
                ],
                "alias": "inventory_c03c7566e0af3dc985b8327cdf89d308"
            },
            {
                "id": 197,
                "dimensions": [
                    "creative",
                    "deal"
                ],
                "alias": "inventory_2b8ec9d2ac6b65b7d42ca92b2b13850d"
            },
            {
                "id": 198,
                "dimensions": [
                    "ad",
                    "placementSize"
                ],
                "alias": "inventory_7905249182fa5b12f7c4a14534df43e9"
            },
            {
                "id": 199,
                "dimensions": [
                    "ad",
                    "placement"
                ],
                "alias": "inventory_f5de7d430adce7669c6fadfa95fb70a5"
            },
            {
                "id": 200,
                "dimensions": [
                    "ad",
                    "deal"
                ],
                "alias": "inventory_d904c594f9170ecf2a6890ca99bf9bb1"
            },
            {
                "id": 201,
                "dimensions": [
                    "placementSize",
                    "placement"
                ],
                "alias": "inventory_4245efd2812eb5a4608e2a3ae6df110b"
            },
            {
                "id": 202,
                "dimensions": [
                    "placementSize",
                    "deal"
                ],
                "alias": "inventory_62b4fe63a4efd7d7bfb3431b60242812"
            },
            {
                "id": 203,
                "dimensions": [
                    "placement",
                    "deal"
                ],
                "alias": "inventory_45a7268c458df745d87e3ee39ea3ac0f"
            },
            {
                "id": 204,
                "dimensions": [
                    "supplySource",
                    "site",
                    "campaign"
                ],
                "alias": "inventory_9121874d979825de2ca37886c3bba468"
            },
            {
                "id": 205,
                "dimensions": [
                    "supplySource",
                    "site",
                    "creative"
                ],
                "alias": "inventory_a9010ca677f4d350ced2059b9c36221f"
            },
            {
                "id": 206,
                "dimensions": [
                    "supplySource",
                    "site",
                    "ad"
                ],
                "alias": "inventory_e6f848476c0afeba2f31a016bcae6efc"
            },
            {
                "id": 207,
                "dimensions": [
                    "supplySource",
                    "site",
                    "placementSize"
                ],
                "alias": "inventory_1d80a6965082f705e607816c61dce0ac"
            },
            {
                "id": 208,
                "dimensions": [
                    "supplySource",
                    "site",
                    "placement"
                ],
                "alias": "inventory_2210eb378108e5e71ba54e07767e6eb1"
            },
            {
                "id": 209,
                "dimensions": [
                    "supplySource",
                    "site",
                    "deal"
                ],
                "alias": "inventory_58b612a19726064f7ab2723adbade2cb"
            },
            {
                "id": 210,
                "dimensions": [
                    "supplySource",
                    "campaign",
                    "creative"
                ],
                "alias": "inventory_38dcc37c3c87ea306d0c81b2c9040fb0"
            },
            {
                "id": 211,
                "dimensions": [
                    "supplySource",
                    "campaign",
                    "ad"
                ],
                "alias": "inventory_fcb83b978bba06a0509220da600c6346"
            },
            {
                "id": 212,
                "dimensions": [
                    "supplySource",
                    "campaign",
                    "placementSize"
                ],
                "alias": "inventory_af6bcd88dba2ce5e8868cf864871f087"
            },
            {
                "id": 213,
                "dimensions": [
                    "supplySource",
                    "campaign",
                    "placement"
                ],
                "alias": "inventory_aa7ccdc3ee7c13461bb6bbe1705902c1"
            },
            {
                "id": 214,
                "dimensions": [
                    "supplySource",
                    "campaign",
                    "deal"
                ],
                "alias": "inventory_7ce453b9b978d0ad8a8c92f324b9730c"
            },
            {
                "id": 215,
                "dimensions": [
                    "supplySource",
                    "creative",
                    "ad"
                ],
                "alias": "inventory_d37345d8ea66cd463456c64759620ddd"
            },
            {
                "id": 216,
                "dimensions": [
                    "supplySource",
                    "creative",
                    "placementSize"
                ],
                "alias": "inventory_0282198676399b54ffc7faaa71c40743"
            },
            {
                "id": 217,
                "dimensions": [
                    "supplySource",
                    "creative",
                    "placement"
                ],
                "alias": "inventory_4fc071752c7ade717fa7da84db646a7a"
            },
            {
                "id": 218,
                "dimensions": [
                    "supplySource",
                    "creative",
                    "deal"
                ],
                "alias": "inventory_d31ba53f8ac27db07a4dc37bccd8d340"
            },
            {
                "id": 219,
                "dimensions": [
                    "supplySource",
                    "ad",
                    "placementSize"
                ],
                "alias": "inventory_0429a89e50f2b09c58df376cee57ea02"
            },
            {
                "id": 220,
                "dimensions": [
                    "supplySource",
                    "ad",
                    "placement"
                ],
                "alias": "inventory_4aeac0bd11bffe8f5f05a48339339b30"
            },
            {
                "id": 221,
                "dimensions": [
                    "supplySource",
                    "ad",
                    "deal"
                ],
                "alias": "inventory_f16b44b6a8690ff0c1452fb9ac310832"
            },
            {
                "id": 222,
                "dimensions": [
                    "supplySource",
                    "placementSize",
                    "placement"
                ],
                "alias": "inventory_bf9e9f8616e4cfb27340dea6e2adfd7b"
            },
            {
                "id": 223,
                "dimensions": [
                    "supplySource",
                    "placementSize",
                    "deal"
                ],
                "alias": "inventory_9a71f08b7226d845a7ee80e80df85e34"
            },
            {
                "id": 224,
                "dimensions": [
                    "supplySource",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_276cb2507ecdd11db0767a9b5be3ba9e"
            },
            {
                "id": 225,
                "dimensions": [
                    "site",
                    "campaign",
                    "creative"
                ],
                "alias": "inventory_e95d4d7e0ef06631dcbe1dbf24e8c82b"
            },
            {
                "id": 226,
                "dimensions": [
                    "site",
                    "campaign",
                    "ad"
                ],
                "alias": "inventory_8e6661e0997e7c0cdbdb7318e32e8909"
            },
            {
                "id": 227,
                "dimensions": [
                    "site",
                    "campaign",
                    "placementSize"
                ],
                "alias": "inventory_a8780cfdaa6b7dc2b28a388d5a5a5bf8"
            },
            {
                "id": 228,
                "dimensions": [
                    "site",
                    "campaign",
                    "placement"
                ],
                "alias": "inventory_6985af67926b3d0212c0ad80182c1544"
            },
            {
                "id": 229,
                "dimensions": [
                    "site",
                    "campaign",
                    "deal"
                ],
                "alias": "inventory_09e3adf5a4ac5ada09045dd24eaf9a81"
            },
            {
                "id": 230,
                "dimensions": [
                    "site",
                    "creative",
                    "ad"
                ],
                "alias": "inventory_a5cf6fd0d7af7ee77dad5b947c6e3af4"
            },
            {
                "id": 231,
                "dimensions": [
                    "site",
                    "creative",
                    "placementSize"
                ],
                "alias": "inventory_4dc3105b6eccd2120cfecb9e859bb130"
            },
            {
                "id": 232,
                "dimensions": [
                    "site",
                    "creative",
                    "placement"
                ],
                "alias": "inventory_7a3c57029d9796d2d31bbe28684bf096"
            },
            {
                "id": 233,
                "dimensions": [
                    "site",
                    "creative",
                    "deal"
                ],
                "alias": "inventory_eb5e8a7729201e85e202e304d6e5a380"
            },
            {
                "id": 234,
                "dimensions": [
                    "site",
                    "ad",
                    "placementSize"
                ],
                "alias": "inventory_cf4efb4f763d8983acdf2e7b3ca0ecab"
            },
            {
                "id": 235,
                "dimensions": [
                    "site",
                    "ad",
                    "placement"
                ],
                "alias": "inventory_4df62d3c1cf785a12636374a6588f5be"
            },
            {
                "id": 236,
                "dimensions": [
                    "site",
                    "ad",
                    "deal"
                ],
                "alias": "inventory_5d0242e3b5960132236bb33485a5ed11"
            },
            {
                "id": 237,
                "dimensions": [
                    "site",
                    "placementSize",
                    "placement"
                ],
                "alias": "inventory_6aaf97708bcee35a044b285892faadfd"
            },
            {
                "id": 238,
                "dimensions": [
                    "site",
                    "placementSize",
                    "deal"
                ],
                "alias": "inventory_b9cd9e32e7c7c328c68f4d4fa4c7ec5a"
            },
            {
                "id": 239,
                "dimensions": [
                    "site",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_0514afaaae1e28c6751c09b540e53b96"
            },
            {
                "id": 240,
                "dimensions": [
                    "campaign",
                    "creative",
                    "ad"
                ],
                "alias": "inventory_1cdef549c0c5537255354e9631b840fe"
            },
            {
                "id": 241,
                "dimensions": [
                    "campaign",
                    "creative",
                    "placementSize"
                ],
                "alias": "inventory_8e77c5cab9a8998324b3dbb7c6494c25"
            },
            {
                "id": 242,
                "dimensions": [
                    "campaign",
                    "creative",
                    "placement"
                ],
                "alias": "inventory_b2beda50d6d105ea7a724a683dc57614"
            },
            {
                "id": 243,
                "dimensions": [
                    "campaign",
                    "creative",
                    "deal"
                ],
                "alias": "inventory_9b5ac1e627c2add30d7f259302d42c13"
            },
            {
                "id": 244,
                "dimensions": [
                    "campaign",
                    "ad",
                    "placementSize"
                ],
                "alias": "inventory_1d277980f2cf108e170b930af41b0c5b"
            },
            {
                "id": 245,
                "dimensions": [
                    "campaign",
                    "ad",
                    "placement"
                ],
                "alias": "inventory_35a39589386f4296d5556d9dcc5ceab8"
            },
            {
                "id": 246,
                "dimensions": [
                    "campaign",
                    "ad",
                    "deal"
                ],
                "alias": "inventory_ea9ae3ed2a3e0a0a8ecaf9d0e3f48866"
            },
            {
                "id": 247,
                "dimensions": [
                    "campaign",
                    "placementSize",
                    "placement"
                ],
                "alias": "inventory_9c6b91778ba0856aa77cab8ab8fc5c0e"
            },
            {
                "id": 248,
                "dimensions": [
                    "campaign",
                    "placementSize",
                    "deal"
                ],
                "alias": "inventory_56926ea59cedc0597224cf2e7d157c15"
            },
            {
                "id": 249,
                "dimensions": [
                    "campaign",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_ef440f17dcdc9b9e979374ceb752b40c"
            },
            {
                "id": 250,
                "dimensions": [
                    "creative",
                    "ad",
                    "placementSize"
                ],
                "alias": "inventory_9057f216cd23266bd31afa8671237b5f"
            },
            {
                "id": 251,
                "dimensions": [
                    "creative",
                    "ad",
                    "placement"
                ],
                "alias": "inventory_7f7aa6af69b4083e79fee1680668b6e1"
            },
            {
                "id": 252,
                "dimensions": [
                    "creative",
                    "ad",
                    "deal"
                ],
                "alias": "inventory_1c0813a09b63ad76536f6468eebae78c"
            },
            {
                "id": 253,
                "dimensions": [
                    "creative",
                    "placementSize",
                    "placement"
                ],
                "alias": "inventory_2590fa3b7a0cf0068b9c3d6a58da0dd9"
            },
            {
                "id": 254,
                "dimensions": [
                    "creative",
                    "placementSize",
                    "deal"
                ],
                "alias": "inventory_05fb42b3c7d6c1dbc531148176c698cf"
            },
            {
                "id": 255,
                "dimensions": [
                    "creative",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_a2e53a77a3a28d30350bb2ed69d56734"
            },
            {
                "id": 256,
                "dimensions": [
                    "ad",
                    "placementSize",
                    "placement"
                ],
                "alias": "inventory_2cc5f075760ab44af8b5361a70262100"
            },
            {
                "id": 257,
                "dimensions": [
                    "ad",
                    "placementSize",
                    "deal"
                ],
                "alias": "inventory_b4c85a3d1514323ada1337a837ebc5e1"
            },
            {
                "id": 258,
                "dimensions": [
                    "ad",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_20685258bd47b1d5aab559f2951a1b36"
            },
            {
                "id": 259,
                "dimensions": [
                    "placementSize",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_900eaf9fbd48595fd6d001f5fbfff194"
            },
            {
                "id": 260,
                "dimensions": [
                    "supplySource",
                    "site",
                    "campaign",
                    "creative"
                ],
                "alias": "inventory_3c3c2880171e79aa89723f64fd30a853"
            },
            {
                "id": 261,
                "dimensions": [
                    "supplySource",
                    "site",
                    "campaign",
                    "ad"
                ],
                "alias": "inventory_40529839ece4622affcc1778f7a83387"
            },
            {
                "id": 262,
                "dimensions": [
                    "supplySource",
                    "site",
                    "campaign",
                    "placementSize"
                ],
                "alias": "inventory_9962194b8149c55703233420414fc60d"
            },
            {
                "id": 263,
                "dimensions": [
                    "supplySource",
                    "site",
                    "campaign",
                    "placement"
                ],
                "alias": "inventory_8af20dc491858e7a455d400e38bdcaa9"
            },
            {
                "id": 264,
                "dimensions": [
                    "supplySource",
                    "site",
                    "campaign",
                    "deal"
                ],
                "alias": "inventory_28b2c666bf6358db25b444e0263656eb"
            },
            {
                "id": 265,
                "dimensions": [
                    "supplySource",
                    "site",
                    "creative",
                    "ad"
                ],
                "alias": "inventory_ed9f4b66668f767ce96604466204a26a"
            },
            {
                "id": 266,
                "dimensions": [
                    "supplySource",
                    "site",
                    "creative",
                    "placementSize"
                ],
                "alias": "inventory_e72d3217ca8764eff8c4ad003820ec7e"
            },
            {
                "id": 267,
                "dimensions": [
                    "supplySource",
                    "site",
                    "creative",
                    "placement"
                ],
                "alias": "inventory_c51d28679940852fe0b3892757ec04eb"
            },
            {
                "id": 268,
                "dimensions": [
                    "supplySource",
                    "site",
                    "creative",
                    "deal"
                ],
                "alias": "inventory_9a74ad9d304deffc715d5864f181d36b"
            },
            {
                "id": 269,
                "dimensions": [
                    "supplySource",
                    "site",
                    "ad",
                    "placementSize"
                ],
                "alias": "inventory_d6758b32f41d10a658b113ce4e7b45da"
            },
            {
                "id": 270,
                "dimensions": [
                    "supplySource",
                    "site",
                    "ad",
                    "placement"
                ],
                "alias": "inventory_407960554799a0aadfa228f23c5006f1"
            },
            {
                "id": 271,
                "dimensions": [
                    "supplySource",
                    "site",
                    "ad",
                    "deal"
                ],
                "alias": "inventory_7e7c655ee3aaf3f6c69d1eae09bdfe14"
            },
            {
                "id": 272,
                "dimensions": [
                    "supplySource",
                    "site",
                    "placementSize",
                    "placement"
                ],
                "alias": "inventory_a5302710c4da5ffaa4264861c7b26019"
            },
            {
                "id": 273,
                "dimensions": [
                    "supplySource",
                    "site",
                    "placementSize",
                    "deal"
                ],
                "alias": "inventory_0fe7a48fa8a753429b7191835cf88358"
            },
            {
                "id": 274,
                "dimensions": [
                    "supplySource",
                    "site",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_8ac1176f030354e3473a27efc61f1ffe"
            },
            {
                "id": 275,
                "dimensions": [
                    "supplySource",
                    "campaign",
                    "creative",
                    "ad"
                ],
                "alias": "inventory_007e218c026dd5880549196343df4721"
            },
            {
                "id": 276,
                "dimensions": [
                    "supplySource",
                    "campaign",
                    "creative",
                    "placementSize"
                ],
                "alias": "inventory_5dbe4b76581c3f3039ed966efa8b7cb6"
            },
            {
                "id": 277,
                "dimensions": [
                    "supplySource",
                    "campaign",
                    "creative",
                    "placement"
                ],
                "alias": "inventory_8537e6c2d68da3d86f779e03990e8a43"
            },
            {
                "id": 278,
                "dimensions": [
                    "supplySource",
                    "campaign",
                    "creative",
                    "deal"
                ],
                "alias": "inventory_6257eb22f3a1831a9aba92100fa06210"
            },
            {
                "id": 279,
                "dimensions": [
                    "supplySource",
                    "campaign",
                    "ad",
                    "placementSize"
                ],
                "alias": "inventory_c7482f0b689ea085ba591cfa4de2fc17"
            },
            {
                "id": 280,
                "dimensions": [
                    "supplySource",
                    "campaign",
                    "ad",
                    "placement"
                ],
                "alias": "inventory_da5180f396c35290b8eb92f2573d61e2"
            },
            {
                "id": 281,
                "dimensions": [
                    "supplySource",
                    "campaign",
                    "ad",
                    "deal"
                ],
                "alias": "inventory_f075bd7bbc4cc34f381fd54794645b56"
            },
            {
                "id": 282,
                "dimensions": [
                    "supplySource",
                    "campaign",
                    "placementSize",
                    "placement"
                ],
                "alias": "inventory_1cdcbb9bebdb1365c4ff85e9606df7a6"
            },
            {
                "id": 283,
                "dimensions": [
                    "supplySource",
                    "campaign",
                    "placementSize",
                    "deal"
                ],
                "alias": "inventory_2401b47633d7132042a7f616ff362478"
            },
            {
                "id": 284,
                "dimensions": [
                    "supplySource",
                    "campaign",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_3dbf249c38d1d3ba5b36018b8e777378"
            },
            {
                "id": 285,
                "dimensions": [
                    "supplySource",
                    "creative",
                    "ad",
                    "placementSize"
                ],
                "alias": "inventory_c136df16b4627bd1bf9dfe7e5ebd0f0b"
            },
            {
                "id": 286,
                "dimensions": [
                    "supplySource",
                    "creative",
                    "ad",
                    "placement"
                ],
                "alias": "inventory_8059f9db4ded9f104e1f10a9ed9f43af"
            },
            {
                "id": 287,
                "dimensions": [
                    "supplySource",
                    "creative",
                    "ad",
                    "deal"
                ],
                "alias": "inventory_2be42ccf8ea712cf90f731e7f2adafd2"
            },
            {
                "id": 288,
                "dimensions": [
                    "supplySource",
                    "creative",
                    "placementSize",
                    "placement"
                ],
                "alias": "inventory_424b209befffbd73d3f84fd2e8d6f7ce"
            },
            {
                "id": 289,
                "dimensions": [
                    "supplySource",
                    "creative",
                    "placementSize",
                    "deal"
                ],
                "alias": "inventory_91b5285418836934e0ce35f3cfde2a63"
            },
            {
                "id": 290,
                "dimensions": [
                    "supplySource",
                    "creative",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_706c83fb207a73076154bead2f2dcd39"
            },
            {
                "id": 291,
                "dimensions": [
                    "supplySource",
                    "ad",
                    "placementSize",
                    "placement"
                ],
                "alias": "inventory_50d1400ec5ef93e7092b9b8da1b71c62"
            },
            {
                "id": 292,
                "dimensions": [
                    "supplySource",
                    "ad",
                    "placementSize",
                    "deal"
                ],
                "alias": "inventory_46a9a0d842dbcfb05e0c6745373ffceb"
            },
            {
                "id": 293,
                "dimensions": [
                    "supplySource",
                    "ad",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_7c2df2927219cc965a438a12a4a112ea"
            },
            {
                "id": 294,
                "dimensions": [
                    "supplySource",
                    "placementSize",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_25f7de0fa18948d94134121616847c2e"
            },
            {
                "id": 295,
                "dimensions": [
                    "site",
                    "campaign",
                    "creative",
                    "ad"
                ],
                "alias": "inventory_03426b4dd33b2abfdd8227a3763f3db6"
            },
            {
                "id": 296,
                "dimensions": [
                    "site",
                    "campaign",
                    "creative",
                    "placementSize"
                ],
                "alias": "inventory_7a37e01b28b73f53df3ef4df53975387"
            },
            {
                "id": 297,
                "dimensions": [
                    "site",
                    "campaign",
                    "creative",
                    "placement"
                ],
                "alias": "inventory_b4b89f7e4ae9e9edbe60e57eab8d6bba"
            },
            {
                "id": 298,
                "dimensions": [
                    "site",
                    "campaign",
                    "creative",
                    "deal"
                ],
                "alias": "inventory_b3467effb7bcee9b5207042b77675833"
            },
            {
                "id": 299,
                "dimensions": [
                    "site",
                    "campaign",
                    "ad",
                    "placementSize"
                ],
                "alias": "inventory_b1ee02875b1218cfdf8a49e3e7d340e8"
            },
            {
                "id": 300,
                "dimensions": [
                    "site",
                    "campaign",
                    "ad",
                    "placement"
                ],
                "alias": "inventory_9a140d4fcd80bf90a8ca33649fe5c48d"
            },
            {
                "id": 301,
                "dimensions": [
                    "site",
                    "campaign",
                    "ad",
                    "deal"
                ],
                "alias": "inventory_c08ecc700bcbc69bea69190288be8c93"
            },
            {
                "id": 302,
                "dimensions": [
                    "site",
                    "campaign",
                    "placementSize",
                    "placement"
                ],
                "alias": "inventory_8d9ff772c11dd0ed72a7fbc627b871ec"
            },
            {
                "id": 303,
                "dimensions": [
                    "site",
                    "campaign",
                    "placementSize",
                    "deal"
                ],
                "alias": "inventory_9bc32def5d6ae4031b4eaeb74ff5b11b"
            },
            {
                "id": 304,
                "dimensions": [
                    "site",
                    "campaign",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_f3065655bb0923aa2b65a462cd230a3e"
            },
            {
                "id": 305,
                "dimensions": [
                    "site",
                    "creative",
                    "ad",
                    "placementSize"
                ],
                "alias": "inventory_aa98e33e5588e9a1c13113a7e6ae932d"
            },
            {
                "id": 306,
                "dimensions": [
                    "site",
                    "creative",
                    "ad",
                    "placement"
                ],
                "alias": "inventory_44d14f9a7733840427ee87d17126df61"
            },
            {
                "id": 307,
                "dimensions": [
                    "site",
                    "creative",
                    "ad",
                    "deal"
                ],
                "alias": "inventory_be42fc383874ea250696035dd4021935"
            },
            {
                "id": 308,
                "dimensions": [
                    "site",
                    "creative",
                    "placementSize",
                    "placement"
                ],
                "alias": "inventory_1eb892c9122c4b01514c2b5c7b4546d0"
            },
            {
                "id": 309,
                "dimensions": [
                    "site",
                    "creative",
                    "placementSize",
                    "deal"
                ],
                "alias": "inventory_63e5321385ec534138011783caa0c378"
            },
            {
                "id": 310,
                "dimensions": [
                    "site",
                    "creative",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_ad3bae518db3f6ed4b6adaf0fb09da81"
            },
            {
                "id": 311,
                "dimensions": [
                    "site",
                    "ad",
                    "placementSize",
                    "placement"
                ],
                "alias": "inventory_ea743a23e8c8e745ad1033c2b67b5781"
            },
            {
                "id": 312,
                "dimensions": [
                    "site",
                    "ad",
                    "placementSize",
                    "deal"
                ],
                "alias": "inventory_a13ab77c46c9ca31287bc7d68337ed28"
            },
            {
                "id": 313,
                "dimensions": [
                    "site",
                    "ad",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_73d453f5854cad5199b734792b9f21c7"
            },
            {
                "id": 314,
                "dimensions": [
                    "site",
                    "placementSize",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_3b32699bfd1303e1e6f56b457937629c"
            },
            {
                "id": 315,
                "dimensions": [
                    "campaign",
                    "creative",
                    "ad",
                    "placementSize"
                ],
                "alias": "inventory_69f6734a8c65117dd4aa1915a3c1aa7e"
            },
            {
                "id": 316,
                "dimensions": [
                    "campaign",
                    "creative",
                    "ad",
                    "placement"
                ],
                "alias": "inventory_dc042a9747e6b3ba2ab26cc9d650a260"
            },
            {
                "id": 317,
                "dimensions": [
                    "campaign",
                    "creative",
                    "ad",
                    "deal"
                ],
                "alias": "inventory_f290a839f0a45cfb8cba566dea92241e"
            },
            {
                "id": 318,
                "dimensions": [
                    "campaign",
                    "creative",
                    "placementSize",
                    "placement"
                ],
                "alias": "inventory_29e3559ee89e5b7cc7cc3810ce9b3aa8"
            },
            {
                "id": 319,
                "dimensions": [
                    "campaign",
                    "creative",
                    "placementSize",
                    "deal"
                ],
                "alias": "inventory_177c9f443a74428888bda72e43567a8f"
            },
            {
                "id": 320,
                "dimensions": [
                    "campaign",
                    "creative",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_b0caf0b2bf4c4c170dd21fb490a7ddf3"
            },
            {
                "id": 321,
                "dimensions": [
                    "campaign",
                    "ad",
                    "placementSize",
                    "placement"
                ],
                "alias": "inventory_12a8a0ad423ba5aef8ccc506aae22f3a"
            },
            {
                "id": 322,
                "dimensions": [
                    "campaign",
                    "ad",
                    "placementSize",
                    "deal"
                ],
                "alias": "inventory_61e13d9ed6fdd57fbefdc61b4470bab0"
            },
            {
                "id": 323,
                "dimensions": [
                    "campaign",
                    "ad",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_a54febc82ffd47943bdaf68f617141bc"
            },
            {
                "id": 324,
                "dimensions": [
                    "campaign",
                    "placementSize",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_39abc07fb13a858150e9b0b61d226392"
            },
            {
                "id": 325,
                "dimensions": [
                    "creative",
                    "ad",
                    "placementSize",
                    "placement"
                ],
                "alias": "inventory_810b7e731696fcb034cb177f6892ff38"
            },
            {
                "id": 326,
                "dimensions": [
                    "creative",
                    "ad",
                    "placementSize",
                    "deal"
                ],
                "alias": "inventory_be761efe76a64c54118d33d045f6422c"
            },
            {
                "id": 327,
                "dimensions": [
                    "creative",
                    "ad",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_67fe3e735075e65d64c7e817b529af5a"
            },
            {
                "id": 328,
                "dimensions": [
                    "creative",
                    "placementSize",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_1a6a955245f4d2ab4f3add18df694419"
            },
            {
                "id": 329,
                "dimensions": [
                    "ad",
                    "placementSize",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_9b864c78c03f78b3350eba41530a70e5"
            },
            {
                "id": 330,
                "dimensions": [
                    "supplySource",
                    "site",
                    "campaign",
                    "creative",
                    "ad"
                ],
                "alias": "inventory_9ebc14eb8f99ee60d5e3fd7614968590"
            },
            {
                "id": 331,
                "dimensions": [
                    "supplySource",
                    "site",
                    "campaign",
                    "creative",
                    "placementSize"
                ],
                "alias": "inventory_70f443d12ed76cba2deede23d60a2b93"
            },
            {
                "id": 332,
                "dimensions": [
                    "supplySource",
                    "site",
                    "campaign",
                    "creative",
                    "placement"
                ],
                "alias": "inventory_6fc4f06911750d2f275eb231adddc468"
            },
            {
                "id": 333,
                "dimensions": [
                    "supplySource",
                    "site",
                    "campaign",
                    "creative",
                    "deal"
                ],
                "alias": "inventory_901786f80233f13c7541062890ee36d4"
            },
            {
                "id": 334,
                "dimensions": [
                    "supplySource",
                    "site",
                    "campaign",
                    "ad",
                    "placementSize"
                ],
                "alias": "inventory_ed0e93233e5fed0edca1bce465608e47"
            },
            {
                "id": 335,
                "dimensions": [
                    "supplySource",
                    "site",
                    "campaign",
                    "ad",
                    "placement"
                ],
                "alias": "inventory_effccddb0e1cad943e7e256b122114e6"
            },
            {
                "id": 336,
                "dimensions": [
                    "supplySource",
                    "site",
                    "campaign",
                    "ad",
                    "deal"
                ],
                "alias": "inventory_2cc709a935632774192a4862b4290d46"
            },
            {
                "id": 337,
                "dimensions": [
                    "supplySource",
                    "site",
                    "campaign",
                    "placementSize",
                    "placement"
                ],
                "alias": "inventory_4b9da3a0875394c8297e6993b39b62db"
            },
            {
                "id": 338,
                "dimensions": [
                    "supplySource",
                    "site",
                    "campaign",
                    "placementSize",
                    "deal"
                ],
                "alias": "inventory_7d90d35548cdb6ed31eac12f8aaa120d"
            },
            {
                "id": 339,
                "dimensions": [
                    "supplySource",
                    "site",
                    "campaign",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_952c1557e56b5f8e5dd1c4b0e5753040"
            },
            {
                "id": 340,
                "dimensions": [
                    "supplySource",
                    "site",
                    "creative",
                    "ad",
                    "placementSize"
                ],
                "alias": "inventory_dae2d7c1889f75418e714d67ad111c9f"
            },
            {
                "id": 341,
                "dimensions": [
                    "supplySource",
                    "site",
                    "creative",
                    "ad",
                    "placement"
                ],
                "alias": "inventory_533e1d9f6b6b77bb390a8b75321d8c61"
            },
            {
                "id": 342,
                "dimensions": [
                    "supplySource",
                    "site",
                    "creative",
                    "ad",
                    "deal"
                ],
                "alias": "inventory_a1ed196cafa17feb85daafa279e4f9b8"
            },
            {
                "id": 343,
                "dimensions": [
                    "supplySource",
                    "site",
                    "creative",
                    "placementSize",
                    "placement"
                ],
                "alias": "inventory_35fec62671812cecc4ec2b733af292e7"
            },
            {
                "id": 344,
                "dimensions": [
                    "supplySource",
                    "site",
                    "creative",
                    "placementSize",
                    "deal"
                ],
                "alias": "inventory_62a45a685740eb9d06f631614954e9a4"
            },
            {
                "id": 345,
                "dimensions": [
                    "supplySource",
                    "site",
                    "creative",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_e196d320dbf1db1eb8b6026d53277dc9"
            },
            {
                "id": 346,
                "dimensions": [
                    "supplySource",
                    "site",
                    "ad",
                    "placementSize",
                    "placement"
                ],
                "alias": "inventory_21c42153ff7ae570ef95acf5b113e7f9"
            },
            {
                "id": 347,
                "dimensions": [
                    "supplySource",
                    "site",
                    "ad",
                    "placementSize",
                    "deal"
                ],
                "alias": "inventory_ceaa1214b8818dcf4440326f7213638e"
            },
            {
                "id": 348,
                "dimensions": [
                    "supplySource",
                    "site",
                    "ad",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_a62b8626ccbcd92cca2b16c3ae353336"
            },
            {
                "id": 349,
                "dimensions": [
                    "supplySource",
                    "site",
                    "placementSize",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_be082862271f7191c0fa834444ebd932"
            },
            {
                "id": 350,
                "dimensions": [
                    "supplySource",
                    "campaign",
                    "creative",
                    "ad",
                    "placementSize"
                ],
                "alias": "inventory_7ddefca620e50ff82c0ffbd410c573b7"
            },
            {
                "id": 351,
                "dimensions": [
                    "supplySource",
                    "campaign",
                    "creative",
                    "ad",
                    "placement"
                ],
                "alias": "inventory_64e45dc6a9abb0367451feab04e54b8d"
            },
            {
                "id": 352,
                "dimensions": [
                    "supplySource",
                    "campaign",
                    "creative",
                    "ad",
                    "deal"
                ],
                "alias": "inventory_a37adefdb7b5de015e65255beb096fc1"
            },
            {
                "id": 353,
                "dimensions": [
                    "supplySource",
                    "campaign",
                    "creative",
                    "placementSize",
                    "placement"
                ],
                "alias": "inventory_9b198b5d338c09c6de9c007dee84509c"
            },
            {
                "id": 354,
                "dimensions": [
                    "supplySource",
                    "campaign",
                    "creative",
                    "placementSize",
                    "deal"
                ],
                "alias": "inventory_4c05bc1f2730e2dc5fe2c286871d9c57"
            },
            {
                "id": 355,
                "dimensions": [
                    "supplySource",
                    "campaign",
                    "creative",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_249984eb51c159cfeca2cc9855fd9d25"
            },
            {
                "id": 356,
                "dimensions": [
                    "supplySource",
                    "campaign",
                    "ad",
                    "placementSize",
                    "placement"
                ],
                "alias": "inventory_25ffd2a5a564b6fdc499570b6ffc2c73"
            },
            {
                "id": 357,
                "dimensions": [
                    "supplySource",
                    "campaign",
                    "ad",
                    "placementSize",
                    "deal"
                ],
                "alias": "inventory_22312649d2b146850353925b0460c79d"
            },
            {
                "id": 358,
                "dimensions": [
                    "supplySource",
                    "campaign",
                    "ad",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_8a842b3c314ecff022dcce4f4d21941b"
            },
            {
                "id": 359,
                "dimensions": [
                    "supplySource",
                    "campaign",
                    "placementSize",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_24aa3c37b452a8b8d5a0a0fcc16fe198"
            },
            {
                "id": 360,
                "dimensions": [
                    "supplySource",
                    "creative",
                    "ad",
                    "placementSize",
                    "placement"
                ],
                "alias": "inventory_b021aa0280eb3c8f227f28af590d965a"
            },
            {
                "id": 361,
                "dimensions": [
                    "supplySource",
                    "creative",
                    "ad",
                    "placementSize",
                    "deal"
                ],
                "alias": "inventory_f0272dc68e3a8666aacb02226907d4f2"
            },
            {
                "id": 362,
                "dimensions": [
                    "supplySource",
                    "creative",
                    "ad",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_1d7133560a7a264e88265065f89dec4d"
            },
            {
                "id": 363,
                "dimensions": [
                    "supplySource",
                    "creative",
                    "placementSize",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_54f3831211a1868d198ef3a0e1639ecd"
            },
            {
                "id": 364,
                "dimensions": [
                    "supplySource",
                    "ad",
                    "placementSize",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_28b45723d1028d003533045df86bc82d"
            },
            {
                "id": 365,
                "dimensions": [
                    "site",
                    "campaign",
                    "creative",
                    "ad",
                    "placementSize"
                ],
                "alias": "inventory_cf6f1489c5c7800ad9e1da1c42e9d106"
            },
            {
                "id": 366,
                "dimensions": [
                    "site",
                    "campaign",
                    "creative",
                    "ad",
                    "placement"
                ],
                "alias": "inventory_426e25e1bf4a2e077de1fc5ea02f5832"
            },
            {
                "id": 367,
                "dimensions": [
                    "site",
                    "campaign",
                    "creative",
                    "ad",
                    "deal"
                ],
                "alias": "inventory_3ba55819c30872812bf8fc29a0d04180"
            },
            {
                "id": 368,
                "dimensions": [
                    "site",
                    "campaign",
                    "creative",
                    "placementSize",
                    "placement"
                ],
                "alias": "inventory_51cba9e7e95ef1b3b5c9a97564632999"
            },
            {
                "id": 369,
                "dimensions": [
                    "site",
                    "campaign",
                    "creative",
                    "placementSize",
                    "deal"
                ],
                "alias": "inventory_f7c79a4dbf43d744acd6d213bfb0d9f9"
            },
            {
                "id": 370,
                "dimensions": [
                    "site",
                    "campaign",
                    "creative",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_2e135d26393b76559ef1c584822d5bcf"
            },
            {
                "id": 371,
                "dimensions": [
                    "site",
                    "campaign",
                    "ad",
                    "placementSize",
                    "placement"
                ],
                "alias": "inventory_a6ca2f835c2d38265f4a0ec50d1120f5"
            },
            {
                "id": 372,
                "dimensions": [
                    "site",
                    "campaign",
                    "ad",
                    "placementSize",
                    "deal"
                ],
                "alias": "inventory_66c32e7c000c7bc75aeb8cce5fe2613a"
            },
            {
                "id": 373,
                "dimensions": [
                    "site",
                    "campaign",
                    "ad",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_5406c732e9680a6441c6842fc954b265"
            },
            {
                "id": 374,
                "dimensions": [
                    "site",
                    "campaign",
                    "placementSize",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_b2732fe08d9ebc970cc4e352a239b0ab"
            },
            {
                "id": 375,
                "dimensions": [
                    "site",
                    "creative",
                    "ad",
                    "placementSize",
                    "placement"
                ],
                "alias": "inventory_aa4d1b4d1d6966d18d47e971f2532578"
            },
            {
                "id": 376,
                "dimensions": [
                    "site",
                    "creative",
                    "ad",
                    "placementSize",
                    "deal"
                ],
                "alias": "inventory_5e0dbf84be72ae5a01c1f1a715a20f3c"
            },
            {
                "id": 377,
                "dimensions": [
                    "site",
                    "creative",
                    "ad",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_74093c1cb0aeadf6357aa8453051ab83"
            },
            {
                "id": 378,
                "dimensions": [
                    "site",
                    "creative",
                    "placementSize",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_8cb071c686d9ba39fd94967bc8aefb2d"
            },
            {
                "id": 379,
                "dimensions": [
                    "site",
                    "ad",
                    "placementSize",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_2b7085f5e59ca06e5839398edfa07f90"
            },
            {
                "id": 380,
                "dimensions": [
                    "campaign",
                    "creative",
                    "ad",
                    "placementSize",
                    "placement"
                ],
                "alias": "inventory_0f2ebc4b427351184751feec1b53e001"
            },
            {
                "id": 381,
                "dimensions": [
                    "campaign",
                    "creative",
                    "ad",
                    "placementSize",
                    "deal"
                ],
                "alias": "inventory_8de864acba24afd43d567b440f409c40"
            },
            {
                "id": 382,
                "dimensions": [
                    "campaign",
                    "creative",
                    "ad",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_0dfdecaa03616f8c88ee09fa20e03f96"
            },
            {
                "id": 383,
                "dimensions": [
                    "campaign",
                    "creative",
                    "placementSize",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_e78cc0d2c22fcf52e532215ef432ad67"
            },
            {
                "id": 384,
                "dimensions": [
                    "campaign",
                    "ad",
                    "placementSize",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_497785be5658142ab05488c0abdaafd4"
            },
            {
                "id": 385,
                "dimensions": [
                    "creative",
                    "ad",
                    "placementSize",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_a290749f10a7d6b6838ae2ffcdf9ceb9"
            },
            {
                "id": 386,
                "dimensions": [
                    "supplySource",
                    "site",
                    "campaign",
                    "creative",
                    "ad",
                    "placementSize"
                ],
                "alias": "inventory_48e260eb121471b1507753f352f4be1e"
            },
            {
                "id": 387,
                "dimensions": [
                    "supplySource",
                    "site",
                    "campaign",
                    "creative",
                    "ad",
                    "placement"
                ],
                "alias": "inventory_664803525559d059605ab016cee55be7"
            },
            {
                "id": 388,
                "dimensions": [
                    "supplySource",
                    "site",
                    "campaign",
                    "creative",
                    "ad",
                    "deal"
                ],
                "alias": "inventory_fdbca8e0e4274588003e0a10a036f325"
            },
            {
                "id": 389,
                "dimensions": [
                    "supplySource",
                    "site",
                    "campaign",
                    "creative",
                    "placementSize",
                    "placement"
                ],
                "alias": "inventory_676d900e274351d40701e9e52e57f068"
            },
            {
                "id": 390,
                "dimensions": [
                    "supplySource",
                    "site",
                    "campaign",
                    "creative",
                    "placementSize",
                    "deal"
                ],
                "alias": "inventory_f785dd18c9af23dd16b9da00ae43105e"
            },
            {
                "id": 391,
                "dimensions": [
                    "supplySource",
                    "site",
                    "campaign",
                    "creative",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_b4a75cdba1e9125e668cabef9bc34364"
            },
            {
                "id": 392,
                "dimensions": [
                    "supplySource",
                    "site",
                    "campaign",
                    "ad",
                    "placementSize",
                    "placement"
                ],
                "alias": "inventory_c299c7292bcea72a0e46843cd65fd2b8"
            },
            {
                "id": 393,
                "dimensions": [
                    "supplySource",
                    "site",
                    "campaign",
                    "ad",
                    "placementSize",
                    "deal"
                ],
                "alias": "inventory_448909111b3ba9cdc24b63b38ba7d871"
            },
            {
                "id": 394,
                "dimensions": [
                    "supplySource",
                    "site",
                    "campaign",
                    "ad",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_078c7bd40ef5b3079104ae1a1a8af5c7"
            },
            {
                "id": 395,
                "dimensions": [
                    "supplySource",
                    "site",
                    "campaign",
                    "placementSize",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_4a1f5192b65a45c8e57e78c114d682a4"
            },
            {
                "id": 396,
                "dimensions": [
                    "supplySource",
                    "site",
                    "creative",
                    "ad",
                    "placementSize",
                    "placement"
                ],
                "alias": "inventory_5c78caf5a3d229ccdbaf5bc62ebc8a50"
            },
            {
                "id": 397,
                "dimensions": [
                    "supplySource",
                    "site",
                    "creative",
                    "ad",
                    "placementSize",
                    "deal"
                ],
                "alias": "inventory_b4f2c670fd886b7e7b78aba88b409027"
            },
            {
                "id": 398,
                "dimensions": [
                    "supplySource",
                    "site",
                    "creative",
                    "ad",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_aee80bf37a753eebc2c4b5d2cd7e08bd"
            },
            {
                "id": 399,
                "dimensions": [
                    "supplySource",
                    "site",
                    "creative",
                    "placementSize",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_badbe92567be5cd80d21606adb8f5931"
            },
            {
                "id": 400,
                "dimensions": [
                    "supplySource",
                    "site",
                    "ad",
                    "placementSize",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_b1c1ba4ddfc5e1e37f11872557fcbc6b"
            },
            {
                "id": 401,
                "dimensions": [
                    "supplySource",
                    "campaign",
                    "creative",
                    "ad",
                    "placementSize",
                    "placement"
                ],
                "alias": "inventory_5b17c662cf3dbd6a33fcecdb4217cf2d"
            },
            {
                "id": 402,
                "dimensions": [
                    "supplySource",
                    "campaign",
                    "creative",
                    "ad",
                    "placementSize",
                    "deal"
                ],
                "alias": "inventory_63d7f8b3497f62449a2dcd4574b427ed"
            },
            {
                "id": 403,
                "dimensions": [
                    "supplySource",
                    "campaign",
                    "creative",
                    "ad",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_ba0e81a2542a6dc30abdad7c8157e09d"
            },
            {
                "id": 404,
                "dimensions": [
                    "supplySource",
                    "campaign",
                    "creative",
                    "placementSize",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_d67a9201222d5e08aefa3cddcc9335de"
            },
            {
                "id": 405,
                "dimensions": [
                    "supplySource",
                    "campaign",
                    "ad",
                    "placementSize",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_a62d78facc40d6c2cd94817f885cb8a5"
            },
            {
                "id": 406,
                "dimensions": [
                    "supplySource",
                    "creative",
                    "ad",
                    "placementSize",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_ef9f660a5c8f801f31d7cefec7ffc576"
            },
            {
                "id": 407,
                "dimensions": [
                    "site",
                    "campaign",
                    "creative",
                    "ad",
                    "placementSize",
                    "placement"
                ],
                "alias": "inventory_825918290a2dc4fc5857b69ad58e8066"
            },
            {
                "id": 408,
                "dimensions": [
                    "site",
                    "campaign",
                    "creative",
                    "ad",
                    "placementSize",
                    "deal"
                ],
                "alias": "inventory_4599138bbb060c9cda0385245c2b4b2a"
            },
            {
                "id": 409,
                "dimensions": [
                    "site",
                    "campaign",
                    "creative",
                    "ad",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_79dec8bd370058f20f18a4a613bafc20"
            },
            {
                "id": 410,
                "dimensions": [
                    "site",
                    "campaign",
                    "creative",
                    "placementSize",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_2a37f866aa901826345e1affca5fee8e"
            },
            {
                "id": 411,
                "dimensions": [
                    "site",
                    "campaign",
                    "ad",
                    "placementSize",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_39957045339738b547c0b0de772bd41e"
            },
            {
                "id": 412,
                "dimensions": [
                    "site",
                    "creative",
                    "ad",
                    "placementSize",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_64e197acf672b0302670a3951a0f27f7"
            },
            {
                "id": 413,
                "dimensions": [
                    "campaign",
                    "creative",
                    "ad",
                    "placementSize",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_07a58efba9e441f81eba37b951867072"
            },
            {
                "id": 414,
                "dimensions": [
                    "supplySource",
                    "site",
                    "campaign",
                    "creative",
                    "ad",
                    "placementSize",
                    "placement"
                ],
                "alias": "inventory_45e58532268aa23e207ac8de2d5cd63f"
            },
            {
                "id": 415,
                "dimensions": [
                    "supplySource",
                    "site",
                    "campaign",
                    "creative",
                    "ad",
                    "placementSize",
                    "deal"
                ],
                "alias": "inventory_f2385d2a84e40d8c60ff2980319b2270"
            },
            {
                "id": 416,
                "dimensions": [
                    "supplySource",
                    "site",
                    "campaign",
                    "creative",
                    "ad",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_700da71ae4c9c4e46558d5ad4febbd5f"
            },
            {
                "id": 417,
                "dimensions": [
                    "supplySource",
                    "site",
                    "campaign",
                    "creative",
                    "placementSize",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_639b8e681452f2faec91c4b3bc3b6702"
            },
            {
                "id": 418,
                "dimensions": [
                    "supplySource",
                    "site",
                    "campaign",
                    "ad",
                    "placementSize",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_04dd481606f3b0fa32c7c98c852ff773"
            },
            {
                "id": 419,
                "dimensions": [
                    "supplySource",
                    "site",
                    "creative",
                    "ad",
                    "placementSize",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_4a8b7813d48445d7bd7c745f0e984f83"
            },
            {
                "id": 420,
                "dimensions": [
                    "supplySource",
                    "campaign",
                    "creative",
                    "ad",
                    "placementSize",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_9563e00ece272e37df19d081c5c8a9ed"
            },
            {
                "id": 421,
                "dimensions": [
                    "site",
                    "campaign",
                    "creative",
                    "ad",
                    "placementSize",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_cbd9711e30626bb8539ac55cd3a129dd"
            },
            {
                "id": 422,
                "dimensions": [
                    "supplySource",
                    "site",
                    "campaign",
                    "creative",
                    "ad",
                    "placementSize",
                    "placement",
                    "deal"
                ],
                "alias": "inventory_640c4d8939c61aa6461cb92f142dac3a"
            }
        ]
    },
    {
        "report_type": "product",
        "reports": [
            {
                "id": 423,
                "dimensions": [
                    "order"
                ],
                "alias": "product_70a17ffa722a3985b86d30b034ad06d7"
            },
            {
                "id": 424,
                "dimensions": [
                    "lineItem"
                ],
                "alias": "product_867cf1ff92974922abc5d5b045b4a2d8"
            },
            {
                "id": 425,
                "dimensions": [
                    "order",
                    "lineItem"
                ],
                "alias": "product_8d7cecaece9ed583d9854d1891aede73"
            }
        ]
    },
    {
        "report_type": "tech",
        "reports": [
            {
                "id": 426,
                "dimensions": [
                    "order"
                ],
                "alias": "tech_70a17ffa722a3985b86d30b034ad06d7"
            },
            {
                "id": 427,
                "dimensions": [
                    "lineItem"
                ],
                "alias": "tech_867cf1ff92974922abc5d5b045b4a2d8"
            },
            {
                "id": 428,
                "dimensions": [
                    "operatingSystem"
                ],
                "alias": "tech_eb7f6fc5e9792b05dda7ad20b41d9570"
            },
            {
                "id": 429,
                "dimensions": [
                    "browserType"
                ],
                "alias": "tech_735c102c31c38018b8ce237318f144cf"
            },
            {
                "id": 430,
                "dimensions": [
                    "browserVersion"
                ],
                "alias": "tech_975e60b5afc499187a993ce39463e933"
            },
            {
                "id": 431,
                "dimensions": [
                    "deviceType"
                ],
                "alias": "tech_4a0339dd5300c55ab2f5fc1917b5180e"
            },
            {
                "id": 432,
                "dimensions": [
                    "environmentType"
                ],
                "alias": "tech_d32c562e113fbd6c8ac9d4841085deca"
            },
            {
                "id": 433,
                "dimensions": [
                    "order",
                    "lineItem"
                ],
                "alias": "tech_8d7cecaece9ed583d9854d1891aede73"
            },
            {
                "id": 434,
                "dimensions": [
                    "order",
                    "operatingSystem"
                ],
                "alias": "tech_376a749f221f2c750513d5145ed2e44f"
            },
            {
                "id": 435,
                "dimensions": [
                    "order",
                    "browserType"
                ],
                "alias": "tech_75a51c4a27326f0b0622c70aaeed84fa"
            },
            {
                "id": 436,
                "dimensions": [
                    "order",
                    "browserVersion"
                ],
                "alias": "tech_fa64b27b6cfd20df5a8099ea849a9942"
            },
            {
                "id": 437,
                "dimensions": [
                    "order",
                    "deviceType"
                ],
                "alias": "tech_42ccedffe1cfb8ebfc1c59fb6471a4e3"
            },
            {
                "id": 438,
                "dimensions": [
                    "order",
                    "environmentType"
                ],
                "alias": "tech_8b2746ddb6ca68e62a7926a758f04132"
            },
            {
                "id": 439,
                "dimensions": [
                    "lineItem",
                    "operatingSystem"
                ],
                "alias": "tech_1b1b684c7b2d6c21cba5734fc68d578b"
            },
            {
                "id": 440,
                "dimensions": [
                    "lineItem",
                    "browserType"
                ],
                "alias": "tech_8d83e57af0ee36d9c6ac3db541384815"
            },
            {
                "id": 441,
                "dimensions": [
                    "lineItem",
                    "browserVersion"
                ],
                "alias": "tech_ad0be51ed5da76d13c75428e374b7768"
            },
            {
                "id": 442,
                "dimensions": [
                    "lineItem",
                    "deviceType"
                ],
                "alias": "tech_a7ae37cfcbabb29ce5ca0ac8988edd15"
            },
            {
                "id": 443,
                "dimensions": [
                    "lineItem",
                    "environmentType"
                ],
                "alias": "tech_ada814a541ab088e5d1314da3ffc09e0"
            },
            {
                "id": 444,
                "dimensions": [
                    "operatingSystem",
                    "browserType"
                ],
                "alias": "tech_65f0404754bc3c52cca38a8fe0f53c61"
            },
            {
                "id": 445,
                "dimensions": [
                    "operatingSystem",
                    "browserVersion"
                ],
                "alias": "tech_3823bdb8f911c17b891b55d24c35c9ba"
            },
            {
                "id": 446,
                "dimensions": [
                    "operatingSystem",
                    "deviceType"
                ],
                "alias": "tech_b48b3ff87f849f73fe5a07af4a496439"
            },
            {
                "id": 447,
                "dimensions": [
                    "operatingSystem",
                    "environmentType"
                ],
                "alias": "tech_670b7dbde63ecb2ec94f378995411666"
            },
            {
                "id": 448,
                "dimensions": [
                    "browserType",
                    "browserVersion"
                ],
                "alias": "tech_72ceda5115ecb8891ac3a7b57419df67"
            },
            {
                "id": 449,
                "dimensions": [
                    "browserType",
                    "deviceType"
                ],
                "alias": "tech_99306aad274fe6b83c5b928d32e7a006"
            },
            {
                "id": 450,
                "dimensions": [
                    "browserType",
                    "environmentType"
                ],
                "alias": "tech_f9cfad767ebf5d6c58ae432ee2dd2f55"
            },
            {
                "id": 451,
                "dimensions": [
                    "browserVersion",
                    "deviceType"
                ],
                "alias": "tech_751b5c72cee0f091a60318aa87783225"
            },
            {
                "id": 452,
                "dimensions": [
                    "browserVersion",
                    "environmentType"
                ],
                "alias": "tech_89bd9d3a190f188a577a31dc196b7fd5"
            },
            {
                "id": 453,
                "dimensions": [
                    "deviceType",
                    "environmentType"
                ],
                "alias": "tech_df6487bba315645474c280937b8e3411"
            },
            {
                "id": 454,
                "dimensions": [
                    "order",
                    "lineItem",
                    "operatingSystem"
                ],
                "alias": "tech_54404b1ec64a37394e1611bd27fcc8ca"
            },
            {
                "id": 455,
                "dimensions": [
                    "order",
                    "lineItem",
                    "browserType"
                ],
                "alias": "tech_7dbcc7fb40d27de1e72e703c08ee7334"
            },
            {
                "id": 456,
                "dimensions": [
                    "order",
                    "lineItem",
                    "browserVersion"
                ],
                "alias": "tech_071c50c8d6b6859428d89bbfa4508b11"
            },
            {
                "id": 457,
                "dimensions": [
                    "order",
                    "lineItem",
                    "deviceType"
                ],
                "alias": "tech_b06fbf47fbcef76e6486b414cf6f535e"
            },
            {
                "id": 458,
                "dimensions": [
                    "order",
                    "lineItem",
                    "environmentType"
                ],
                "alias": "tech_f44f72956846be6af28367df74854843"
            },
            {
                "id": 459,
                "dimensions": [
                    "order",
                    "operatingSystem",
                    "browserType"
                ],
                "alias": "tech_fe7f4f4f75874d6014934233f5e9655e"
            },
            {
                "id": 460,
                "dimensions": [
                    "order",
                    "operatingSystem",
                    "browserVersion"
                ],
                "alias": "tech_460d3abd48f62ecc55c210845e498bb5"
            },
            {
                "id": 461,
                "dimensions": [
                    "order",
                    "operatingSystem",
                    "deviceType"
                ],
                "alias": "tech_4ee26bf888d794527a0f2d2c7e60db0a"
            },
            {
                "id": 462,
                "dimensions": [
                    "order",
                    "operatingSystem",
                    "environmentType"
                ],
                "alias": "tech_e57f000e66452ec144a68631fa721bb1"
            },
            {
                "id": 463,
                "dimensions": [
                    "order",
                    "browserType",
                    "browserVersion"
                ],
                "alias": "tech_f8818796649b99241cc6a44a1d6412ea"
            },
            {
                "id": 464,
                "dimensions": [
                    "order",
                    "browserType",
                    "deviceType"
                ],
                "alias": "tech_46669d320a5df9d3e26b28bdbf3bc3ff"
            },
            {
                "id": 465,
                "dimensions": [
                    "order",
                    "browserType",
                    "environmentType"
                ],
                "alias": "tech_eb3eea3e9e1ddccdd1e0d986a7829c55"
            },
            {
                "id": 466,
                "dimensions": [
                    "order",
                    "browserVersion",
                    "deviceType"
                ],
                "alias": "tech_4695814f10e4fcfd9645361940deaacf"
            },
            {
                "id": 467,
                "dimensions": [
                    "order",
                    "browserVersion",
                    "environmentType"
                ],
                "alias": "tech_4551f643d9f41a3c3a825ff60d0138ca"
            },
            {
                "id": 468,
                "dimensions": [
                    "order",
                    "deviceType",
                    "environmentType"
                ],
                "alias": "tech_3cda1bf294470c6ef977a6bd9a78f119"
            },
            {
                "id": 469,
                "dimensions": [
                    "lineItem",
                    "operatingSystem",
                    "browserType"
                ],
                "alias": "tech_f7fd921a8e1e1979eeef705ca00be915"
            },
            {
                "id": 470,
                "dimensions": [
                    "lineItem",
                    "operatingSystem",
                    "browserVersion"
                ],
                "alias": "tech_40c0e7689b920a1d6ed0c88998124e19"
            },
            {
                "id": 471,
                "dimensions": [
                    "lineItem",
                    "operatingSystem",
                    "deviceType"
                ],
                "alias": "tech_57b7b86141d6b41bdcee1b8b1df08e91"
            },
            {
                "id": 472,
                "dimensions": [
                    "lineItem",
                    "operatingSystem",
                    "environmentType"
                ],
                "alias": "tech_b8b81785d8c9380c4a1276a30daa7279"
            },
            {
                "id": 473,
                "dimensions": [
                    "lineItem",
                    "browserType",
                    "browserVersion"
                ],
                "alias": "tech_c27721e53f500481ae2a6c87832b1ef2"
            },
            {
                "id": 474,
                "dimensions": [
                    "lineItem",
                    "browserType",
                    "deviceType"
                ],
                "alias": "tech_3865c0aac7c008b428e25aef33b64e33"
            },
            {
                "id": 475,
                "dimensions": [
                    "lineItem",
                    "browserType",
                    "environmentType"
                ],
                "alias": "tech_4bba7f22147faa4fc6a6b8b9c790958b"
            },
            {
                "id": 476,
                "dimensions": [
                    "lineItem",
                    "browserVersion",
                    "deviceType"
                ],
                "alias": "tech_48b5155b7ec2275a8026c433a533c2d3"
            },
            {
                "id": 477,
                "dimensions": [
                    "lineItem",
                    "browserVersion",
                    "environmentType"
                ],
                "alias": "tech_57f8bd650216f3e11f256230d316d5d6"
            },
            {
                "id": 478,
                "dimensions": [
                    "lineItem",
                    "deviceType",
                    "environmentType"
                ],
                "alias": "tech_f70b476cefb43e61ff30eb60617477a6"
            },
            {
                "id": 479,
                "dimensions": [
                    "operatingSystem",
                    "browserType",
                    "browserVersion"
                ],
                "alias": "tech_d61ddcc5b9228d00e827deb0862abede"
            },
            {
                "id": 480,
                "dimensions": [
                    "operatingSystem",
                    "browserType",
                    "deviceType"
                ],
                "alias": "tech_0efffdf816d3d2a22a80ba632a77a1bc"
            },
            {
                "id": 481,
                "dimensions": [
                    "operatingSystem",
                    "browserType",
                    "environmentType"
                ],
                "alias": "tech_bc92da6a201796765c111f209478b9e6"
            },
            {
                "id": 482,
                "dimensions": [
                    "operatingSystem",
                    "browserVersion",
                    "deviceType"
                ],
                "alias": "tech_b086ae2e2a11f9c36022fb6e34294aea"
            },
            {
                "id": 483,
                "dimensions": [
                    "operatingSystem",
                    "browserVersion",
                    "environmentType"
                ],
                "alias": "tech_3a722bc3641221b6f487e66ac5b403ad"
            },
            {
                "id": 484,
                "dimensions": [
                    "operatingSystem",
                    "deviceType",
                    "environmentType"
                ],
                "alias": "tech_db68980b0796745a2d4a887121aac705"
            },
            {
                "id": 485,
                "dimensions": [
                    "browserType",
                    "browserVersion",
                    "deviceType"
                ],
                "alias": "tech_50d443775f479efb4d630e0daa6cf399"
            },
            {
                "id": 486,
                "dimensions": [
                    "browserType",
                    "browserVersion",
                    "environmentType"
                ],
                "alias": "tech_770fe80f24299e8ad1bf8137af42c114"
            },
            {
                "id": 487,
                "dimensions": [
                    "browserType",
                    "deviceType",
                    "environmentType"
                ],
                "alias": "tech_b3ae730776cfffd0a86f2225d74c4d51"
            },
            {
                "id": 488,
                "dimensions": [
                    "browserVersion",
                    "deviceType",
                    "environmentType"
                ],
                "alias": "tech_648068a8c14703565a8daa528a0b85ef"
            },
            {
                "id": 489,
                "dimensions": [
                    "order",
                    "lineItem",
                    "operatingSystem",
                    "browserType"
                ],
                "alias": "tech_f3a56135bb97dcd4e6c568b742654e80"
            },
            {
                "id": 490,
                "dimensions": [
                    "order",
                    "lineItem",
                    "operatingSystem",
                    "browserVersion"
                ],
                "alias": "tech_db86440ee6c2a2fe52e8a95c2f206d09"
            },
            {
                "id": 491,
                "dimensions": [
                    "order",
                    "lineItem",
                    "operatingSystem",
                    "deviceType"
                ],
                "alias": "tech_fc3d4f76c1066ff82a5a0b1b2d9e7663"
            },
            {
                "id": 492,
                "dimensions": [
                    "order",
                    "lineItem",
                    "operatingSystem",
                    "environmentType"
                ],
                "alias": "tech_674a57041ab6484d046e75481a5145dd"
            },
            {
                "id": 493,
                "dimensions": [
                    "order",
                    "lineItem",
                    "browserType",
                    "browserVersion"
                ],
                "alias": "tech_50dc1d06da60f71ec41e4938858efce3"
            },
            {
                "id": 494,
                "dimensions": [
                    "order",
                    "lineItem",
                    "browserType",
                    "deviceType"
                ],
                "alias": "tech_0d9ef60dbd5c86b33ceeb6bc826d5f00"
            },
            {
                "id": 495,
                "dimensions": [
                    "order",
                    "lineItem",
                    "browserType",
                    "environmentType"
                ],
                "alias": "tech_e8b6fa41c4ceda09f041c6d40a8e54e7"
            },
            {
                "id": 496,
                "dimensions": [
                    "order",
                    "lineItem",
                    "browserVersion",
                    "deviceType"
                ],
                "alias": "tech_55acbba4cb7b3105d4955030bda5cf59"
            },
            {
                "id": 497,
                "dimensions": [
                    "order",
                    "lineItem",
                    "browserVersion",
                    "environmentType"
                ],
                "alias": "tech_0dce988d1ebd19af49f96edfceb72cf4"
            },
            {
                "id": 498,
                "dimensions": [
                    "order",
                    "lineItem",
                    "deviceType",
                    "environmentType"
                ],
                "alias": "tech_bb3ffe20ad94a98ca1378436449421a8"
            },
            {
                "id": 499,
                "dimensions": [
                    "order",
                    "operatingSystem",
                    "browserType",
                    "browserVersion"
                ],
                "alias": "tech_05ab87652c7aa197af8d5a72f97864ea"
            },
            {
                "id": 500,
                "dimensions": [
                    "order",
                    "operatingSystem",
                    "browserType",
                    "deviceType"
                ],
                "alias": "tech_f00fefe4da47123cae31de5a54c82702"
            },
            {
                "id": 501,
                "dimensions": [
                    "order",
                    "operatingSystem",
                    "browserType",
                    "environmentType"
                ],
                "alias": "tech_bb931f8e8918d7f340c3a20c5b19b412"
            },
            {
                "id": 502,
                "dimensions": [
                    "order",
                    "operatingSystem",
                    "browserVersion",
                    "deviceType"
                ],
                "alias": "tech_6f916875809162dea1f6064033632d71"
            },
            {
                "id": 503,
                "dimensions": [
                    "order",
                    "operatingSystem",
                    "browserVersion",
                    "environmentType"
                ],
                "alias": "tech_b5e5ccbfb876f6866cf98af911708d7f"
            },
            {
                "id": 504,
                "dimensions": [
                    "order",
                    "operatingSystem",
                    "deviceType",
                    "environmentType"
                ],
                "alias": "tech_4cb22cd90589da71b1cda443daacdca2"
            },
            {
                "id": 505,
                "dimensions": [
                    "order",
                    "browserType",
                    "browserVersion",
                    "deviceType"
                ],
                "alias": "tech_b6c36c87be195ac2acdb1357cf576582"
            },
            {
                "id": 506,
                "dimensions": [
                    "order",
                    "browserType",
                    "browserVersion",
                    "environmentType"
                ],
                "alias": "tech_da4e37e4c7566f0b08b6c81e18669c68"
            },
            {
                "id": 507,
                "dimensions": [
                    "order",
                    "browserType",
                    "deviceType",
                    "environmentType"
                ],
                "alias": "tech_403d3def68ba4c94833feaf9ee890d8f"
            },
            {
                "id": 508,
                "dimensions": [
                    "order",
                    "browserVersion",
                    "deviceType",
                    "environmentType"
                ],
                "alias": "tech_057bbb67337f0453991f85777492e964"
            },
            {
                "id": 509,
                "dimensions": [
                    "lineItem",
                    "operatingSystem",
                    "browserType",
                    "browserVersion"
                ],
                "alias": "tech_e4453d937dc6ae4a467adc078eafb85a"
            },
            {
                "id": 510,
                "dimensions": [
                    "lineItem",
                    "operatingSystem",
                    "browserType",
                    "deviceType"
                ],
                "alias": "tech_a9dc69830dc338c91e2d415fbb29517f"
            },
            {
                "id": 511,
                "dimensions": [
                    "lineItem",
                    "operatingSystem",
                    "browserType",
                    "environmentType"
                ],
                "alias": "tech_32c41d918aede6e0e33705bd164e2dcc"
            },
            {
                "id": 512,
                "dimensions": [
                    "lineItem",
                    "operatingSystem",
                    "browserVersion",
                    "deviceType"
                ],
                "alias": "tech_cad1189d54b2427cfab55a35b869cd95"
            },
            {
                "id": 513,
                "dimensions": [
                    "lineItem",
                    "operatingSystem",
                    "browserVersion",
                    "environmentType"
                ],
                "alias": "tech_5de9318902e680304bdd4fe68b538c4d"
            },
            {
                "id": 514,
                "dimensions": [
                    "lineItem",
                    "operatingSystem",
                    "deviceType",
                    "environmentType"
                ],
                "alias": "tech_27cf64d3be0e1c56a9e7c002110b173c"
            },
            {
                "id": 515,
                "dimensions": [
                    "lineItem",
                    "browserType",
                    "browserVersion",
                    "deviceType"
                ],
                "alias": "tech_099918cf812d882c138692f2a4a53731"
            },
            {
                "id": 516,
                "dimensions": [
                    "lineItem",
                    "browserType",
                    "browserVersion",
                    "environmentType"
                ],
                "alias": "tech_c608f7b052513374bc386f4183ef1543"
            },
            {
                "id": 517,
                "dimensions": [
                    "lineItem",
                    "browserType",
                    "deviceType",
                    "environmentType"
                ],
                "alias": "tech_5bcdc9bc80c5410a699b2b73472d08b2"
            },
            {
                "id": 518,
                "dimensions": [
                    "lineItem",
                    "browserVersion",
                    "deviceType",
                    "environmentType"
                ],
                "alias": "tech_c079dcbf287c9385fd0a729582bc9971"
            },
            {
                "id": 519,
                "dimensions": [
                    "operatingSystem",
                    "browserType",
                    "browserVersion",
                    "deviceType"
                ],
                "alias": "tech_a140b79a130bd94b4641d4d48254b843"
            },
            {
                "id": 520,
                "dimensions": [
                    "operatingSystem",
                    "browserType",
                    "browserVersion",
                    "environmentType"
                ],
                "alias": "tech_a503fc3649edc1c86d2f365c389f2d43"
            },
            {
                "id": 521,
                "dimensions": [
                    "operatingSystem",
                    "browserType",
                    "deviceType",
                    "environmentType"
                ],
                "alias": "tech_ba5f528d4759e5d26f22ba4a1609bf12"
            },
            {
                "id": 522,
                "dimensions": [
                    "operatingSystem",
                    "browserVersion",
                    "deviceType",
                    "environmentType"
                ],
                "alias": "tech_036efdb8b622b72d96c9a8487e5cc578"
            },
            {
                "id": 523,
                "dimensions": [
                    "browserType",
                    "browserVersion",
                    "deviceType",
                    "environmentType"
                ],
                "alias": "tech_a3657d350bde17b8875b45bbaa56c587"
            },
            {
                "id": 524,
                "dimensions": [
                    "order",
                    "lineItem",
                    "operatingSystem",
                    "browserType",
                    "browserVersion"
                ],
                "alias": "tech_b5e5aeb140761e406034dfd2860c3831"
            },
            {
                "id": 525,
                "dimensions": [
                    "order",
                    "lineItem",
                    "operatingSystem",
                    "browserType",
                    "deviceType"
                ],
                "alias": "tech_52410df2508b451a46ada3fe7ff7e6a5"
            },
            {
                "id": 526,
                "dimensions": [
                    "order",
                    "lineItem",
                    "operatingSystem",
                    "browserType",
                    "environmentType"
                ],
                "alias": "tech_95e0824e6ed4d29f83db06e02b7fb84c"
            },
            {
                "id": 527,
                "dimensions": [
                    "order",
                    "lineItem",
                    "operatingSystem",
                    "browserVersion",
                    "deviceType"
                ],
                "alias": "tech_1f127f37b586564c123df586f9be9c1b"
            },
            {
                "id": 528,
                "dimensions": [
                    "order",
                    "lineItem",
                    "operatingSystem",
                    "browserVersion",
                    "environmentType"
                ],
                "alias": "tech_c138c440805bd549542941be1f4ca25e"
            },
            {
                "id": 529,
                "dimensions": [
                    "order",
                    "lineItem",
                    "operatingSystem",
                    "deviceType",
                    "environmentType"
                ],
                "alias": "tech_6a7349286d6a36e499e04a2cd99e35f2"
            },
            {
                "id": 530,
                "dimensions": [
                    "order",
                    "lineItem",
                    "browserType",
                    "browserVersion",
                    "deviceType"
                ],
                "alias": "tech_94628728b1f985e4ca9c85d434173322"
            },
            {
                "id": 531,
                "dimensions": [
                    "order",
                    "lineItem",
                    "browserType",
                    "browserVersion",
                    "environmentType"
                ],
                "alias": "tech_ea107d368dac416cf91cf61ecf6546a4"
            },
            {
                "id": 532,
                "dimensions": [
                    "order",
                    "lineItem",
                    "browserType",
                    "deviceType",
                    "environmentType"
                ],
                "alias": "tech_8ad8498512405beed244c89c52bcd1c0"
            },
            {
                "id": 533,
                "dimensions": [
                    "order",
                    "lineItem",
                    "browserVersion",
                    "deviceType",
                    "environmentType"
                ],
                "alias": "tech_f2aa94dbaf976a04c42b144accf11351"
            },
            {
                "id": 534,
                "dimensions": [
                    "order",
                    "operatingSystem",
                    "browserType",
                    "browserVersion",
                    "deviceType"
                ],
                "alias": "tech_9e4ba717d17f1969cc3f6d2c54c1ff24"
            },
            {
                "id": 535,
                "dimensions": [
                    "order",
                    "operatingSystem",
                    "browserType",
                    "browserVersion",
                    "environmentType"
                ],
                "alias": "tech_3d84e3138d747b010010082470e7e2e4"
            },
            {
                "id": 536,
                "dimensions": [
                    "order",
                    "operatingSystem",
                    "browserType",
                    "deviceType",
                    "environmentType"
                ],
                "alias": "tech_e43ec1e831d997dc30e53eaeeb6d9565"
            },
            {
                "id": 537,
                "dimensions": [
                    "order",
                    "operatingSystem",
                    "browserVersion",
                    "deviceType",
                    "environmentType"
                ],
                "alias": "tech_5ea3c948cbd34291cee8aa8341378763"
            },
            {
                "id": 538,
                "dimensions": [
                    "order",
                    "browserType",
                    "browserVersion",
                    "deviceType",
                    "environmentType"
                ],
                "alias": "tech_45c8589087369ca8bac2ac0e191e1f65"
            },
            {
                "id": 539,
                "dimensions": [
                    "lineItem",
                    "operatingSystem",
                    "browserType",
                    "browserVersion",
                    "deviceType"
                ],
                "alias": "tech_442d776b71c711fc3fce10f042e005ee"
            },
            {
                "id": 540,
                "dimensions": [
                    "lineItem",
                    "operatingSystem",
                    "browserType",
                    "browserVersion",
                    "environmentType"
                ],
                "alias": "tech_ef6611b3bc478bc2bfada1c56623fdf6"
            },
            {
                "id": 541,
                "dimensions": [
                    "lineItem",
                    "operatingSystem",
                    "browserType",
                    "deviceType",
                    "environmentType"
                ],
                "alias": "tech_0125308fabb72291c43bba5eaa13c6d0"
            },
            {
                "id": 542,
                "dimensions": [
                    "lineItem",
                    "operatingSystem",
                    "browserVersion",
                    "deviceType",
                    "environmentType"
                ],
                "alias": "tech_bdd460d6c48c2ff1e37d09467d06dabf"
            },
            {
                "id": 543,
                "dimensions": [
                    "lineItem",
                    "browserType",
                    "browserVersion",
                    "deviceType",
                    "environmentType"
                ],
                "alias": "tech_48e33bfbeb2d161964e79cd29914d2fc"
            },
            {
                "id": 544,
                "dimensions": [
                    "operatingSystem",
                    "browserType",
                    "browserVersion",
                    "deviceType",
                    "environmentType"
                ],
                "alias": "tech_72d0f5d246d19efb5c0a3dd6f66da75d"
            },
            {
                "id": 545,
                "dimensions": [
                    "order",
                    "lineItem",
                    "operatingSystem",
                    "browserType",
                    "browserVersion",
                    "deviceType"
                ],
                "alias": "tech_c1df27b069ac3a9ffea98185d3608e1c"
            },
            {
                "id": 546,
                "dimensions": [
                    "order",
                    "lineItem",
                    "operatingSystem",
                    "browserType",
                    "browserVersion",
                    "environmentType"
                ],
                "alias": "tech_2a707179d27f319c9d44addd52cce55e"
            },
            {
                "id": 547,
                "dimensions": [
                    "order",
                    "lineItem",
                    "operatingSystem",
                    "browserType",
                    "deviceType",
                    "environmentType"
                ],
                "alias": "tech_3b29aa308e569e609688e1ec4b2b9a04"
            },
            {
                "id": 548,
                "dimensions": [
                    "order",
                    "lineItem",
                    "operatingSystem",
                    "browserVersion",
                    "deviceType",
                    "environmentType"
                ],
                "alias": "tech_6ae9a768caa1cf8d058d4ed98d3b7038"
            },
            {
                "id": 549,
                "dimensions": [
                    "order",
                    "lineItem",
                    "browserType",
                    "browserVersion",
                    "deviceType",
                    "environmentType"
                ],
                "alias": "tech_6ef033e8ac30de475eaeb913b9c95428"
            },
            {
                "id": 550,
                "dimensions": [
                    "order",
                    "operatingSystem",
                    "browserType",
                    "browserVersion",
                    "deviceType",
                    "environmentType"
                ],
                "alias": "tech_c720a90d3d9f40fc6b2479b40d6315f4"
            },
            {
                "id": 551,
                "dimensions": [
                    "lineItem",
                    "operatingSystem",
                    "browserType",
                    "browserVersion",
                    "deviceType",
                    "environmentType"
                ],
                "alias": "tech_59ff39ee31f0ebc8df347774b2c27927"
            },
            {
                "id": 552,
                "dimensions": [
                    "order",
                    "lineItem",
                    "operatingSystem",
                    "browserType",
                    "browserVersion",
                    "deviceType",
                    "environmentType"
                ],
                "alias": "tech_3489c8a36c7e209a59883a21203647d6"
            }
        ]
    }
]