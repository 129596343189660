<div class="modal-header">
  <h1 class="modal-title pull-left">
    View Transaction
  </h1>
  <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="table-responsive">
    <table class="table table-sm table-hover table-nowrap card-table">
      <ng-container>
        <tbody class="list font-size-base">
          <tr>
            <td width='25%'>
              Subscription Id:
            </td>
            <td width='75%'>
              <span class="small text-muted" id="subscriptionId"> {{data.subscription_id | number:'6.0' | removeComma}} </span>
            </td>
          </tr>
          <tr>
            <td width='25%'>
              Transaction Id:
            </td>
            <td width='75%'>
              <span class="small text-muted" id="transactionId" [tooltip]="data.transaction_id">
                {{data.transaction_id | truncate:[45, '...']}}
              </span>
            </td>
          </tr>
          <tr>
            <td width='25%'>
              Company:
            </td>
            <td width='75%'>
              <span class="small text-muted" id="company" [tooltip]="data.company">{{ data.company | truncate:[45, '...'] }}</span>
            </td>
          </tr>
          <tr>
            <td width='25%'>
              Email:
            </td>
            <td width='75%'>
              <span class="small text-muted" id="emailAddress" [tooltip]="data.email_address">
                {{ data.email_address | truncate:[45, '...'] }}
              </span>
            </td>
          </tr>
          <tr>
            <td width='25%'>
              Product Name:
            </td>
            <td width='75%'>
              <span class="small text-muted" id="productName" [tooltip]="data.product_name">
                {{data.product_name | truncate:[45, '...']}}
              </span>
            </td>
          </tr>
          <tr>
            <td width='25%'>
              Subscription Name:
            </td>
            <td width='75%'>
              <span class="small text-muted" id="subscriptionName" [tooltip]="data.subscription_name">
                {{data.subscription_name | truncate:[45, '...']}}
              </span>
            </td>
          </tr>
          <tr>
            <td width='25%'>
              Status:
            </td>
            <td width='75%'>
              <span class="small text-muted" id="status">{{data.status}}</span>
            </td>
          </tr>
          <tr>
            <td width='25%'>
              Error Message:
            </td>
            <td width='75%'>
              <span class="small text-muted" id="errMsg" [tooltip]="data.err_msg">
                {{data.err_msg | truncate:[50, '...']}}
              </span>
            </td>
          </tr>
          <tr>
            <td width='25%'>
              Owner:
            </td>
            <td width='75%'>
              <span class="small text-muted" id="owner" [tooltip]="data.owner">
                {{data.owner | truncate:[45, '...']}}
              </span>
            </td>
          </tr>
          <tr>
            <td width='25%'>
              Sender:
            </td>
            <td width='75%'>
              <span class="small text-muted" id="sender" [tooltip]="data.sender">{{data.sender | truncate:[45, '...']}}</span>
            </td>
          </tr>
          <tr>
            <td width='25%'>
              Modified At:
            </td>
            <td width='75%'>
              <span class="small text-muted" id="modifiedAt">{{data.modified_at}}</span>
            </td>
          </tr>
        </tbody>
      </ng-container>
    </table>
  </div>
</div>
<div class="modal-footer">
  <a href class="btn btn-sm btn-info mt-3" (click)="copyMessage(data);">Copy Details</a>
</div>