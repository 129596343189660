import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-shopify-identity-select',
  templateUrl: './help-shopify-identity-select.component.html',
})
export class HelpShopifyIdentitySelectComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
