import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { copyDetails } from 'src/app/core/functions/utility';
import { ImageServiceService } from 'src/app/shared/services/image-service.service';

@Component({
  selector: 'app-modal-job-view',
  templateUrl: './modal-job-view.component.html',
  styleUrls: ['./modal-job-view.component.scss']
})
export class ModalJobViewComponent implements OnInit {

  data: any = {};
  pipeline: any = {};
  copyMessage = copyDetails;
  constructor(
    public bsModalRef: BsModalRef,
    public imageServiceService: ImageServiceService
  ) { }

  ngOnInit(): void {
  }

}
