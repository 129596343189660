import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { observableToPromise } from '../../core/functions/utility';


@Injectable({
  providedIn: 'root'
})
export class LastUpdatedCheckService {

  lastUpdated: any = null;

  constructor(
    private httpClient: HttpClient
  ) { }

  async serverVersionIsDifferent(): Promise<boolean> {
    try {
      const response = await this.getLastUpdated();

      // If the inclided deltaEpoch is a older than the server's deltaEpoch
      if (this.lastUpdated !== null && this.lastUpdated.deltaEpoch < response['deltaEpoch']) {
        return true;
      }

      return false;
    }
    catch (error) {
      throw error;
    }
  }

  async setLastUpdated(): Promise<void> {
    this.lastUpdated = await this.getLastUpdated();
  }

  async getLastUpdated(): Promise<any> {
    const siteOriginUrl = window.location.origin;
    return await observableToPromise(this.httpClient.get(siteOriginUrl + '/last-updated.json'));
  }


}
