import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { IdentityTypeService } from 'src/app/shared/services/identity-type.service';
import { PaginationService } from 'src/app/shared/services/pagination.service';

@Component({
  selector: 'app-filter-identities',
  templateUrl: './filter-identities.component.html',
  styleUrls: ['./filter-identities.component.scss']
})
export class FilterIdentitiesComponent implements OnInit {

  @Input() filterableObjects: any[] = [];
  @Input() identityList: any[] = [];
  @Output() filteredObjects = new EventEmitter<any[]>();
  @Output() updatedPageSize = new EventEmitter<number>();
  // show error alert if there is no identities found when query parameter identityId present
  @Output() showError = new EventEmitter<boolean>();

  pageSize = 10;
  urlParams: any;
  filterSearch = new UntypedFormControl('');
  activeStatusType: 'all' | 'valid' | 'invalid' = 'all';
  activeIdentityTypeFilter: 'all' | any = 'all';

  constructor(
    private identityTypeService: IdentityTypeService,
    private paginationService: PaginationService,
    protected activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.pageSize = this.paginationService.getPageSize();    
    this.urlParams = this.activatedRoute.snapshot.queryParams || null;

    if(this.urlParams.identityId) {
      this.processFilteredTextForRemoteIdentityId(this.urlParams.identityId);
    }
    else {
      this.processFilteredText(this.filterSearch.value);
      // this.filteredObjects.emit([...this.filterableObjects]);
    }

  }

  ngOnChanges(): void {

    this.urlParams = this.activatedRoute.snapshot.queryParams || null;
    
    if(this.urlParams.identityId) {
      this.processFilteredTextForRemoteIdentityId(this.urlParams.identityId);
    }
    else {
      this.processFilteredText(this.filterSearch.value);
      // this.filteredObjects.emit([...this.filterableObjects]);
    }

  }

  processFilteredText(filterText: string): void {

    if (filterText === '') {
      
      if(this.urlParams.identityId) {
        this.processFilteredTextForRemoteIdentityId(this.urlParams.identityId);
      }
      else {
        this.setFilter(this.activeStatusType, this.activeIdentityTypeFilter);
        // this.filteredObjects.emit([...this.filterableObjects]);
      }
      
    }
    else {
      const filteredObjects: any[] = [];

      this.filterableObjects.forEach((val, idx) => {
        if (val.name && val.name.toLowerCase().includes(filterText.toLowerCase())) {
          filteredObjects.push(val);
        }
        else if (val.id && val.id === +filterText) {
          filteredObjects.push(val);
        }
        else if (val.customerAlias && val.customerAlias.toLowerCase().includes(filterText.toLowerCase())) {
          filteredObjects.push(val);
        }
        else if (val.email && val.email.toLowerCase().includes(filterText.toLowerCase())) {
          filteredObjects.push(val);
        }
        else if (val.remoteIdentityTypeId) {
          const identityType = this.identityTypeService.findIdentityTypeFromId(val.remoteIdentityTypeId);
          if (identityType && identityType?.name?.toLowerCase().includes(filterText.toLowerCase())) {
            filteredObjects.push(val);
          }
        }
        // else if (val.firstName && val.firstName.toLowerCase().includes(filterText.toLowerCase())) {
        //   filteredObjects.push(val);
        // }
        // else if (val.lastName && val.lastName.toLowerCase().includes(filterText.toLowerCase())) {
        //   filteredObjects.push(val);
        // }
      });

      this.filteredObjects.emit([...filteredObjects]);
    }
  }

  processFilteredTextForRemoteIdentityId(filterText: string): void {
    if (filterText === '') {
      this.filteredObjects.emit([...this.filterableObjects]);
    }
    else {
      const filteredObjects: any[] = [];

      this.filterableObjects.forEach((val, idx) => {
        if (val.id && val.id === +filterText) {
          filteredObjects.push(val);
        }
      });
      
      // show error alert if there is no identities found when query parameter identityId present
      const state: boolean = filteredObjects.length === 0 ? true : false;
      this.showError.emit(state);
      
      this.filteredObjects.emit([...filteredObjects]);
    }
  }

  applyFilter(event: Event, dropdown: BsDropdownDirective): void {
    event.stopPropagation();
    this.filterSearch.setValue('');
    this.setFilter(this.activeStatusType, this.activeIdentityTypeFilter);
    dropdown.hide();
  }


  setFilter(filterType: 'all' | 'valid' | 'invalid', identityType: 'all' | any): boolean {
    let filteredObjects: any[] = [];
    if (filterType === 'all' && identityType ==='all') {
      filteredObjects = [...this.filterableObjects];
    }
    else {      
      this.filterableObjects.forEach((val, idx) => {

        if (identityType === 'all') {
          if (filterType === 'valid' && val.invalidIdentity === 0) {
            filteredObjects.push(val);
          }
          if (filterType === 'invalid' && val.invalidIdentity === 1) {
            filteredObjects.push(val);
          }
        } 
        if (identityType === val.remoteIdentityTypeId) {
          if (filterType === 'all') {
            filteredObjects.push(val);
          }
          if (filterType === 'valid' && val.invalidIdentity === 0) {
            filteredObjects.push(val);
          }
          if (filterType === 'invalid' && val.invalidIdentity === 1) {
            filteredObjects.push(val);
          }
        }
      });
    }
    this.filteredObjects.emit(filteredObjects);
    // Return false to prevent default.
    return false;
  }

  updatePageEntries(pageSize): void {
    this.updatedPageSize.emit(pageSize);
  }
}
