import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-modal-refresh-token-display',
  templateUrl: './modal-refresh-token-display.component.html'
})
export class ModalRefreshTokenDisplayComponent implements OnInit {
  formGroup: UntypedFormGroup
  modalState: any;
  refreshToken: string;
  onClose$: Subject<any> = new Subject<any>();

  
  constructor(
    private bsModalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
    this.formGroup = new UntypedFormGroup({
      refreshToken: new UntypedFormControl(this.refreshToken),
    });
  }

  onSubmit($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
  }

  cancel(): void {
    this.onClose$.next(false);
    this.closeModalWithComponent();
  }

  private closeModalWithComponent(): void {
    if (this.bsModalRef !== null) {
       this.bsModalRef.hide();
    }
    this.bsModalRef = null;
  }  
}
