import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { observableToPromise } from '../functions/utility';

@Injectable({
  providedIn: 'root'
})
export class ConsoleErrorsService {

  constructor(
    protected httpClient: HttpClient,
    protected router: Router
  ) { }

  logError(errorDetails: any): void {
    const errorDetailsFormatted = this.formatError(errorDetails)
    const payload: ConsoleErrorPayload = this.generatePayload(errorDetailsFormatted);
    observableToPromise(this.httpClient.post(`${environment.openbridgeApiUris.account}/ce`, payload));
    console.error('Error logged to server', errorDetails);
  }

  generatePayload(errorDetails: string): ConsoleErrorPayload {

    const errorPayload = {
      user_agent: navigator.userAgent,
      current_route: this.router.url,
      error_message: errorDetails
    }

    return {
      data: {
        type: 'ConsoleErrors',
        attributes: {
          error_details: errorPayload
        }  
      }
    };
  }

  formatError(error: unknown): string {
    let errorString: string = '';
    if (typeof error === "string") {
      errorString = error; // Direct string error
    } else if (error instanceof Error) {
      errorString =  error.message; // Error object
    } else if (typeof error === "object" && error !== null) {
      errorString =  JSON.stringify(error); // Object error
    }

    return errorString;
  }  
}

interface ConsoleErrorPayload {
  data: {
    type: string;
    attributes: {
      error_details: any;
    }
  }
}