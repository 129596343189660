<!-- Image -->
<div class="avatar avatar-sm card-avatar">
  <img src="{{integration.logoPath}}" class="avatar-img rounded" alt="..."
    (error)="imageServiceService.handleMissingImage($event)">
</div>
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader"> {{ integration | integrationFullname }} Datasets </h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline"> Organize and control access to your tables and views </p>
<!-- Divider -->
<hr class="mb-4">
<!-- Heading -->
<h4 class="mt-2 mb-2"> Select or Create {{ integration | integrationFullname }} Dataset </h4>
<ol>
  <li class="text-muted mb-2"> You need to create at least one dataset before we can load data into {{ integration |
    integrationFullname }} </li>
  <li class="text-muted mb-2"> If you already have one or more datasets, you can select one for us to store data into
  </li>
  <li class="text-muted mb-2"> You are not charged for creating, updating, or deleting a dataset </li>
  <li class="text-muted mb-2"> Dataset names must be unique for each project </li>
  <li class="text-muted mb-2"> Maker sure you data is set to never <code>expire</code>. If a dataset expires, you risk
    complete data loss </li>
</ol>