import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-csv-validation-schema-generation',
  templateUrl: './help-csv-validation-schema-generation.component.html',
})
export class HelpCsvValidationSchemaGenerationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
