  <div class="row">
    <div class="col-12">
      <!-- Card -->
      <div class="card">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-auto pe-3">
              <!-- Avatar -->
              <div class="avatar avatar-md">
                <span class="avatar-title rounded-circle text-wait" style="font-size: 1.4rem;">
                  <i class="fad fa-key-skeleton"></i></span>
              </div>
            </div>
            <div class="col ps-0">
              <!-- Heading -->
              <span class="h3 mb-0"> Create New {{ integration.name }} Authorization</span>
              <!-- Text -->
              <p class="card-text small text-muted mb-1">Grant data access permissions to your {{ integration.name }}
                account. Once authorized, the {{ integration.name }} identity will appear in the list below.</p>
            </div>
            <div class="col pt-3">
              <div class="mb-3">
              </div>
              <div class="mb-0">
                <!-- Card -->
                <div class="card">
                  <a [routerLink]="identityWizardLink" [queryParams]="queryParams" class="btn btn-ls btn-white" >Authorize</a>
                </div>
              </div>
            </div>
          </div>
          <!-- /.row -->
        </div>
      </div>
    </div>
  </div>
