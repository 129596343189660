<div class="modal-header">
  <h1 class="modal-title pull-left">
    View Job
  </h1>
  <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="table-responsive">
    <table class="table table-sm table-hover table-nowrap card-table">      
        <tbody class="list font-size-base">
          <tr>
            <td width='25%'>
              Job Id:
            </td>
            <td width='75%'>
              <span class="small text-muted" id="jobId"> 
                {{data.id | number:'6.0' | removeComma}} 
              </span>
            </td>
          </tr>
          <tr>
            <td width='25%'>
              Subscription Id:
            </td>
            <td width='75%'>
              <span class="small text-muted" id="subscriptionId" [tooltip]="data.subscription_id">
                {{data.attributes.subscription_id | number:'6.0' | removeComma}}
              </span>
            </td>
          </tr>
          
          <tr>
            <td width='25%'>
              Subscription Name:
            </td>
            <td width='75%'>
              <span class="small text-muted" id="subscriptionName" [tooltip]="pipeline.name">
                {{ pipeline.name | truncate:[35, '...'] }}
              </span>
            </td>
          </tr>
          <tr>
            <td width='25%'>
              Request Date:
            </td>
            <td width='75%'>
              <span class="small text-muted" id="createdAt">
                {{ data.attributes.created_at | customDate}}
              </span>
            </td>
          </tr>
          <tr>
            <td width='25%'>
              Report Date:
            </td>
            <td width='75%'>
              <span class="small text-muted" id="reportDate">
                {{ data.attributes.report_date | customDate}}
              </span>
            </td>
          </tr>
          <tr>
            <td width='25%'>
              Job Type:
            </td>
            <td width='75%'>
              <span class="small text-muted" id="jobType">
                {{data.attributes.is_primary ? 'Recurring' : 'History'}}
              </span>
            </td>
          </tr>
          <tr>
            <td width='25%'>
              Execution Schedule:
            </td>
            <td width='75%'>
              <span class="small text-muted" id="schedule">
                {{ data.attributes.schedule | 
                  convertCronExpression: {
                    startDate: data.attributes.valid_date_start,
                    reportDate: data.attributes.report_date,
                    status: data.attributes.status
                  } }}
              </span>
            </td>
          </tr>
          <tr>
            <td width='25%'>
              Status:
            </td>
            <td width='75%'>
              <span id="status" class="text-capitalize badge" [ngClass]="{
                'badge-green': (data.attributes.status === 'active'),
                'badge-blue': (data.attributes.status === 'paused' || data.attributes.status === 'STOPPED'),
                'badge-red': (data.attributes.status === 'invalid'),
                'badge-grey': (data.attributes.status === 'cancelled')
              }">
                {{ data.attributes.status }}
              </span>
            </td>
          </tr>
        </tbody>
    </table>
  </div>
</div>
<div class="modal-footer">
  <a href class="btn btn-sm btn-info mt-3" (click)="copyMessage(data);">Copy Details</a>
</div>