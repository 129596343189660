import { Injectable, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import {
  wizardIntegrationConfig, wizardIntegrationInit, wizardIntegrationPreviousStage,
  wizardIntegrationRequestCacheUpdate,
  wizardIntegrationSave, wizardIntegrationSpecificStage, wizardIntegrationStagelessConfig
} from '../../core/store/actions/wizard.actions';
import { AppState } from '../../core/store/reducers';
import { UntypedFormGroup } from '@angular/forms';
import { HttpClient, HttpErrorResponse, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  flashNotificationsFormValidationError,
  flashNotificationsFormValidationErrorWithDetails,
  flashNotificationsFormValidationSuccess
} from '../..//core/store/actions/flash-notifications.actions';
import { generateKeyFromObject } from '../../core/functions/cache';
import { wizardCurrentCacheSelector } from '../../core/store/selectors/wizard.selectors';
import { Integration } from '../../shared/models/integration.model';
import { observableToPromise } from '../../core/functions/utility';

@Injectable({
  providedIn: 'root'
})
export class BaseWizardService {

  wizardRequesetCacheState$: any;
  wizardRequestCache: object;
  integration: Integration;

  currentStageIndex: number;
  stageListState$: any = null;
  stageDataRequestState$ = new Subject<any>();
  stageData$ = new Subject<any>();
  stageProcessed = new Subject<any>();
  stages: string[];
  stageIndex = 0;

  constructor(
    protected httpClient: HttpClient,
    protected store$: Store<AppState>
  ) {
    this.stageListState$ = this.store$.select('wizard').subscribe(response => {
      if(response) {
        if (response.stages != null && response.stages.length > 0) {
          this.stages = response.stages;
          this.stageIndex = this.stages.findIndex((element) => element === response.stage);
        }
      }
    });

    this.wizardRequesetCacheState$ = this.store$.pipe(select(wizardCurrentCacheSelector));  

    this.wizardRequesetCacheState$.subscribe(response => {
      this.wizardRequestCache = response;
    });
  }

  initializeIntegration(integration: Integration): void {
    this.integration = integration;
  }

  initializeWizardState(routerLink: string, stage: string, stages: string[], config: any, productId?: number): void {
    this.store$.dispatch(wizardIntegrationInit({
      config: config,
      productId: (productId) ? productId : null,
      routerLink: routerLink,
      stage: stage,
      stages: stages
    }));
  }

  validateFormGroup(formGroup: UntypedFormGroup): void {
    const errors: string[] = [];
    const controls = Object.keys(formGroup.controls);

    controls.forEach((controlKey) => {
      const controlErrors: any[] = [];

      if (formGroup.controls[controlKey].status === 'INVALID') {
        const errorObj = formGroup.controls[controlKey].errors;
        const errorKeys = Object.keys(errorObj);
        errorKeys.forEach((errorKey) => {
          if (errorObj[errorKey] === true) {
            controlErrors.push(errorKey);
          }
        });

      }
    });
  }

  doProcessConfig(config: any, stagesOverride: string[] | null = null): void {

    if(stagesOverride !== null) {
      this.stages = stagesOverride;
    }

    if (this.stages[(this.stageIndex + 1)] === 'save') {
      // Dispatch
      this.store$.dispatch(wizardIntegrationStagelessConfig({
        config: config
      }));

      this.store$.dispatch(wizardIntegrationSave());
    } else {
      this.nextStageIndex();
      const nextStage = this.stages[this.stageIndex];

      // Dispatch save integration.
      this.store$.dispatch(wizardIntegrationConfig({
        config: config,
        stage: nextStage
      }));
    }
  }

  resetStageIndex(): void {
    this.stageIndex = 0;
  }

  doProcessError(error: string): void {
    this.store$.dispatch(flashNotificationsFormValidationError({ message: error }));
  }

  doProcessErrorWithDetails(error: string, details: string): void {
    this.store$.dispatch(flashNotificationsFormValidationErrorWithDetails({ message: error, details: details }));
  }

  doProcessSuccess(success: string): void {
    this.store$.dispatch(flashNotificationsFormValidationSuccess({ message: success }));
  }

  // Old functions below. we may keep some we may not keep others..
  nextStageIndex(): void {
    this.stageIndex = (this.stageIndex !== (this.stages.length - 1)) ? this.stageIndex + 1 : this.stageIndex;
  }

  previousStageIndex(): void {
    this.stageIndex = (this.stageIndex !== 0) ? this.stageIndex - 1 : this.stageIndex;
  }

  previousStage(): void {
    this.previousStageIndex();
    const previousStage = this.stages[this.stageIndex];
    // Dispatch
    this.store$.dispatch(wizardIntegrationPreviousStage({
      stage: previousStage,
    }));
  }

  nextStage(): void {
    if (this.currentStageIndex < this.stages.length) {
      this.currentStageIndex++;
    }
  }

  specficStage(stageIndex: number): void {
    this.stageIndex = stageIndex;
    const stage = this.stages[this.stageIndex];

    // Dispatch
    this.store$.dispatch(wizardIntegrationSpecificStage({
      stage: stage,
    }));
  }

  setServiceStages(stages: string[]): void {
    this.stages = stages;
  }

  generatePayload(stagePosition: number, totalStages: number, nextStage: string, formValue: any): any {
    return {
      stagePosition: stagePosition,
      totalStages: totalStages,
      nextStage: this.stages[this.currentStageIndex],
      formValue: formValue
    };
  }

  findRecordForSegment(segmentName: string, segmentValue: string, objectList: any[]): any {
    return objectList.find(x => x[segmentName] === segmentValue);
  }

  async getIdentityMetaForIdentityId(identityId: number, identityTypeMetaKeyId?: number): Promise<any> {

    const apiEndpoint = environment.openbridgeApiUris.identities + '/rim?remote_identity='
      + identityId + '&remoteidentitytypemetakey=' + identityTypeMetaKeyId;
    const response = await observableToPromise(this.httpClient.get(apiEndpoint));

    // There can be only 1, so return just the data we care about.
    if (response['data'].length > 0) {
      return response['data'][0];
    }

    return false;
  }

  async getDataFromProvider(provider: string, method: string, pathParams: number | any[], params?: object): Promise<object> {
    let paramString: string | null = null;
    let pathString: string | null = null;

    if (params) {
      paramString = this.simpleSerialize(params);
    }

    const cacheParams = {
      provider: provider,
      method: method,
      pathParams: pathParams,
      ...params
    };

    const cacheKey = this.getCacheKey(cacheParams);
    const cacheObjectKeys = Object.keys(this.wizardRequestCache);

    if (cacheObjectKeys.includes(cacheKey)) {
      return JSON.parse(this.wizardRequestCache[cacheKey]);
    }

    if (typeof pathParams === 'number') {
      pathString = pathParams.toString();
    } else if (typeof pathParams === 'string') {
      pathString = pathParams;
    } else {
      pathString = pathParams.join('/');
    }

    let apiEndpoint = environment.openbridgeApiUris.service + '/service/'
      + provider + '/' + method + '/' + pathString;

    if (paramString) {
      apiEndpoint += ('?' + paramString);
    }

    const maxRetries = 5;
    let retryCount = 0;
    const baseDelay = 5000; // 5 seconds

    while (retryCount <= maxRetries) {
      try {
        const response = await observableToPromise(this.httpClient.get(apiEndpoint));

        const cacheData = { key: cacheKey, cache: JSON.stringify(response) };
        this.store$.dispatch(wizardIntegrationRequestCacheUpdate(cacheData));
        return response;
      } catch (error: any) {
        if (error.status === 429 && retryCount < maxRetries) {
          retryCount++;
          const delay = baseDelay * retryCount + Math.random() * 1000; // Add some jitter
          console.warn(`429 Too Many Requests. Retrying after ${delay}ms (Attempt ${retryCount}/${maxRetries})`);
          await new Promise(resolve => setTimeout(resolve, delay));
        } else {
          throw error; // Re-throw other errors or if max retries reached
        }
      }
    }

    throw new Error('Failed to fetch data after multiple retries.');
  }


  async getDataForService(urlParams: any[], params?: object): Promise<object> {

    let paramString: string = null;
    const urlPath = urlParams.join('/');

    if (params) {
      paramString = this.simpleSerialize(params);
    }

    const cacheKey = this.getCacheKey(params);
    const cacheObjectKeys = Object.keys(this.wizardRequestCache);

    let apiEndpoint = environment.openbridgeApiUris.service + '/service/'
      + urlPath;

    if (paramString) {
      apiEndpoint += ('?' + paramString);
    }

    const response = await observableToPromise(this.httpClient.get(apiEndpoint));

    const cacheData = { key: cacheKey, cache: JSON.stringify(response) };
    this.store$.dispatch(wizardIntegrationRequestCacheUpdate(cacheData));

    return response;
  }

  protected emitStageDataObject(stageData: any): void {
    this.stageData$.next(stageData);
  }

  protected simpleSerialize(params: object): string {
    const keys = Object.keys(params);
    let serializedString = '';

    keys.forEach((value, idx) => {
      const paramString = value + '=' + encodeURI(params[value]);

      if (serializedString !== '') {
        serializedString += '&';
      }
      serializedString += paramString;
    });

    return serializedString;
  }

  initializeConfigState(wizardMode: string, subscription: any, spm: any): any {
    // Initialize the stage index.
    this.stageIndex = 0;
  }

  async getSubscriptionProductMeta(subscriptionId: number): Promise<any> {
    return await observableToPromise(this.httpClient.get(`${environment.openbridgeApiUris.subscription}/spm?subscription=${subscriptionId}`));
  }

  buildObjectFromSpmDataArray(spmDataArray: any[]): any {
    const dataObject = {};
    spmDataArray.forEach((val) => {
      dataObject[val.attributes.data_key] = val.attributes.data_value;
    });

    return dataObject;
  }

  // to find wizard current stage
  findCurrentStageInWizard(totalStages, currentStage): number {
    const index = totalStages.indexOf(currentStage);
    return index + 1;
  }

  getCacheKey(params: object): string {
    return generateKeyFromObject(params);
  }

  async manageAsyncPolling(asyncResponse: HttpResponseBase, pollLength: number = 180): Promise<any> {
    let asyncPoll: boolean = true;
    const location = asyncResponse.headers.get('location');

    if(!location) {
      throw Error('Location header not present in response');
    }

    const asyncStartEpoch = Math.floor((Date.now()/1000));
    const asyncForcePollEndEpoch = asyncStartEpoch + pollLength;

    let finalResponse: HttpResponse<any> | HttpErrorResponse | PollingErrorResponse | null = null;

    while(asyncPoll) {
      let asyncResponse = await observableToPromise(this.httpClient.get(location, {observe: 'response'}));

      const nowEpoch = Math.floor((Date.now()/1000));

      if(asyncResponse.status === 200) {
        finalResponse = asyncResponse;
        asyncPoll = false;
      }
      else if(nowEpoch > asyncForcePollEndEpoch) {
        finalResponse = new PollingErrorResponse();
        finalResponse['error'] = {
            errors: 'Exceeding polling limit'
        };
        asyncPoll = false;
      }
      else {
        const delay = ms => new Promise(res => setTimeout(res, ms));
        await delay(2000);
      }
    }

    return finalResponse;
  }

  async getSubscriptionSpmValueByProductIdByKeyValue(productId: number, key: string, value: string): Promise<any> {
    return await observableToPromise(this.httpClient.get(`${environment.openbridgeApiUris.subscription}/spm?product=${productId}&data_key=${key}&data_value=${value}`));
  } 
}

export class PollingErrorResponse {
  error: any;
  name: string = 'PollingErrorResponse';
  ok: boolean = false;
}