import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modal-refresh-token-revoke',
  templateUrl: './modal-refresh-token-revoke.component.html'
})
export class ModalRefreshTokenRevokeComponent implements OnInit {

  modalState: any;
  formGroup: UntypedFormGroup = new UntypedFormGroup({
    refreshTokenRevokeConfirm: new UntypedFormControl(null, [Validators.required, Validators.pattern(/^revoke$/)]),
  });
  onClose$: Subject<any> = new Subject<any>();

  constructor(
    private bsModalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
  }

  onSubmit(): void {
    if (this.formGroup.valid) {
      this.onClose$.next({
        revokeToken: true
      });
      this.closeModalWithComponent();
    }
    else {
      this.formGroup.markAsTouched();
    }
  }

  cancel(): void {
    this.onClose$.next({
      revokeToken: false
    });
    this.closeModalWithComponent();
  }

  private closeModalWithComponent(): void {
    if (this.bsModalRef !== null) {
       this.bsModalRef.hide();
    }
    this.bsModalRef = null;
  }

}
