import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { IntegrationService } from 'src/app/shared/services/integration.service';
import { PaginationService } from 'src/app/shared/services/pagination.service';

@Component({
  selector: 'app-filter-pipelines',
  templateUrl: './filter-pipelines.component.html',
  styleUrls: ['./filter-pipelines.component.scss']
})
export class FilterPipelinesComponent implements OnInit {

  @Input() filterableObjects: any[] = [];
  @Input() productList: any[] = [];
  @Output() filteredObjects = new EventEmitter<any[]>();
  @Output() updatedPageSize = new EventEmitter<number>();
  // show error alert if there is no subscription found when query parameter subscriptionId present
  @Output() showError = new EventEmitter<boolean>();

  pageSize = 10;
  urlParams: any;
  filterSearch = new UntypedFormControl('');
  activeStatusFilter: 'all' | 'active' | 'inactive' = 'all';
  activeProductFilter: 'all' | any = 'all';

  constructor(
    private integrationService: IntegrationService,
    private paginationService: PaginationService,
    protected activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.urlParams = this.activatedRoute.snapshot.queryParams || null;
    this.pageSize = this.paginationService.getPageSize();

    if(this.urlParams.subscriptionId) {
      this.processFilteredTextForSubscriptionId(this.urlParams.subscriptionId);
    }
    else {
      this.processFilteredText(this.filterSearch.value);
      // this.filteredObjects.emit([...this.filterableObjects]);
    }

  }

  ngOnChanges(): void {

    this.urlParams = this.activatedRoute.snapshot.queryParams || null;

    if(this.urlParams.subscriptionId) {
      this.processFilteredTextForSubscriptionId(this.urlParams.subscriptionId);
    }
    else {
      this.processFilteredText(this.filterSearch.value);
      // this.filteredObjects.emit([...this.filterableObjects]);
    }

  }

  processFilteredText(filterText: string): void {
    if (filterText === '') {

      if(this.urlParams.subscriptionId) {
        this.processFilteredTextForSubscriptionId(this.urlParams.subscriptionId);
      }
      else {
        this.setFilter(this.activeStatusFilter, this.activeProductFilter);
        // this.filteredObjects.emit([...this.filterableObjects]);
      }
      
    }
    else {
      const filteredObjects: any[] = [];

      this.filterableObjects.forEach((val, idx) => {

        const productFullName = this.productFullNameFromProductId(val.productId);

        if (val.name.toLowerCase().includes(filterText.toLowerCase())) {
          filteredObjects.push(val);
        }
        else if (val.id === +filterText) {
          filteredObjects.push(val);
        }
        else if (productFullName.toLowerCase().includes(filterText.toLowerCase())) {
          filteredObjects.push(val);
        }
        else if (val.remoteIdentityName.toLowerCase().includes(filterText.toLowerCase())) {
          filteredObjects.push(val);
        }
        else if (val.storageGroupName && val.storageGroupName.toLowerCase().includes(filterText.toLowerCase())) {
          filteredObjects.push(val);
        }

        // if(val.keywords && val?.keywords?.includes(filterText.toLowerCase())) {
        //   filteredObjects.push(val);
        // }
      });

      this.filteredObjects.emit([...filteredObjects]);
    }
  }

  processFilteredTextForSubscriptionId(filterText: string): void {
    if (filterText === '') {
      this.filteredObjects.emit([...this.filterableObjects]);
    }
    else {
      const filteredObjects: any[] = [];

      this.filterableObjects.forEach((val, idx) => {
        if (val.id && val.id === +filterText) {
          filteredObjects.push(val);
        }
      });

      // show error alert if there is no subscription found when query parameter subscriptionId present
      const state: boolean = filteredObjects.length === 0 ? true : false;
      this.showError.emit(state);

      this.filteredObjects.emit([...filteredObjects]);
    }
  }

  updatePageEntries(pageSize): void {
    this.updatedPageSize.emit(pageSize);
  }

  applyFilter(event: Event, dropdown: BsDropdownDirective): void {
    event.stopPropagation();
    this.filterSearch.setValue('');
    this.setFilter(this.activeStatusFilter, this.activeProductFilter);
    dropdown.hide();
  }


  setFilter(status: 'all' | 'active' | 'inactive', product: 'all' | any): boolean {
    let filteredObjects: any[] = [];
    
    if (status === 'all' && product === 'all') {
      filteredObjects = [...this.filterableObjects];
    }
    else {      
      this.filterableObjects.forEach((val, idx) => {

        if (product === 'all') {
          if (status === 'active' && val.status === 'active') {
            filteredObjects.push(val);
          }
          if (status === 'inactive' && val.status === 'cancelled') {
            filteredObjects.push(val);
          }
        }
        if (product === val.productId) {
          if (status === 'all') {
            filteredObjects.push(val);
          }
          if (status === 'active' && val.status === 'active') {
            filteredObjects.push(val);
          }
          if (status === 'inactive' && val.status === 'cancelled') {
            filteredObjects.push(val);
          }
        }
      });
    }    
    this.filteredObjects.emit(filteredObjects);
    // Return false to prevent default.
    return false;
  }

  productFullNameFromProductId(productId: number): string {
    const integration = this.integrationService.findIntegrationFromId(productId);
    if (integration) {
      return integration.brand + ' ' + integration.name;
    }
    return '';
  }
}
