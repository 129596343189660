<div class="modal-header">
  <h1 class="modal-title pull-left">Before you deactivate... </h1>
  <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="mb-1">
    <p>You need to deactivate all attached active source pipelines.</p>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" routerLink="/pipelines" (click)="bsModalRef.hide()">
    Review Active Pipelines <i class="fe fe-arrow-right-circle ms-2"></i>
  </button>
</div>