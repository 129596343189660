import { NgClass } from '@angular/common';
import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { S3Service } from 'src/app/shared/services/s3.service';
import { observableToPromise } from '../../../../core/functions/utility';

@Component({
  selector: 'app-s3-signed-uploader',
  templateUrl: './s3-signed-uploader.component.html',
  styleUrls: ['./s3-signed-uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => S3SignedUploaderComponent),
      multi: true
    }
  ]
})
export class S3SignedUploaderComponent implements OnInit, ControlValueAccessor {

  @Input() mode: string;
  @Input() innerId: string;
  @Input('ngClass') ngClass: NgClass["ngClass"];

  disabled: boolean = false;
  value: string = '';
  httpTransactionState: string | null = null;

  onChange: any = () => { };
  onTouched: any = () => { };
  containerMessage: string;

  constructor(
    private s3Service: S3Service
  ) { }

  ngOnInit(): void {
    this.containerMessage = (this.mode !== 'edit') ? 'No file chosen' : 'Use Existing configuration';
  }


  async fileToUploadChange($event): Promise<void> {
    try {
      const file = $event.target.files[0];
      this.containerMessage = file.name;
      this.httpTransactionState = 'processing';
      const s3Url = await this.s3Service.presignedS3UploadUrl('airbyte_config');
      const postRequest = await observableToPromise(this.s3Service.uploadPresignedS3File(s3Url.data.url, s3Url.data.fields, file, false));
      const putUrl = s3Url.data.url + s3Url.data.fields['key'];
      const getUrl = await this.s3Service.presignedS3GetUrl(putUrl);
      this.value = getUrl;
      this.httpTransactionState = 'complete-success';
      this.onChange(this.value);
    }
    catch(error) {
      this.httpTransactionState = 'complete-error';
    }
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
