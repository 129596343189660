<div class="help-drawer-overlay" [@helpDrawerOverlay]="helpDrawerOverlay" (click)="toggleModal()"></div>
<div>
  <div [@helpDrawer]="helpDrawer" class="help-drawer">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="btn-close pull-right" id="helpDrawerCloseBtn" aria-label="Close" (click)="toggleModal()">
          <span aria-hidden="true">&times;</span>
        </button>
        <div [ngSwitch]="helpComponentIdentifier">
          <div *ngSwitchCase="'common-checklist'">
            <app-help-common-checklist [integration]="integration"></app-help-common-checklist>
          </div>
          <div *ngSwitchCase="'common-identity'">
            <app-help-common-identity [integration]="integration"></app-help-common-identity>
          </div>
          <div *ngSwitchCase="'common-details'">
            <app-help-common-details [integration]="integration"></app-help-common-details>
          </div>
          <div *ngSwitchCase="'sources'">
            <app-help-sources></app-help-sources>
          </div>
          <div *ngSwitchCase="'destinations'">
            <app-help-destinations></app-help-destinations>
          </div>
          <div *ngSwitchCase="'identities'">
            <app-help-identities></app-help-identities>
          </div>
          <div *ngSwitchCase="'pipelines'">
            <app-help-pipelines></app-help-pipelines>
          </div>
          <div *ngSwitchCase="'healthchecks'">
            <app-help-healthchecks></app-help-healthchecks>
          </div>
          <div *ngSwitchCase="'jobs'">
            <app-help-jobs></app-help-jobs>
          </div>
          <div *ngSwitchCase="'pricing'">
            <app-help-pricing></app-help-pricing>
          </div>
          <div *ngSwitchCase="'general'">
            <app-help-general></app-help-general>
          </div>
          <div *ngSwitchCase="'billing'">
            <app-help-billing></app-help-billing>
          </div>
          <div *ngSwitchCase="'security'">
            <app-help-security></app-help-security>
          </div>
          <div *ngSwitchCase="'destinations-select'">
            <app-help-destinations-select></app-help-destinations-select>
          </div>
          <div *ngSwitchCase="'amazon-advertising-checklist'">
            <app-help-amazon-advertising-checklist [integration]="integration"></app-help-amazon-advertising-checklist>
          </div>
          <div *ngSwitchCase="'amazon-advertising-identity'">
            <app-help-amazon-advertising-identity [integration]="integration"></app-help-amazon-advertising-identity>
          </div>
          <div *ngSwitchCase="'amazon-advertising-details'">
            <app-help-amazon-advertising-details [integration]="integration"></app-help-amazon-advertising-details>
          </div>
          <div *ngSwitchCase="'amazon-attribution-checklist'">
            <app-help-amazon-attribution-checklist [integration]="integration"></app-help-amazon-attribution-checklist>
          </div>
          <div *ngSwitchCase="'amazon-attribution-identity'">
            <app-help-amazon-attribution-identity [integration]="integration"></app-help-amazon-attribution-identity>
          </div>
          <div *ngSwitchCase="'amazon-attribution-details'">
            <app-help-amazon-attribution-details [integration]="integration"></app-help-amazon-attribution-details>
          </div>
          <div *ngSwitchCase="'amazon-dsp-checklist'">
            <app-help-amazon-dsp-checklist [integration]="integration"></app-help-amazon-dsp-checklist>
          </div>
          <div *ngSwitchCase="'amazon-dsp-identity'">
            <app-help-amazon-dsp-identity [integration]="integration"></app-help-amazon-dsp-identity>
          </div>
          <div *ngSwitchCase="'amazon-dsp-details'">
            <app-help-amazon-dsp-details [integration]="integration"></app-help-amazon-dsp-details>
          </div>

          <div *ngSwitchCase="'amazon-spapi-checklist'">
            <app-help-amazon-spapi-checklist [integration]="integration"></app-help-amazon-spapi-checklist>
          </div>
          <div *ngSwitchCase="'amazon-spapi-identity'">
            <app-help-amazon-spapi-identity [integration]="integration"></app-help-amazon-spapi-identity>
          </div>
          <div *ngSwitchCase="'amazon-spapi-details'">
            <app-help-amazon-spapi-details [integration]="integration"></app-help-amazon-spapi-details>
          </div>

          <div *ngSwitchCase="'amzn-athena-checklist'">
            <app-help-amzn-athena-checklist [integration]="integration"></app-help-amzn-athena-checklist>
          </div>
          <div *ngSwitchCase="'amzn-athena-details'">
            <app-help-amzn-athena-details [integration]="integration"></app-help-amzn-athena-details>
          </div>
          <div *ngSwitchCase="'amzn-redshift-checklist'">
            <app-help-amzn-redshift-checklist [integration]="integration"></app-help-amzn-redshift-checklist>
          </div>
          <div *ngSwitchCase="'amzn-redshift-details'">
            <app-help-amzn-redshift-details [integration]="integration"></app-help-amzn-redshift-details>
          </div>
          <div *ngSwitchCase="'amzn-spectrum-checklist'">
            <app-help-amzn-spectrum-checklist [integration]="integration"></app-help-amzn-spectrum-checklist>
          </div>
          <div *ngSwitchCase="'amzn-spectrum-details'">
            <app-help-amzn-spectrum-details [integration]="integration"></app-help-amzn-spectrum-details>
          </div>
          <div *ngSwitchCase="'azure-blob-storage-checklist'">
            <app-help-azure-blob-storage-checklist [integration]="integration"></app-help-azure-blob-storage-checklist>
          </div>
          <div *ngSwitchCase="'azure-blob-storage-details'">
            <app-help-azure-blob-storage-details [integration]="integration"></app-help-azure-blob-storage-details>
          </div>
          <div *ngSwitchCase="'azure-data-lake-checklist'">
            <app-help-azure-data-lake-checklist [integration]="integration"></app-help-azure-data-lake-checklist>
          </div>
          <div *ngSwitchCase="'azure-data-lake-details'">
            <app-help-azure-data-lake-details [integration]="integration"></app-help-azure-data-lake-details>
          </div>
          <div *ngSwitchCase="'bigquery-checklist'">
            <app-help-bigquery-checklist [integration]="integration"></app-help-bigquery-checklist>
          </div>
          <div *ngSwitchCase="'bigquery-identity'">
            <app-help-bigquery-identity [integration]="integration"></app-help-bigquery-identity>
          </div>
          <div *ngSwitchCase="'bigquery-details'">
            <app-help-bigquery-details [integration]="integration"></app-help-bigquery-details>
          </div>
          <div *ngSwitchCase="'csv-batch-loader-checklist'">
            <app-help-csv-batch-loader-checklist [integration]="integration"></app-help-csv-batch-loader-checklist>
          </div>
          <div *ngSwitchCase="'csv-batch-loader-details'">
            <app-help-csv-batch-loader-details [integration]="integration"></app-help-csv-batch-loader-details>
          </div>
          <div *ngSwitchCase="'csv-batch-loader-tester'">
            <app-help-csv-batch-loader-tester [integration]="integration"></app-help-csv-batch-loader-tester>
          </div>
          <div *ngSwitchCase="'csv-batch-loader-authorization'">
            <app-help-csv-batch-loader-authorization [integration]="integration"></app-help-csv-batch-loader-authorization>
          </div>
          <div *ngSwitchCase="'pipeline-name'">
            <app-help-common-pipeline-name></app-help-common-pipeline-name>
          </div>
          <div *ngSwitchCase="'destination-name'">
            <app-help-common-destination-name></app-help-common-destination-name>
          </div>
          <div *ngSwitchCase="'destination-select'">
            <app-help-common-destination-select></app-help-common-destination-select>
          </div>
          <div *ngSwitchCase="'destination-complete'">
            <app-help-common-complete></app-help-common-complete>
          </div>
          <div *ngSwitchCase="'databricks-checklist'">
            <app-help-databricks-checklist [integration]="integration"></app-help-databricks-checklist>
          </div>
          <div *ngSwitchCase="'databricks-details'">
            <app-help-databricks-details [integration]="integration"></app-help-databricks-details>
          </div>
          <div *ngSwitchCase="'facebook-marketing-checklist'">
            <app-help-facebook-marketing-checklist [integration]="integration"></app-help-facebook-marketing-checklist>
          </div>
          <div *ngSwitchCase="'facebook-marketing-identity'">
            <app-help-facebook-marketing-identity [integration]="integration"></app-help-facebook-marketing-identity>
          </div>
          <div *ngSwitchCase="'facebook-marketing-details'">
            <app-help-facebook-marketing-details [integration]="integration"></app-help-facebook-marketing-details>
          </div>
          <div *ngSwitchCase="'facebook-common-checklist'">
            <app-help-facebook-common-checklist [integration]="integration"></app-help-facebook-common-checklist>
          </div>
          <div *ngSwitchCase="'facebook-common-identity'">
            <app-help-facebook-common-identity [integration]="integration"></app-help-facebook-common-identity>
          </div>
          <div *ngSwitchCase="'facebook-common-details'">
            <app-help-facebook-common-details [integration]="integration"></app-help-facebook-common-details>
          </div>
          <div *ngSwitchCase="'google-ads-checklist'">
            <app-help-google-ads-checklist [integration]="integration"></app-help-google-ads-checklist>
          </div>
          <div *ngSwitchCase="'google-ads-identity'">
            <app-help-google-ads-identity [integration]="integration"></app-help-google-ads-identity>
          </div>
          <div *ngSwitchCase="'google-ads-customer'">
            <app-help-google-ads-customer [integration]="integration"></app-help-google-ads-customer>
          </div>
          <div *ngSwitchCase="'google-ads-managed-customer'">
            <app-help-google-ads-managed-customer [integration]="integration"></app-help-google-ads-managed-customer>
          </div>
          <div *ngSwitchCase="'google-analytics-360-checklist'">
            <app-help-google-analytics-360-checklist [integration]="integration">
            </app-help-google-analytics-360-checklist>
          </div>
          <div *ngSwitchCase="'google-analytics-360-identity'">
            <app-help-google-analytics-360-identity [integration]="integration">
            </app-help-google-analytics-360-identity>
          </div>
          <div *ngSwitchCase="'google-analytics-360-project-id'">
            <app-help-google-analytics-360-project-id [integration]="integration">
            </app-help-google-analytics-360-project-id>
          </div>
          <div *ngSwitchCase="'google-analytics-360-dataset-id'">
            <app-help-google-analytics-360-dataset-id [integration]="integration">
            </app-help-google-analytics-360-dataset-id>
          </div>
          <div *ngSwitchCase="'google-campaign-manager-checklist'">
            <app-help-google-campaign-manager-checklist [integration]="integration">
            </app-help-google-campaign-manager-checklist>
          </div>
          <div *ngSwitchCase="'google-campaign-manager-identity'">
            <app-help-google-campaign-manager-identity [integration]="integration">
            </app-help-google-campaign-manager-identity>
          </div>
          <div *ngSwitchCase="'google-campaign-manager-profile'">
            <app-help-google-campaign-manager-profile [integration]="integration">
            </app-help-google-campaign-manager-profile>
          </div>
          <div *ngSwitchCase="'google-campaign-manager-report'">
            <app-help-google-campaign-manager-report [integration]="integration">
            </app-help-google-campaign-manager-report>
          </div>
          <div *ngSwitchCase="'google-search-ads-360-checklist'">
            <app-help-google-search-ads-360-checklist [integration]="integration">
            </app-help-google-search-ads-360-checklist>
          </div>
          <div *ngSwitchCase="'google-search-ads-360-identity'">
            <app-help-google-search-ads-360-identity [integration]="integration">
            </app-help-google-search-ads-360-identity>
          </div>
          <div *ngSwitchCase="'google-search-ads-360-details'">
            <app-help-google-search-ads-360-details [integration]="integration">
            </app-help-google-search-ads-360-details>
          </div>
          <div *ngSwitchCase="'instagram-business-iq-checklist'">
            <app-help-instagram-business-iq-checklist></app-help-instagram-business-iq-checklist>
          </div>
          <div *ngSwitchCase="'instagram-business-iq-identity'">
            <app-help-instagram-business-iq-identity></app-help-instagram-business-iq-identity>
          </div>
          <div *ngSwitchCase="'instagram-business-iq-details'">
            <app-help-instagram-business-iq-details></app-help-instagram-business-iq-details>
          </div>
          <div *ngSwitchCase="'instagram-stories-checklist'">
            <app-help-instagram-stories-checklist></app-help-instagram-stories-checklist>
          </div>
          <div *ngSwitchCase="'instagram-stories-identity'">
            <app-help-instagram-stories-identity></app-help-instagram-stories-identity>
          </div>
          <div *ngSwitchCase="'instagram-stories-details'">
            <app-help-instagram-stories-details></app-help-instagram-stories-details>
          </div>
          <div *ngSwitchCase="'mws-finances-checklist'">
            <app-help-mws-finances-checklist></app-help-mws-finances-checklist>
          </div>
          <div *ngSwitchCase="'mws-finances-identity'">
            <app-help-mws-finances-identity></app-help-mws-finances-identity>
          </div>
          <div *ngSwitchCase="'mws-realtime-orders-checklist'">
            <app-help-mws-realtime-orders-checklist></app-help-mws-realtime-orders-checklist>
          </div>
          <div *ngSwitchCase="'mws-realtime-orders-identity'">
            <app-help-mws-realtime-orders-identity></app-help-mws-realtime-orders-identity>
          </div>
          <div *ngSwitchCase="'mws-reporting-checklist'">
            <app-help-mws-reporting-checklist></app-help-mws-reporting-checklist>
          </div>
          <div *ngSwitchCase="'mws-reporting-identity'">
            <app-help-mws-reporting-identity></app-help-mws-reporting-identity>
          </div>
          <div *ngSwitchCase="'mws-settlement-reports-checklist'">
            <app-help-mws-settlement-reports-checklist></app-help-mws-settlement-reports-checklist>
          </div>
          <div *ngSwitchCase="'mws-settlement-reports-identity'">
            <app-help-mws-settlement-reports-identity></app-help-mws-settlement-reports-identity>
          </div>
          <div *ngSwitchCase="'postgresql-checklist'">
            <app-help-postgresql-checklist></app-help-postgresql-checklist>
          </div>
          <div *ngSwitchCase="'postgresql-details'">
            <app-help-postgresql-details></app-help-postgresql-details>
          </div>
          <div *ngSwitchCase="'snowflake-checklist'">
            <app-help-snowflake-checklist [integration]="integration"></app-help-snowflake-checklist>
          </div>
          <div *ngSwitchCase="'snowflake-details'">
            <app-help-snowflake-details [integration]="integration"></app-help-snowflake-details>
          </div>
          <div *ngSwitchCase="'youtube-single-channel-common-checklist'">
            <app-help-youtube-single-channel-common-checklist [integration]="integration">
            </app-help-youtube-single-channel-common-checklist>
          </div>
          <div *ngSwitchCase="'youtube-single-channel-common-identity'">
            <app-help-youtube-single-channel-common-identity [integration]="integration">
            </app-help-youtube-single-channel-common-identity>
          </div>
          <div *ngSwitchCase="'youtube-single-channel-common-details'">
            <app-help-youtube-single-channel-common-details [integration]="integration">
            </app-help-youtube-single-channel-common-details>
          </div>
          <div *ngSwitchCase="'youtube-multi-channel-common-checklist'">
            <app-help-youtube-multi-channel-common-checklist [integration]="integration">
            </app-help-youtube-multi-channel-common-checklist>
          </div>
          <div *ngSwitchCase="'youtube-multi-channel-common-identity'">
            <app-help-youtube-multi-channel-common-identity [integration]="integration">
            </app-help-youtube-multi-channel-common-identity>
          </div>
          <div *ngSwitchCase="'youtube-multi-channel-common-details'">
            <app-help-youtube-multi-channel-common-details [integration]="integration">
            </app-help-youtube-multi-channel-common-details>
          </div>
          <div *ngSwitchCase="'youtube-channel-insights-checklist'">
            <app-help-youtube-channel-insights-checklist></app-help-youtube-channel-insights-checklist>
          </div>
          <div *ngSwitchCase="'youtube-channel-insights-identity'">
            <app-help-youtube-channel-insights-identity></app-help-youtube-channel-insights-identity>
          </div>
          <div *ngSwitchCase="'youtube-channel-insights-details'">
            <app-help-youtube-channel-insights-details></app-help-youtube-channel-insights-details>
          </div>
          <div *ngSwitchCase="'youtube-competitor-checklist'">
            <app-help-youtube-competitor-checklist></app-help-youtube-competitor-checklist>
          </div>
          <div *ngSwitchCase="'youtube-competitor-identity'">
            <app-help-youtube-competitor-identity></app-help-youtube-competitor-identity>
          </div>
          <div *ngSwitchCase="'youtube-competitor-details'">
            <app-help-youtube-competitor-details></app-help-youtube-competitor-details>
          </div>
          <div *ngSwitchCase="'youtube-video-insights-checklist'">
            <app-help-youtube-video-insights-checklist></app-help-youtube-video-insights-checklist>
          </div>
          <div *ngSwitchCase="'youtube-video-insights-identity'">
            <app-help-youtube-video-insights-identity></app-help-youtube-video-insights-identity>
          </div>
          <div *ngSwitchCase="'youtube-video-insights-details'">
            <app-help-youtube-video-insights-details></app-help-youtube-video-insights-details>
          </div>
          <div *ngSwitchCase="'youtube-video-iq-checklist'">
            <app-help-youtube-video-iq-checklist></app-help-youtube-video-iq-checklist>
          </div>
          <div *ngSwitchCase="'youtube-video-iq-identity'">
            <app-help-youtube-video-iq-identity></app-help-youtube-video-iq-identity>
          </div>
          <div *ngSwitchCase="'youtube-video-iq-details'">
            <app-help-youtube-video-iq-details></app-help-youtube-video-iq-details>
          </div>
          <div *ngSwitchCase="'help-csv-validation-schema-generation'">
            <app-help-csv-validation-schema-generation></app-help-csv-validation-schema-generation>
          </div>
          <div *ngSwitchCase="'help-drawer-default'">
            <app-help-drawer-default></app-help-drawer-default>
          </div>
          <div *ngSwitchDefault>
            This is the default text in case someone doesn't pass the helper
            type.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>