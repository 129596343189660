import { Component, Input, OnInit } from '@angular/core';
import { Integration } from 'src/app/shared/models/integration.model';
import { ImageServiceService } from 'src/app/shared/services/image-service.service';

@Component({
  selector: 'app-help-google-ads-managed-customer',
  templateUrl: './help-google-ads-managed-customer.component.html',
})
export class HelpGoogleAdsManagedCustomerComponent implements OnInit {
  @Input() integration: Integration;

  constructor(
    public imageServiceService: ImageServiceService
  ) { }

  ngOnInit(): void {
  }

}
