import { Component, Input, OnInit } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { DateTimeService, DATE_FORMAT_MMM_DD, DATE_FORMAT_YYYY_MM_DD } from 'src/app/core/services/date-time.service';
import { colors } from '../chart-options';


@Component({
  selector: 'app-chart-bar',
  templateUrl: './chart-bar.component.html'
})
export class ChartBarComponent implements OnInit {
  // Data Related
  @Input() chartDataSets: any[];
  @Input() chartLabels: any[];
  @Input() chartTickLimit: number;
  @Input() chartId: string;

  // Style related.
  @Input() chartClass: string;
  @Input() chartHeight: string;
  @Input() chartWidth: string;

  chartType: ChartType = 'bar';
  chartOptions: ChartOptions;
  chartDataLengthError: boolean;

  constructor(
    private dateTimeService: DateTimeService
  ) { }

  ngOnInit(): void {
    const dateTimeService = this.dateTimeService;

    this.chartDataSets.forEach(dataSet => {
      this.chartDataLengthError = dataSet.data.length > this.chartLabels.length ? true : false;
    });

    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          text: 'Count of data pipelines, by day, for the prior 30 days',
          position: 'bottom',
          padding: 20
        }
      },
      scales: {
        x: {
          border: {
            display: false
          },
          grid: {
            display: false,
            drawTicks: false,
            drawOnChartArea: false,
            color: 'rgba(0, 0, 0, 0)',
          },
          ticks: {
            callback: function (value, index, ticks) {

              const currentValue = this.getLabelForValue(index)
              const xAxisDateLabel = dateTimeService.format(currentValue, DATE_FORMAT_MMM_DD, DATE_FORMAT_YYYY_MM_DD);
              return xAxisDateLabel;
            },
            color: colors.gray[700],
            // property for limiting the labels
            maxTicksLimit: this.chartTickLimit

            // custom function for limiting the labels
            // callback: function (value, index) {
            // const date = this.dateTimeService.format(value, DATE_FORMAT_MMM_DD, DATE_FORMAT_YYYY_MM_DD);
            //   return index % 5 === 0 ? date : null;
            // },
          }
        },
        y: {
          border: {
            display: false,
            dash: [2],
            dashOffset: 2
          },
          grid: {
            color: colors.gray[900],
            drawTicks: false,
          },
          min: 1,
          offset: true,
          ticks: {
            stepSize: 1,
            color: colors.gray[700],
            callback: function (value, index, ticks) {
              // const currentValue = this.getLabelForValue(index)
              return value;
            }
          },
        },
      },
    }
  }
}
