<!-- Header -->
<div class="row justify-content-center">
  <div class="col-12 col-md-10 col-lg-8 col-xl-6 text-center">

    <!-- Pretitle -->
    <app-step-counter [stagePosition]="stagePosition" [totalStages]="totalStages"></app-step-counter>

    <!-- Image -->
    <div class="avatar avatar-xl card-avatar">
      <img [src]="logoPath" class="avatar-img rounded bg-white" style="padding: 6px;" [alt]="logoText" [title]="logoText"
        (error)="imageServiceService.handleMissingImage($event)">
    </div>
    <ng-container *ngIf="badge !== 'none'">
      <h2>
        <span class="badge mt-n1 text-capitalize"
        [ngClass]="{ 'bg-secondary-soft': (badge === 'legacy'), 'bg-success-soft': (badge === 'new') }">{{ badge }}</span>
      </h2>
    </ng-container>

    <!-- Title -->
    <h1 class="mb-2" id="headline">
      {{ headline }}
    </h1>

    <!-- Subtitle -->
    <p class="mb-6 text-muted" id="tagline">
      {{ tagline }}
    </p>

  </div>
</div> <!-- / .row -->