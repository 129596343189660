import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { observableToPromise } from '../../core/functions/utility';

@Injectable({
  providedIn: 'root'
})
export class HealthchecksService {
  transactionsSubscription$: Subject<any> = new Subject<any>();
  healthCheckRequestState$: Subject<{ state: string, payload: any }> = new Subject<{ state: string, payload: any }>();

  constructor(
    private http: HttpClient
  ) { }

  async getHealthChecks(
    accountId: number,
    subscriptionId: number,
    page: number,
    pageSize: number,
    sender: string,
    startDate: string,
    endDate: string,
    status: string
  ): Promise<void> {

    try {
      this.healthCheckRequestState$.next({ state: 'healthcheck-request-initialize', payload: null });
      let params = {};

      if (sender === 'all') {
        params = {
          subscription_id: subscriptionId,
          page: page,
          status: status,
          page_size: pageSize,
          modified_at__gte: startDate,
          modified_at__lte: endDate,
          order_by: '-modified_at',
        };
      } else {
        params = {
          subscription_id: subscriptionId,
          page: page,
          status: status,
          page_size: pageSize,
          sender: sender,
          modified_at__gte: startDate,
          modified_at__lte: endDate,
          order_by: '-modified_at',
        };
      }

      const httpParams: HttpParams = new HttpParams({ fromObject: params });
      const healthCheckRequestUrl = environment.openbridgeApiUris.service +
        '/service/healthchecks/' + environment.openbridgeApiUris.healtChecksPartialIdentifier +
        '/healthchecks/account/' + accountId + '?' + httpParams.toString();

      await this.getHealthChecksFromUrl(healthCheckRequestUrl);
      this.healthCheckRequestState$.next({ state: 'healthcheck-request-complete', payload: null });
    }
    catch (error) {
      this.healthCheckRequestState$.next({ state: 'healthcheck-request-complete', payload: null });
      throw error;
    }
  }

  async getHealthChecksFromUrl(url: string): Promise<void> {
    const response = await observableToPromise(this.http.get(url));
    this.transactionsSubscription$.next(response);
  }
}
