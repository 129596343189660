import { reportTypes } from './../../../constants/reportTypes';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormArray, ValidatorFn, AbstractControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { AppState } from 'src/app/core/store/reducers';
import { DspReportDefinition, findReportByAlias, dspReportMap } from 'src/app/modules/wizards/pages/amazon-dsp-v2/dsp-reports-map';
import { Integration } from 'src/app/shared/models/integration.model';
import { Md5 } from 'ts-md5';

@Component({
  selector: 'app-modal-template-generation',
  templateUrl: './modal-template-generation.component.html',
  styleUrls: ['./modal-template-generation.component.scss']
})
export class ModalTemplateGenerationComponent implements OnInit {
  // modal state properties (this comes with modal initialization)
  integration: Integration;
  editData: any | null = null;  // This will hold the existing templateName, reportType, and selectedDimensions.

  formGroup: UntypedFormGroup = new UntypedFormGroup({
    templateName: new UntypedFormControl('', [Validators.required]),
    reportType: new UntypedFormControl(null, [Validators.required]),  // Initialize as null to show placeholder
    // FormArray to handle the dimensions checkboxes
    selectedDimensions: new UntypedFormArray([], this.atLeastOneCheckboxCheckedValidator(1)) // custom validator
  });

  reports = reportTypes;
  selectedReport: any;
  // FormArray containing the dimension controls
  selectedDimensions: UntypedFormArray = this.formGroup.get('selectedDimensions') as UntypedFormArray;
  // Observable to handle modal closure
  onClose$: Subject<any> = new Subject<any>();

  constructor(
    private bsModalRef: BsModalRef,
    protected store$: Store<AppState>,
  ) { }

  // Initialize the form with data if in edit data exist
  ngOnInit(): void {
    if (this.editData) {
      this.populateForm(this.editData);
    }
  }

  // Populate form with existing data
  populateForm(editData: any): void {
    // Set the template name
    this.formGroup.get('templateName')?.setValue(editData.templateName);
    // Set the report type and trigger the dimensions setup
    this.formGroup.get('reportType')?.setValue(editData.reportType);
    this.onReportTypeChange();

    // Pre-select the dimensions
    const selectedReport = this.reports.find(report => report.reportType === editData.reportType);
    if (selectedReport) {
      selectedReport.dimensions.forEach((dimension, index) => {
        const isSelected = editData.selectedDimensions.find(
          selectedDim => selectedDim.dimension === dimension.dimension
        ) !== undefined;  // If a match is found, the dimension is selected
        this.selectedDimensions.at(index).setValue(isSelected);
      });
    }

    // mark fields as touched or dirty to trigger validation checks
    this.formGroup.markAllAsTouched();
  }

  // Handle form submission
  onSubmit(): void {
      this.formGroup.markAllAsTouched();

      if (this.formGroup.valid) {
        // Filter only selected (checked) dimensions
        const selectedDimensions = this.selectedDimensions.controls
          .map((control, i) => control.value ? this.selectedReport?.dimensions[i] : null)
          .filter(dimension => dimension !== null); // Remove unchecked dimensions

        const sortedDimensionString = selectedDimensions.map(item => item.dimension).sort().join(',');
        const sortedDimensionKey = this.formGroup.value.reportType + '_' + Md5.hashStr(sortedDimensionString);
        const dspReportDefinition: DspReportDefinition = findReportByAlias(dspReportMap, sortedDimensionKey);

        const payload = {
          templateName: this.formGroup.value.templateName,
          stageId: dspReportDefinition.id,
          reportType: this.formGroup.value.reportType,
          dimensions: selectedDimensions
        };

        if(this.editData) {
          if (this.editData.id === null && this.editData.isDeleted) {
            // handle create for deleted template
            this.onClose$.next({
              postPresetPayload: payload
            });
          } 
          else {
            // handle edit for the existing data 
            this.onClose$.next({
              patchPresetPayload: payload
            });
          }
        } 
        else {
          // handle create for the new data
          this.onClose$.next({
            postPresetPayload: payload
          });
        }

        this.closeModalWithComponent();
      }
    
  }

  async deleteTemplate(): Promise<void> {

    if (this.editData.id === null && this.editData.isDeleted) {
      // handle the delete for deleted template
      this.onClose$.next({
        deleteMissingStageId: true
      });
    } 
    else {
      // handle the deleted data
      this.onClose$.next({
        deletePresetPayload: true
      });
    }

    this.closeModalWithComponent();
  }

  // Handle report type change  
  onReportTypeChange(): void {
    // Find the selected report based on the selected reportType
    this.selectedReport = this.reports.find(report => report.reportType === this.formGroup.get('reportType')?.value);

    this.selectedDimensions.clear(); // Clear the form array when report changes

    // Populate the form array with controls for the dimensions of the selected report
    if (this.selectedReport) {
      this.selectedReport.dimensions.forEach(() => {
        this.selectedDimensions.push(new UntypedFormControl(false)); // Add unchecked checkboxes for each dimension
      });
    }

    // Pre-select dimensions if edit mode is active
    if (this.editData && this.editData.selectedDimensions) {
      this.selectedReport.dimensions.forEach((dimension, index) => {
        const isSelected = this.editData.selectedDimensions.includes(dimension.dimension);
        this.selectedDimensions.at(index).setValue(isSelected);
      });
    }
  }

  // Utility function to close the modal
  private closeModalWithComponent(): void {
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
    this.bsModalRef = null;
  }

  // Handle cancel action
  cancel(): void {
    this.onClose$.next({ canceled: true });
    this.closeModalWithComponent();
  }


  // Custom validator function to ensure at least one dimension is selected
  atLeastOneCheckboxCheckedValidator(minRequired: number = 1): ValidatorFn {
    return (formArray: AbstractControl): { [key: string]: boolean } | null => {
      const totalSelected = (formArray as UntypedFormArray).controls
        .map(control => control.value)
        .reduce((acc, value) => (value ? acc + 1 : acc), 0);

      return totalSelected >= minRequired ? null : { required: true };
    };
  }

}
