import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-display-list',
  templateUrl: './display-list.component.html'
})
export class DisplayListComponent{
  @Input() cardData = [];
  @Input() parentForm = UntypedFormGroup;
  @Input() controlName: string = null;
  @Output() removeCardEmmitter = new EventEmitter<string>();

  constructor(
    private changeDetection: ChangeDetectorRef
 ){}

  ngAfterViewInit(): void {
    this.changeDetection.detectChanges();
  }

  removeCard(id: any): void {
    this.removeCardEmmitter.emit(id);
  }

}
