import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { observableToPromise } from '../functions/utility';

@Injectable({
  providedIn: 'root'
})
export class ApiStateService {

  constructor(
    private httpClient: HttpClient
  ) { }

  async createOauthState(oauthStateParams: any): Promise<HttpResponse<object>> {
    try{

      const oauthState: OauthState = {
          ...oauthStateParams.extraParams,
          account_id: oauthStateParams.accountId,
          external: oauthStateParams.external,
          remote_identity_type_id: oauthStateParams.remoteIdentityTypeId,
          return_url: oauthStateParams.returnUrl,
          user_id: oauthStateParams.userId
        };

      if (oauthStateParams.region) {
        oauthState['region'] = oauthStateParams.region;
      }

      if (oauthStateParams.oauthAppId) {
        oauthState['oauth_id'] = oauthStateParams.oauthAppId;
      }

      if (oauthStateParams.queryParams) {
        oauthState['query_params'] = oauthStateParams.queryParams;
      }

      // Create
      return this.createStateForOauth(oauthState);

    }
    catch (error) {
      throw error;
    }
  }

  async createExternalReauthState(oauthStateParams: any): Promise<HttpResponse<object>> {
      try{

      const oauthState = {
          ...oauthStateParams.extraParams,
          external: oauthStateParams.external,
          remote_identity_type_id: oauthStateParams.remoteIdentityTypeId,
          return_url: oauthStateParams.returnUrl,
        };

      if (oauthStateParams.region) {
        oauthState['region'] = oauthStateParams.region;
      }

      // Create
      return this.createState(oauthState);

    }
    catch (error) {
      throw error;
    }
  }

  // Call the state api to create state.
  private async createState(stateDetails: any): Promise<HttpResponse<object>> {
    try {
      const payload: State = {
        data: {
          type: 'ClientState',
          attributes: {
            state: stateDetails
          }
        }
      };

      // Call and return the createState function
      return await observableToPromise(this.httpClient.post(environment.openbridgeApiUris.state, payload, { observe: 'response' }));
    }
    catch (error) {
      throw error;
    }
  }

  // Call the state api to create state.
  private async createStateForOauth(stateDetails: any): Promise<HttpResponse<object>> {
    try {
      const payload: State = {
        data: {
          type: 'ClientState',
          attributes: {
            state: stateDetails
          }
        }
      };

      // Call and return the createState function
      return await observableToPromise(this.httpClient.post(environment.openbridgeApiUris.state + '/oauth', payload, { observe: 'response' }));
    }
    catch (error) {
      throw error;
    }
  }


}

// Define the state interface
interface State {
  data: {
    type: string;
    attributes: {
      state: string;
    }
  };
}

// Define the Oauth State interface
 // regions 'global' | 'na' | 'eu' | 'fe' ;
interface OauthState {
  account_id: number;
  user_id: number;
  remote_identity_type_id: number;
  return_url: string;
  region?: string; // 'global' | 'na' | 'eu' | 'fe' ;
  identityId?: number;
  customer_oauth_app_id?: number;
  shop_url?: string;
}
