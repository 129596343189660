<!-- Image -->
<div class="avatar avatar-sm card-avatar">
  <img src="{{integration.logoPath}}" class="avatar-img rounded" alt="..."
    (error)="imageServiceService.handleMissingImage($event)">
</div>
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader"> {{ integration.name }} Services </h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline"> Selecting Your {{ integration.name }} Services </p>
<!-- Divider -->
<hr class="mb-4">
<!-- Heading-->
<h4 class="mt-2 mb-2"> Selecting A {{integration.name}} service</h4>
<ol>
  <li class="text-muted mb-2">
    The {{ integration.name }} permissions granted to Openbridge limit access only to those resources within an account
    that a user has permission.
  </li>
  <li class="text-muted mb-2">
    Any {{ integration.name }} services we display are based on the response from the {{ integration.name }} API. If you
    do not see a service listed, you need to verify that the user has the correct access permissions.
  </li>
  <li class="text-muted mb-2">See the {{ integration | integrationFullname }}
    <a href="{{ integration | integrationDocLink}}" rel="noopener" target="_blank">help docs</a> for
    guides, tips, and troubleshooting
  </li>
</ol>