<!-- Image -->
<div class="avatar avatar-sm card-avatar">
  <img src="{{integration.logoPath}}" class="avatar-img rounded" alt="..."
    (error)="imageServiceService.handleMissingImage($event)">
</div>
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader"> Authorization </h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline"> {{ integration.name }} Authorization </p>
<!-- Divider -->
<hr class="mb-4">
<!-- Heading-->
<h4 class="mt-2 mb-2"> Getting ready for {{ integration.name }} access authorization </h4>
<ol>
  <li class="text-muted mb-2">
    If you are already logged into {{ integration.name }} you may want to first log out. {{ integration.name }} will
    recognize your current login status and default to using this account during authorization.
  </li>

  <li class="text-muted mb-2">
    {{ integration.name }} will display a service-specific “Login dialog” or “consent screen” that will prompt you to
    authorize Openbridge. If they are not logged into {{ integration.name }}, they will first be prompted to log in,
    then prompted to authorize Openbridge. {{ integration.name }} will present a dialog that allows grant permissions
    that allow Openbridge access to the API on your behalf.
  </li>
  <li class="text-muted mb-2">
    You must grant any valid {{ integration.name }} roles and permissions approval required by Openbridge in the dialog.
    Not doing so will cause an authorization error.
  </li>
  <li class="text-muted mb-2">
    Openbridge requests the {{ integration.name }} scopes that enable our application access only to the resources that
    it needs.
  </li>
  <li class="text-muted mb-2">See the {{ integration | integrationFullname }}
    <a href="{{ integration | integrationDocLink}}" rel="noopener" target="_blank">help docs</a> for
    guides, tips, and troubleshooting
  </li>
</ol>