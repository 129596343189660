<span *ngIf="showLoading" [tooltip]="tooltipText" class="cursor-pointer">
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-history">
    <style>
      @keyframes rotate-arrow {
        from { transform: rotate(0deg); }
        to { transform: rotate(360deg); }
      }
  
      #rotating-arrow {
        transform-origin: 50% 50%;
        animation: rotate-arrow 2s linear infinite;
      }
    </style>
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M12 8l0 4l2 2" />
    <path id="rotating-arrow" d="M3.05 11a9 9 0 1 1 .5 4m-.5 5v-5h5" />
  </svg>
</span>
