<!-- Image -->
<div class="avatar avatar-sm card-avatar">
  <img src="{{integration.logoPath}}" class="avatar-img rounded" alt="..."
    (error)="imageServiceService.handleMissingImage($event)">
</div>
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader"> Connection </h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline"> Connecting to {{ integration | integrationFullname }} </p>
<!-- Divider -->
<hr class="mb-4">
<!-- Heading-->
<h4 class="mt-2 mb-2"> {{ integration | integrationFullname }} configuration notes: </h4>
<ol>
  <li class="text-muted mb-2"> The configuration process assumes you have an Amazon Web Services or AWS account, with a
    running Redshift instance. </li>
  <li class="text-muted mb-2"> Our automated <code>redshift.sql</code> will help with basic user, password, database,
    schema, and search path configuration.
  <li class="text-muted mb-2"> Depending on your Amazon settings, you will need to grant our IP addresses
    <code>52.54.227.22</code> and <code>52.2.68.68</code> access to your Redshift instance via a security group. Please
    review the {{ integration | integrationFullname }} documentation which describes how to allow us access to your
    cluster.
  </li>
  <li class="text-muted mb-2">Insufficient permission, incorrect names, or invalid settings provided during set up will
    cause the connection to your destination to fail.</li>
</ol>