<div class="modal-header">
  <h4 class="modal-title pull-left">Requesting Job reports</h4>
</div>
<div class="modal-body">
  <div class="mb-1">
      <div [ngSwitch]="state">
          <div *ngSwitchCase="'job-process-complete'" id="complete">
              Process Complete.
          </div>
          <div *ngSwitchCase="'job-process-initialize'" id="initialize">
              Processing to request your data.
          </div>
          <div *ngSwitchDefault id="default">
              Processing request. This will take couple of seconds.
          </div>
      </div>
  </div>
  <div class="progress">
      <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="25"
          aria-valuemin="0" aria-valuemax="100" [style]="widthStyle"></div>
  </div>
</div>