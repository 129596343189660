import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { emit } from 'process';

@Component({

  selector: '[appDisplayListCard]',
  templateUrl: './display-list-card.component.html'
})
export class DisplayListCardComponent implements OnInit {
  @Input() cardData;
  @Input() controlName: string;
  @Input() parentForm = UntypedFormGroup;
  @Output() removeCardEmmitter = new EventEmitter<string>();
  imgSrc: string;

  constructor() { }

  ngOnInit(): void {
    this.imgSrc = this.cardData.logoUri;
  }

  removeCard(id: any): void {
    this.removeCardEmmitter.emit(id);
  }

}
