import { IconForNotificationPipe } from './pipes/icon-for-notification.pipe';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PickListComponent } from './components/form/pick-list/pick-list.component';
import { PickListCardComponent } from './components/form/pick-list-card/pick-list-card.component';
import { DisplayListComponent } from './components/form/display-list/display-list.component';
import { DisplayListCardComponent } from './components/form/display-list-card/display-list-card.component';
import { PickListFilterComponent } from './components/form/pick-list-filter/pick-list-filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HelpDrawerComponent } from './components/help-drawer/help-drawer.component';

import { ToastrModule } from 'ngx-toastr';

import { HelpBigqueryChecklistComponent } from './components/help-drawer/help/help-bigquery-checklist/help-bigquery-checklist.component';
import { HelpBigqueryIdentityComponent } from './components/help-drawer/help/help-bigquery-identity/help-bigquery-identity.component';
import { HelpBigqueryDetailsComponent } from './components/help-drawer/help/help-bigquery-details/help-bigquery-details.component';
import { HelpCommonDestinationNameComponent } from './components/help-drawer/help/help-common-destination-name/help-common-destination-name.component';
import { HelpCommonCompleteComponent } from './components/help-drawer/help/help-common-complete/help-common-complete.component';
import { HelpAmznRedshiftChecklistComponent } from './components/help-drawer/help/help-amzn-redshift-checklist/help-amzn-redshift-checklist.component';
import { HelpAmznRedshiftDetailsComponent } from './components/help-drawer/help/help-amzn-redshift-details/help-amzn-redshift-details.component';
import { HelpAmznAthenaChecklistComponent } from './components/help-drawer/help/help-amzn-athena-checklist/help-amzn-athena-checklist.component';
import { HelpAmznSpectrumChecklistComponent } from './components/help-drawer/help/help-amzn-spectrum-checklist/help-amzn-spectrum-checklist.component';
import { HelpAmznAthenaDetailsComponent } from './components/help-drawer/help/help-amzn-athena-details/help-amzn-athena-details.component';
import { HelpAmznSpectrumDetailsComponent } from './components/help-drawer/help/help-amzn-spectrum-details/help-amzn-spectrum-details.component';
import { HelpSnowflakeDetailsComponent } from './components/help-drawer/help/help-snowflake-details/help-snowflake-details.component';
import { HelpSnowflakeChecklistComponent } from './components/help-drawer/help/help-snowflake-checklist/help-snowflake-checklist.component';
import { HelpPostgresqlDetailsComponent } from './components/help-drawer/help/help-postgresql-details/help-postgresql-details.component';
import { HelpPostgresqlChecklistComponent } from './components/help-drawer/help/help-postgresql-checklist/help-postgresql-checklist.component';
import { HelpAzureBlobStorageChecklistComponent } from './components/help-drawer/help/help-azure-blob-storage-checklist/help-azure-blob-storage-checklist.component';
import { HelpAzureBlobStorageDetailsComponent } from './components/help-drawer/help/help-azure-blob-storage-details/help-azure-blob-storage-details.component';
import { HelpAzureDataLakeChecklistComponent } from './components/help-drawer/help/help-azure-data-lake-checklist/help-azure-data-lake-checklist.component';
import { HelpAzureDataLakeDetailsComponent } from './components/help-drawer/help/help-azure-data-lake-details/help-azure-data-lake-details.component';
import { HelpShopifyIdentityComponent } from './components/help-drawer/help/help-shopify-identity/help-shopify-identity.component';
import { HelpShopifyChecklistComponent } from './components/help-drawer/help/help-shopify-checklist/help-shopify-checklist.component';
import { HelpShopifyProductComponent } from './components/help-drawer/help/help-shopify-product/help-shopify-product.component';
import { HelpShopifyDetailsComponent } from './components/help-drawer/help/help-shopify-details/help-shopify-details.component';
import { HelpShopifyIdentitySelectComponent } from './components/help-drawer/help/help-shopify-identity-select/help-shopify-identity-select.component';
import { HelpShopifyIdentityMerchantComponent } from './components/help-drawer/help/help-shopify-identity-merchant/help-shopify-identity-merchant.component';
import { HelpShopifyIdentityCredentialsComponent } from './components/help-drawer/help/help-shopify-identity-credentials/help-shopify-identity-credentials.component';
import { HelpShopifyIdentityRedirectComponent } from './components/help-drawer/help/help-shopify-identity-redirect/help-shopify-identity-redirect.component';
import { WizardHeaderComponent } from './components/product-header/wizard-header.component';
import { StepCounterComponent } from './components/step-counter/step-counter.component';
import { FilterNavComponent } from './components/filter/filter-nav/filter-nav.component';
import { FilterFormComponent } from './components/filter/filter-form/filter-form.component';
import { HelpSourcesComponent } from './components/help-drawer/help/help-sources/help-sources.component';
import { HelpDestinationsComponent } from './components/help-drawer/help/help-destinations/help-destinations.component';
import { ChartComponent } from './components/charts/chart/chart.component';
import { ChartPieComponent } from './components/charts/chart-pie/chart-pie.component';
import { ChartBarComponent } from './components/charts/chart-bar/chart-bar.component';
import { ChartLineComponent } from './components/charts/chart-line/chart-line.component';
import { ChartDoughnutComponent } from './components/charts/chart-doughnut/chart-doughnut.component';
import { ChartSparklineComponent } from './components/charts/chart-sparkline/chart-sparkline.component';
import { FormListIdentityComponent } from './components/form/form-list-identity/form-list-identity.component';
import { FlashNotificationsComponent } from './components/flash-notifications/flash-notifications.component';
import { IdentityOauthCreateComponent } from './components/identities/identity-oauth-create/identity-oauth-create.component';
import { IdentityBigqueryCreateComponent } from './components/identities/identity-bigquery-create/identity-bigquery-create.component';
import { IdentityMwsCreateComponent } from './components/identities/identity-mws-create/identity-mws-create.component';
import { IdentityShopifyCreateComponent } from './components/identities/identity-shopify-create/identity-shopify-create.component';
import { HelpIdentitiesComponent } from './components/help-drawer/help/help-identities/help-identities.component';
import { HelpPipelinesComponent } from './components/help-drawer/help/help-pipelines/help-pipelines.component';
import { HelpAmazonAdvertisingChecklistComponent } from './components/help-drawer/help/help-amazon-advertising-checklist/help-amazon-advertising-checklist.component';
import { HelpAmazonAdvertisingIdentityComponent } from './components/help-drawer/help/help-amazon-advertising-identity/help-amazon-advertising-identity.component';
import { HelpAmazonAdvertisingDetailsComponent } from './components/help-drawer/help/help-amazon-advertising-details/help-amazon-advertising-details.component';
import { HelpAmazonAttributionChecklistComponent } from './components/help-drawer/help/help-amazon-attribution-checklist/help-amazon-attribution-checklist.component';
import { HelpAmazonAttributionIdentityComponent } from './components/help-drawer/help/help-amazon-attribution-identity/help-amazon-attribution-identity.component';
import { HelpAmazonAttributionDetailsComponent } from './components/help-drawer/help/help-amazon-attribution-details/help-amazon-attribution-details.component';
import { HelpGoogleAdsChecklistComponent } from './components/help-drawer/help/help-google-ads-checklist/help-google-ads-checklist.component';
import { HelpGoogleAdsIdentityComponent } from './components/help-drawer/help/help-google-ads-identity/help-google-ads-identity.component';
import { HelpGoogleAdsDetailComponent } from './components/help-drawer/help/help-google-ads-detail/help-google-ads-detail.component';
import { HelpGoogleAdsDetailsComponent } from './components/help-drawer/help/help-google-ads-details/help-google-ads-details.component';
import { HelpGoogleAdsCustomerComponent } from './components/help-drawer/help/help-google-ads-customer/help-google-ads-customer.component';
import { HelpGoogleAdsManagedCustomerComponent } from './components/help-drawer/help/help-google-ads-managed-customer/help-google-ads-managed-customer.component';

import { HelpGoogleAnalytics360ProjectIdComponent } from './components/help-drawer/help/help-google-analytics-360-project-id/help-google-analytics-360-project-id.component';
import { HelpGoogleAnalytics360DatasetIdComponent } from './components/help-drawer/help/help-google-analytics-360-dataset-id/help-google-analytics-360-dataset-id.component';
import { HelpGoogleAnalytics360ChecklistComponent } from './components/help-drawer/help/help-google-analytics-360-checklist/help-google-analytics-360-checklist.component';
import { HelpGoogleAnalytics360IdentityComponent } from './components/help-drawer/help/help-google-analytics-360-identity/help-google-analytics-360-identity.component';

import { HelpGoogleCampaignManagerChecklistComponent } from './components/help-drawer/help/help-google-campaign-manager-checklist/help-google-campaign-manager-checklist.component';
import { HelpGoogleCampaignManagerIdentityComponent } from './components/help-drawer/help/help-google-campaign-manager-identity/help-google-campaign-manager-identity.component';
import { HelpGoogleCampaignManagerProfileComponent } from './components/help-drawer/help/help-google-campaign-manager-profile/help-google-campaign-manager-profile.component';
import { HelpGoogleCampaignManagerReportComponent } from './components/help-drawer/help/help-google-campaign-manager-report/help-google-campaign-manager-report.component';

import { HelpGoogleSearchAds360ChecklistComponent } from './components/help-drawer/help/help-google-search-ads-360-checklist/help-google-search-ads-360-checklist.component';
import { HelpGoogleSearchAds360IdentityComponent } from './components/help-drawer/help/help-google-search-ads-360-identity/help-google-search-ads-360-identity.component';
import { HelpGoogleSearchAds360DetailsComponent } from './components/help-drawer/help/help-google-search-ads-360-details/help-google-search-ads-360-details.component';

import { HelpInstagramBusinessIqChecklistComponent } from './components/help-drawer/help/help-instagram-business-iq-checklist/help-instagram-business-iq-checklist.component';
import { HelpInstagramBusinessIqIdentityComponent } from './components/help-drawer/help/help-instagram-business-iq-identity/help-instagram-business-iq-identity.component';
import { HelpInstagramBusinessIqDetailsComponent } from './components/help-drawer/help/help-instagram-business-iq-details/help-instagram-business-iq-details.component';
import { HelpMwsFinancesChecklistComponent } from './components/help-drawer/help/help-mws-finances-checklist/help-mws-finances-checklist.component';
import { HelpMwsFinancesIdentityComponent } from './components/help-drawer/help/help-mws-finances-identity/help-mws-finances-identity.component';
import { HelpMwsRealtimeOrdersChecklistComponent } from './components/help-drawer/help/help-mws-realtime-orders-checklist/help-mws-realtime-orders-checklist.component';
import { HelpMwsRealtimeOrdersIdentityComponent } from './components/help-drawer/help/help-mws-realtime-orders-identity/help-mws-realtime-orders-identity.component';
import { HelpMwsReportingChecklistComponent } from './components/help-drawer/help/help-mws-reporting-checklist/help-mws-reporting-checklist.component';
import { HelpMwsReportingIdentityComponent } from './components/help-drawer/help/help-mws-reporting-identity/help-mws-reporting-identity.component';
import { HelpMwsSettlementReportsChecklistComponent } from './components/help-drawer/help/help-mws-settlement-reports-checklist/help-mws-settlement-reports-checklist.component';
import { HelpMwsSettlementReportsIdentityComponent } from './components/help-drawer/help/help-mws-settlement-reports-identity/help-mws-settlement-reports-identity.component';
import { HelpYoutubeChannelInsightsChecklistComponent } from './components/help-drawer/help/help-youtube-channel-insights-checklist/help-youtube-channel-insights-checklist.component';
import { HelpYoutubeChannelInsightsIdentityComponent } from './components/help-drawer/help/help-youtube-channel-insights-identity/help-youtube-channel-insights-identity.component';
import { HelpYoutubeChannelInsightsDetailsComponent } from './components/help-drawer/help/help-youtube-channel-insights-details/help-youtube-channel-insights-details.component';
import { HelpYoutubeVideoInsightsChecklistComponent } from './components/help-drawer/help/help-youtube-video-insights-checklist/help-youtube-video-insights-checklist.component';
import { HelpYoutubeVideoInsightsIdentityComponent } from './components/help-drawer/help/help-youtube-video-insights-identity/help-youtube-video-insights-identity.component';
import { HelpYoutubeVideoInsightsDetailsComponent } from './components/help-drawer/help/help-youtube-video-insights-details/help-youtube-video-insights-details.component';
import { HelpYoutubeCompetitorChecklistComponent } from './components/help-drawer/help/help-youtube-competitor-checklist/help-youtube-competitor-checklist.component';
import { HelpYoutubeCompetitorIdentityComponent } from './components/help-drawer/help/help-youtube-competitor-identity/help-youtube-competitor-identity.component';
import { HelpYoutubeCompetitorDetailsComponent } from './components/help-drawer/help/help-youtube-competitor-details/help-youtube-competitor-details.component';
import { HelpYoutubeVideoIqChecklistComponent } from './components/help-drawer/help/help-youtube-video-iq-checklist/help-youtube-video-iq-checklist.component';
import { HelpYoutubeVideoIqIdentityComponent } from './components/help-drawer/help/help-youtube-video-iq-identity/help-youtube-video-iq-identity.component';
import { HelpYoutubeVideoIqDetailsComponent } from './components/help-drawer/help/help-youtube-video-iq-details/help-youtube-video-iq-details.component';
import { HelpCommonPipelineNameComponent } from './components/help-drawer/help/help-common-pipeline-name/help-common-pipeline-name.component';
import { HelpCommonDestinationSelectComponent } from './components/help-drawer/help/help-common-destination-select/help-common-destination-select.component';
import { FilterPipelinesComponent } from './components/filter/filter-pipelines/filter-pipelines.component';
import { FilterNavPipelinesComponent } from './components/filter/filter-nav-pipelines/filter-nav-pipelines.component';
import { FilterNavIdentitiesComponent } from './components/filter/filter-nav-identities/filter-nav-identities.component';
import { FilterIdentitiesComponent } from './components/filter/filter-identities/filter-identities.component';
import { FilterJobTransactionsComponent } from './components/filter/filter-job-transactions/filter-job-transactions.component';
import { ObjectHasKeyPipe } from './pipes/object-has-key.pipe';
import { IdentityTypeNameFromIdentityTypeIdPipe } from './pipes/identity-type-name-from-identity-type-id.pipe';
import { IntegrationNameFromProductIdPipe } from './pipes/integration-name-from-product-id.pipe';
import { LogoPathFromProductIdPipe } from './pipes/logo-path-from-product-id.pipe';
import { IntegrationFullnamePipe } from './pipes/integration-fullname.pipe';
import { IntegrationPathFromProductIdPipe } from './pipes/integration-path-from-product-id.pipe';
import { IntegrationIsConfigurablePipe } from './pipes/integration-is-configurable.pipe';
import { LogoPathFromIdentityTypeIdPipe } from './pipes/logo-path-from-identity-type-id.pipe';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { RemoveCommaPipe } from './pipes/remove-comma.pipe';
import { CardDailyPipelinesComponent } from './components/cards/card-daily-pipelines/card-daily-pipelines.component';
import { CardCreatePipelineComponent } from './components/cards/card-create-pipeline/card-create-pipeline.component';
import { CardTrialPlanComponent } from './components/cards/card-trial-plan/card-trial-plan.component';
import { FilterPaginationComponent } from './components/filter/filter-pagination/filter-pagination.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { EmptyNotifierComponent } from './components/form/empty-notifier/empty-notifier.component';
import { RouterModule } from '@angular/router';
import { HelpPricingComponent } from './components/help-drawer/help/help-pricing/help-pricing.component';
import { HelpDestinationsSelectComponent } from './components/help-drawer/help/help-destinations-select/help-destinations-select.component';
import { SortArrayPipe } from './pipes/sort-array.pipe';
import { ComingSoonIntegrationPipe } from './pipes/coming-soon-integration.pipe';
import { BooleanFalseToNullPipe } from './pipes/boolean-false-to-null.pipe';
import { BooleanTrueToNullFalseToTruePipe } from './pipes/boolean-true-to-null-false-to-true.pipe';
import { CanRequestHistoryPipe } from './pipes/can-request-history.pipe';
import { HelpGeneralComponent } from './components/help-drawer/help/help-general/help-general.component';
import { HelpBillingComponent } from './components/help-drawer/help/help-billing/help-billing.component';
import { HelpSecurityComponent } from './components/help-drawer/help/help-security/help-security.component';
import { ModalPlanUpgradeComponent } from './components/modals/modal-plan-upgrade/modal-plan-upgrade.component';
import { ModalActivePremiumSubscriptionComponent } from './components/modals/modal-active-premium-subscription/modal-active-premium-subscription.component';
import { ModalPipelineStatusComponent } from './components/modals/modal-pipeline-status/modal-pipeline-status.component';
import { HelpAmazonDspChecklistComponent } from './components/help-drawer/help/help-amazon-dsp-checklist/help-amazon-dsp-checklist.component';
import { HelpAmazonDspIdentityComponent } from './components/help-drawer/help/help-amazon-dsp-identity/help-amazon-dsp-identity.component';
import { HelpAmazonDspDetailsComponent } from './components/help-drawer/help/help-amazon-dsp-details/help-amazon-dsp-details.component';
import { ModalPlanExpiredComponent } from './components/modals/modal-plan-expired/modal-plan-expired.component';
import { HelpAmazonSpapiChecklistComponent } from './components/help-drawer/help/help-amazon-spapi-checklist/help-amazon-spapi-checklist.component';
import { HelpAmazonSpapiDetailsComponent } from './components/help-drawer/help/help-amazon-spapi-details/help-amazon-spapi-details.component';
import { HelpAmazonSpapiIdentityComponent } from './components/help-drawer/help/help-amazon-spapi-identity/help-amazon-spapi-identity.component';
import { HelpHealthchecksComponent } from './components/help-drawer/help/help-healthchecks/help-healthchecks.component';
import { FilterServerSidePaginationComponent } from './components/filter/filter-server-side-pagination/filter-server-side-pagination.component';
import { FilterTransactionsComponent } from './components/filter/filter-transactions/filter-transactions.component';
import { ModalTransactionViewComponent } from './components/modals/modal-transaction-view/modal-transaction-view.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalProcessHealthcheckRequestComponent } from './components/modals/modal-process-healthcheck-request/modal-process-healthcheck-request.component';

import { RemoveAllWhiteSpacePipe } from './pipes/remove-all-white-space.pipe';
import { HelpFacebookMarketingChecklistComponent } from './components/help-drawer/help/help-facebook-marketing-checklist/help-facebook-marketing-checklist.component';
import { HelpFacebookMarketingIdentityComponent } from './components/help-drawer/help/help-facebook-marketing-identity/help-facebook-marketing-identity.component';
import { HelpFacebookMarketingDetailsComponent } from './components/help-drawer/help/help-facebook-marketing-details/help-facebook-marketing-details.component';
import { HelpFacebookCommonChecklistComponent } from './components/help-drawer/help/help-facebook-common-checklist/help-facebook-common-checklist.component';
import { HelpFacebookCommonDetailsComponent } from './components/help-drawer/help/help-facebook-common-details/help-facebook-common-details.component';
import { HelpFacebookCommonIdentityComponent } from './components/help-drawer/help/help-facebook-common-identity/help-facebook-common-identity.component';

import { IntegrationDocLinkPipe } from './pipes/integration-doc-link.pipe';
import { ModalGlobalErrorComponent } from './components/modals/modal-global-error/modal-global-error.component';
import { HelpYoutubeSingleChannelCommonDetailsComponent } from './components/help-drawer/help/help-youtube-single-channel-common-details/help-youtube-single-channel-common-details.component';
import { HelpYoutubeSingleChannelCommonChecklistComponent } from './components/help-drawer/help/help-youtube-single-channel-common-checklist/help-youtube-single-channel-common-checklist.component';
import { HelpYoutubeSingleChannelCommonIdentityComponent } from './components/help-drawer/help/help-youtube-single-channel-common-identity/help-youtube-single-channel-common-identity.component';
import { HelpDrawerDefaultComponent } from './components/help-drawer/help/help-drawer-default/help-drawer-default.component';
import { ModalOauthButtonLoadingComponent } from './components/modals/modal-oauth-button-loading/modal-oauth-button-loading.component';
import { ModalDeactivateDestinationComponent } from './components/modals/modal-deactivate-destination/modal-deactivate-destination.component';
import { ModalDeactivateSourceComponent } from './components/modals/modal-deactivate-source/modal-deactivate-source.component';
import { ModalSiteUpdateRefreshComponent } from './components/modals/modal-site-update-refresh/modal-site-update-refresh.component';
import { IdentityWizardComponent } from './components/identities/identity-wizard/identity-wizard.component';
import { HelpCommonChecklistComponent } from './components/help-drawer/help/help-common-checklist/help-common-checklist.component';
import { HelpCommonDetailsComponent } from './components/help-drawer/help/help-common-details/help-common-details.component';
import { HelpCommonIdentityComponent } from './components/help-drawer/help/help-common-identity/help-common-identity.component';
import { HelpYoutubeMultiChannelCommonChecklistComponent } from './components/help-drawer/help/help-youtube-multi-channel-common-checklist/help-youtube-multi-channel-common-checklist.component';
import { HelpYoutubeMultiChannelCommonDetailsComponent } from './components/help-drawer/help/help-youtube-multi-channel-common-details/help-youtube-multi-channel-common-details.component';
import { HelpYoutubeMultiChannelCommonIdentityComponent } from './components/help-drawer/help/help-youtube-multi-channel-common-identity/help-youtube-multi-channel-common-identity.component';
import { LoadingNotifierComponent } from './components/loading-notifier/loading-notifier.component';
import { PipelineInputComponent } from './components/inputs/pipeline-input/pipeline-input.component';
import { ModalGeneralProgressComponent } from './components/modals/modal-general-progress/modal-general-progress.component';
import { ModalMemberInviteComponent } from './components/modals/modal-member-invite/modal-member-invite.component';
import { CheckListComponent } from './components/form/check-list/check-list.component';
import { CheckListCardComponent } from './components/form/check-list-card/check-list-card.component';
import { HelpDatabricksChecklistComponent } from './components/help-drawer/help/help-databricks-checklist/help-databricks-checklist.component';
import { HelpDatabricksDetailsComponent } from './components/help-drawer/help/help-databricks-details/help-databricks-details.component';

import { ModalCommonComponent } from './components/modals/modal-common/modal-common.component';
import { ModalGeneralErrorComponent } from './components/modals/modal-general-error/modal-general-error.component';
import { ModalRefreshTokenCreateComponent } from './components/modals/modal-refresh-token-create/modal-refresh-token-create.component';
import { ModalRefreshTokenRevokeComponent } from './components/modals/modal-refresh-token-revoke/modal-refresh-token-revoke.component';
import { ModalRefreshTokenDisplayComponent } from './components/modals/modal-refresh-token-display/modal-refresh-token-display.component';
import { HelpCsvValidationSchemaGenerationComponent } from './components/help-drawer/help/help-csv-validation-schema-generation/help-csv-validation-schema-generation.component';
import { HelpCsvBatchLoaderChecklistComponent } from './components/help-drawer/help/help-csv-batch-loader-checklist/help-csv-batch-loader-checklist.component';
import { HelpCsvBatchLoaderTesterComponent } from './components/help-drawer/help/help-csv-batch-loader-tester/help-csv-batch-loader-tester.component';
import { HelpCsvBatchLoaderDetailsComponent } from './components/help-drawer/help/help-csv-batch-loader-details/help-csv-batch-loader-details.component';
import { HelpCsvBatchLoaderAuthorizationComponent } from './components/help-drawer/help/help-csv-batch-loader-authorization/help-csv-batch-loader-authorization.component';
import { FileInputComponent } from './components/inputs/file-input/file-input.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BannerSupportProxyComponent } from './component/banners/banner-support-proxy/banner-support-proxy.component';
import { ModalThirdPartyIdentityComponent } from './components/modals/modal-third-party-identity/modal-third-party-identity.component';
import { ModalPipelineDetailsComponent } from './components/modals/modal-pipeline-details/modal-pipeline-details.component';
import { CanViewPipelineDetailsPipe } from './pipes/can-view-pipeline-details.pipe';
import { S3SignedUploaderComponent } from './components/forms/s3-signed-uploader/s3-signed-uploader.component';
import { FilterCardPaginationComponent } from './components/filter/filter-card-pagination/filter-card-pagination.component';
import { ModalEditAliasComponent } from './components/modals/modal-edit-alias/modal-edit-alias.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { FlagFromRegionIdPipe } from './pipes/flag-from-region-id.pipe';
import { IdentityLabelComponent } from './components/identity-label/identity-label.component';
import { ModalProcessJobRequestComponent } from './components/modals/modal-process-job-request/modal-process-job-request.component';
import { ModalJobViewComponent } from './components/modals/modal-job-view/modal-job-view.component';
import { HelpJobsComponent } from './components/help-drawer/help/help-jobs/help-jobs.component';
import { ConvertCronExpressionPipe } from './pipes/convert-cron-expression.pipe';
import { FilterJobsComponent } from './components/filter/filter-jobs/filter-jobs.component';
import { SubscriptionInfoLabelComponent } from './components/subscription-info-label/subscription-info-label.component';
import { FilterNavJobsComponent } from './components/filter/filter-nav-jobs/filter-nav-jobs.component';
import { NgxDropzoneModule } from '../modules/dropzone/ngx-dropzone.module';
import { ProductSupportsHistoryPipe } from './pipes/product-supports-history.pipe';
import { LoadingClockSpinnerComponent } from './components/loading-clock-spinner/loading-clock-spinner.component';
import { ModalGenericConfirmComponent } from './components/modals/modal-generic-confirm/modal-generic-confirm.component';
import { ProductTableSelectorComponent } from './components/product-table-selector/product-table-selector.component';
import { ProductTableCheckboxComponent } from './components/product-table-checkbox/product-table-checkbox.component';
import { ModalDatasetTemplateDeleteComponent } from './components/modals/modal-dataset-template-delete/modal-dataset-template-delete.component';
import { ModalTemplateGenerationComponent } from './components/modals/modal-template-generation/modal-template-generation.component';
import { CalculateReportDateForJobsPipe } from './pipes/calculate-report-date-for-jobs.pipe';

@NgModule({
  declarations: [
    ChartComponent,
    ChartPieComponent,
    ChartBarComponent,
    ChartLineComponent,
    ChartDoughnutComponent,
    ChartSparklineComponent,
    FilterNavComponent,
    FilterFormComponent,
    FormListIdentityComponent,
    HelpDrawerComponent,
    HelpBigqueryChecklistComponent,
    HelpBigqueryIdentityComponent,
    HelpBigqueryDetailsComponent,
    HelpCommonDestinationNameComponent,
    HelpCommonCompleteComponent,
    HelpAmznRedshiftChecklistComponent,
    HelpAmznRedshiftDetailsComponent,
    HelpAmznAthenaChecklistComponent,
    HelpAmznSpectrumChecklistComponent,
    HelpAmznAthenaDetailsComponent,
    HelpAmznSpectrumDetailsComponent,
    HelpSnowflakeDetailsComponent,
    HelpSnowflakeChecklistComponent,
    HelpPostgresqlDetailsComponent,
    HelpPostgresqlChecklistComponent,
    HelpAzureBlobStorageChecklistComponent,
    HelpAzureBlobStorageDetailsComponent,
    HelpAzureDataLakeChecklistComponent,
    HelpAzureDataLakeDetailsComponent,
    HelpShopifyIdentityComponent,
    HelpShopifyChecklistComponent,
    HelpShopifyProductComponent,
    HelpShopifyDetailsComponent,
    HelpShopifyIdentitySelectComponent,
    HelpShopifyIdentityMerchantComponent,
    HelpShopifyIdentityCredentialsComponent,
    HelpShopifyIdentityRedirectComponent,
    WizardHeaderComponent,
    HelpSourcesComponent,
    HelpDestinationsComponent,
    IdentityOauthCreateComponent,
    IdentityBigqueryCreateComponent,
    IdentityMwsCreateComponent,
    DisplayListComponent,
    DisplayListCardComponent,
    PickListComponent,
    PickListCardComponent,
    PickListFilterComponent,
    StepCounterComponent,
    FlashNotificationsComponent,
    IdentityShopifyCreateComponent,
    IntegrationFullnamePipe,
    IntegrationNameFromProductIdPipe,
    HelpAmazonAdvertisingChecklistComponent,
    HelpAmazonAdvertisingIdentityComponent,
    HelpAmazonAdvertisingDetailsComponent,
    HelpAmazonAttributionChecklistComponent,
    HelpAmazonAttributionIdentityComponent,
    HelpAmazonAttributionDetailsComponent,
    HelpGoogleAdsChecklistComponent,
    HelpGoogleAdsIdentityComponent,
    HelpGoogleAdsDetailComponent,
    HelpGoogleAdsDetailsComponent,
    HelpGoogleAnalytics360ProjectIdComponent,
    HelpGoogleAnalytics360DatasetIdComponent,
    HelpGoogleAnalytics360ChecklistComponent,
    HelpGoogleAnalytics360IdentityComponent,
    HelpGoogleCampaignManagerChecklistComponent,
    HelpGoogleCampaignManagerIdentityComponent,
    HelpGoogleCampaignManagerProfileComponent,
    HelpGoogleCampaignManagerReportComponent,
    HelpGoogleSearchAds360ChecklistComponent,
    HelpGoogleSearchAds360IdentityComponent,
    HelpGoogleSearchAds360DetailsComponent,
    HelpInstagramBusinessIqChecklistComponent,
    HelpInstagramBusinessIqIdentityComponent,
    HelpInstagramBusinessIqDetailsComponent,
    HelpMwsFinancesChecklistComponent,
    HelpMwsFinancesIdentityComponent,
    HelpMwsRealtimeOrdersChecklistComponent,
    HelpMwsRealtimeOrdersIdentityComponent,
    HelpMwsReportingChecklistComponent,
    HelpMwsReportingIdentityComponent,
    HelpMwsSettlementReportsChecklistComponent,
    HelpMwsSettlementReportsIdentityComponent,
    HelpYoutubeChannelInsightsChecklistComponent,
    HelpYoutubeChannelInsightsIdentityComponent,
    HelpYoutubeChannelInsightsDetailsComponent,
    HelpYoutubeVideoInsightsChecklistComponent,
    HelpYoutubeVideoInsightsIdentityComponent,
    HelpYoutubeVideoInsightsDetailsComponent,
    HelpYoutubeCompetitorChecklistComponent,
    HelpYoutubeCompetitorIdentityComponent,
    HelpYoutubeCompetitorDetailsComponent,
    HelpYoutubeVideoIqChecklistComponent,
    HelpYoutubeVideoIqIdentityComponent,
    HelpYoutubeVideoIqDetailsComponent,
    HelpCommonPipelineNameComponent,
    HelpCommonDestinationSelectComponent,
    HelpIdentitiesComponent,
    HelpPipelinesComponent,
    FilterNavIdentitiesComponent,
    FilterNavPipelinesComponent,
    FilterPipelinesComponent,
    FilterIdentitiesComponent,
    FilterJobTransactionsComponent,
    IdentityTypeNameFromIdentityTypeIdPipe,
    LoadingSpinnerComponent,
    LogoPathFromIdentityTypeIdPipe,
    LogoPathFromProductIdPipe,
    ObjectHasKeyPipe,
    IntegrationPathFromProductIdPipe,
    IntegrationIsConfigurablePipe,
    RemoveCommaPipe,
    TruncatePipe,
    CustomDatePipe,
    IdentityTypeNameFromIdentityTypeIdPipe,
    IconForNotificationPipe,
    CardDailyPipelinesComponent,
    CardCreatePipelineComponent,
    CardTrialPlanComponent,
    FilterPaginationComponent,
    FilterCardPaginationComponent,
    EmptyNotifierComponent,
    HelpPricingComponent,
    HelpDestinationsSelectComponent,
    SortArrayPipe,
    ComingSoonIntegrationPipe,
    CanRequestHistoryPipe,
    CanViewPipelineDetailsPipe,
    BooleanFalseToNullPipe,
    BooleanTrueToNullFalseToTruePipe,
    RemoveAllWhiteSpacePipe,
    RemoveAllWhiteSpacePipe,
    IntegrationDocLinkPipe,
    HelpGeneralComponent,
    HelpBillingComponent,
    HelpSecurityComponent,
    ModalPlanUpgradeComponent,
    ModalActivePremiumSubscriptionComponent,
    ModalPipelineStatusComponent,
    HelpAmazonDspChecklistComponent,
    HelpAmazonDspIdentityComponent,
    HelpAmazonDspDetailsComponent,
    ModalPlanExpiredComponent,
    HelpAmazonSpapiChecklistComponent,
    HelpAmazonSpapiDetailsComponent,
    HelpAmazonSpapiIdentityComponent,
    HelpHealthchecksComponent,
    FilterServerSidePaginationComponent,
    FilterTransactionsComponent,
    ModalTransactionViewComponent,
    ModalProcessHealthcheckRequestComponent,
    RemoveAllWhiteSpacePipe,
    HelpFacebookMarketingChecklistComponent,
    HelpFacebookMarketingIdentityComponent,
    HelpFacebookMarketingDetailsComponent,
    HelpFacebookCommonChecklistComponent,
    HelpFacebookCommonDetailsComponent,
    HelpFacebookCommonIdentityComponent,
    HelpGoogleAdsCustomerComponent,
    HelpGoogleAdsManagedCustomerComponent,
    ModalGlobalErrorComponent,
    HelpYoutubeSingleChannelCommonDetailsComponent,
    HelpYoutubeSingleChannelCommonChecklistComponent,
    HelpYoutubeSingleChannelCommonIdentityComponent,
    HelpDrawerDefaultComponent,
    ModalOauthButtonLoadingComponent,
    ModalDeactivateDestinationComponent,
    ModalDeactivateSourceComponent,
    ModalSiteUpdateRefreshComponent,
    IdentityWizardComponent,
    HelpCommonChecklistComponent,
    HelpCommonDetailsComponent,
    HelpCommonIdentityComponent,
    HelpYoutubeMultiChannelCommonChecklistComponent,
    HelpYoutubeMultiChannelCommonDetailsComponent,
    HelpYoutubeMultiChannelCommonIdentityComponent,
    LoadingNotifierComponent,
    PipelineInputComponent,
    ModalGeneralProgressComponent,
    ModalMemberInviteComponent,
    CheckListComponent,
    CheckListCardComponent,
    HelpDatabricksChecklistComponent,
    HelpDatabricksDetailsComponent,
    ModalCommonComponent,
    ModalGeneralErrorComponent,
    ModalRefreshTokenCreateComponent,
    ModalRefreshTokenRevokeComponent,
    ModalRefreshTokenDisplayComponent,
    HelpCsvValidationSchemaGenerationComponent,
    HelpCsvBatchLoaderChecklistComponent,
    HelpCsvBatchLoaderTesterComponent,
    HelpCsvBatchLoaderDetailsComponent,
    HelpCsvBatchLoaderAuthorizationComponent,
    FileInputComponent,
    BannerSupportProxyComponent,
    ModalThirdPartyIdentityComponent,
    ModalPipelineDetailsComponent,
    S3SignedUploaderComponent,
    ModalEditAliasComponent,
    BreadcrumbComponent,
    FlagFromRegionIdPipe,
    IdentityLabelComponent,
    ModalProcessJobRequestComponent,
    ModalJobViewComponent,
    HelpJobsComponent,
    ConvertCronExpressionPipe,
    FilterJobsComponent,
    SubscriptionInfoLabelComponent,
    FilterNavJobsComponent,
    ProductSupportsHistoryPipe,
    LoadingClockSpinnerComponent,
    ModalGenericConfirmComponent,    
    ProductTableSelectorComponent,
    ProductTableCheckboxComponent,
    ModalTemplateGenerationComponent,
    ModalDatasetTemplateDeleteComponent,
    CalculateReportDateForJobsPipe,
  ],
  exports: [
    ChartComponent,
    ChartPieComponent,
    ChartBarComponent,
    ChartLineComponent,
    ChartDoughnutComponent,
    ChartSparklineComponent,
    CardDailyPipelinesComponent,
    CardCreatePipelineComponent,
    CardTrialPlanComponent,
    FilterNavComponent,
    FilterNavIdentitiesComponent,
    FilterNavPipelinesComponent,
    FilterFormComponent,
    FilterIdentitiesComponent,
    FilterJobTransactionsComponent,
    FilterPipelinesComponent,
    FilterPaginationComponent,
    FilterCardPaginationComponent,
    FilterServerSidePaginationComponent,
    FilterTransactionsComponent,
    FilterJobsComponent,
    FlashNotificationsComponent,
    FormListIdentityComponent,
    HelpDrawerComponent,
    IdentityOauthCreateComponent,
    IdentityBigqueryCreateComponent,
    IdentityMwsCreateComponent,
    IdentityTypeNameFromIdentityTypeIdPipe,
    IntegrationFullnamePipe,
    IntegrationNameFromProductIdPipe,
    IntegrationPathFromProductIdPipe,
    IntegrationIsConfigurablePipe,
    LoadingSpinnerComponent,
    LogoPathFromIdentityTypeIdPipe,
    LogoPathFromProductIdPipe,
    ObjectHasKeyPipe,
    RemoveCommaPipe,
    TruncatePipe,
    CustomDatePipe,
    IconForNotificationPipe,
    RemoveAllWhiteSpacePipe,
    SortArrayPipe,
    ComingSoonIntegrationPipe,
    CanRequestHistoryPipe,
    ProductSupportsHistoryPipe,
    CanViewPipelineDetailsPipe,
    BooleanFalseToNullPipe,
    BooleanTrueToNullFalseToTruePipe,
    IntegrationDocLinkPipe,
    FlagFromRegionIdPipe,    
    CalculateReportDateForJobsPipe,
    PickListComponent,
    PickListCardComponent,
    DisplayListComponent,
    DisplayListCardComponent,
    PickListFilterComponent,
    StepCounterComponent,
    WizardHeaderComponent,
    EmptyNotifierComponent,
    ModalSiteUpdateRefreshComponent,
    IdentityWizardComponent,
    LoadingNotifierComponent,
    PipelineInputComponent,
    CheckListComponent,
    CheckListCardComponent,
    FileInputComponent,
    BannerSupportProxyComponent,
    S3SignedUploaderComponent,
    BreadcrumbComponent,
    IdentityLabelComponent,
    ConvertCronExpressionPipe,
    SubscriptionInfoLabelComponent,
    FilterNavJobsComponent,
    LoadingClockSpinnerComponent,    
    ProductTableSelectorComponent,
    ModalTemplateGenerationComponent,
    ModalDatasetTemplateDeleteComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    ToastrModule.forRoot(
      {
        positionClass: 'toast-top-right',
        closeButton: true,
        preventDuplicates: true,
        disableTimeOut: true,
        tapToDismiss: true,
        newestOnTop: true,  
        enableHtml: true    
      }
    ),
    NgxDropzoneModule
  ]
})
export class SharedModule { }
