import { Injectable } from '@angular/core';
import { fileInstandDownload } from 'src/app/core/functions/files';


@Injectable({
  providedIn: 'root'
})
export class CsvExportService {

  constructor() { }

  createExportableArrayFromHeaders(headers: any, dataArray: any[]): any {
    const headerKeys = Object.keys(headers);
    const newData = [];

    for (const dataLine of dataArray) {
      const exportable = {};
      for (const key of headerKeys) {

        if (key === 'status' && dataLine[key] === 'cancelled') {
          exportable[key] = 'inactive';
        }
        else if (key === 'productBadge' && dataLine[key] === 'none') {
          exportable[key] = '';
        }
        else {
          exportable[key] = dataLine[key];
        }
      }

      newData.push(exportable);
    }

    return newData;
  }

  convertToCSV(objArray: any): any {
    const dataArray = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < dataArray.length; i++) {
      const keys = Object.keys(dataArray[i]);
      let line = '';
      for (const key of keys) {
        if (line !== '') { line += ','; }
        line += dataArray[i][key];
      }
      str += line + '\r\n';
    }

    return str;
  }

  exportCSVFile(headers: any, items: any[], fileTitle?: string): void {
    if (headers) {
        items.unshift(headers);
    }

    // Convert Object to JSON
    const jsonObject = JSON.stringify(items);
    const csv = this.convertToCSV(jsonObject);

    let exportedFilename = 'openbridge-export.csv';

    if (fileTitle) {
       exportedFilename = fileTitle + '.csv';
    }

    fileInstandDownload(exportedFilename, csv, 'text/csv;charset=utf-8;');

  //   const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  //   const link = document.createElement("a");
  //   if (link.download !== undefined) { // feature detection
  //       // Browsers that support HTML5 download attribute
  //       const url = URL.createObjectURL(blob);
  //       link.setAttribute('href', url);
  //       link.setAttribute('download', exportedFilenmae);
  //       link.style.visibility = 'hidden';
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //   }
  // }
  }
}
