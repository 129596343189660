<app-help-drawer></app-help-drawer>

<div class="container">
  <div class="row justify-content-center">
    <div class="col-12">
      <!-- Header -->
      <div class="header">
        <div class="header-body">
          <div class="row align-items-top">
            <div class="col-auto">
              <!-- Avatar -->
              <div class="avatar avatar-lg">
                <span class="avatar-title rounded-circle text-wait" style="font-size: 1.9rem;">
                  <i class="fa-duotone fa-key-skeleton-left-right"></i>
                </span>
              </div>
            </div>
            <div class="col ms-n3 ms-md-n2">
              <!-- Pretitle -->
              <h6 class="header-pretitle"> Refresh Tokens </h6>
              <!-- Title -->
              <h1 class="header-title"> API Refresh Tokens </h1>
              <p class="text-muted">Listing current valid refresh tokens. See the <a href id="helpDrawer"
                  (click)="toggleHelpDrawer('refreshtokens')"><i class="fa-duotone text-info fa-beat fa-circle-question"
                    style="--fa-animation-duration: .8s; --fa-animation-iteration-count: 5;"></i></a> guide for
                moreinfo.</p>
            </div>
            <div class="col-auto">
              <!-- Buttons -->
              <!-- <a (click)="createToken()" class="btn btn-primary lift" id="createPipelineBtn"> Create Refresh Token <i
                  class="fa-duotone fa-right-from-line fa-lg ps-1"></i></a> -->
            </div>
          </div> <!-- / .row -->
        </div>
      </div>

      <div class="card">
        <div class="card-header">

          <div class="row align-items-center">
            <div class="col">
              <!-- Title -->
              <h4 class="card-header-title">
                Tokens
              </h4>
            </div>

            <!-- <div class="col-auto me-n3">
              <form>
                <select class="form-select form-select-sm cursor-pointer" placeholder="Select Sort Order" id="sortOrder"
                  name="sortOrder" #item (change)="sort(item.value)">
                  <option value="desc" selected>Sort Order</option>
                  <option value="name">Name</option>
                  <option value="created_at">Creation Date</option>
                </select>
              </form>
            </div> -->

            <div class="col-auto">
              <!-- Button -->
              <a (click)="createToken()" class="btn btn-sm btn-primary" id="createRefreshTokenBtn">
                Create Refresh Token
              </a>
            </div>
          </div>

        </div>

        <div class="card-body">
          <!-- List -->
          <ul class="list-group list-group-lg list-group-flush list my-n4">
            <ng-container *ngIf="refreshTokens.length > 0">
              <ng-container *ngFor="let token of refreshTokens; let i = index; let last = last">
                <li class="list-group-item">
                  <div class="row align-items-center">

                    <div class="col-auto">
                      <div class="avatar">
                        <span class="avatar-title rounded-circle text-wait" style="font-size: 1.3rem;">
                          <i class="fa-duotone fa-key"></i>
                        </span>
                      </div>
                    </div>

                    <div class="col ms-n2">
                      <!-- Title -->
                      <h4 class="mb-1">
                        <span class="text-muted">
                          Name:
                        </span>
                        <span id="refreshTokenName" [tooltip]="token.attributes['name']">
                          {{ ( token.attributes['name'] || 'unnamed' ) | truncate:[50, '...'] }}
                        </span>
                      </h4>

                      <!-- Text -->
                      <small>
                        <span class="text-muted">
                          Date of Creation:
                        </span>
                        <span id="refreshTokenCreatedAt">
                          {{ token.attributes['created_at'] | customDate }}
                        </span>
                      </small>
                    </div>

                    <div class="col-auto">
                      <!-- dropdown -->
                      <div class="btn-group" dropdown #dropdown="bs-dropdown" container="body">
                        <a dropdownToggle (click)="clickDropdown($event, dropdown)" type="button"
                          class="dropdown-ellipses  dropdown-toggle" data-display="static">
                          <i class="fe fe-more-vertical"></i>
                        </a>
                        <div *dropdownMenu class="dropdown-menu dropdown-menu-right"
                          [ngClass]="{'dropdown-up': last == true }" role="menu">
                          <a href (click)="$event.preventDefault(); revokeAccess(token)" class="dropdown-item">
                            Revoke </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="refreshTokens.length === 0">
              <li class="list-group-item">
                <div class="text-center" id="emptyRefreshToken">
                  You have no refresh tokens.
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>

      <!-- Tab content -->
      <!-- <div class="tab-content">
        <div class="tab-pane fade" id="refreshKeysListTab" role="tabpanel" aria-labelledby="refreshKeysListTab"
          [ngClass]="listClasses">
          <div class="card" id="companiesList">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col">

                  <h4 class="card-header-title">
                    Tokens
                  </h4>
                </div>
                <div class="col-auto">

                  <a href="#!" class="btn btn-sm btn-white">
                    Create
                  </a>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-sm table-hover table-nowrap card-table">
                <thead>
                  <tr>
                    <th>
                      <a class="list-sort text-muted" data-sort="item-name" (click)="sort('name')" href>Name</a>
                    </th>
                    <th>
                      <a class="list-sort text-muted" data-sort="item-industry" (click)="sort('created_at')"
                        href>Creation Date</a>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <ng-container *ngIf="refreshTokens.length === 0">
                  <tbody class="list font-size-base">
                    <tr>
                      <td colspan="4" class="text-center" id="emptySubscription">
                        You have no refresh tokens.
                      </td>
                    </tr>
                  </tbody>
                </ng-container>
                <ng-container *ngIf="refreshTokens.length > 0">
                  <tbody>
                    <ng-container *ngFor="let token of refreshTokens; let i = index; let last = last">
                      <tr>
                        <td>{{ token.attributes['name'] || 'unnamed' }}</td>
                        <td>{{ token.attributes['created_at'] | customDate }}</td>
                        <td class="text-end">
                          <div class="btn-group" dropdown #dropdown="bs-dropdown" container="body">
                            <a dropdownToggle (click)="clickDropdown($event, dropdown)" type="button"
                              class="dropdown-ellipses dropdown-toggle" data-display="static">
                              <i class="fe fe-more-vertical"></i>
                            </a>
                            <div *dropdownMenu class="dropdown-menu dropdown-menu-right"
                              [ngClass]="{'dropdown-up': last == true }" role="menu">
                              <a href (click)="$event.preventDefault(); revokeAccess(token.id)" class="dropdown-item">
                                Revoke </a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </ng-container>
              </table>
            </div>
          </div>
        </div>
      </div> -->

    </div>
  </div>
</div>