<div class="row">
  <div class="col-auto align-self-start">
    <!-- Avatar -->
    <div class="avatar avatar-lg">
      <img [src]="cardData.logoUri" [alt]="cardData.logoAltText" [title]="cardData.logoAltText"
        class="avatar-img rounded" (error)="imageServiceService.handleMissingImage($event)">
    </div>
  </div>
  <div class="col align-self-center ms-n2">
    <!-- Title -->
    <h4 class="mb-1 name text-white">
      {{ cardData.title }}
      <span *ngIf="cardData.new" class="badge bg-success-soft ms-2">New</span>
    </h4>

    <ng-container *ngFor="let metaData of cardData.meta; index as metaIdx;">
      <!-- Text -->
      <ng-container *ngIf="metaData | objectHasKey: 'link' ; else nolink">
        <p class="card-text small text-muted mb-1"> {{ metaData.name }}: <a [href]="metaData.link" target="_blank"
            rel="noopener">{{ metaData.value }}</a>
      </ng-container>
      <ng-template #nolink>
        <p class="card-text small text-muted mb-1">
          {{ metaData.name }}:
          <span [tooltip]="metaData.value" placement="auto">
            {{ metaData.value | truncate:[230, '...'] }}
          </span>
        </p>
      </ng-template>
    </ng-container>
  </div>
  <div class="col-auto align-self-center">
    <button type="button" *ngIf="!selected" (click)="toggleSelection()" class="btn btn-sm btn-white d-none d-md-inline-block">Select</button>
    <button type="button" id="selectedBtn" *ngIf="selected" (click)="toggleSelection()" class="btn btn-sm btn-outline-success d-none d-md-inline-block" [attr.disabled]="!allSelected | booleanTrueToNullFalseToTruePipe">
      <!-- <i class="fe fe-check"></i> -->
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 28 28" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-check"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M5 12l5 5l10 -10"></path>
        </svg>
      </span>
      <span class="me-2">
        Selected
      </span>
    </button>
  </div>
  <div class="col-auto align-self-center">
    <!-- Dropdown -->
    <p class="card-text small text-white">
      <span class="text-success">●</span> Active
    </p>
  </div>
</div> <!-- / .row -->