<label [for]="innerId" class="w-100">
  <div class="input-group input-group-merge">
    <div class="form-control d-flex align-content-stretch p-0" [ngClass]="ngClass">
      <div class="h-100 d-flex align-items-center" style="padding: 0.5rem 0.75rem;">
        <ng-container *ngIf="mode === 'edit'; else chooseFile">
          Update File
        </ng-container>
        <ng-template #chooseFile>
          Choose File
        </ng-template>
      </div>
      <div class="flex-fill">
        <div class="h-100 w-100 d-flex align-items-center" style="background-color: #283e59;padding: 0.5rem 0.75rem;">
          <!-- <ng-container *ngIf="mode === 'edit' else noFileChoosen">
            Use Existing configuration
          </ng-container>
          <ng-template #noFileChoosen>
            No file chosen
          </ng-template> -->
          {{ containerMessage }}
        </div>
      </div>
    </div>
    <div class="input-group-text" style="background-color: #283e59">
      <ng-container [ngSwitch]="httpTransactionState">
        <ng-container class="fade" *ngSwitchCase="'complete-success'">
          <!-- success state -->
          <span>
            <i class="fa-solid fa-circle-check fa-lg" style="color: #1d9160;"></i>
          </span>
        </ng-container>
        <ng-container class="fade" *ngSwitchCase="'complete-error'">
          <!-- error state -->
          <span>
            <i class="fa-solid fa-circle-exclamation" style="color: #bd2642;"></i>
          </span>
        </ng-container>
        <ng-container class="fade" *ngSwitchCase="'processing'">
          <!-- loading state -->
          <div class="spinner-border spinner-border-sm text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </ng-container>
        <ng-container class="fade" *ngSwitchDefault></ng-container>
      </ng-container>
    </div>

  </div>
</label>
<input type="file" [id]="innerId" style="display:none" (change)="fileToUploadChange($event)">
