import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-pick-list',
  templateUrl: './pick-list.component.html'
})
export class PickListComponent implements AfterViewInit {
  @Input() cardData = [];
  @Input() parentForm = UntypedFormGroup;
  @Input() controlName: string = null;

  constructor(
    private changeDetection: ChangeDetectorRef
 ){}

  ngAfterViewInit(): void {
    this.changeDetection.detectChanges();
  }
}
