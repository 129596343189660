<div class="card">
  <div class="card-body">
    <div class="row align-items-center">
      <div class="col-auto">
        <div class="avatar avatar-md">
          <span class="avatar-title rounded-circle text-success" style="font-size: 1.5rem;">
            <i class="fa-duotone fa-circle-plus"></i></span>
        </div>

      </div>
      <div class="col ms-n2">
        <!-- Text -->
        <!-- Badge -->
        <span class="badge bg-primary-soft mt-n1 mb-2">
          Active
        </span>
        <!-- Title -->
        <h2 class="mb-0" id="pipeline-card-header">
          {{ status === 100 ? 'Account Configured, Pipelines Running' : 'Activate A Data Pipeline' }}
        </h2>
        <!-- Text -->
        <div [ngSwitch]="status">
          <p class="text-muted mb-2">
            <!-- Create your first data pipeline by activating a source to
                    destination -->
            <span *ngSwitchCase="0" id="pipeline-card-tagline">Create a destination to store your data</span>
            <span *ngSwitchCase="50" id="pipeline-card-tagline">Create your first data pipeline</span>
            <span *ngSwitchCase="100" id="pipeline-card-tagline">Congratulations! <a routerLink="/sources">Create</a>
              another data pipeline</span>
            <span *ngSwitchDefault id="pipeline-card-tagline">Create a destination to store your data</span>
          </p>
        </div>
        <!-- Progress -->
        <div class="row align-items-center g-0">
          <div class="col-auto">
            <!-- Value-->
            <div class="small me-2" id="pipeline-status"> {{status}}% </div>
            <!-- <div class="small me-2">50%</div> -->
          </div>
          <div class="col">
            <!-- Progress -->
            <div class="progress progress-sm">
              <div class="progress-bar" role="progressbar" [ngClass]="{
                            'status1-progress-bar-width': status === 0, 
                            'status2-progress-bar-width' : status === 50, 
                            'status3-progress-bar-width' : status === 100
                          }" aria-valuemin="0" aria-valuemax="100">
              </div>
            </div>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <div class="col-auto" *ngIf="status != 100">
        <!-- Button -->
        <a routerLink="/sources" class="btn btn-white"> Create </a>
      </div>
    </div>
    <!-- / .row -->
  </div>
  <!-- / .card-body -->
</div>