<div class="row align-items-center">
  <div class="col">
    <!-- Form -->
    <form>
      <div class="input-group input-group-flush input-group-merge input-group-reverse">
        <input class="form-control list-search" type="search" placeholder="Search" [formControl]="filterSearch" id="searchInput"
        (keyup)="processFilteredText(filterSearch.value)">
        <span class="input-group-text">
          <i class="fe fe-search"></i>
        </span>
      </div>
    </form>
  </div>

  <div class="col-auto me-n3">
    <!-- Select -->
    <form>
      <select class="form-select form-select-sm cursor-pointer" placeholder="select page size" id="pageSize" [(ngModel)]="pageSize"
        name="pageSize" #item (change)="updatePageEntries(item.value)">
        <option value="10" selected>10 per page</option>
        <option value="25">25 per page</option>
        <option value="50">50 per page</option>
      </select>
    </form>

  </div>

  <div class="col-auto">
  
    <!-- Dropdown -->
    <div class="dropdown" dropdown #dropdown="bs-dropdown" [insideClick]="true">
  
      <!-- Toggle -->
      <button dropdownToggle class="btn btn-sm btn-white" type="button">
        <i class="fe fe-sliders me-1"></i> Filter <span class="badge bg-primary ms-1 d-none">0</span>
      </button>
  
      <!-- Menu -->
      <form *dropdownMenu class="dropdown-menu dropdown-menu-end dropdown-menu-card" style="border: 1px solid #95aac9;">
        <div class="card-header">
  
          <!-- Title -->
          <h4 class="card-header-title">
            Filters
          </h4>
  
          <!-- Link -->
          <button class="btn btn-sm btn-link text-reset d-none" type="reset">
            <small>Clear filters</small>
          </button>
  
        </div>
        <div class="card-body">
  
          <!-- List group -->
          <div class="list-group list-group-flush mt-n4 mb-4">
            <div class="list-group-item">
              <div class="row">
                <div class="col">
  
                  <!-- Text -->
                  <small>Status</small>
  
                </div>
                <div class="col-auto">
  
                  <!-- Select -->
                  <select class="form-select form-select-sm cursor-pointer form-select-custom" placeholder="select type" [(ngModel)]="activeStatusType" name="activeStatusType">
                    <option value="all" selected>All</option>
                    <option value="valid">Valid</option>
                    <option value="invalid">Invalid</option>
                  </select>
  
                </div>
              </div> <!-- / .row -->
            </div>
            <div class="list-group-item">
              <div class="row">
                <div class="col">
  
                  <small>Type</small>
  
                </div>
                <div class="col-auto">
  
                  <select class="form-select form-select-sm cursor-pointer form-select-custom" [(ngModel)]="activeIdentityTypeFilter" name="activeIdentityTypeFilter">
                    <option value="all" selected>Any</option>
                    <option *ngFor="let identity of identityList" [ngValue]="identity.remoteIdentityTypeId">
                      {{ identity.remoteIdentityTypeName }}
                    </option>                   
                  </select>
  
                </div>
              </div>
            </div>
            <!-- <div class="list-group-item">
              <div class="row">
                <div class="col">
  
                  <small>Lead scrore</small>
  
                </div>
                <div class="col-auto">
  
                  <select class="form-select form-select-sm" name="item-score">
                    <option value="*" selected>Any</option>
                    <option value="1/10">1+</option>
                    <option value="2/10">2+</option>
                    <option value="3/10">3+</option>
                  </select>
  
                </div>
              </div>
            </div> -->
          </div>
  
          <!-- Button -->
          <button class="btn w-100 btn-primary" type="submit" (click)="applyFilter($event, dropdown)">
            Apply filter
          </button>
  
        </div>
      </form>
  
    </div>
  
  </div>
  <!-- <div class="col-auto me-n3">
    <!-- Select ->
    <form>
      <select class="custom-select custom-select-sm form-control-flush" data-bs-toggle="select"
              data-options='{"minimumResultsForSearch": -1}'>
        <option value="5" selected>5 per page</option>
        <option value="10">10 per page</option>
        <option value="*">All</option>
      </select>
    </form>
  </div> -->
  <!-- <div class="col-auto">
    <!-- Dropdown ->
    <div class="dropdown">
      <!-- Toggle ->
      <button class="btn btn-sm btn-white" type="button" data-bs-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
        <i class="fe fe-sliders me-1"></i> Filter <span class="badge badge-primary ms-1">1</span>
      </button>
      <!-- Menu ->
      <form class="dropdown-menu dropdown-menu-right dropdown-menu-card">
        <div class="card-header">
          <!-- Title ->
          <h4 class="card-header-title"> Filters </h4>
          <!-- Link ->
          <button class="btn btn-sm btn-link text-reset" type="reset">
            <small>Clear filters</small>
          </button>
        </div>
        <div class="card-body">
          <!-- List group ->
          <div class="list-group list-group-flush mt-n4 mb-4">
            <div class="list-group-item">
              <div class="row">
                <div class="col">
                  <!-- Text ->
                  <small>Title</small>
                </div>
                <div class="col-auto">
                  <!-- Select ->
                  <select class="custom-select custom-select-sm" name="item-title" data-bs-toggle="select"
                          data-options='{"minimumResultsForSearch": -1}'>
                    <option value="*">Any</option>
                    <option value="Designer" selected>Designer</option>
                    <option value="Developer">Developer</option>
                    <option value="Owner">Owner</option>
                    <option value="Founder">Founder</option>
                  </select>
                </div>
              </div> <!-- / .row ->
            </div>
            <div class="list-group-item">
              <div class="row">
                <div class="col">
                  <!-- Text ->
                  <small>Lead scrore</small>
                </div>
                <div class="col-auto">
                  <!-- Select ->
                  <select class="custom-select custom-select-sm" name="item-score" data-bs-toggle="select"
                          data-options='{"minimumResultsForSearch": -1}'>
                    <option value="*" selected>Any</option>
                    <option value="1/10">1+</option>
                    <option value="2/10">2+</option>
                    <option value="3/10">3+</option>
                    <option value="4/10">4+</option>
                    <option value="5/10">5+</option>
                    <option value="6/10">6+</option>
                    <option value="7/10">7+</option>
                    <option value="8/10">8+</option>
                    <option value="9/10">9+</option>
                    <option value="10/10">10</option>
                  </select>
                </div>
              </div> <!-- / .row ->
            </div>
          </div>
          <!-- Button ->
          <button class="btn btn-block btn-primary" type="submit"> Apply filter </button>
        </div>
      </form>
    </div>
  </div> -->
</div> <!-- / .row -->