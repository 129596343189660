import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-filter-nav-jobs',
  templateUrl: './filter-nav-jobs.component.html',
  styleUrls: ['./filter-nav-jobs.component.scss']
})
export class FilterNavJobsComponent implements OnInit {
  @Input() subscriptionId: string | null = null;
  constructor() { }

  ngOnInit(): void {
  }


}
