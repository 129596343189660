import { HttpClient } from '@angular/common/http';
import { EncryptionService } from 'src/app/core/services/encryption.service';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AccMappingTypes } from 'src/app/shared/models/accmapping.interface';
import { environment  } from 'src/environments/environment';
import { observableToPromise } from '../functions/utility';

@Injectable({
  providedIn: 'root'
})
export class AccmappingService {

  testMapCreationState$: Subject<boolean>;

  constructor(
    private httpClient: HttpClient,
    private encryptionService: EncryptionService
  ) {
    this.testMapCreationState$ = new Subject<boolean>();
  }

  public async createAccountMapping(payload: AccMappingTypes, isTestStorage: boolean): Promise<{
    path: string;
    isTestStorage: boolean;
  }> {

    // Create the response object definition.
    const response: {
      path: string,
      isTestStorage: boolean
    } = { path: null, isTestStorage: isTestStorage };

    const encryptedPayload = await this.encryptionService.encryptAccountMappingPayload(payload);

    // tslint:disable-next-line: max-line-length
    const accMappingPostUri = environment.openbridgeApiUris.service + '/service/accmapping/' + environment.openbridgeApiUris.partialIdentifier + '/v2/accmapping/' + encodeURIComponent(payload.data.attributes.path);

    const postResponse = await observableToPromise(this.httpClient.post(accMappingPostUri, encryptedPayload, { observe: 'response' }));

    response.path = payload.data.attributes.path;

    return response;
  }

  public generateAccmappingPath(storageKey: string): string {
    return '/ebs/ftpd/' + storageKey;
  }

  public generateStorageKey(accountId: number, isTestStorage: boolean = false): string {
      return this.generateTempStorageKey(accountId, isTestStorage);
  }

  private generateTempStorageKey(accountId: number, isTestStorage: boolean = false): string {
    const storageKey = String(Date.now());
    return this.createStorageKeyString(accountId, storageKey, isTestStorage);
  }

  private createStorageKeyString(accountId: number, storageKey: string, isTestStorage: boolean = false): string {
    const testPrefix = (isTestStorage) ? 'test-' : '';
    return testPrefix + storageKey + '-' + this.padNumber(String(accountId), 6, '0');
  }

  private padNumber(num: string, padlen: number, padchar: string = '0'): string {
    const pad = new Array(1 + padlen).join(padchar);
    return (pad + num).slice(-pad.length);
  }

}
