<!-- Image -->
<div class="avatar avatar-sm card-avatar">
  <img src="{{integration.logoPath}}" class="avatar-img rounded" alt="..."
    (error)="imageServiceService.handleMissingImage($event)">
</div>
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader"> Getting Started </h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline"> Fully-managed data pipeline into {{ integration |
  integrationFullname }} </p>
<!-- Divider -->
<hr class="mb-4">
<!-- Heading-->
<h4 class="mt-2 mb-2"> Before you get started activating Snowflake... </h4>
<ol>
  <li class="text-muted mb-2"> The configuration process assumes you have an active, billing enabled Snowflake account.
    Snowflake offers <a rel="noopener" target="_blank" href="https://signup.snowflake.com/"> free trials and service
      credits</a> for new customers. </li>
  <li class="text-muted mb-2"> Make sure your Snowflake account is billing enabled. If billing is not enabled it can
    disrupt our ability to deliver data. </li>
  <li class="text-muted mb-2"> You can use our <code>snowflake.sql</code> set up script to automate configuration of
    your Snowflake environment. See our guide <a
      href="https://docs.openbridge.com/en/articles/5024964-how-to-setup-snowflake-data-destination" rel="noopener"
      target="_blank">How To Setup Snowflake Data Destination</a> </li>
  <li class="text-muted mb-2"> If you have set up Snowflake manually or used the <code>snowflake.sql</code> script, make
    sure you have you taken note of the <code>username</code>, <code>password</code>, <code>account id</code>,
    <code>warehouse name</code> and <code>database name</code>. You will need this information in the next step.
  </li>
  <li class="text-muted mb-2"> If you have a Snowflake network policy you must whitelist our IP Addresses:
    <code>52.54.227.22</code> and <code>52.2.68.68</code> or the connection will be blocked
  </li>
  <li class="text-muted mb-2">See the {{ integration | integrationFullname }} <a
      href="{{ integration | integrationDocLink}}" rel="noopener" target="_blank">help docs</a> for
    guides, tips, and troubleshooting</li>
</ol>