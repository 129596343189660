<div class="card">
  <div class="card-body" id="planLoading" *ngIf="stripeCustomerData == null">
    Loading plan details...
  </div>
  <div class="card-body" *ngIf="stripeCustomerData != null">
    <!-- trial customer start -->
    <div class="row align-items-center trialCustomer" *ngIf="isTrialCustomer">
      <div class="col-auto">
        <!-- Avatar -->
        <div class="avatar avatar-md">
          <span class="avatar-title rounded-circle text-warning" style="font-size: 1.5rem">
            <i class="fa-duotone fa-up-from-line"></i></span>
        </div>
      </div>
      <div class="col ms-n2">
        <!-- Badge -->
        <span class="badge bg-warning-soft mt-n1 mb-2"> Upgrade </span>
        <!-- Title -->
        <h2 class="mb-0" id="planHeader">
          {{
          daysDifferenceFromNow > 0
          ? "Trial Plan: " + daysDifferenceFromNow + " Days Left"
          : "Trial Plan is Expired"
          }}
        </h2>
        <!-- Text -->
        <p class="text-muted mb-2" id="planTagline">
          {{ getInnerText(daysInActivePlan, daysDifferenceFromNow) }}
        </p>
        <ng-container *ngIf="stripeDetails.cancelAt;">
          <p class="text-muted mb-2" id="planCancelAt">
            Subscription ends on
            {{ dateTimeService.convertTimeStampToDate(stripeDetails.cancelAt,
            dateFormat) }}
          </p>
        </ng-container>
        <div class="row align-items-center g-0" *ngIf="daysDifferenceFromNow > 0">
          <div class="col-auto">
            <!-- Value-->
            <div class="small me-2" id="daysPassed">
              {{ calculateDaysPassed(daysInActivePlan, daysDifferenceFromNow) }}
              days in trial
            </div>
          </div>
          <div class="col">
            <!-- Progress -->
            <div class="progress progress-sm">
              <div class="progress-bar" role="progressbar" [style]="getProgressBarStyle()" aria-valuemin="0"
                aria-valuemax="100"></div>
            </div>
          </div>
        </div>
        <!-- / .row -->
      </div>

      <div class="col-auto">
        <!-- Button -->
        <a routerLink="/plans" class="btn btn-primary d-none d-md-inline-block">
          Upgrade <i class="fa-duotone fa-up-from-line ms-2"></i>
        </a>
      </div>
    </div>
    <!-- trial customer end -->

    <!-- paid customer start -->
    <div class="row align-items-center customerHavingSubscription" *ngIf="hasPaidSubscription">
      <div class="col-auto">
        <!-- Avatar -->
        <div class="avatar avatar-md">
          <span class="avatar-title rounded-circle text-success" style="font-size: 1.5rem">
            <i class="fa-duotone fa-tags"></i></span>
        </div>
      </div>
      <div class="col ms-n2">
        <!-- Badge -->
        <span class="badge bg-primary-soft mt-n1 mb-2"> Active </span>
        <!-- Title -->
        <h2 class="mb-0" id="planName">
          {{ stripeDetails.planName }}
        </h2>
        <!-- Text -->
        <ng-container *ngIf="stripeDetails.cancelAt; else normalBilling">
          <p class="text-muted mb-2" id="planCancelAt">
            Subscription ends on
            {{ dateTimeService.convertTimeStampToDate(stripeDetails.cancelAt,
            dateFormat) }}
          </p>
        </ng-container>
        <ng-template #normalBilling>
          <p class="text-muted mb-2" id="planCurrentPeriodEnds">
            Billing period ends on
            {{ dateTimeService.convertTimeStampToDate(stripeDetails.planCurrentPeriodEnds,
            dateFormat) }}
          </p>
        </ng-template>
        <div class="row align-items-center g-0">
          <div class="col-auto">
            <div class="small me-2" id="plan-status">
              {{ calculateDaysPassed(daysInActivePlan, daysDifferenceFromNow) }}
            </div>
          </div>
          <div class="col">
            <div class="progress progress-sm">
              <div class="progress-bar" role="progressbar" [style]="getProgressBarStyle()" aria-valuemin="0"
                aria-valuemax="100"></div>
            </div>
          </div>
        </div>
        <!-- / .row -->
      </div>

      <div class="col-auto">
        <!-- Button -->
        <a routerLink="/account/billing" class="btn btn-outline-primary d-none d-md-inline-block">
          Go To Billing <i class="fa-duotone fa-arrow-right-long"></i>
        </a>
      </div>
    </div>
    <!-- paid customer end -->

    <!-- plan expired start -->
    <div class="row align-items-center planExpired" *ngIf="hasSubscription == false">
      <div class="col ms-n2">
        <!-- Badge -->
        <span class="badge bg-warning-soft mt-n1 mb-2"> Upgrade </span>
        <!-- Title -->
        <h2 class="mb-1" id="planExpiredHeader">
          <a>Plan has Expired</a>
        </h2>
        <p class="text-muted" id="planExpiredTagline">
          An active plan unlocks your ability to create data pipelines.
          Selecting a plan is quick and easy.
        </p>
      </div>

      <div class="col-auto">
        <!-- Button -->
        <a routerLink="/plans" class="btn btn-warning d-none d-md-inline-block">
          Upgrade Now <i class="fa-duotone fa-arrow-from-left ms-2 fa-lg"></i>
        </a>
      </div>
    </div>
    <!-- plan expired end -->
    <!-- / .row -->
  </div>
  <!-- / .card-body -->
</div>