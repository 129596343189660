<div class="row align-items-center">
  <div class="col">
    <!-- Nav -->
    <ul class="nav nav-tabs nav-overflow header-tabs">
      <li class="nav-item">
        <a href (click)="setFilter('all')" class="nav-link text-nowrap" id="all"
          [ngClass]="{'active': activeFilterType == 'all' }"> All <span id="allJobsCount"
            class="badge rounded-pill bg-secondary-soft">{{
            allJobsCount }}</span>
        </a>
      </li>
      <li class="nav-item">
        <a href (click)="setFilter('primary')" class="nav-link text-nowrap" id="primary"
          [ngClass]="{'active': activeFilterType == 'primary' }"> Primary <span id="allPrimaryCount"
            class="badge rounded-pill bg-secondary-soft">{{ allPrimaryCount }}</span>
        </a>
      </li>
      <li class="nav-item">
        <a href (click)="setFilter('history')" class="nav-link text-nowrap" id="history"
          [ngClass]="{'active': activeFilterType == 'history'}"> History <span id="allHistoryCount"
            class="badge rounded-pill bg-secondary-soft">{{ allHistoryCount }}</span>
        </a>
      </li>
    </ul>
  </div>
</div>