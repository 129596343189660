import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImageServiceService {

  constructor() { }

  handleMissingImage(event: Event): void {
    (event.target as HTMLImageElement).style.display = 'none';
  }

}
