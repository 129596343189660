<!-- Image -->
<div class="avatar avatar-sm card-avatar">
  <img src="{{integration.logoPath}}" class="avatar-img rounded" alt="..."
    (error)="imageServiceService.handleMissingImage($event)">
</div>
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader"> Authorization </h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline"> Access to Authorization </p>
<!-- Divider -->
<hr class="mb-4">
<!-- Heading-->
<h4 class="mt-2 mb-2"> Getting ready for automated {{ integration | integrationFullname }} data pipelines </h4>
<ol>
  <li class="text-muted mb-2"> The permissions granted to us limit the access only to those profiles within an
    advertiser's account that a user has permission. The profile list is dynamically generated based on the response
    from the Google API. If you do not see a profile listed, you need to verify that the
    user has the correct access permissions. </li>

  <li class="text-muted mb-2">
    Accounts represent a single advertiser. All data is associated with this single account.
  </li>

</ol>