import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-clock-spinner',
  templateUrl: './loading-clock-spinner.component.html',
  styleUrls: ['./loading-clock-spinner.component.scss']
})
export class LoadingClockSpinnerComponent implements OnInit {
  @Input() showLoading: boolean;
  @Input() tooltipText: string = '';  
  constructor() { }

  ngOnInit(): void {
  }
}
