import { Integration } from '../../models/integration.model';
import { destinations } from './destinations';
import { opensource } from './opensource';
import { integrationAmazonAdvertisingBrandMetrics, integrationAmazonBrandStoreInsights, integrationAmazonAdvertisingMarketStream, integrationAmazonAdvertisingRecommendations, integrationAmazonSponsoredAdsV2, integrationAmazonSponsoredAdsV3,
         integrationAmazonAttribution, integrationAmazonDsp, 
         integrationAmazonDspV3,
         integrationAmazonAdvertisingMarketStreamV2} from './amz-ads-products';
import { integrationAmzVendorRealtimeAnalytics, integrationAmzVendorsBrandAnalyticsReports,
         integrationAmzVendorsRetailAnalytics, integrationAmzVendorsRetailProcurmentOrder } from './amz-vendor-products';
import { integrationFacebookMarketing, integrationFacebookPageInsights, integrationInstagramInsights,
         integrationInstagramStories } from './facebook-products';
import { integrationGoogleAds, integrationGoogleAnalytics360, integrationGoogleCampaignManager
        } from './google-products';
import { integrationAmzSellingBusRepFees, integrationAmzSellingBusRepFullfilment, integrationAmzSellingBusRepInventory,
         integrationAmzSellingBusRepInventoryRt, integrationAmzSellingBusRepSales, 
         integrationAmzSellingPartnersBrandAnalyticsReports, integrationAmzSellingPartnersFinanceRt, 
         integrationAmzSellingPartnersInbound, integrationAmzSellingPartnersInboundV2, integrationAmzSellingPartnersNotifications, integrationAmzSellingPartnersOrders, 
         integrationAmzSellingPartnersOrdersPii, 
         integrationAmzSellingPartnerSqp, 
         integrationAmzSellingPartnersSalesAndTraffic, integrationAmzSellingPartnersSettlement
       } from './amz-seller-partner-products';
import { integrationYoutubeChannelInsights, integrationYoutubeCompetitorChannels, integrationYoutubeCompetitorVideo,
         integrationYoutubeVideoInsights } from './youtube-products';
import { integrationAirbyte, integrationAmzCatalogApiAsin, integrationAmzCatalogApiKeyword, integrationAmzPricingApi, integrationAwsS3Source, integrationCsvBatchLoader, integrationShopifyInsights } from './other-products';

/**
 * All integration definitions are now stored in constants.  However desintations Array is needed in other files
 * so we kept it as an array to add here.  Desintations should for the time being continue to be added to the array
 * and and that array included here.
 */
export const sources: Integration[] = [
  ...destinations,
  ...opensource,
  integrationAmazonSponsoredAdsV2,
  integrationAmazonSponsoredAdsV3,
  integrationAmazonAdvertisingBrandMetrics,
  integrationAmazonBrandStoreInsights,
  integrationAmazonAdvertisingMarketStream,
  integrationAmazonAdvertisingMarketStreamV2,
  integrationAmazonAdvertisingRecommendations,  
  integrationAmazonAttribution,
  integrationAmazonDsp,
  integrationAmazonDspV3,
  integrationAmzVendorsRetailAnalytics,
  integrationAmzVendorsRetailProcurmentOrder,
  integrationAmzVendorsBrandAnalyticsReports,
  integrationAmzVendorRealtimeAnalytics,
  integrationAmzPricingApi,
  integrationAmzCatalogApiKeyword,
  integrationAmzCatalogApiAsin,
  integrationAwsS3Source,
  integrationFacebookMarketing,
  integrationFacebookPageInsights,
  integrationInstagramInsights,
  integrationInstagramStories,
  integrationGoogleAds,
  integrationGoogleAnalytics360,
  integrationGoogleCampaignManager,
  integrationAmzSellingPartnersOrders,
  integrationAmzSellingPartnersOrdersPii,  
  integrationAmzSellingPartnersFinanceRt,
  integrationAmzSellingPartnersInbound,
  integrationAmzSellingPartnersInboundV2,
  integrationAmzSellingPartnersSettlement,
  integrationAmzSellingBusRepFullfilment,
  integrationAmzSellingBusRepInventoryRt,
  integrationAmzSellingBusRepInventory,
  integrationAmzSellingBusRepSales,
  integrationAmzSellingBusRepFees,
  integrationAmzSellingPartnersSalesAndTraffic,
  integrationAmzSellingPartnersBrandAnalyticsReports,
  integrationAmzSellingPartnerSqp,
  integrationAmzSellingPartnersNotifications,
  integrationYoutubeChannelInsights,
  integrationYoutubeCompetitorChannels,
  integrationYoutubeVideoInsights,
  integrationYoutubeCompetitorVideo,
  integrationCsvBatchLoader,
  integrationAirbyte,
  integrationShopifyInsights
];

