import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-filter-nav-pipelines',
  templateUrl: './filter-nav-pipelines.component.html'
})
export class FilterNavPipelinesComponent implements OnInit, OnChanges {
  @Input() pipelines: any[] = [];
  @Output() filteredPipelines = new EventEmitter<any[]>();
  @Output() resetOffset = new EventEmitter<number>();
  allPipelinesCount: number;
  allSourcesCount: number;
  allDestinationsCount: number;
  activeFilterType: 'all' | 'source' | 'destination' = 'all';

  constructor() { }

  ngOnInit(): void {
    this.allPipelinesCount = this.pipelines.length;
    this.allSourcesCount = this.countPipelinesByType('source');
    this.allDestinationsCount = this.countPipelinesByType('destination');
    this.setFilter('all');
  }

  ngOnChanges(): void {
    this.allPipelinesCount = this.pipelines.length;
    this.allSourcesCount = this.countPipelinesByType('source');
    this.allDestinationsCount = this.countPipelinesByType('destination');
    this.setFilter(this.activeFilterType);
  }

  countPipelinesByType(filterType: 'source' | 'destination' | 'enterprise'): number {
    let count = 0;
    this.pipelines.forEach((val, idx) => {
      if (filterType === 'source' && val.isStorage === false) {
        count++;
      }
      if (filterType === 'destination' && val.isStorage === true) {
        count++;
      }
    });
    return count;
  }

  setFilter(filterType: 'all' | 'source' | 'destination'): boolean {
    this.activeFilterType = filterType;
    let filteredPipelines: any[] = [];
    if (filterType === 'all') {
      filteredPipelines = [...this.pipelines];
    }
    else {
      this.pipelines.forEach((val, idx) => {

        if (filterType === 'source' && val.isStorage === false) {
          filteredPipelines.push(val);
        }
        if (filterType === 'destination' && val.isStorage === true) {
          filteredPipelines.push(val);
        }
      });
    }

    this.filteredPipelines.emit(filteredPipelines);
    // Return false to prevent default.
    return false;
  }

  resetOffsetValue(filter: any): boolean {
    this.resetOffset.emit(0);
    this.setFilter(filter);

    return false;
  }
}
