import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Integration } from 'src/app/shared/models/integration.model';

@Component({
  selector: 'app-modal-oauth-button-loading',
  templateUrl: './modal-oauth-button-loading.component.html',
  styleUrls: ['./modal-oauth-button-loading.component.scss']
})
export class ModalOauthButtonLoadingComponent implements OnInit {
  integration: Integration;
  constructor(
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit(): void {
  }

}
