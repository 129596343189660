<hr>
<div class="row">
    <div class="col-12 col-xl-12">
        <!-- Card -->
        <div>
            <p class="mb-4 text-center">
                <span data-cy="empty-notifier-message">
                    {{ message }}
                </span>
            </p>
        </div>
    </div>
</div>