import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store/reducers';

@Component({
  selector: 'app-card-create-pipeline',
  templateUrl: './card-create-pipeline.component.html',
  styleUrls: ['./card-create-pipeline.component.scss']
})
export class CardCreatePipelineComponent implements OnInit {
  subscription: any[];
  subscriptionsReducerSubscription$: any;
  hasStorage = false;
  hasSource = false;

  status = 0;

  constructor(private store$: Store<AppState>) { }

  ngOnInit(): void {
    this.subscriptionsReducerSubscription$ = this.store$.select('subscriptions').subscribe(response => {
      this.subscription = [...response.subscriptions];
      if (this.subscription.length === 0) {
        this.status = 0;
      }
      if (this.subscription.length === 1) {
        this.subscription.forEach(val => {
          if (val.isStorage === true) {
            this.hasStorage = true;
          }
          if (val.isStorage === false) {
            this.hasSource = true;
          }
        });
      }
      if (this.subscription.length >= 2) {
        this.subscription.some(val => {
          if (val.isStorage === true) {
            this.hasStorage = true;
          }
          if (val.isStorage === false) {
            this.hasSource = true;
          }
        });
      }

      if (this.hasStorage === true && this.hasSource === false) {
        this.status = 50;
      }
      if (this.hasSource === true && this.hasStorage === true) {
        this.status = 100;
      }
      if (this.hasStorage === false && this.hasSource === false) {
        this.status = 0;
      }
    });
  }

}
