import { StripeService } from './../../../services/stripe.service';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { DateTimeService, DATE_FORMAT_MMM_DD_YYYY } from 'src/app/core/services/date-time.service';
import { AppState } from 'src/app/core/store/reducers';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-card-trial-plan',
  templateUrl: './card-trial-plan.component.html',
  styleUrls: ['./card-trial-plan.component.scss']
})
export class CardTrialPlanComponent implements OnInit {
  stripeReducer$: any;
  stripeCustomerData: any;
  subscriptionData: any[];
  daysDifferenceFromNow: number;
  daysInActivePlan: number;

  isTrialCustomer = false;
  hasTrialSubscription = false;
  hasPaidSubscription = false;
  hasSubscription = false;
  stripeDetails: any = null;
  dateFormat = DATE_FORMAT_MMM_DD_YYYY;

  constructor(
    private stripe: StripeService,
    private store$: Store<AppState>,
    public dateTimeService: DateTimeService,
  ) { }

  ngOnInit(): void {

    // Get the current time as a base epoch time without milliseconds
    const nowTime = Math.floor(new Date().getTime() / 1000);

    this.stripeReducer$ = this.store$.select('stripe').subscribe(response => {
      if (response.stripeCustomer !== null) {
        this.stripeCustomerData = { ...response.stripeCustomer };
        if (this.stripeCustomerData) {
          this.subscriptionData = this.stripeCustomerData.subscriptions.data;
          this.hasSubscription = (this.subscriptionData.length > 0) ? true : false;
          if (this.hasSubscription) {
            this.subscriptionData.forEach(val => {
              if (val.plan.id === environment.stripe.prices.trialPlanId) {
                this.hasTrialSubscription = true;
                this.stripeDetails = this.stripe.processStripeCustomer(this.stripeCustomerData);
                // check if cancel_at is available, if yes then calculate days from cancel_at date
                if (this.stripeDetails.cancelAt) {
                  this.daysDifferenceFromNow = this.calculateDifferenceInDays(this.stripeDetails.cancelAt, nowTime);
                  this.daysInActivePlan = this.calculateDifferenceInDays(this.stripeDetails.cancelAt, val.current_period_start);
                }
                else {
                  this.daysDifferenceFromNow = this.calculateDifferenceInDays(val.current_period_end, nowTime);
                  this.daysInActivePlan = this.calculateDifferenceInDays(val.current_period_end, val.current_period_start);
                }
              }
              else {
                this.hasPaidSubscription = true;
                this.stripeDetails = this.stripe.processStripeCustomer(this.stripeCustomerData);

                this.daysDifferenceFromNow = this.calculateDifferenceInDays(this.stripeDetails.planCurrentPeriodEnds, nowTime);
                this.daysInActivePlan = this.calculateDifferenceInDays(
                  this.stripeDetails.planCurrentPeriodEnds, this.stripeDetails.planCurrentPeriodStart
                );
              }
            });
            if (this.hasTrialSubscription && !this.hasPaidSubscription) {
              this.isTrialCustomer = true;
            }
          }
        }
      }
    });
  }

  processStripeData(stripeData): any {
    const subscriptions = stripeData.subscriptions;
    return {
      id: subscriptions.data[0].id,
      planId: subscriptions.data[0].plan.id,
      planName: subscriptions.data[0].plan.name,
      cancelAt: subscriptions.data[0].cancel_at,
      planCurrentPeriodStart: subscriptions.data[0].current_period_start,
      planCurrentPeriodEnds: subscriptions.data[0].current_period_end
    };
  }

  calculateDaysPassed(subscriptionDayLength: number, day: number): number {
    return subscriptionDayLength - day;
  }

  calculateDifferenceInDays(endTime: number, nowTime: number): number {
    const difference = endTime - nowTime;
    const daysDifference = Math.ceil(difference / 86400);
    return daysDifference;
  }

  getInnerText(daysInActivePlan, daysDifferenceFromNow): string {
    if (daysDifferenceFromNow > 14 && daysDifferenceFromNow <= daysInActivePlan) {
      return 'Welcome to your trial';
    }
    else if (daysDifferenceFromNow > 9 && daysDifferenceFromNow <= 14) {
      return 'Enjoy Your ' + daysDifferenceFromNow + ' days of trial.';
    }
    else if (daysDifferenceFromNow > 5 && daysDifferenceFromNow <= 9) {
      return 'Your trial has ' + daysDifferenceFromNow + ' days left.';
    }
    else if (daysDifferenceFromNow > 2 && daysDifferenceFromNow <= 5) {
      return 'Only ' + daysDifferenceFromNow + ' days left. Upgrade soon!';
    }
    else if (daysDifferenceFromNow >= 0 && daysDifferenceFromNow <= 2) {
      return 'You have ' + daysDifferenceFromNow + ' days left. Upgrade now!';
    } else {
      return 'Trial plan is expired. Upgrade to continue service';
    }
  }

  getProgressBarWidthClass(daysDifferenceFromNow): string {
    const daysPassed = this.calculateDaysPassed(this.daysInActivePlan, daysDifferenceFromNow);
    return 'progress-bar-width-' + daysPassed;
  }

  getProgressBarStyle(): string {
    if (this.daysInActivePlan === 0) return 'width: 0%';
    const width = Math.min((this.daysInActivePlan - this.daysDifferenceFromNow) / this.daysInActivePlan * 100, 100);
    return `width: ${width}%`;
  }

}
