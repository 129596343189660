import { Component, OnInit } from '@angular/core';
import { ImageServiceService } from 'src/app/shared/services/image-service.service';

@Component({
  selector: 'app-help-common-destination-select',
  templateUrl: './help-common-destination-select.component.html'
})
export class HelpCommonDestinationSelectComponent implements OnInit {

  constructor(
    public imageServiceService: ImageServiceService
  ) { }

  ngOnInit(): void {
  }

}
