import { Chart } from 'chart.js';

// Define the chart colors
export const colors = {
  gray: {
    300: '#E3EBF6',
    600: '#95AAC9',
    700: '#9fb0c7',
    800: '#152E4D',
    900: '#283E59'
  },
  primary: {
    100: '#D2DDEC',
    300: '#A6C5F7',
    700: '#2C7BE5',
  },
  black: '#12263F',
  white: '#FFFFFF',
  blue: '214677',
  transparent: 'transparent',
};

// Define the font used in the charts
export const fonts = {
  base: 'Cerebri Sans'
};

export const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: 0
  },
  legend: {
    display: false,
    position: 'bottom',
    labels: {
      usePointStyle: true,
      padding: 16
    }
  },
  elements: {
    point: {
      radius: 0,
      backgroundColor: colors.primary[700]
    },
    line: {
      tension: .4,
      borderWidth: 3,
      borderColor: colors.primary[700],
      borderCapStyle: 'rounded',
      backgroundColor: colors.transparent
    },
    rectangle: {
      backgroundColor: colors.primary[700],
      maxBarThickness: 10
    },
    arc: {
      backgroundColor: colors.blue,
      borderColor: colors.blue,
      borderWidth: 4,
      hoverBorderColor: colors.blue
    }
  }
};

export function generateGlobalDefaults() {

  // 
  const globalConfig = Chart.defaults;

  // Global
  globalConfig.responsive = chartOptions.responsive;
  globalConfig.maintainAspectRatio = chartOptions.maintainAspectRatio;

  // Default
  globalConfig.color = colors.gray[700];
  globalConfig.font.family = fonts.base;
  globalConfig.font.size = 13;

  // Layout
  globalConfig.layout.padding = 0;

  // Legend
  globalConfig.plugins.legend.display = false;
  globalConfig.plugins.legend.position = 'bottom';
  globalConfig.plugins.legend.labels.usePointStyle = true;
  globalConfig.plugins.legend.labels.padding = 16;


  // Point
  globalConfig.elements.point.radius = 0;
  globalConfig.elements.point.backgroundColor = colors.primary[700];

  // Line
  globalConfig.elements.line.tension = 0.4;
  globalConfig.elements.line.borderWidth = 3;
  globalConfig.elements.line.borderColor = colors.primary[700];
  globalConfig.elements.line.backgroundColor = colors.transparent;
  globalConfig.elements.line.borderCapStyle = 'round';

  // Bar
  globalConfig.elements.bar.backgroundColor = colors.primary[700];
  globalConfig.elements.bar.borderWidth = 0;
  globalConfig.elements.bar.borderRadius = 10;
  globalConfig.elements.bar.borderSkipped = false;
  globalConfig.datasets.bar.maxBarThickness = 10;

  // Arc
  globalConfig.elements.arc.backgroundColor = colors.blue;
  globalConfig.elements.arc.borderColor = colors.blue;
  globalConfig.elements.arc.borderWidth = 4;
  globalConfig.elements.arc.hoverBorderColor = colors.blue;

  // Tooltips
  globalConfig.plugins.tooltip.enabled = false;
  globalConfig.plugins.tooltip.mode = 'index';
  globalConfig.plugins.tooltip.intersect = false;
  globalConfig.plugins.tooltip.external = function (context: any) {
    // Tooltip Element
    const { chart, tooltip } = context;
    let tooltipEl = chart.canvas.parentNode?.querySelector('div');

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.setAttribute('id', 'chart-tooltip');
      tooltipEl.setAttribute('role', 'tooltip');
      tooltipEl.classList.add('popover', 'bs-popover-top');

      const arrowEl = document.createElement('div');
      arrowEl.classList.add('popover-arrow', 'translate-middle-x');

      const contentEl = document.createElement('div');
      contentEl.classList.add('popover-content');

      tooltipEl.appendChild(arrowEl);
      tooltipEl.appendChild(contentEl);
      chart.canvas.parentNode?.appendChild(tooltipEl);
    }

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.visibility = 'hidden';
      return;
    }

    // Set Text
    if (tooltip.body) {
      const titleLines = tooltip.title || [];
      const bodyLines = tooltip.body.map((b: any) => b.lines);

      const headEl = document.createElement('div');
      titleLines.forEach((title: any) => {
        const headingEl = document.createElement('h3');
        headingEl.classList.add('popover-header', 'text-center', 'text-nowrap');

        const text = document.createTextNode(title);

        headingEl.appendChild(text);
        headEl.appendChild(headingEl);
      });

      const bodyEl = document.createElement('div');
      bodyLines.forEach((body: any, i: any) => {
        const colors = tooltip.labelColors[i];

        const indicatorEl = document.createElement('span');
        indicatorEl.classList.add('popover-body-indicator');
        indicatorEl.style.backgroundColor =
          chart.config.type === 'line' && colors.borderColor !== 'rgba(0,0,0,0.1)' ? colors.borderColor : colors.backgroundColor;

        const contentEl = document.createElement('div');
        contentEl.classList.add('popover-body', 'd-flex', 'align-items-center', 'text-nowrap');
        contentEl.classList.add(bodyLines.length > 1 ? 'justify-content-left' : 'justify-content-center');

        const text = document.createTextNode(body);

        contentEl.appendChild(indicatorEl);
        contentEl.appendChild(text);
        bodyEl.appendChild(contentEl);
      });

      const rootEl = tooltipEl.querySelector('.popover-content');

      // Remove old children
      while (rootEl.firstChild) {
        rootEl.firstChild.remove();
      }

      // Add new children
      rootEl.appendChild(headEl);
      rootEl.appendChild(bodyEl);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.visibility = 'visible';
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.transform = 'translateX(-50%) translateY(-100%) translateY(-1rem)';
  };

  globalConfig.plugins.tooltip.callbacks.label = function (ctx: any) {
    let content = '';
    const scale = ctx.chart.scales[ctx.dataset.yAxisID || 'y'];
    if (scale) {
      const label = ctx.chart.tooltip.dataPoints.length > 1 ? ' ' + ctx.dataset.label + ' ' : ' ';
      content = label + scale.options.ticks.callback.apply(scale, [ctx.parsed.y, 0, []]);
    } else {
      const callbacks = ctx.chart.options.plugins.tooltip.callbacks;
      const before = callbacks.beforeLabel() || '';
      const after = callbacks.afterLabel() || '';
      content = before + ctx.formattedValue + after;
    }

    return content;
  };

  // Doughnut
  Chart.overrides.doughnut.cutout = '83%';

  // yAxis
  globalConfig.scales.linear.border = { ...globalConfig.scales.linear.border, ...{ display: false, dash: [2], dashOffset: 2 } };
  globalConfig.scales.linear.grid = {
    ...globalConfig.scales.linear.grid,
    ...{ color: colors.gray[300], drawTicks: false },
  };

  // beginAtZero is default set to true
  // globalConfig.scales.linear.ticks.beginAtZero = true;
  globalConfig.scales.linear.ticks.padding = 10;
  globalConfig.scales.linear.ticks.stepSize = 10;

  // xAxis
  globalConfig.scales.category.border = { ...globalConfig.scales.category.border, ...{ display: false } };
  globalConfig.scales.category.grid = { ...globalConfig.scales.category.grid, ...{ display: false, drawTicks: false, drawOnChartArea: false } };
  globalConfig.scales.category.ticks.padding = 20;


  return globalConfig;
}






