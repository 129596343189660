import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

export interface CommonModalData {
  modalTitle: string;
  modalContent: string;
}

@Component({
  selector: 'app-modal-common',
  templateUrl: './modal-common.component.html',
})

export class ModalCommonComponent implements OnInit {
  modalData: any = {};

  constructor(
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit(): void {
  }

}
