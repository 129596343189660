import { Component, OnInit } from '@angular/core';
import { ImageServiceService } from 'src/app/shared/services/image-service.service';

@Component({
  selector: 'app-help-common-destination-name',
  templateUrl: './help-common-destination-name.component.html'
})
export class HelpCommonDestinationNameComponent implements OnInit {

  constructor(
    public imageServiceService: ImageServiceService
  ) { }

  ngOnInit(): void {
  }

}
