<!-- Image -->
<div class="avatar avatar-sm card-avatar">
  <img src="../assets/img/logos/openbridge.svg" class="avatar-img rounded" alt="..."
    (error)="imageServiceService.handleMissingImage($event)">
</div>
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader"> Name Your Destination </h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline"> Set A Recognizable Data Destination Label </p>
<!-- Divider -->
<hr class="mb-4">
<!-- Heading -->
<h4 class="mb-2">
</h4>
<ol>
  <li class="text-muted mb-2"> The name we will use to refer to your destination. We recommend you choosing a name that
    will describe this system. For example, <code>my-ecommerce-data</code> or <code>my-clients</code>. </li>
  <li class="text-muted mb-2"> A name must be unique for a destination type. For example, you can not have a BigQuery
    and Azure Data Lake destinations with the same <code>my-ecommerce-data</code> name. </li>
</ol>