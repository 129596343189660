<div class="modal-header">
  <h3 class="modal-title pull-left">
    <span id="modalTitle">
      {{ title }}
    </span>
  </h3>
  <button type="button" class="btn-close pull-right" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body text-muted">
  <div class="mb-4" id="modalMessage">
    {{ message }}
  </div>
</div>
<div class="modal-footer p-3">
  <div class="text-center">
    <button type="button" class="btn btn-sm btn-primary" (click)="confirm()">
      Confirm
    </button>
  </div>
</div>