import { Component, Input, OnInit } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';

@Component({
  selector: 'app-chart-line',
  templateUrl: './chart-line.component.html'
})
export class ChartLineComponent implements OnInit {

  // Data Related
  @Input() chartDataSets: any[];
  @Input() chartLabels: any[];
  @Input() chartPlugins: any[];
  @Input() chartLegend: boolean;

  // Style related.
  @Input() chartClass: string;
  @Input() chartHeight: string;
  @Input() chartStyle: string;
  @Input() chartWidth: string;

  chartType: ChartType = 'line';
  chartOptions: ChartOptions;

  constructor() { }

  ngOnInit(): void {
    this.chartOptions = {
      // scales: {
      //   xAxes: [{
      //     gridLines: {
      //       zeroLineColor: "rgba(0, 0, 0, 0)",
      //       color: "rgba(0, 0, 0, 0)",
      //     }
      //   }],
      //   yAxes: [{
      //     id: 'yAxisOne',
      //     type: 'linear',
      //     display: 'auto',
      //     gridLines: {
      //       borderDash: [2],
      //       borderDashOffset: 2,
      //       color: colors.gray[900],
      //       drawBorder: false,
      //       drawTicks: false,
      //       zeroLineColor: colors.gray[900],
      //       zeroLineBorderDash: [2],
      //       zeroLineBorderDashOffset: 2
      //     },      
      //     ticks: {
      //       callback: function(value) {
      //         return '$' + value + 'k';
      //       }
      //     }
      //   }, {
      //     id: 'yAxisTwo',
      //     type: 'linear',
      //     display: 'auto',
      //     ticks: {
      //       callback: function(value) {
      //         return value + 'hrs';
      //       }
      //     }
      //   }]
      // }
    }
  }
}
/*

{
      type: 'line',
      options: {
        scales: {
          yAxes: [{
            id: 'yAxisOne',
            type: 'linear',
            display: 'auto',
            ticks: {
              callback: function(value) {
                return '$' + value + 'k';
              }
            }
          }, {
            id: 'yAxisTwo',
            type: 'linear',
            display: 'auto',
            ticks: {
              callback: function(value) {
                return value + 'hrs';
              }
            }
          }]
        }
      },
      data: {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [{
          label: 'Earned',
          data: [0, 10, 5, 15, 10, 20, 15, 25, 20, 30, 25, 40],
          yAxisID: 'yAxisOne'
        }, {
          label: 'Hours Worked',
          data: [7, 35, 12, 27, 34, 17, 19, 30, 28, 32, 24, 39],
          yAxisID: 'yAxisTwo',
          hidden: true
        }]
      }


*/