import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-identities',
  templateUrl: './help-identities.component.html',
})
export class HelpIdentitiesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
