import { ChangeDetectorRef, Component, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { IdentityService } from 'src/app/core/services/identity.service';
import { AppState } from 'src/app/core/store/reducers';
import { Integration } from 'src/app/shared/models/integration.model';
import { IdentityTypeNameFromIdentityTypeIdPipe } from 'src/app/shared/pipes/identity-type-name-from-identity-type-id.pipe';
import { LogoPathFromIdentityTypeIdPipe } from 'src/app/shared/pipes/logo-path-from-identity-type-id.pipe';

@Component({
  selector: 'app-form-list-identity',
  templateUrl: './form-list-identity.component.html',
  providers: [LogoPathFromIdentityTypeIdPipe, IdentityTypeNameFromIdentityTypeIdPipe]
})
export class FormListIdentityComponent implements OnInit {
  @Input() formGroup: UntypedFormGroup;
  @Input() remoteIdentityTypeId: number;
  @Input() integration: Integration | undefined;
  @Input() notifierType: string = null;

  cardData: any[];
  filteredCardData: {}[];
  includeRegions: string[] | false = false;

  constructor(
    private identityService: IdentityService,
    private changeDetector: ChangeDetectorRef,
    private logoPathFromIdentityTypeIdPipe: LogoPathFromIdentityTypeIdPipe,
    private identityTypeNameFromIdentityTypeIdPipe: IdentityTypeNameFromIdentityTypeIdPipe,
    private store$: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.cardData = [];
    this.filteredCardData = [];

    if (this.integration !== undefined) {
      this.includeRegions = this.integration.includeRegions;
    }

    this.identityService.identities$.subscribe(response => {

      // Clear card data before rebuild.
      this.cardData = [];

      setTimeout(() => {
        const identities = response.filter(i => i.remoteIdentityTypeId === this.remoteIdentityTypeId);
        const unsortedCardData = [];

        // Make sure the identity has the right logo and logo text
        identities.forEach(identity => {
          identity.logoUri = this.logoPathFromIdentityTypeIdPipe.transform(identity.remoteIdentityTypeId);
          identity.logoAltText = this.identityTypeNameFromIdentityTypeIdPipe.transform(identity.remoteIdentityTypeId);

          const exists = unsortedCardData.find(identityObj => identityObj.id === identity.id);
          let includeIdentityForRegion = true;

          if (Array.isArray(this.includeRegions)) {
            includeIdentityForRegion = this.includeRegions.includes(identity.region);
          }

          if (!exists && includeIdentityForRegion) {
            unsortedCardData.push(identity);
          }
        });

        this.cardData = unsortedCardData.sort((a: any, b: any) => a.createdAt - b.createdAt);;
        this.cardData.reverse();

        // To prevent "Expression has changed after it was checked errors"
        this.changeDetector.detectChanges();
      }, 0);
    });

    this.store$.select('identities').subscribe(state => {
      this.identityService.getIdentitiesByRemoteIdentityType(this.remoteIdentityTypeId);
    });

  }

  updateFilteredData(data): void {
    this.filteredCardData = data;
  }

}
