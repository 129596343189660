import { Pipe, PipeTransform } from '@angular/core';

/**
 * This pipe converts a false boolean value to null.  It was created because
 * [attrib.disabled] requires a true or null value instead of a true or false value.
 */
@Pipe({
  name: 'booleanTrueToNullFalseToTruePipe'
})
export class BooleanTrueToNullFalseToTruePipe implements PipeTransform {
  constructor() { }

  transform(value: boolean): boolean | null {
    return (value === false) ? true : null;
  }
}
