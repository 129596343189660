<app-chart
  [chartType]="chartType"
  [chartLabels]="chartLabels"
  [chartDataSets]="chartDataSets"
  [chartOptions]="chartOptions"
  [chartClass]="chartClass"
  [chartHeight]="chartHeight"
  [chartWidth]="chartWidth"
  [ngClass]="chartClass"
  [style]="chartStyle"
></app-chart>