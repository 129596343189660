<div class="row g-0 mt-4">

  <!-- Pagination (prev) -->
  <ul class="col list-pagination-prev pagination pagination-tabs justify-content-start">
    <li class="page-item cursor-pointer" (click)="selectPage(currentPage - 1, $event)"
      [class.disabled]="currentPage == 1" id="prevBtn">
      <a class="page-link">
        <i class="fe fe-arrow-left me-1"></i> Prev
      </a>
    </li>
  </ul>

  <!-- Pagination -->
  <ul class="col list-pagination pagination pagination-tabs justify-content-center">
    <li class="disabled" (click)="cancelEvent($event)" *ngIf="(currentPage - range) > 1">
      <a class="page" href="">
        ...
      </a>
    </li>
    <li [class.active]="page == currentPage" *ngFor="let page of pages | async">
      <a class="page" href="javascript:void(0)" (click)="selectPage(page, $event)">
        {{page}}
      </a>
    </li>
    <li class="disabled" (click)="cancelEvent($event)" *ngIf="(currentPage + range) < totalPages">
      <a class="page" href="">
        ...
      </a>
    </li>
  </ul>

  <!-- Pagination (next) -->
  <ul class="col list-pagination-next pagination pagination-tabs justify-content-end">
    <li class="page-item cursor-pointer" (click)="selectPage(currentPage + 1, $event)"
      [class.disabled]="currentPage == totalPages" id="nextBtn">
      <a class="page-link">
        Next <i class="fe fe-arrow-right ms-1"></i>
      </a>
    </li>
  </ul>

</div>