<form [formGroup]="formGroup">
  <div class="row">
    <div class="col-12">
      <!-- Card -->
      <div class="card">
        <div class="card-body">
          <div class="form-group" *ngIf="identityRegions.length > 1">
            <!-- Label -->
            <label for="region" class="mb-1">
              Region
            </label>

            <!-- Form text -->
            <small class="form-text text-muted">
              Selecting a region is required before creating an identity.
            </small>

            <!-- Input -->

            <div class="input-group mb-3">
              <span class="input-group-text">
                <img [src]="formGroup.value.region | flagFromRegionId" [alt]="formGroup.value.region"
                  [title]="formGroup.value.region" (error)="imageServiceService.handleMissingImage($event)">
              </span>
              <select class="form-control" name="region" id="region" formControlName="region" [ngClass]="{ 
                'is-invalid': formGroup.controls.region.invalid && formGroup.controls.region.touched,
                'is-valid': formGroup.controls.region.valid && formGroup.controls.region.touched 
              }">
                <option value="">Select your region</option>
                <option *ngFor="let region of identityRegions" [disabled]="region.disabled" [ngValue]="region.value">
                  {{ region.name }}
                </option>
              </select>
              <div *ngIf="formGroup.controls.region.invalid && 
              (formGroup.controls.region.dirty || formGroup.controls.region.touched)" class="invalid-feedback">
                A valid Region is required.
              </div>
            </div>
            
          </div>

          <input *ngIf="identityRegions.length == 1" name="identityRegion" id="globalRegion" value="global" type="hidden"
            formControlName="region">

          <!-- Divider -->
          <hr class="my-5" *ngIf="identityRegions.length > 1">

          <!-- new identities -->
          <div class="row align-items-center">
            <div class="col-auto pe-3">
              <!-- Avatar -->
              <div class="avatar avatar-md">
                <span class="avatar-title rounded-circle text-wait" style="font-size: 1.4rem;">
                  <i class="fad fa-key-skeleton"></i></span>
              </div>
            </div>
            <div class="col ps-0">
              <!-- Heading -->
              <span class="h3 mb-0"> Create New {{ integration.name }} Authorization</span>
              <!-- Text -->
              <p class="card-text small text-muted mb-1">Grant data access permissions to your {{ integration.name }}
                account. Once authorized, the {{ integration.name }} identity will appear in the list below.</p>
            </div>
            <div class="col pt-3">
              <div class="mb-0">
                <!-- Card -->
                <div class="card">
                  <button *ngIf="(!identityType.authButton); else signInImage" type="button"
                    class="btn btn-ls btn-white" id="authorizeBtn" (click)="submitForm()">Authorize</button>
                  <ng-template #signInImage>
                    <div class="image-center">
                      <img [src]="identityType.authButton.imagePath" alt="Signin with Google"
                        [ngClass]="identityType.authButton.imageClasses" (click)="submitForm()"
                        (error)="imageServiceService.handleMissingImage($event)" />
                    </div>
                  </ng-template>


                  <!-- <ng-container [ngSwitch]="integration.brand">
                    <div *ngSwitchCase="'Google'" class="image-center">
                      <img src="assets/img/vendor/btn_google_signin.png" alt="Signin with Google"
                        class="google-signin-btn cursor-pointer lift" (click)="submitForm()" 
                        (error)="imageServiceService.handleMissingImage($event)"/>
                    </div>
                    <div *ngSwitchCase="'Facebook'" class="image-center">
                      <img src="assets/img/vendor/btn_facebook_login.png" alt="Signin with Facebook"
                        class="facebook-login-btn cursor-pointer lift" (click)="submitForm()" 
                        (error)="imageServiceService.handleMissingImage($event)"/>
                    </div>
                    <div *ngSwitchCase="'Amazon'" class="image-center">
                      <img src="assets/img/vendor/btn_amazon_login_gold_195x46.png" alt="login with Amazon"
                        class="amazon-login-btn cursor-pointer lift" (click)="submitForm()" 
                        (error)="imageServiceService.handleMissingImage($event)"/>
                    </div>
                    <button type="button" *ngSwitchDefault class="btn btn-ls btn-white" id="authorizeBtn"
                      (click)="submitForm()">Authorize</button>
                  </ng-container> -->
                </div>
              </div>
            </div>
          </div>

          <!-- Divider -->
          <div class="position-relative">
            <hr class="bg-200 my-5" style="border-top: 2px dashed;">
            <div class="divider-content-center">
              or invite someone to authorize
            </div>
          </div>

          <!-- Dashed Divider -->
          <!-- <hr class="my-5" style="border-top: 2px dashed;"> -->


          <!-- <p class="card-text small text-muted text-center my-5">
            --------------------------------- OR ---------------------------------
          </p> -->

          <!-- external identities -->
          <div class="row align-items-center">
            <div class="col-auto pe-3">
              <!-- Avatar -->
              <div class="avatar avatar-md">
                <span class="avatar-title rounded-circle text-wait" style="font-size: 1.4rem;">
                  <i class="fa-duotone fa-chart-user"></i>
                </span>
              </div>
            </div>
            <div class="col ps-0">
              <!-- Heading -->
              <span class="h3 mb-0"> Authorize {{ integration.name }} with External Identity</span>
              <!-- Text -->
              <p class="card-text small text-muted mb-1">
                External identities allow you to invite someone to provide an authorization without needing
                an Openbridge account.
                <a href="https://docs.openbridge.com/en/articles/8075889-using-trusted-external-identities"
                  target="_blank" rel="noopener" class="light-link">
                  Read more about this here</a>.
              </p>
            </div>
            <div class="col pt-3">
              <div class="mb-0">
                <!-- Card -->
                <div class="card">
                  <div class="text-center">
                    <button type="button" class="btn btn-ls btn-white" style="width: 250px;" id="externalIdentityBtn"
                      (click)="submitAsThirdPartySharable()">
                      Create External Identity
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /.row -->
        </div>
      </div>
    </div>
  </div>
</form>