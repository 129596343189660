import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-pick-list-filter',
  templateUrl: './pick-list-filter.component.html'
})
export class PickListFilterComponent implements OnInit, OnChanges {
  @Input() cardData: any[];
  @Output() filteredDataEmitter = new EventEmitter<any>();
  filterForm: UntypedFormGroup;

  constructor() { }

  ngOnInit(): void {
    this.filterForm = new UntypedFormGroup({
      filterInput: new UntypedFormControl('')
    });
    this.filter('');
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filter('');
  }

  filter(filterText: string): void {
    let filteredData: any[] = [];

    // Filter text search.
    if (filterText === '') {
      filteredData = this.cardData;
    }
    else {
      this.cardData.forEach((value, index) => {
        let add = false;

        if (value.title.toLowerCase().includes(filterText.toLowerCase())) {
          add = true;
        }

        value.meta.forEach((metaValue, metaIndex) => {
          // The .includes() API is part of the String and Array data type.
          if (metaValue.value && metaValue.value.toString().toLowerCase().includes(filterText.toLowerCase())) {
            add = true;
          }
        });

        // filter for the children meta information.
        if(value.children) {
          value.children.forEach(childrenValue => {
            if (childrenValue.id && childrenValue.id.toString().toLowerCase().includes(filterText.toLowerCase())) {
              add = true;
            }
            if (childrenValue.name && childrenValue.name.toString().toLowerCase().includes(filterText.toLowerCase())) {
              add = true;
            }
            if (childrenValue.logoAltText && childrenValue.logoAltText.toString().toLowerCase().includes(filterText.toLowerCase())) {
              add = true;
            }
          });
        }

        if (add === true) {
          filteredData.push(value);
        }
      });
    }

    // let filteredData: any[] = this.cardData;
    this.filteredDataEmitter.emit(filteredData);
  }

  onKeyUp(event): void {
    this.filter(this.filterForm.value.filterInput);
  }

  onSubmit(): boolean {
    return false;
  }
}
