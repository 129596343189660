import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner-support-proxy',
  templateUrl: './banner-support-proxy.component.html'
})
export class BannerSupportProxyComponent implements OnInit {
  proxyMode: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.proxyMode = (localStorage.getItem('proxy') !== null);
  }

  resetProxy($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    localStorage.removeItem('proxy');
    window.location.reload();
    return false;
  }

}
