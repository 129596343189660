import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'objectHasKey'})
@Injectable({
  providedIn: 'root'
})
export class ObjectHasKeyPipe implements PipeTransform {
  transform(value: object, objKey: string = ''): boolean {

    if (objKey in value) {
      return true;
    }

    return false;
  }
}