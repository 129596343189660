import { Component, Input, OnInit } from '@angular/core';
import { Integration } from 'src/app/shared/models/integration.model';
import { ImageServiceService } from 'src/app/shared/services/image-service.service';

@Component({
  selector: 'app-help-youtube-single-channel-common-details',
  templateUrl: './help-youtube-single-channel-common-details.component.html',
})
export class HelpYoutubeSingleChannelCommonDetailsComponent implements OnInit {
  @Input() integration: Integration;

  constructor(
    public imageServiceService: ImageServiceService
  ) { }

  ngOnInit(): void {
  }

}
