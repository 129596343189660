import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-dataset-template-delete',
  templateUrl: './modal-dataset-template-delete.component.html',
  styleUrls: ['./modal-dataset-template-delete.component.scss']
})
export class ModalDatasetTemplateDeleteComponent implements OnInit{
  constructor(
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit(): void {
  }

}
