<!-- Image -->
<div class="avatar avatar-sm card-avatar">
  <img src="{{integration.logoPath}}" class="avatar-img rounded" alt="..."
    (error)="imageServiceService.handleMissingImage($event)">
</div>
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader"> Getting Started </h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline"> Activating Your {{ integration | integrationFullname }} </p>
<!-- Divider -->
<hr class="mb-4">
<!-- Heading-->
<h4 class="mt-2 mb-2"> Configuring {{ integration | integrationFullname }} </h4>
<ol>
  <li class="text-muted mb-2"> The configuration process assumes you have an Azure Cloud account. Azure offers <a
      href="https://azure.microsoft.com/en-us/free/" rel="noopener" target="_blank" class="light-link"> free trials and
      service credits</a> for new customers </li>
  <li class="text-muted mb-2"> If you already have an Azure account, follow the <a
      href="https://docs.openbridge.com/en/articles/4384989-configuring-your-azure-data-lake-storage-data-destination"
      rel="noopener" target="_blank" class="light-link">{{ integration | integrationFullname }} setup guide</a>. </li>
  <li class=" text-muted mb-2"> Make sure you have you taken note of your {{ integration | integrationFullname }} <code>Storage Account
      Name</code>, <code>Container Name</code>, and <code>Connection String</code> in the Azure interface. You will
    need
    this in the next step.</li>
  <li class="text-muted mb-2">See the {{ integration | integrationFullname }} <a
      href="{{ integration | integrationDocLink}}" rel="noopener" target="_blank">help docs</a> for
    guides, tips, and troubleshooting</li>
</ol>