<ng-container *ngIf="cardData.length > 1">
  <form novalidate [formGroup]="filterForm" (ngSubmit)="onSubmit()">
    <div class="row align-items-center">
      <div class="col">
        <div class="input-group input-group-flush input-group-merge input-group-reverse">
          <input class="form-control list-search" type="search" placeholder="Search" name="filterInput" id="filterInput"
            formControlName="filterInput" (keyup)="onKeyUp($event)">
          <span class="input-group-text">
            <i class="fe fe-search"></i>
          </span>
        </div>
      </div>
    </div>
  </form>
</ng-container>