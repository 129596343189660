import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-filter-job-transactions',
  templateUrl: './filter-job-transactions.component.html',
  styleUrls: ['./filter-job-transactions.component.scss']
})
export class FilterJobTransactionsComponent implements OnInit {
  @Input() filterableObjects: any[] = [];
  @Output() filteredObjects = new EventEmitter<any[]>();
  @Output() updatedPageSize = new EventEmitter<number>();

  filterSearch = new UntypedFormControl('');
  pageSize = 10;

  constructor(
  ) { }

  ngOnInit(): void {
    
    this.processFilteredText(this.filterSearch.value);
  }
  
  ngOnChanges(): void {
    this.processFilteredText(this.filterSearch.value);
  }

  processFilteredText(filterText: string): void {
    if (filterText === '') {
      this.filteredObjects.emit([...this.filterableObjects]);
    }
    else {
      const filteredObjects: any[] = [];

      this.filterableObjects.forEach((val, idx) => {
        // filter for id and dataset name
        if (val.dataset && val.dataset.toLowerCase().includes(filterText.toLowerCase())) {
          filteredObjects.push(val);
        }
        else if (val.id && val.id === +filterText) {
          filteredObjects.push(val);
        }
      });

      this.filteredObjects.emit([...filteredObjects]);
    }
  }

  updatePageEntries(pageSize): void {
    this.updatedPageSize.emit(pageSize);
  }
}
