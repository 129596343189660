import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { IdentityService } from 'src/app/core/services/identity.service';

@Component({
  selector: 'app-modal-edit-alias',
  templateUrl: './modal-edit-alias.component.html'
})
export class ModalEditAliasComponent implements OnInit {

  modalData: any;
  formGroup: UntypedFormGroup = new UntypedFormGroup({
    customerAlias: new UntypedFormControl(null, [Validators.required]),
  });
  onClose$: Subject<any> = new Subject<any>();

  constructor(
    private bsModalRef: BsModalRef,
    private identityService: IdentityService
  ) { }

  ngOnInit(): void {
    this.formGroup = new UntypedFormGroup({
      customerAlias: new UntypedFormControl(this.modalData.identity.customerAlias, [Validators.required]),
    });
  }

  async onSubmit(): Promise<void> {
    if (this.formGroup.valid) {
      try {
        const response = await this.identityService.updateIdentityCustomerAlias(this.modalData.identity.id, this.formGroup.value.customerAlias);
        this.onClose$.next({
          identityId: this.modalData.identity.id,
          customerAlias: response.data.attributes.customer_alias
        });
      }
      catch {

      }
    }
    else {

    }
    this.closeModalWithComponent();
  }

  cancel(): void {
    this.onClose$.next({
      canceled: true
    });
    this.closeModalWithComponent();
  }

  private closeModalWithComponent(): void {
    if (this.bsModalRef !== null) {
       this.bsModalRef.hide();
    }
    this.bsModalRef = null;
  }


}
