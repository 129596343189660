import { Component, OnInit } from '@angular/core';
import { ImageServiceService } from 'src/app/shared/services/image-service.service';

@Component({
  selector: 'app-help-destinations-select',
  templateUrl: './help-destinations-select.component.html',
})
export class HelpDestinationsSelectComponent implements OnInit {

  constructor(
    public imageServiceService: ImageServiceService
  ) { }

  ngOnInit(): void {
  }

}
