import { Component, Input, OnInit } from '@angular/core';
import { Integration } from 'src/app/shared/models/integration.model';
import { ImageServiceService } from 'src/app/shared/services/image-service.service';

@Component({
  selector: 'app-help-azure-data-lake-details',
  templateUrl: './help-azure-data-lake-details.component.html'
})
export class HelpAzureDataLakeDetailsComponent implements OnInit {
  @Input() integration: Integration;

  constructor(
    public imageServiceService: ImageServiceService
  ) { }

  ngOnInit(): void {
  }

}
