<div class="modal-header">
  <h4 class="modal-title pull-left">Revoke refresh token.</h4>
  <button type="button" class="btn-close pull-right" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <!-- <form class="tab-content"> -->
  <form class="tab-content" novalidate [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="refreshTokenRevokeConfirm" class="mb-2">
            Please type the word &lsquo;<strong>revoke</strong>&rsquo; to revoke your refresh token.
          </label>
          <input type="text" class="form-control" id="refreshTokenRevokeConfirm"
            formControlName="refreshTokenRevokeConfirm" aria-describedby="refreshTokenRevokeConfirmHelp"
            placeholder="Revoke confirmation." [ngClass]="{
              'is-invalid': (formGroup.controls.refreshTokenRevokeConfirm.invalid && formGroup.controls.refreshTokenRevokeConfirm.touched) || (formGroup.invalid && !formGroup.untouched),
              'is-valid': formGroup.controls.refreshTokenRevokeConfirm.valid && formGroup.controls.refreshTokenRevokeConfirm.touched
            }" formControlName="refreshTokenRevokeConfirm">
          <div
            *ngIf="(formGroup.controls.refreshTokenRevokeConfirm.invalid && (formGroup.controls.refreshTokenRevokeConfirm.dirty || formGroup.controls.refreshTokenRevokeConfirm.touched)) || (formGroup.invalid && !formGroup.untouched)"
            class="invalid-feedback">
            Please type the word &lsquo;<strong>revoke</strong>&rsquo; to revoke you token.
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <button type="submit" class="btn btn-danger">Revoke</button>
          <!-- <button type="button" class="btn btn-primary m-2" (click)="cancel()">Cancel</button> -->
        </div>
      </div>
    </div>
  </form>
</div>