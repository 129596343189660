<!-- for DSP V2 only-->
<div *ngIf="integration.productId === 85; else commonDiv">


  <div class="row mt-3">
    <div class="col" [ngClass]="{
      'custom-opacity': deletedTemplate === true
    }">
      <div class="form-check">
        <input 
          class="form-check-input" 
          type="checkbox" 
          [id]="inputData.attributes.stage_id" 
          [value]="inputData.attributes.stage_id" 
          [checked]="inputData.isSelected"
          (change)="onCheckboxChange($event)">
    
        <div class="d-flex align-items-center justify-content-between">
          <div>
            <label class="form-check-label mb-1" [for]="inputData.attributes.stage_id" [tooltip]="inputData.attributes.name">
              {{ inputData.attributes.name | truncate:[20, '...'] }}
            </label>
            <span *ngIf="templateEditMode">
              <a href (click)="templateDetails($event, inputData)">
               (Edit)
              </a>
            </span>
          </div>
        </div>
        <p class="small text-muted mb-1"> Report Type: 
          {{ selectedReport?.reportTypeName }}
        </p>
        <p class="small text-muted mb-1"> Dimensions: 
          <span [tooltip]="getCommaSeparatedDimensions(inputData?.attributes?.dimensions)">
            {{ getCommaSeparatedDimensions(inputData?.attributes?.dimensions) | truncate:[35, '...'] }}
          </span>
        </p>
      </div>
    </div>
    <div class="col-auto">
      <button type="button" 
        *ngIf="deletedTemplate"
        class="btn btn-xs badge bg-secondary-soft" 
        tooltip="The global template was deleted" 
        (click)="openDeleteInfoModal($event)">
        Deleted
      </button>
    </div>
  </div>



</div>

<!-- for rest of products -->
<ng-template #commonDiv>
  <div class="form-check mt-3">
    <label class="form-check-label" [for]="inputData.attributes.stage_id">
      <input 
        class="form-check-input" 
        type="checkbox" 
        [id]="inputData.attributes.stage_id" 
        [value]="inputData.attributes.stage_id" 
        [checked]="inputData.isSelected"
        (change)="onCheckboxChange($event)">
      <span [tooltip]="inputData.attributes.name">
        {{ inputData.attributes.name | truncate:[35, '...'] }}
      </span>
    </label>
  </div>  
</ng-template>
