<!-- Image -->
<div class="avatar avatar-sm card-avatar">
  <img src="{{integration.logoPath}}" class="avatar-img rounded" alt="..."
    (error)="imageServiceService.handleMissingImage($event)">
</div>
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader"> Authorization </h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline"> Grant Permissions </p>
<!-- Divider -->
<hr class="mb-4">
<!-- Heading-->
<h4 class="mt-2 mb-2"> Authorize access to {{ integration | integrationFullname }} </h4>
<ol>
  <li class="text-muted mb-2">Make sure your region matches the seller region you are authorizing when redirected at
    Amazon. For example, if you select "Germany" on Openbridge, do not select the United States, Sweden, Japan, or any
    other region. Creating a mismatched region/marketplace can cause the processes to error.</li>

  <li class="text-muted mb-2">On the <code>Login with Amazon</code> authorization consent page, you can review the data
    access requested by Openbridge, and then click <code>Confirm</code> to continue. If you click <code>Cancel</code>
    the process will exit without authorizing. If you <code>Confirm</code> Amazon briefly displays a page indicating
    they are authorizing our to access to your account to collect data on your behalf. You will then be redirected back
    to Openbridge from Amazon.</li>

  <li class="text-muted mb-2">Amazon does not supply the seller's name in the API. As a result, we display the
    Amazon-provided merchant or seller ID with the marketplace associated with the ID. To locate your merchant ID, see
    the Amazon doc <a href="https://sellercentral.amazon.com/help/hub/reference/48321" rel="noopener"
      target="_blank">Your Merchant Token</a>. </li>

</ol>