<div class="modal-header">
  <h4 class="modal-title pull-left">Edit alias.</h4>
  <button type="button" class="btn-close pull-right" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <!-- <form class="tab-content"> -->
  <form class="tab-content" novalidate [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="dbHost" class="mb-2">Alias name</label>
          <input type="text" class="form-control" id="customerAlias" formControlName="customerAlias"
            aria-describedby="customerAliasHelp" placeholder="Alias name" [ngClass]="{
              'is-invalid': formGroup.controls.customerAlias.invalid && formGroup.controls.customerAlias.touched,
              'is-valid': formGroup.controls.customerAlias.valid && formGroup.controls.customerAlias.touched
            }" formControlName="customerAlias">
          <div
            *ngIf="formGroup.controls.customerAlias.invalid && (formGroup.controls.customerAlias.dirty || formGroup.controls.customerAlias.touched)"
            class="invalid-feedback">
            A alias name is required.
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <button type="submit" class="btn btn-primary">Update</button> &nbsp;
          <button type="button" (click)="cancel()" class="btn btn-danger">Cancel</button>
        </div>
      </div>
    </div>
  </form>
</div>