import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store/reducers';
import { identityTypes } from 'src/app/shared/constants/identity-types';
import { IdentityType } from 'src/app/shared/models/identity-type.type';

@Component({
  selector: 'app-identity-wizard',
  templateUrl: './identity-wizard.component.html'
})
export class IdentityWizardComponent implements OnInit {
  @Input() integration: any;
  @Input() identityTypeId: number | null = null;

  identityType: IdentityType;
  identityWizardLink: string;
  queryParams: any = {} ;

  constructor(
    protected store$: Store<AppState>,
    private activatedRoute: ActivatedRoute

  ) { }

  ngOnInit(): void {
    this.queryParams = this.activatedRoute.snapshot.queryParams;

    let remoteIdentityTypeId = this.queryParams.remoteIdentityTypeId || null;
    if(remoteIdentityTypeId === null) {
      remoteIdentityTypeId = (this.identityTypeId !== null) ? this.identityTypeId : this.integration.remoteIdentityTypeId;
    }

    this.identityType = identityTypes.find(type => type.remoteIdentityTypeId === +remoteIdentityTypeId);

    const integrationUrlIdentifier = this.integration.routerLink.split('/');

    this.identityWizardLink = '/identity/' + this.identityType.urlIdentifier + '/' + integrationUrlIdentifier[2];
  }
}
