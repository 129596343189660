Default
<ngx-dropzone></ngx-dropzone>

<ngx-dropzone #drop [id]="'idtest'" [aria-label]="'al-test'" [aria-labelledby]="'alb-test'"
	(change)="onFilesAdded($event)">
	<ngx-dropzone-label>Drop it, baby!</ngx-dropzone-label>
	<ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
		<ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
	</ngx-dropzone-preview>
</ngx-dropzone>

<button (click)="drop.showFileSelector()">Open</button>

<ngx-dropzone (change)="onFilesAdded($event)" [processDirectoryDrop]="true">
	<ngx-dropzone-label>Drop your directories in here, to see the magic happen!</ngx-dropzone-label>
	<ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
		<ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
	</ngx-dropzone-preview>
</ngx-dropzone>

<ngx-dropzone (change)="onFilesAdded($event)" [disabled]="true" [expandable]="true">
	<ngx-dropzone-label>Drop it like it's hot!</ngx-dropzone-label>
	<ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
		<ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
	</ngx-dropzone-preview>
</ngx-dropzone>

<ngx-dropzone (change)="onFilesAdded($event)" [multiple]="false" [disableClick]="true">
	<ngx-dropzone-label>Drop it like it's hott!</ngx-dropzone-label>
	<ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
		<ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
	</ngx-dropzone-preview>
</ngx-dropzone>

<div class="custom-dropzone" ngx-dropzone [accept]="'image/png'" (change)="onFilesAdded($event)">
	<ngx-dropzone-label>
		<div>
			<h2>My custom dropzone I</h2>
		</div>
	</ngx-dropzone-label>
	<p>P-Element</p>
	<ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f" [removable]="true"
		(removed)="onRemove(f)">
		<ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
	</ngx-dropzone-image-preview>
</div>

<div class="custom-dropzone" ngx-dropzone [accept]="'video/*'" (change)="onFilesAdded($event)">
	<ngx-dropzone-label>
		<div>
			<h2>My custom dropzone II</h2>
		</div>
	</ngx-dropzone-label>
	<ngx-dropzone-video-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f" [removable]="true"
		(removed)="onRemove(f)">
		<ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
	</ngx-dropzone-video-preview>
</div>

<div class="custom-dropzone" ngx-dropzone (change)="onFilesAdded($event)">
	<ngx-dropzone-label>
		<div>
			<h2>My custom dropzone III</h2>
		</div>
	</ngx-dropzone-label>
	<ngx-dropzone-preview *ngFor="let f of files" [file]="f" [removable]="true" (removed)="onRemove(f)">
		<ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
	</ngx-dropzone-preview>
</div>
