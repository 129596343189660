<div class="modal-header">
  <h4 class="modal-title pull-left">Create refresh token.</h4>
  <button type="button" class="btn-close pull-right" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <!-- <form class="tab-content"> -->
  <p>This is your refresh token. Please copy it to a secure place, you will not be presented with it again. If you lose
    it, you will need to generate a new one.
  </p>
  <form class="tab-content" novalidate [formGroup]="formGroup" (ngSubmit)="onSubmit($event)">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="refreshToken" class="mb-2">Refresh token</label>
          <input type="text" class="form-control" id="refreshToken" formControlName="refreshToken"
            aria-describedby="refreshTokenHelp" formControlName="refreshToken" [readonly]="'true'">
        </div>
      </div>
    </div>
  </form>
</div>