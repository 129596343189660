<div *ngIf="pipeline.remoteIdentityId != null" id="itemLocation">
  <h5 class="text-muted mb-1" id="remoteIdentityName">
    <a [routerLink]="['/identities']" [queryParams]="{ identityId: pipeline.remoteIdentityId}" [tooltip]="pipeline.remoteIdentityName">
      {{ pipeline.remoteIdentityName | truncate:[17, '...'] }}
    </a>
    <ng-container *ngIf="identity != null">    
      <span class="badge" [ngClass]="identity.invalidIdentity === 0 ? 'bg-success' : 'bg-danger'">
        {{ identity.invalidIdentity === 0 ? 'Authorized' : 'Unauthorized'}}
      </span>
    </ng-container>
  </h5>
</div>