<!-- Image -->
<div class="avatar avatar-sm card-avatar">
  <img src="{{integration.logoPath}}" class="avatar-img rounded" alt="..."
    (error)="imageServiceService.handleMissingImage($event)">
</div>
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader"> Profiles </h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline"> Selecting Your {{ integration | integrationFullname }} Profile </p>
<!-- Divider -->
<hr class="mb-4">
<!-- Heading-->
<h4 class="mt-2 mb-2"> Select A {{integration.name}} Profile </h4>
<ol>

  <li class="text-muted mb-2">
    The <code>Selling Parter ID</code> reflects the merchant SP-API identifier. The <code>Names</code>,
    <code>Country Codes</code>, <code>Default Languages</code>, and <code>Default Currencies</code> are supplied
    directly by the API. These reflect settings on your account at Amazon.
  </li>

  <li class="text-muted mb-2"> The listed profile(s) reflect the permissions associated with the
    a seller account authorizations. The profile list is dynamically generated based on the response
    from the API. If you do not see a seller or vendor profile listed, you need to verify that the
    user has the correct access permissions. </li>


  <li class="text-muted mb-2">
    Sellers who operate in more than one marketplace (i.e., Amazon.com, Amazon.co.uk, Amazon.co.jp) have one
    profile for each each marketplace, <a
      href="https://docs.openbridge.com/en/articles/5013964-amazon-multi-marketplace-seller-accounts" rel="noopener"
      target="_blank">unless they are configured as multi-marketplace accounts</a>. If you see multiple country codes
    listed (i.e., <code>Country Codes: MX, CA, CA, BR, US, MX, US, US</code>) the seller likely is multi-marketplace
    enabled for the account. </li>

  <li class="text-muted mb-2">
    In the event you do not see profile information, registering in the <a href="https://brandservices.amazon.com/"
      rel="noopener" target="_blank">Amazon Brand Registry</a> is required.
  </li>

</ol>