import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Integration } from 'src/app/shared/models/integration.model';

@Component({
  selector: 'app-filter-form',
  templateUrl: './filter-form.component.html',
  styleUrls: ['./filter-form.component.scss']
})
export class FilterFormComponent implements OnInit {
  @Input() integrations: Integration[] = [];
  @Output() filteredIntegrations = new EventEmitter<Integration[]>();
  filterSearch = new UntypedFormControl('');
  // @Output() updatedPageSize = new EventEmitter<number>();
  // pageSize = 10;

  constructor() { }

  ngOnInit(): void {
    this.processFilteredText(this.filterSearch.value);
    // this.filteredIntegrations.emit([...this.integrations]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.processFilteredText(this.filterSearch.value);
    // this.filteredIntegrations.emit([...this.integrations]);
  }

  processFilteredText(filterText: string): void {

    if (filterText === '') {
      this.filteredIntegrations.emit([...this.integrations]);
    }
    else {
      const filteredIntegrations: Integration[] = [];

      this.integrations.forEach((val, idx) => {
        if (val.name.toLowerCase().includes(filterText.toLowerCase())) {
          filteredIntegrations.push(val);
        }
        else if (val.brand.toLowerCase().includes(filterText.toLowerCase())) {
          filteredIntegrations.push(val);
        }
        else if (val.tagLine.toLowerCase().includes(filterText.toLowerCase())) {
          filteredIntegrations.push(val);
        }
        else if (val.keywords && val?.keywords?.includes(filterText.toLowerCase())) {
          filteredIntegrations.push(val);
        }
      });

      this.filteredIntegrations.emit([...filteredIntegrations]);
    }
  }

  // updatePageEntries(pageSize): void {
  //   this.updatedPageSize.emit(pageSize);
  // }

}
