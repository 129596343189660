<div>
   <!-- Divider -->
   <hr class="my-5">
   <h3 class="mb-2">
     Select Datasets
   </h3>
  <!-- Info -->
  <p class="text-muted">
    Below is a list of available <strong>{{integration.name}}</strong> datasets for your request. Choose the ones you're most interested in. As you select datasets, keep an eye on the Load Index, which reflects the potential impact on <strong>{{integration.name}}</strong> API performance. If you need more details, such as dataset descriptions or schemas, you can consult the 
    <a href="https://chatgpt.com/g/g-Sg4qP7r3v-openbridge-data-copilot" rel="noopener" target="_blank" class="light-link">
      Openbridge AI Data Copilot
    </a> for assistance.
  </p>
  
  <!-- Checklist -->
  <div class="checklist my-3">
    <div class="row">
      <div class="col-12 col-md-6" *ngFor="let item of stageListData; let i = index">
        <app-product-table-checkbox 
          [inputData]="item" 
          [integration]="integration" 
          [templateEditMode]="templateEditMode" 
          [preSelectedStageList]="preSelectedStageList"
          (selectedStageList)="selectedStageList($event)"
          (populateTemplateEditInfo)="populateTemplateEditInfo($event)"
          >
        </app-product-table-checkbox>
      </div>
    </div>
  </div>
  <!-- Checklist End-->
  
  
  <div class="row align-items-center g-0 my-5">
    <div class="col-12">
      <!-- Warning -->
      <div class="card bg-light border">
        <div class="card-body">
  
          <div class="row align-items-center">
            <div class="col">
              <!-- Heading -->
              <h4 class="mb-2">
                <i class="me-2 fa-duotone fa-clock"></i> Important: API Load Index
              </h4>
  
              <!-- Text -->
              <p class="small text-muted">
                The Load Index provides an estimate of the <strong>{{integration.name}}</strong> API load based on the number of datasets selected.  The Load Index is based limits set by the <strong>{{integration.name}}</strong> API, not Openbridge. Different API have different limits. By showing the expected <strong>{{integration.name}}</strong> API load level, the Load Index allows users to gauge the impact of the selected datasets. 
                
                Learn more about <a href="https://docs.openbridge.com/en/articles/9824142-api-load-index-and-dataset-selection-guide" rel="noopener" target="_blank" class="light-link">
                  Load Index and Dataset Selection.
                </a>
              </p>
              <p class="small text-muted mb-0">
                Normal Load Index values (green) indicate normal,  smooth operation, while higher values (yellow, orange, and red) suggest increasing demand, longer processing times, or  throttling.
              </p>
  
              <!-- PROGRESS METER -->
              <div class="row align-items-center g-0 mt-3">
  
                <div class="col-auto">
  
                  <!-- Value -->
                  <div class="small me-2">
                    Load Index
                  </div>
  
                </div>
  
                <div class="col">
                  <!-- Progress -->
                  <div class="progress progress-sm">
                    <div class="progress-bar" [style.width.%]="getProgressPercentage()" [ngClass]="{
                      'bg-danger': totalScore <= 25,
                      'bg-warning': totalScore > 25 && totalScore <= 50,
                      'bg-info': totalScore > 50 && totalScore <= 75,
                      'bg-success': totalScore > 75
                    }" role="progressbar" aria-valuemin="0" aria-valuemax="100">
                      <!-- {{ getProgressPercentage() }}% -->
                    </div>
                  </div>
  
                </div>
  
              </div>
              <!-- PROGRESS METER END-->
  
              <div class="d-flex flex-wrap">
                
              </div>
              <!-- color scheme -->
              <div class="row">
                <div class="col-12">
                  <div class="mt-3">
                    <div class="d-flex flex-wrap align-items-center">
                      <ng-container *ngFor="let item of colorScheme">
                        <div class="d-flex align-items-center me-3">
                          <div [ngClass]="item.color" class="color-box me-2"></div>
                          <div class="small text-muted">{{ item.label }}</div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
              <!-- color scheme end-->
  
            </div>
  
            <!-- Score Board -->
            <div class="col-auto">
              <div class="avatar avatar-xxl">
                <span class="avatar-title rounded" id="scoreBoard" [ngClass]="{
                  'bg-gray': totalScore === null,
                  'bg-danger': totalScore <= 25,
                  'bg-warning': totalScore > 25 && totalScore <= 50,
                  'bg-info': totalScore > 50 && totalScore <= 75,
                  'bg-success': totalScore > 75
                }">
                <ng-container *ngIf="totalScore">
                  {{totalScore}}
                </ng-container>
                </span>
              </div>
            </div>
            <!-- Score Board end-->
          </div>
        </div>
      </div>
      <!-- Warning end-->
  
    </div>
  </div>
</div>