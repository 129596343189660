import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {
  // default page size
  private pageSize: number = 10;

  constructor() { }

  getPageSize(): number {  
    return this.pageSize;
  }

  setPageSize(size: number): void {
    this.pageSize = size;
  }

  getCurrentPage(offset: number, limit: number): number {
    return Math.floor(offset / limit) + 1;
  }

  getTotalPages(limit: number, size: number): number {
    return Math.ceil(Math.max(size, 1) / Math.max(limit, 1));
  }

  // isValidPageNumber(page: number, totalPages: number): boolean {
  //   return page > 0 && page <= totalPages;
  // }

}
