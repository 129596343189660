import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Integration } from '../../models/integration.model';

@Component({
  selector: 'app-product-table-selector',
  templateUrl: './product-table-selector.component.html',
  styleUrls: ['./product-table-selector.component.scss']
})
export class ProductTableSelectorComponent implements OnInit, OnChanges {
  @Input() integration: Integration;
  @Input() availableStages: any = [];
  @Input() stageListData: any = [];
  // make sure to initialize preSelectedStageList as null 
  @Input() preSelectedStageList: any[] | null = null;
  @Input() templateEditMode: boolean = false;
  @Output() populateStageList = new EventEmitter<any[]>();
  @Output() templateEditData = new EventEmitter<any[]>();

  selectedStageData: any = [];

  numberOfPayloads: number = 0;
  maxPayloadSize: number = 10;
  totalScore: number = null;
  colorScheme = [
    { color: 'bg-success', label: 'Normal' },
    { color: 'bg-info', label: 'Low' },
    { color: 'bg-warning', label: 'High' },
    { color: 'bg-danger', label: 'Severe' }
  ];

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  async ngOnInit(): Promise<void> {

    const integrationKeys = Object.keys(this.integration);
    if(integrationKeys.includes('excludeTablesFromSelection')) {
      this.stageListData = this.excludeDataTables(this.stageListData, this.integration.excludeTablesFromSelection);
    }

    // Initialize selectedItems with pre-selected items
    this.maxPayloadSize = this.stageListData.length;
    this.calculateScore();
  }

  ngOnChanges(): void {
    this.maxPayloadSize = this.stageListData.length;
    this.calculateScore();
  }

  getProgressPercentage(): number {
    const progress = (this.numberOfPayloads / this.maxPayloadSize) * 100;
    return Math.min(progress, 100); // Ensure progress does not exceed 100%
  }

  selectedStageList(data: any): void {
    if (data.isSelected) {
      // Add the item to the array if it doesn't already exist
      const exists = this.selectedStageData.some(item => item.stageId === data.stageId);
      if (!exists) {
        this.selectedStageData.push(data);
      }
    } else {
      // Remove the item from the fresh array if it exists
      this.selectedStageData = this.selectedStageData.filter(item => item.stageId !== data.stageId);
    }

    this.numberOfPayloads = this.selectedStageData.length;

    this.calculateScore();
    
    // Emit the updated array to the parent component
    this.populateStageList.emit(this.selectedStageData);
    this.changeDetectorRef.detectChanges();

  }

  populateTemplateEditInfo(data: any): void {
    this.templateEditData.emit(data);
  }

  calculateScore(): void {
    const productWeight = this.integration.tableWeight;
    const numberOfProductTables = this.stageListData.length;
    const numberOfSelectedTables = this.selectedStageData.length;

    const productTableWeight = ((100 / numberOfProductTables) * productWeight);
    const selectedProductTableWeight = (productTableWeight * numberOfSelectedTables);
    const score = 100 - selectedProductTableWeight;
    // Ensure Score is not negative    
    this.totalScore = Math.max(Math.round(score), 0);
    // this.totalScore = Math.max(Number(score.toFixed(2)), 0);
  }

  excludeDataTables(data, excludeList: any): string[] {
    return data.filter(item => !excludeList.includes(item.attributes.name));
  }  
}
