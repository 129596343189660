import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: []
})
export class BreadcrumbComponent implements OnInit {
  @Input() selectedPrefixes: any[] = [];
  @Input() bucketName: string = '';

  @Output() updatedListOfSelectedPrefixes: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  navigatePrefix(data) {

    if (data === 'root') {
      // If the bucket name then sent empty array 
      this.updatedListOfSelectedPrefixes.emit([]);
    } else {
      const index = this.selectedPrefixes.indexOf(data);
      if (index !== -1) {
        this.updatedListOfSelectedPrefixes.emit(this.selectedPrefixes.slice(0, index + 1));
        return this.selectedPrefixes.slice(0, index + 1);
      } 
      // If the clicked Item is not found, return the original array. it won't happen but added for check
      this.updatedListOfSelectedPrefixes.emit(this.selectedPrefixes);
    }
  }

}
