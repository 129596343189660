import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { modalsClose, modalsOpen } from 'src/app/core/store/actions/modals.action';
import { AppState } from 'src/app/core/store/reducers';
import { BaseWizardService } from 'src/app/modules/wizards/base-wizard.service';
import { HelpDrawerService } from 'src/app/shared/components/help-drawer/help-drawer.service';
import { ModalRefreshTokenCreateComponent } from 'src/app/shared/components/modals/modal-refresh-token-create/modal-refresh-token-create.component';
import { ModalRefreshTokenDisplayComponent } from 'src/app/shared/components/modals/modal-refresh-token-display/modal-refresh-token-display.component';
import { ModalRefreshTokenRevokeComponent } from 'src/app/shared/components/modals/modal-refresh-token-revoke/modal-refresh-token-revoke.component';
import { errorRefreshTokenCreation } from 'src/app/shared/constants/flash-notifications';
// tslint:disable-next-line: max-line-length
import { modalMessageRefreshTokenCreation, modalMessageRefreshTokenRevoke, modalTitleRefreshTokenCreation, modalTitleRefreshTokenRevoke } from 'src/app/shared/constants/modal-titles-and-messages';
import { ApiManagementService } from '../../api-management.service';
import { IntercomService } from 'src/app/shared/services/intercom.service';

@Component({
  selector: 'app-refresh-token',
  templateUrl: './refresh-token.component.html',
  styleUrls: ['./refresh-token.component.scss']
})
export class RefreshTokenComponent implements OnInit {
  private lastOpened: BsDropdownDirective | null = null;

  refreshToken: string = '';
  formGroup: UntypedFormGroup;
  listClasses: string[] = ['tab-pane', 'fade', 'show', 'active'];
  refreshTokens: any[] = [];
  bsModalRef: BsModalRef;
  sortState = {
    key: null,
    order: 'asc'
  };

  constructor(
    private apiManagementService: ApiManagementService,
    private drawerService: HelpDrawerService,
    private modalService: BsModalService,
    private store$: Store<AppState>,
    private baseWizardService: BaseWizardService,
    private intercomService: IntercomService
  ) { }

  async ngOnInit(): Promise<void> {
    this.generateForm();
    await this.getTokens();
  }

  async getTokens(): Promise<void> {
    const response = await this.apiManagementService.getRefreshTokens();
    this.refreshTokens = [
      ...response
    ];
  }

  createToken(): void {
    this.bsModalRef = this.modalService.show(ModalRefreshTokenCreateComponent);
    this.bsModalRef.content.onClose$.subscribe(async (result) => {
      try {
        const resultKeys = Object.keys(result)

        if (resultKeys.includes('refreshTokenName')) {

          this.store$.dispatch(modalsOpen({
            modalType: 'progress',
            title: modalTitleRefreshTokenCreation,
            message: modalMessageRefreshTokenCreation,
            progress: 50
          }));

          const refreshToken = await this.apiManagementService.createRefreshToken(result.refreshTokenName);

          const modalState: ModalOptions = {
            initialState: {
              refreshToken: refreshToken
            }
          };

          this.bsModalRef = this.modalService.show(ModalRefreshTokenDisplayComponent, modalState);

          await this.getTokens();

          // intercom token creation event
          this.intercomService.dispatchIntercomRefreshTokenEvent(result.refreshTokenName, 'create');

          this.store$.dispatch(modalsClose());
        }

        return;


      }
      catch (error) {
        this.store$.dispatch(modalsClose());
        this.baseWizardService.doProcessErrorWithDetails(errorRefreshTokenCreation, error.error);
      }
    });
  }

  async revokeAccess(token: any): Promise<boolean> {
    this.bsModalRef = this.modalService.show(ModalRefreshTokenRevokeComponent);
    this.bsModalRef.content.onClose$.subscribe(async (result) => {
      
      if (result.revokeToken === true) {
        try {
          this.store$.dispatch(modalsOpen({
            modalType: 'progress',
            title: modalTitleRefreshTokenRevoke,
            message: modalMessageRefreshTokenRevoke,
            progress: 50
          }));
          await this.apiManagementService.deleteRefreshToken(token.id);
          await this.getTokens();

          // intercom token revoke event
          this.intercomService.dispatchIntercomRefreshTokenEvent(token.attributes.name, 'revoke');

          this.store$.dispatch(modalsClose());
        }
        catch (error) {
          this.store$.dispatch(modalsClose());
          this.baseWizardService.doProcessErrorWithDetails(errorRefreshTokenCreation, error.error);
        }
      }
    });

    return false;
  }

  // Generate the form.
  protected generateForm(): void {
    this.formGroup = new UntypedFormGroup({
      refreshToken: new UntypedFormControl(this.refreshToken)
    });
  }

  toggleHelpDrawer(helpComponentIdentifier: string): boolean {
    this.drawerService.toggleDrawer(helpComponentIdentifier);
    return false;
  }

  bubbleSort(key: string, order: string = 'asc'): void {

    const sortedTokens = [...this.refreshTokens];
    let swapped: boolean;

    do {
      swapped = false;

      for (let idx = 0; idx < (sortedTokens.length - 1); idx++) {
        if (sortedTokens[idx][key] > sortedTokens[idx + 1][key]) {
          const temp = sortedTokens[idx];
          sortedTokens[idx] = sortedTokens[(idx + 1)];
          sortedTokens[(idx + 1)] = temp;
          swapped = true;
        }
      }

    } while (swapped);

    if (order === 'asc') {
      this.refreshTokens = [...sortedTokens];
    }
    else if (order === 'desc') {
      this.refreshTokens = [...sortedTokens.reverse()];
    }
  }

  sort(filterKey: string): boolean {
    if (this.sortState.key === filterKey) {
      if (this.sortState.order === 'asc') {
        this.sortState.order = 'desc';
      }
      else {
        this.sortState.order = 'asc';
      }
    }
    else {
      this.sortState = {
        key: filterKey,
        order: 'asc'
      };
    }
    this.bubbleSort(this.sortState.key, this.sortState.order);
    return false;
  }

  // prevent dropdown click loop
  clickDropdown(event: Event, dropdown: BsDropdownDirective): void {
    event.stopPropagation();

    if (!!this.lastOpened && this.lastOpened !== dropdown) {
      this.lastOpened.hide();
    }
    this.lastOpened = dropdown;
  }

}
