import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccMappingTypes } from 'src/app/shared/models/accmapping.interface';
import { environment } from 'src/environments/environment';
import { observableToPromise } from '../functions/utility';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {

  constructor(
    private http: HttpClient
  ) { }

  async encryptAccountMappingPayload(payload: AccMappingTypes): Promise<any> {
    const accMappingEncryption = environment.openbridgeApiUris.service + '/service/encrypt/accmapping_enc';
    return await observableToPromise(this.http.post(accMappingEncryption, payload));
  }

  async encryptStringList(payloadObject: {}): Promise<any> {

    const payload: StringsEncryptRequest = {
      data: {
        attributes: payloadObject
      }
    };

    const encryptUri = environment.openbridgeApiUris.service + '/service/encrypt/encrypt';
    const encryptResponse = await observableToPromise(this.http.post(encryptUri, payload));
    return encryptResponse;
  }
}

interface StringsEncryptRequest {
  data: {
    attributes: {};
  };
}

interface StringsDecryptRequest {
  'data': {
    attributes: {};
  };
}