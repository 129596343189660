<div class="modal-header">
  <h4 class="modal-title pull-left">Invite member</h4>
  <button type="button" class="btn-close pull-right" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="tab-content" novalidate [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="email" class="mb-2">Email to invite</label>
          <input type="text" class="form-control" id="email" aria-describedby="emailHelp" placeholder="Email Address"
            [ngClass]="{
              'is-invalid': formGroup.controls.email.invalid && formGroup.controls.email.touched,
              'is-valid': formGroup.controls.email.valid && formGroup.controls.email.touched
            }" formControlName="email">
          <div
            *ngIf="formGroup.controls.email.invalid && (formGroup.controls.email.dirty || formGroup.controls.email.touched)"
            class="invalid-feedback">
            A valid hostname or IP Address is required.
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <button type="submit" class="btn btn-primary">Send Invitation</button>
          <!-- <button type="button" class="btn btn-danger m-2" (click)="cancel()">Cancel</button> -->
        </div>
      </div>
    </div>
  </form>
</div>