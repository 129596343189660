<!-- Image -->
<div class="avatar avatar-sm card-avatar">
  <img src="{{integration.logoPath}}" class="avatar-img rounded" alt="..."
    (error)="imageServiceService.handleMissingImage($event)" />
</div>
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader">Getting Started</h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline">
  Fully-automated {{ integration | integrationFullname }} data pipeline
</p>
<!-- Divider -->
<hr class="mb-4" />
<!-- Heading-->
<h4 class="mt-2 mb-2">
  Getting ready for automated {{ integration | integrationFullname }} data
  pipelines
</h4>
<ol>
  <li class="text-muted mb-2">
    Openbridge is an approved Amazon SP-API developer. For more information on
    Amazon developers see
    <a href="https://developer.amazonservices.com/" rel="noopener" target="_blank">Amazon Selling Partners</a>
    and more detailed technical information visit
    <a href="https://developer-docs.amazon.com/sp-api/" rel="noopener" target="_blank">API documentation.</a>
  </li>
  <li class="text-muted mb-2">
    The authorization model for {{ integration | integrationFullname }} is based
    on Login with Amazon. If you already have a Seller Central user account,
    make sure you have the correct permissions to grant access to your account
    to collect performance data.
  </li>

  <li class="text-muted mb-2">
    See our Amazon Selling Partner
    <a href="https://docs.openbridge.com/en/?q=amazon+selling-partner" rel="noopener" target="_blank">help docs</a>
    for guides, tips, and troubleshooting.
  </li>
</ol>