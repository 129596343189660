<!-- modal header -->
<div class="modal-header">
  <h2 class="modal-title pull-left">{{ editData ? 'Edit' : 'Create' }} Data Report Template</h2>
  <button type="button" class="btn-close pull-right" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<!-- modal header close -->

<!-- modal body -->
<div class="modal-body">

  <p class="text-muted">
    Configure a dataset based on the relevant <strong>{{ integration | integrationFullname }}</strong> report type and dimensions that matter most. Different combinations of reports and dimensions tailor the data Amazon delivers. The configured template will be saved and can be reused across multiple subscriptions to ensure standardized data analysis across different projects or clients. 
  </p>
  <form class="tab-content" novalidate [formGroup]="formGroup">
    <div class="row">
      <div class="col">        
        <!-- Report Type Selection -->
        <div class="form-group">
          <label for="reportType" class="mb-2">Report Type</label>
          <select class="form-control" id="reportType" formControlName="reportType" name="reportType" (change)="onReportTypeChange()"
            formControlName="reportType" [ngClass]="{
              'is-invalid': formGroup.controls.reportType.invalid && formGroup.controls.reportType.touched,
              'is-valid': formGroup.controls.reportType.valid && formGroup.controls.reportType.touched
            }">
            <option disabled hidden [value]="null">Select Report Type</option> <!-- Placeholder -->
            <option *ngFor="let report of reports" [value]="report.reportType">{{ report.reportTypeName }}</option>
          </select>
          <p class="small text-muted mt-2 mb-3">
            <span [tooltip]="selectedReport?.reportTypeLabel">
              {{ selectedReport?.reportTypeLabel | truncate:[60, '...'] }}
            </span>
          </p>
          <div
            *ngIf="formGroup.controls.reportType.invalid && (formGroup.controls.reportType.dirty || formGroup.controls.reportType.touched)"
            class="invalid-feedback">
            A valid Report Type is required.
          </div>
        </div>

        <!-- Dimensions Checklist (Dynamically shown based on selected report) -->
        <div class="checklist mb-3" *ngIf="selectedDimensions.controls.length > 0" formArrayName="selectedDimensions">
          <div class="mb-2">Dimensions</div> 
          <div class="row">
            <div class="col-12" *ngFor="let dimensionCtrl of selectedDimensions.controls; let i = index">
              <div class="form-check mb-3">
                <!-- Bind to selectedReport variable -->
                <input class="form-check-input" type="checkbox" 
                       [id]="selectedReport?.dimensions[i]?.dimension" 
                       [formControlName]="i">
                <label class="form-check-label" [for]="selectedReport?.dimensions[i]?.dimension">
                  {{ selectedReport?.dimensions[i]?.dimensionName }}
                </label>
                <p class="small text-muted mb-1">
                  <span [tooltip]="selectedReport?.dimensions[i]?.dimensionLabel">
                    {{ selectedReport?.dimensions[i]?.dimensionLabel | truncate:[60, '...'] }}
                  </span>
                </p>
                
              </div>
            </div>
          </div>

          <!-- Display validation error if no dimensions are selected -->
          <div *ngIf="formGroup.controls.selectedDimensions.invalid && 
              (formGroup.controls.selectedDimensions.dirty || formGroup.controls.selectedDimensions.touched)" 
              class="custom-invalid-feedback">
            Please select at least one dimension.
          </div>
        </div>
        
      </div>
    </div>
    <div class="row" *ngIf="formGroup.controls.reportType.value && selectedDimensions.controls.length > 0">
      <div class="col">
        <!-- Template Name -->
        <div class="form-group">
          <label for="templateName" class="mb-2">Template Name</label>
          <input type="text" class="form-control" id="templateName" formControlName="templateName"
            placeholder="Template name" [ngClass]="{
                'is-invalid': formGroup.controls.templateName.invalid && formGroup.controls.templateName.touched,
                'is-valid': formGroup.controls.templateName.valid && formGroup.controls.templateName.touched
              }">
          <div *ngIf="formGroup.controls.templateName.invalid && (formGroup.controls.templateName.dirty || formGroup.controls.templateName.touched)"
            class="invalid-feedback">
            A valid Template name is required.
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<!-- modal body close -->

<!-- modal footer -->
<div class="modal-footer">
  <button *ngIf="this.editData" type="button" class="btn btn-danger" href (click)="deleteTemplate()">Delete</button>
  <button type="button" (click)="onSubmit()" id="submitBtn" class="btn btn-primary">
    Save
  </button>
</div>
<!-- modal footer close-->