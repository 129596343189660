import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ImageServiceService } from 'src/app/shared/services/image-service.service';

@Component({
  selector: '[appCheckListCard]',
  templateUrl: './check-list-card.component.html',
  styleUrls: ['./check-list-card.component.scss']
})
export class CheckListCardComponent implements OnChanges, OnInit {
  @Input() allSelected: boolean;
  @Input() cardData;
  @Input() selectedCards: string[];
  @Output() updateAdvertiserSelection = new EventEmitter<string>();

  // tslint:disable-next-line: no-inferrable-types
  selected: boolean = false;

  constructor(
    public imageServiceService: ImageServiceService,
    private changeDetection: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    if (this.selectedCards instanceof Array && this.selectedCards.includes(this.cardData.id)) {
      this.selected = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // If we all selected is toggled we set the current selection to that.
    if(changes.allSelected && (changes.allSelected)) {
      this.selected = this.allSelected;
    } else
    // If selected card changes
    if (changes.selectedCards) {
      if (changes.selectedCards.currentValue !== null && changes.selectedCards.currentValue.includes(this.cardData.id)) {
        this.selected = true;
      } else {
        this.selected = false;
      }
    }
  }

  toggleSelection(): void {
    this.updateAdvertiserSelection.emit(this.cardData.id);
  }

}
