import { Pipe, PipeTransform } from '@angular/core';
import { IntegrationService } from '../services/integration.service';

@Pipe({
  name: 'productSupportsHistory'
})

// checks if product is eligible to request history

export class ProductSupportsHistoryPipe implements PipeTransform {


  constructor(
    private integrationService: IntegrationService
  ) {
  }

  productSupportsHistory(value: number): boolean {
    const productSupportsHistory: boolean = this.integrationService.canRequestHistory(value);    
    return productSupportsHistory;
  }

  transform(value: number): boolean {
    return this.productSupportsHistory(value);
  }

}
