import { JobsService } from './../../../../modules/jobs/jobs.service';
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-process-job-request',
  templateUrl: './modal-process-job-request.component.html',
  styleUrls: []
})
export class ModalProcessJobRequestComponent implements OnInit {
  title: string;
  closeBtnName: string;
  completePercent: number;
  state: string;
  widthStyle = 'width: 0%';
  states: string[] = [
    'job-process-initialize',
    'job-process-complete'
  ];
  statePercent: number = (100 / this.states.length);

  constructor(
    public bsModalRef: BsModalRef,
    private jobsService: JobsService
  ) { }

  ngOnInit(): void {
    this.completePercent = this.statePercent;
    this.updateWidthStyle(this.completePercent.toString());
    this.jobsService.jobRequestState$.subscribe(response => {
      if (this.state !== response.state) {
        this.state = response.state;
        this.completePercent += this.statePercent;
        this.updateWidthStyle(this.completePercent.toString());
      }
    });
  }

  private updateWidthStyle(percent: string): void {
    this.widthStyle = 'width: ' + percent + '%';
  }

}
