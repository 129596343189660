export const reportTypes = [
  {
    reportType: 'audience',
    reportTypeName: 'Audience',
    reportTypeLabel: '',
    dimensions: [
      {
        dimension: 'order',
        dimensionName: 'Order',
        dimensionLabel: ''
      },
      {
        dimension: 'lineItem',
        dimensionName: 'Line Item',
        dimensionLabel: ''
      }
    ]
  },
  {
    reportType: 'audio_and_video',
    reportTypeName: 'Audio and Video',
    reportTypeLabel: '',
    dimensions: [
      {
        dimension: 'campaign',
        dimensionName: 'Campaign',
        dimensionLabel: ''
      },
      {
        dimension: 'ad',
        dimensionName: 'Ad',
        dimensionLabel: ''
      },
      {
        dimension: 'creative',
        dimensionName: 'Creative',
        dimensionLabel: ''
      },
      {
        dimension: 'supplySource',
        dimensionName: 'Supply Source',
        dimensionLabel: ''
      },
      {
        dimension: 'content',
        dimensionName: 'Content',
        dimensionLabel: ''
      }
    ]
  },
  {
    reportType: 'campaign',
    reportTypeName: 'Campaign',
    reportTypeLabel: '',
    dimensions: [
      {
        dimension: 'campaign',
        dimensionName: 'Campaign',
        dimensionLabel: ''
      },
      {
        dimension: 'ad',
        dimensionName: 'Ad',
        dimensionLabel: ''
      },
      {
        dimension: 'creative',
        dimensionName: 'Creative',
        dimensionLabel: ''
      }
    ]
  },
  {
    reportType: 'geo',
    reportTypeName: 'Geo',
    reportTypeLabel: '',
    dimensions: [
      {
        dimension: 'order',
        dimensionName: 'Order',
        dimensionLabel: ''
      },
      {
        dimension: 'lineItem',
        dimensionName: 'Line Item',
        dimensionLabel: ''
      },
      {
        dimension: 'country',
        dimensionName: 'Country',
        dimensionLabel: ''
      },
      {
        dimension: 'region',
        dimensionName: 'Region',
        dimensionLabel: ''
      },
      {
        dimension: 'city',
        dimensionName: 'City',
        dimensionLabel: ''
      },
      {
        dimension: 'postalCode',
        dimensionName: 'Postal Code',
        dimensionLabel: ''
      },
      {
        dimension: 'dma',
        dimensionName: 'Dma',
        dimensionLabel: ''
      }
    ]
  },
  {
    reportType: 'inventory',
    reportTypeName: 'Inventory',
    reportTypeLabel: '',
    dimensions: [
      {
        dimension: 'supplySource',
        dimensionName: 'Supply Source',
        dimensionLabel: ''
      },
      {
        dimension: 'site',
        dimensionName: 'Site',
        dimensionLabel: ''
      },
      {
        dimension: 'campaign',
        dimensionName: 'Campaign',
        dimensionLabel: ''
      },
      {
        dimension: 'creative',
        dimensionName: 'Creative',
        dimensionLabel: ''
      },
      {
        dimension: 'ad',
        dimensionName: 'Ad',
        dimensionLabel: ''
      },
      {
        dimension: 'placementSize',
        dimensionName: 'Placement Size',
        dimensionLabel: ''
      },
      {
        dimension: 'placement',
        dimensionName: 'Placement',
        dimensionLabel: ''
      },
      {
        dimension: 'deal',
        dimensionName: 'Deal',
        dimensionLabel: ''
      }
    ]
  },
  {
    reportType: 'product',
    reportTypeName: 'Product',
    reportTypeLabel: '',
    dimensions: [
      {
        dimension: 'order',
        dimensionName: 'Order',
        dimensionLabel: ''
      },
      {
        dimension: 'lineItem',
        dimensionName: 'Line Item',
        dimensionLabel: ''
      }
    ]
  },
  {
    reportType: 'tech',
    reportTypeName: 'Tech',
    reportTypeLabel: '',
    dimensions: [
      {
        dimension: 'order',
        dimensionName: 'Order',
        dimensionLabel: ''
      },
      {
        dimension: 'lineItem',
        dimensionName: 'Line Item',
        dimensionLabel: ''
      },
      {
        dimension: 'operatingSystem',
        dimensionName: 'Operating System',
        dimensionLabel: ''
      },
      {
        dimension: 'browserType',
        dimensionName: 'Browser Type',
        dimensionLabel: ''
      },
      {
        dimension: 'browserVersion',
        dimensionName: 'Browser Version',
        dimensionLabel: ''
      },
      {
        dimension: 'deviceType',
        dimensionName: 'Device Type',
        dimensionLabel: ''
      },
      {
        dimension: 'environmentType',
        dimensionName: 'Environment Type',
        dimensionLabel: ''
      }
    ]
  }
];