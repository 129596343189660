import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, range } from 'rxjs';
import { map, filter, toArray } from 'rxjs/operators';

@Component({
  selector: 'app-filter-pagination',
  templateUrl: './filter-pagination.component.html',
  styleUrls: ['./filter-pagination.component.scss']
})
export class FilterPaginationComponent implements OnInit {
  @Input() limit: number; // record per page
  @Input() currentPage: number;
  @Input() totalPages: number;
  @Output() pageChange = new EventEmitter<any>();

  range = 3;
  pages: Observable<number[]>;

  constructor() { }

  ngOnInit(): void {
    this.getPages();
  }

  ngOnChanges(): void {
    this.getPages();
  }

  getPages(): void {
    this.pages = range(-this.range, this.range * 2 + 1)
      .pipe(
        map(index => this.currentPage + index),
        filter(page => this.isValidPageNumber(page, this.totalPages)),
        toArray()
      );
  }

  isValidPageNumber(page: number, totalPages: number): boolean {
    return page > 0 && page <= totalPages;
  }

  selectPage(page: number, event: any): void {
    event.preventDefault();
    if (this.isValidPageNumber(page, this.totalPages)) {
      this.pageChange.emit((page - 1) * this.limit);
    }
  }

  cancelEvent(event): void {
    event.preventDefault();
  }

}
